import {Formik} from 'formik';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {Loader} from '../../components/Loader';
import {useAuth} from '../../hooks/useAuth';
import {ErrorCodes} from '../../models/enums/errors';
import {RootState} from '../../redux';
import {isEmail, isPhoneNumber} from '../../utils/helper';
import LoginArea from '../LoginPage/components/LoginArea';
import './styles.scss';

import ForgotPasswordForm from './components/ForgotPasswordForm';

export const ForgotPassword = () => {
  const {
    isLoading,
    error,
    errorCode,
    forgotPasswordByPhoneRequestCode,
    forgotPasswordByEmailRequestCode,
    forgotPasswordRequestResetState,
  } = useAuth();
  const {t} = useTranslation('login');
  const {smsSent, emailSent} = useSelector((store: RootState) => store.user);
  const navigate = useNavigate();

  const formInitialValues = {
    phoneOrEmail: '',
  };

  const handlePress = (data: any) => {
    if (isEmail(data.phoneOrEmail)) {
      forgotPasswordByEmailRequestCode(data.phoneOrEmail);
    } else if (isPhoneNumber(data.phoneOrEmail)) {
      forgotPasswordByPhoneRequestCode(data.phoneOrEmail);
    }
  };

  useEffect(() => {
    forgotPasswordRequestResetState();
  }, []);

  useEffect(() => {
    if (smsSent || emailSent) {
      navigate('/verification-code');
    }
  }, [smsSent, emailSent]);

  return (
    <div data-testid={'forgotPassword-page'} className="forgotPassword-page">
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <LoginArea
          isOpacity={
            error &&
            errorCode !== ErrorCodes.userNotFound &&
            errorCode !== ErrorCodes.invalidCredentials &&
            errorCode !== ErrorCodes.invalidUserRole
          }>
          {!error ||
          !errorCode ||
          errorCode === ErrorCodes.userNotFound ||
          errorCode === ErrorCodes.invalidCredentials ||
          errorCode === ErrorCodes.invalidUserRole ? (
            <span className="title">{t('forgotPasswordTitle')}</span>
          ) : null}
          <div className="form-container">
            <Formik initialValues={formInitialValues} onSubmit={handlePress}>
              <ForgotPasswordForm />
            </Formik>
          </div>
        </LoginArea>
      </div>
    </div>
  );
};
export default ForgotPassword;
