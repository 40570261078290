import React from 'react';
import './styles.scss';

type Props = {
  text: string;
  customStyles?: object;
};
const OfferTag: React.FC<Props> = ({text, customStyles}) => {
  return (
    <div style={customStyles && customStyles} className="offer-tag">
      <span className="offer-tag-text">{text}</span>
    </div>
  );
};

export default OfferTag;
