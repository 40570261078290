import {combineReducers} from 'redux';
import uiReducer from './uiReducer';
import adReducer from './adReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import dealReducer from './dealReducer';
import profileReducer from './profileReducer';
import registerReducer from './registerReducer';
import businessReducer from './businessReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  deal: dealReducer,
  ad: adReducer,
  ui: uiReducer,
  register: registerReducer,
  profile: profileReducer,
  business: businessReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
