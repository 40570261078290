import {IRegisterBody} from '../../models/interfaces/register';
import {registerApi} from '../../services/api/registerAPI';
import {
  CLEAR_REGISTER_DATA,
  GET_BUSINESS_IDENTIFICATION_SUCCESS,
  GET_COMMUNITIES_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_FAIL,
  GET_REQUEST,
  SET_ERROR_CODE,
  SET_REGISTER_DATA_COORDENATES,
  SET_REGISTER_DATA_SUGGESTIONS,
  SET_REGISTER_DATA,
  SET_STEP,
  SUCCESS_REGISTER_DATA,
} from '../types/registerTypes';

export const setStep = (value: any) => ({
  type: SET_STEP,
  payload: value,
});

export const setRegisterData = (object: any) => ({
  type: SET_REGISTER_DATA,
  payload: object,
});

export const setRegisterDataCoordenates = (object: any) => ({
  type: SET_REGISTER_DATA_COORDENATES,
  payload: object,
});

export const setRegisterDataSuggestions = (object: any) => ({
  type: SET_REGISTER_DATA_SUGGESTIONS,
  payload: object,
});

export const getBusinessIdentificationSuccess = (data: any) => ({
  type: GET_BUSINESS_IDENTIFICATION_SUCCESS,
  payload: data,
});

export const getCountriesSuccess = (data: any) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: data,
});

export const getCommunitiesSuccess = (data: any) => ({
  type: GET_COMMUNITIES_SUCCESS,
  payload: data,
});

export const sendSuccessRegisterData = () => ({
  type: SUCCESS_REGISTER_DATA,
});

export const clearRegisterData = () => ({
  type: CLEAR_REGISTER_DATA,
});

export const getRequest = () => ({
  type: GET_REQUEST,
});

export const getFail = (data: any) => ({
  type: GET_FAIL,
  payload: data,
});

export const setErrorCode = (code: any) => ({
  type: SET_ERROR_CODE,
  payload: code,
});

// Business identification
export const getBusinessIdentification =
  (languageCode: string) => async (dispatch: any) => {
    dispatch(getRequest());
    try {
      const response: any = await registerApi.businessIdentification(
        languageCode,
      );
      if (response) {
        dispatch(getBusinessIdentificationSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getFail('Network Error'));
    }
  };

// Countries
export const getCountries = () => async (dispatch: any) => {
  dispatch(getRequest());
  try {
    const response: any = await registerApi.countries();
    if (response) {
      dispatch(getCountriesSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getFail('Network Error'));
  }
};

// Communities
export const getCommunities = () => async (dispatch: any) => {
  dispatch(getRequest());
  try {
    const response: any = await registerApi.communities();
    if (response) {
      dispatch(getCommunitiesSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getFail('Network Error'));
  }
};

// Send Register Data
export const sendRegisterData =
  (data: IRegisterBody) => async (dispatch: any) => {
    dispatch(getRequest());
    try {
      const response: any = await registerApi.sendRegisterData(data);
      if (response) {
        dispatch(getFail(''));
        dispatch(setErrorCode(''));
        dispatch(sendSuccessRegisterData());
      }
    } catch (error: any) {
      console.log(error);
      dispatch(getFail(error.message));
      dispatch(setErrorCode(error.errorCode));
    }
  };
