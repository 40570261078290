import React, {useEffect, useState} from 'react';
import BadgePopUp from '../../../../components/BadgePopUp';
import DefaultProfilePicture from '../../../../assets/images/DefaultProfilePicture.png';
import IconSimpleUploadImage from '../../../../components/IconSimpleUploadImage';
import {RootState} from '../../../../redux';
import {useLocation} from 'react-router';
import {EBadgeColors} from '../../../../models/enums/ui';
import {
  setHasErrors,
  setProfileData,
} from '../../../../redux/actions/profileActions';
import {useTranslation} from 'react-i18next';
import {createImageFile} from '../../../../utils/createImageFile';
import {useDispatch, useSelector} from 'react-redux';
import {ReactComponent as FaceIcon} from '../../../../assets/icons/happy-face.svg';
import './styles.scss';

const HeaderProfile = () => {
  //Hooks
  const {data, hasErrors, isPendingAuth} = useSelector(
    (state: RootState) => state.profile,
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const {t} = useTranslation();

  // Data
  const {pathname} = location;
  const params = pathname.split('/');
  const myBusinessPage = params[2];
  const userId = Number(params[3]);
  const isProfileEditionView = myBusinessPage && !isNaN(userId);

  // States
  const [profileImagePreview, setProfileImagePreview] = useState<string>(
    DefaultProfilePicture,
  );
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const [firstLoader, setFirstLoader] = useState<boolean>(true);

  // Effects
  useEffect(() => {
    setFirstLoader(false);
    if (data.userProfileImage && data.userProfileImage.length) {
      setProfileImagePreview(data.userProfileImage);
      getImageFile(data.userProfileImage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoader && data.userProfileImage) {
      setProfileImagePreview(data.userProfileImage);
    } else {
      setProfileImagePreview(DefaultProfilePicture);
    }
  }, [data.userProfileImage]);

  useEffect(() => {
    if (!firstLoader && data.profileImage) {
      setProfileImagePreview(data.profileImage);
    } else {
      setProfileImagePreview(DefaultProfilePicture);
    }
  }, [data.profileImage]);

  useEffect(() => {
    if (profilePicture) {
      const imageUrl = URL.createObjectURL(profilePicture);
      setProfileImagePreview(imageUrl);

      const urlPicture = profilePicture && URL.createObjectURL(profilePicture);

      if (isProfileEditionView) {
        dispatch(
          setProfileData({
            profileImage: urlPicture,
            profileImageUrl: urlPicture,
          }),
        );
      } else {
        dispatch(
          setProfileData({
            userProfileImage: urlPicture,
          }),
        );
        dispatch(
          setHasErrors({
            ...hasErrors,
            vendorInformation: false,
          }),
        );
      }
    } else {
      setProfileImagePreview(DefaultProfilePicture);
    }
  }, [profilePicture]);

  // Handlers - Functions
  const getImageFile = async (image: any) => {
    try {
      const imageFile = await createImageFile(image, 'profileImage');
      setProfilePicture(imageFile);
    } catch (e) {
      console.log('error');
    }
  };

  return (
    <div className="business-header-profile">
      <div className="info-business-wrapper">
        <div className="business-header-img">
          {isProfileEditionView && (
            <img
              src={data.profileImage || profileImagePreview}
              alt="img-profile"
            />
          )}

          {!isProfileEditionView && (
            <img
              src={data.userProfileImage || profileImagePreview}
              alt="img-profile"
            />
          )}
          <div className="icon-camera">
            <IconSimpleUploadImage onClick={setProfilePicture} />
          </div>
        </div>

        <div className="business-header-info">
          <span className="business-name">
            {isProfileEditionView ? data?.businessName : data.userFirstName}
          </span>
          <span className="business-email">
            {isProfileEditionView ? data?.contactEmail : data.userUsername}
          </span>
        </div>
      </div>

      <div className="badge-message-wrapper">
        {isPendingAuth && isProfileEditionView && (
          <BadgePopUp
            color={EBadgeColors.Blue}
            icon={<FaceIcon />}
            text={t('profilePage:badgeAuthorizationText')}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderProfile;
