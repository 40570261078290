import React, {useCallback} from 'react';
import {ETypeCommunication} from '../../models/enums/generals';
import {ITextFieldCustomStyles} from '../TextField';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {setCreateDealData} from '../../redux/actions/dealActions';
import CompleteTextField from '../CompleteTextField';
import FormField from '../FormField/FormField';

interface IProps {
  isValid?: boolean | null;
  setIsValid?: (isValid: boolean) => void;
  setValue: (value: string) => void;
  styles?: ITextFieldCustomStyles;
  value: string;
  typeCommunication: ETypeCommunication;
}

const FieldTitle: React.FC<IProps> = ({
  isValid,
  setIsValid,
  setValue,
  styles,
  value,
  typeCommunication,
}) => {
  const {t} = useTranslation('createDeal');
  const dispatch = useDispatch();

  const handleChangeValue = useCallback((value: string) => {
    setValue(value);
    if (typeCommunication === ETypeCommunication.Deal) {
      dispatch(setCreateDealData({title: value}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormField
      title={t('fieldTitleTitle')}
      description={t('fieldTitleDescription')}>
      <CompleteTextField
        customStyles={styles}
        hideLabel
        id={'title'}
        isValid={isValid}
        maxLength={65}
        placeholder={t('placeholderTitle')}
        setIsValid={setIsValid}
        setValue={handleChangeValue}
        value={value}
        withContentValidation
        withCounter
      />
    </FormField>
  );
};

export default FieldTitle;
