import React from 'react';
import './styles.scss';
import {IStepTitleProps} from './types';

const StepTitle: React.FC<IStepTitleProps> = ({title, subtitle}) => {
  
  return (
    <div className='create-account-title-step-parent'>
      <span className='create-account-title-step-title'>
        { title }
      </span>
      <span className='create-account-title-step-subtitle'>
        { subtitle }
      </span>
    </div>
  )
};

export default StepTitle;
