import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {ErrorTypes, StoreType} from '../../../../models/enums/registerForm';
import {Form, Formik, FormikErrors} from 'formik';
import {HTTPS_TEXT} from '../../../../constants/urls';
import {ICountries} from '../../../../models/interfaces/country';
import {IDropDownOptions} from '../../../../models/interfaces/generals';
import {isEmptyValue} from '../../../../utils/isEmpty';
import {TextField} from '../../../../components/TextField';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useSteps} from '../../../../hooks/useSteps';
import {useTranslation} from 'react-i18next';
import {validateUrl} from '../../../../utils/validateUrl';
import {
  getCountries,
  setRegisterData,
} from '../../../../redux/actions/registerActions';
import {
  EAnalyticsCustomEventNames,
  EAnalyticsRegisterSteps,
} from '../../../../models/enums/analytics';
import ErrorsValidations from '../../../../components/ErrorsValidations/index';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

type Props = {
  setBackDisplay?: (value: StoreType) => void;
};

export interface IBusinessOnlineForm {
  country: string;
  websiteUrl: string;
  formIsValid: boolean;
}

const BusinessPhysicalStep: React.FC<Props> = ({setBackDisplay}) => {
  //Hooks
  const dispatch = useDispatch();
  const {t} = useTranslation('register');
  const stateRegister = useSelector((state: AppState) => state.register);
  const {incrementStep} = useSteps();
  const {eventTrack} = useAnalytics();

  //States
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [countries, setcountries] = useState<[]>([]);
  const [countriesOptions, setCountriesOptions] = useState<object[]>([]);

  //Data
  const {countryAlpha2Code, webSiteUrl} = stateRegister.data;
  const initialValues: IBusinessOnlineForm = {
    country: countryAlpha2Code,
    websiteUrl: webSiteUrl || HTTPS_TEXT,
    formIsValid: false,
  };

  //Effects
  useEffect(() => {
    dispatch(getCountries());
    if (!isEmptyValue(countryAlpha2Code) && !isEmptyValue(webSiteUrl)) {
      setIsDisabledButton(false);
    }

    eventTrack(EAnalyticsCustomEventNames.Register, {
      register: {step: EAnalyticsRegisterSteps.BusinessOnlineStore},
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get countries
    if (stateRegister.countries) {
      setcountries(stateRegister.countries);
    }
  }, [stateRegister]);

  useEffect(() => {
    // setCountriesOptions
    if (countries && countries.length > 0) {
      const options: IDropDownOptions[] = countries?.map(
        (country: ICountries) => ({
          value: country.alpha2Code,
          label: country.translations?.[0].value,
        }),
      );
      setCountriesOptions(options);
    }
  }, [countries]);

  return (
    <div className="business-online-step-form">
      <Formik
        initialValues={initialValues}
        validateOnBlur={true}
        validateOnChange={false}
        validate={(values: IBusinessOnlineForm) => {
          const {country, websiteUrl} = values;
          const errors: FormikErrors<IBusinessOnlineForm> = {};

          // Mandatories
          if (isEmptyValue(country) || isEmptyValue(websiteUrl)) {
            errors.formIsValid = ErrorTypes.Required;
          }

          // WebsiteUrl
          if (!isEmptyValue(websiteUrl) && !validateUrl(websiteUrl)) {
            errors.websiteUrl = ErrorTypes.InvalidUrlFormat;
          }

          // Button
          if (Object.keys(errors).length === 0) {
            setIsDisabledButton(false);
          } else {
            setIsDisabledButton(true);
          }

          return errors;
        }}
        onSubmit={values => {
          const {country, websiteUrl} = values;

          dispatch(
            setRegisterData({
              businessLocationType: StoreType.Online,
              countryAlpha2Code: country,
              webSiteUrl: websiteUrl,
              businessLocations: [
                {
                  location: {
                    streetAddress: '',
                    apartmentSuit: '',
                    zipCode: '',
                    cityId: '',
                    googlePlaceId: '',
                    latitude: '',
                    longitude: '',
                    country: '',
                    state: '',
                    cityName: '',
                    neighborhood: '',
                  },
                  primary: false,
                },
              ],
            }),
          );
          incrementStep();
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          const {country, websiteUrl} = values;

          return (
            <Form>
              <SelectField
                items={countriesOptions}
                onChange={value => setFieldValue('country', value.value)}
                placeHolder={t(
                  'register:businessPhysicalStepCountryPlaceholder',
                )}
                label={t('businessPhysicalStepCountryTitle')}
                isEdit={true}
                defaultOption={country}
              />

              <div className="field-container">
                <TextField
                  handleChange={handleChange}
                  id="websiteUrl"
                  label={t('businessOnlineStepWebsiteTitle')}
                  maxLength={65}
                  placeHolder={t('profilePage:businessWebsiteFieldPlaceholder')}
                  onBlur={handleBlur}
                  type={'text'}
                  value={websiteUrl}
                />
                {errors.websiteUrl === ErrorTypes.InvalidUrlFormat &&
                  touched.websiteUrl && (
                    <ErrorsValidations type="InvalidUrlFormat" />
                  )}
              </div>

              <div className="previous-next-buttons-container">
                <PreviousNextButtons
                  onNext={handleSubmit}
                  nextDisabled={isDisabledButton}
                  setBackDisplay={setBackDisplay}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BusinessPhysicalStep;
