import React, {useState, useEffect} from 'react';
import InfoPage from '../../components/InfoPage';
import HeaderPage from '../../components/HeaderPage';
import CreateAdForm from './components/CreateAdForm';
import CreateAdPreview from '../CreateDeal/components/CreateAdPreview';
import ModalConfirmation from '../../components/ModalConfirmation';
import useWindowDimensions from '../../hooks/useWindowsDimensions';
import {AppState} from '../../redux';
import {IPackage} from '../../models/api/packages';
import {useParams} from 'react-router';
import {getAdById} from '../../services/api/adsAPI';
import {useNavigate} from 'react-router-dom';
import {getPackages} from '../../services/api/packageAPI';
import {useTranslation} from 'react-i18next';
import {TypeActionModal} from '../../models/enums/typeAction';
import {createImageFile} from '../../utils/createImageFile';
import {useDispatch, useSelector} from 'react-redux';
import {setClearAdData, setCreateadData} from '../../redux/actions/adActions';
import './styles.scss';
import {useAds} from '../../hooks/useAds';

const CreateAd = () => {
  // Hooks
  const {height} = useWindowDimensions();
  const {id}: any = useParams();
  const {t} = useTranslation('createAd');
  const {ui} = useSelector((state: AppState) => state);
  const {onPackageChange} = useAds({isEditPage: id});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  const [adIdToEdit, setAdIdToEdit] = useState<number | null>(null);
  const [currentPackageSelection, setCurrentPackageSelection] =
    useState<IPackage | null>(null);
  const [isEditPage, setIsEditPage] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [packageList, setPackageList] = useState<IPackage[]>([]);

  // Effects
  useEffect(() => {
    getAllPackages();
  }, []);

  useEffect(() => {
    if (id) {
      setIsEditPage(true);
      setAdIdToEdit(id);
      const getAd = async () => {
        try {
          const result = await getAdById(id);
          if (result.data) {
            // Package
            if (result.data.packageId) {
              const packageId = result.data.packageId;
              const packageData = packageList;
              const filterPackage = packageData.find(
                tier => tier.id === packageId,
              );

              if (filterPackage) {
                setCurrentPackageSelection(filterPackage);
              }
            }

            // Set all data
            dispatch(setCreateadData(result.data));

            // Image preview
            if (result.data.images[0]?.url) {
              dispatch(
                setCreateadData({
                  filePreview: result.data.images[0].url,
                }),
              );

              // Convert image to file
              try {
                const file = await createImageFile(
                  result.data.images[0].url,
                  result.data.images[0].name,
                );
                dispatch(
                  setCreateadData({
                    files: [file],
                  }),
                );
              } catch (e) {
                console.log('error');
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      getAd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, packageList]);

  useEffect(() => {
    if (currentPackageSelection) {
      onPackageChange(currentPackageSelection?.id || 1);
    }
  }, [currentPackageSelection]);

  // Handlebars - Functions
  const getAllPackages = async () => {
    try {
      const result = await getPackages();
      if (result.data.length > 0) {
        setPackageList(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = () => {
    dispatch(setClearAdData());
    navigate('/home');
  };

  return (
    <div className="create-ad-page">
      <div className="create-ad-page-header-wrapper">
        {height > 719 ? (
          <InfoPage
            item={isEditPage ? t('titleEdit') : t('title')}
            title={t('ads:title')}
          />
        ) : (
          <div style={{marginTop: 72}}></div>
        )}
        <HeaderPage
          onClickBack={ui.hideUnsavedModal ? goBack : setIsOpen}
          title={isEditPage ? t('titleEdit') : t('title')}
        />
      </div>
      <div
        className="create-ad-page-content-wrapper"
        style={{height: height - 108}}>
        <CreateAdForm
          currentPackageSelection={currentPackageSelection}
          id={adIdToEdit}
          isEditPage={isEditPage}
          packageList={packageList}
          setCurrentPackageSelection={setCurrentPackageSelection}
        />
        <CreateAdPreview />
      </div>

      {modalIsOpen && (
        <ModalConfirmation
          actionType={TypeActionModal.Unsaved}
          modalIsOpen={modalIsOpen}
          onConfirm={goBack}
          setIsOpen={setIsOpen}
          subtitle={t('createDeal:unsavedChangesMsg')}
          title={t('createDeal:unsavedChanges')}
          type="alert"
        />
      )}
    </div>
  );
};

export default CreateAd;
