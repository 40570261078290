export enum ErrorCodes {
  userNotFound = 'UserNotFound',
  invalidUserRole = 'InvalidUserRole',
  serLoginNotAllowed = 'UserLoginNotAllowed',
  userLockedOut = 'UserLockedOut',
  invalidCredentials = 'InvalidCredentials',
  internalError = 'InternalServerError',
  badRequest = 'BadRequest',
  networkError = 'NetWorkError',
  invalidLocation = 'InvalidLocation',
  inappropriateContent = 'InappropriateContent',
}

export enum EGeneralDaraErrors {
  invalidDate = 'Invalid date',
}
