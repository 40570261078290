import React, {useEffect} from 'react';
import {AppState} from '../../../../redux';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {EColors} from '../../../../models/enums/colors';
import {EMenuViews} from '../../../../models/enums/generals';
import {getFail, setErrorCode} from '../../../../redux/actions/registerActions';
import {IConfirmEmail} from '../../../../models/interfaces/register';
import {ReactComponent as ImageLogo} from '../../../../assets/images/register/mana-logo.svg';
import {registerApi} from '../../../../services/api/registerAPI';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import ImageStep8 from './../../../../assets/images/register/step-8.svg';
import ProgressBar from '../../../../components/ProgressBar';
import StepTitle from '../StepTitle';
import './styles.scss';

const ConfirmEmail: React.FC = () => {
  // Hooks
  const {t} = useTranslation('register');
  const dispatch = useDispatch();
  const location = useLocation();
  const stateRegister = useSelector((state: AppState) => state.register);

  // Data
  const {pathname} = location;
  const {eventTrack} = useAnalytics();
  const params = pathname.split('/');
  const userId = params[2];
  const verificationToken = params[params.length - 1];

  // State
  const image = <ImageStep8 />;

  useEffect(() => {
    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {page_view: EMenuViews.ConfirmEmail},
    });

    if (userId && verificationToken) {
      sendConfirmEmail({userId, token: verificationToken});
      eventTrack(EAnalyticsCustomEventNames.ConfirmEmail, {
        confirm_email: {user_id: userId},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, verificationToken]);

  const sendConfirmEmail = async (body: IConfirmEmail) => {
    try {
      const response: any = await registerApi.sendConfirmEmail(body);
      if (response) {
        dispatch(getFail(''));
        dispatch(setErrorCode(''));
      }
    } catch (error: any) {
      dispatch(getFail(error.message));
      dispatch(setErrorCode(error.errorCode));
    }
  };

  return (
    <div className="register-page">
      <div
        className="image-section"
        style={{backgroundImage: `url(${image.type})`}}>
        <ImageLogo />
      </div>

      <div className="form-section">
        <div className="form-content-fields">
          <ProgressBar
            bgColor={EColors.colorBlueCornflower}
            progress={100}
            height={8}
          />

          <StepTitle
            title={t('verifyAccountTitle')}
            subtitle={t('verifyAccountSubTitle')}
          />

          <div className="account-verification-step-form">
            <span className="account-verfication-step-description">
              {t('verifyAccountDescriptionFirstPart')}&nbsp;
              <span className="account-verification-step-email">
                {stateRegister.data?.username}
              </span>
              &nbsp;
              {t('verifyAccountDescriptionSecondPart')}
            </span>

            <span className="account-verfication-step-business-days">
              {t('verifyAccountDescriptionThirdPart')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
