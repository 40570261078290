import {AxiosResponse} from 'axios';
import {IContentValidationResult} from '../../models/api/contentValidationResult';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericGet} from './genericAPI';

export const checkContent = async (
  content: string,
): Promise<AxiosResponse<IContentValidationResult>> => {
  const token = store.getState().auth.token;
  return await genericGet(
    `${endpoints.contentValidation.vocavularyCheck}?text=${content}`,
    token,
  );
};
