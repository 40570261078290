import {IBusinessCategory, IBusinessSubcategory} from "../models/interfaces/categories";

export const useCategories = (categories: IBusinessCategory[]) => {
  const r: IBusinessSubcategory[] = [];
  categories.forEach((item: IBusinessCategory) => {
    item.subCategories.forEach((sc: IBusinessSubcategory) => {
        r.push(sc);
      });
  });
  return {
    subCategoriesAsCategories: r
  };
};
