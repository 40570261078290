import React, {useCallback, useState} from 'react';
import {AppState} from '../../../../redux';
import {clearAuthErrors} from '../../../../redux/actions/authActions';
import {Form, useFormikContext} from 'formik';
import {isEmail, isPhoneNumber} from '../../../../utils/helper';
import {LoginErrorMessage} from '../../../LoginPage/components/LoginForm/LoginErrorMessage';
import {TextField} from '../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import WideButton from '../../../../components/WideButton';
import './styles.scss';

export interface IFormValues {
  phoneOrEmail: string;
}

const ForgotPasswordForm = () => {
  const {t} = useTranslation();
  const {error} = useSelector((state: AppState) => state.auth);
  const formik = useFormikContext<IFormValues>();
  const dispatch = useDispatch();

  const [emailLabelIsHidden, setEmailLabelIsHidden] = useState<boolean>(
    formik?.values.phoneOrEmail.length === 0,
  );

  const handleChangeInterceptor = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (error) dispatch(clearAuthErrors());
      formik.handleChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error],
  );

  const submitIsValid = (phoneOrEmail: string) => {
    return isPhoneNumber(phoneOrEmail) || isEmail(phoneOrEmail);
  };

  return (
    <div data-testid="forgot-password-form" className="forgot-password-form">
      <LoginErrorMessage />
      <Form>
        <TextField
          id="phoneOrEmail"
          type="text"
          hideLabel={emailLabelIsHidden}
          value={formik?.values.phoneOrEmail || ''}
          handleChange={handleChangeInterceptor}
          onFocus={() => setEmailLabelIsHidden(false)}
          onBlur={() =>
            setEmailLabelIsHidden(formik?.values.phoneOrEmail.length === 0)
          }
          error={!formik?.isValid}
          placeHolder={t('login:phoneOrEmail')}
          required={true}
          maxLength={50}
          customStyles={{
            container: {
              marginBottom: 26,
            },
          }}
        />

        <div className="bottom-container">
          <WideButton
            label={t('login:buttonSend')}
            onClick={formik?.submitForm}
            disabled={!submitIsValid(formik.values.phoneOrEmail)}
          />
        </div>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
