import React, { useState } from 'react';
import BusinessImages from './components/BusinessImages';
import ProfilePicture from './components/ProfilePicture';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import { useSteps } from '../../../../hooks/useSteps';
import { useDispatch } from 'react-redux';
import { setRegisterData } from '../../../../redux/actions/registerActions';
import './styles.css';

const BusinessImagesStep = () => {

  // hooks
  const dispatch = useDispatch();
  const { incrementStep } = useSteps()

  // state
  const [profileImage, setProfileImage] = useState<any>(null)
  const [images, setImages] = useState<any>([])

  // functions
  const handleNextButton = () => {
    dispatch(
      setRegisterData({
        profileImage: profileImage && URL.createObjectURL(profileImage),
        images: [...images],
      }),
    );
    incrementStep()
  };

  return (
    <div className='business-images-step-wrapper'>

      <ProfilePicture profileImage={profileImage} setProfileImage={setProfileImage} />
      <BusinessImages images={images} setImages={setImages} />

      <div className='business-images-bottom-buttons'>
        <PreviousNextButtons
          onNext={handleNextButton}
          nextDisabled={!images.length}
        />
      </div>
    </div >)
};

export default BusinessImagesStep;
