import React from 'react';
import './styles.scss';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import {useTranslation} from 'react-i18next';

type Props = {
  isPromotion?: boolean;
};
const PreviewHeader: React.FC<Props> = ({isPromotion}) => {
  const {t} = useTranslation('previewDeal');
  return (
    <div className="preview-header">
      <ChevronDown className="preview-header-icon" width={17} height={17} />
      <span className="preview-title">
        {isPromotion ? t('headerTitlePromotion') : t('headerTitle')}
      </span>
    </div>
  );
};

export default PreviewHeader;
