import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../../../redux';
import {EPanelFormProfile} from '../../../../../../models/enums/profile';
import {ErrorTypes} from '../../../../../../models/enums/registerForm';
import {Form, Formik, FormikErrors} from 'formik';
import {IPanelFormProfile} from '../../../../../../models/interfaces/profile';
import {isEmptyValue} from '../../../../../../utils/isEmpty';
import {TextField} from '../../../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  sendProfileEditUserData,
  setErrorCode,
  setHasErrors,
} from '../../../../../../redux/actions/profileActions';
import Button from '../../../../../../components/Button';
import ErrorsValidations from '../../../../../../components/ErrorsValidations/index';
import validateEmail from '../../../../../../services/app/validations';
import './styles.scss';

type Props = {
  setShowPanelForm: (val: IPanelFormProfile) => void;
};

export interface IChangeEmailForm {
  formIsValid: boolean;
  userNewEmail: string;
  userRepeatEmail: string;
}

const customStyles = {
  email: {
    img: {
      float: 'right',
      marginRight: '10px',
      left: '0',
    },
  },
};

const ChangeEmailForm: React.FC<Props> = ({setShowPanelForm}) => {
  //Hooks
  const dispatch = useDispatch();
  const stateProfile = useSelector((state: AppState) => state.profile);
  const {t} = useTranslation('register');

  const initialValues: IChangeEmailForm = {
    formIsValid: true,
    userNewEmail: '',
    userRepeatEmail: '',
  };

  //States
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [isFirstLoader, setIsFirstLoader] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  // Effects
  useEffect(() => {
    setIsFirstLoader(false);
    dispatch(setErrorCode(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirstLoader && stateProfile.errorCode) {
      if (stateProfile.errorCode === ErrorTypes.InvalidCredentials) {
        setIsDisabledButton(true);
      } else if (stateProfile.errorCode === ErrorTypes.NoError) {
        setShowPanelForm({open: false, type: EPanelFormProfile.Email});
        dispatch(
          setHasErrors({
            ...stateProfile.hasErrors,
            vendorInformation: false,
          }),
        );
      } else {
        setError(stateProfile.errorCode);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProfile.errorCode]);

  return (
    <div className="change-email-form">
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validate={(values: IChangeEmailForm) => {
          const {userNewEmail, userRepeatEmail} = values;
          const errors: FormikErrors<IChangeEmailForm> = {};

          // Mandatories
          if (isEmptyValue(userNewEmail) || isEmptyValue(userRepeatEmail)) {
            errors.formIsValid = ErrorTypes.Required;
          }

          // Igual Emails
          if (
            !isEmptyValue(userNewEmail) &&
            !isEmptyValue(userRepeatEmail) &&
            userNewEmail !== userRepeatEmail
          ) {
            errors.userRepeatEmail = ErrorTypes.DoesntMatchEmail;
          }
          // Email
          if (!validateEmail(userNewEmail)) {
            errors.userNewEmail = ErrorTypes.InvalidEmailFormat;
          }
          if (!validateEmail(userRepeatEmail)) {
            errors.userRepeatEmail = ErrorTypes.InvalidEmailFormat;
          }

          // Block General Buttom
          if (Object.entries(errors).length > 0) {
            setIsDisabledButton(true);
          } else {
            setIsDisabledButton(false);
          }

          return errors;
        }}
        onSubmit={values => {
          const {userNewEmail} = values;

          // Send Data
          dispatch(
            sendProfileEditUserData({
              userEmail: userNewEmail,
            }),
          );
        }}>
        {({errors, handleBlur, handleChange, handleSubmit, values}) => {
          const {userNewEmail, userRepeatEmail} = values;

          return (
            <Form>
              <TextField
                customStyles={customStyles.email}
                errorElement={
                  errors.userNewEmail === ErrorTypes.InvalidEmailFormat && (
                    <ErrorsValidations type={ErrorTypes.InvalidEmailFormat} />
                  )
                }
                handleChange={handleChange}
                id="userNewEmail"
                label={t('profilePage:fieldNewEmailTitle')}
                maxLength={60}
                minLength={7}
                onBlur={handleBlur}
                required={true}
                type="text"
                value={userNewEmail}
              />

              <TextField
                customStyles={customStyles.email}
                errorElement={
                  error ? (
                    <ErrorsValidations type={error} />
                  ) : (
                    errors.userRepeatEmail === ErrorTypes.DoesntMatchEmail && (
                      <ErrorsValidations type={ErrorTypes.DoesntMatchEmail} />
                    )
                  )
                }
                handleChange={handleChange}
                id="userRepeatEmail"
                label={t('profilePage:fieldRepeatEmailTitle')}
                onBlur={handleBlur}
                required={true}
                type="text"
                value={userRepeatEmail}
                maxLength={60}
                allowPaste={false}
              />

              <div className="change-email-buttons-container">
                <Button
                  label={t('global:cancel')}
                  onClick={() => {
                    setShowPanelForm({
                      open: false,
                      type: EPanelFormProfile.Email,
                    });
                    dispatch(
                      setHasErrors({
                        ...stateProfile.hasErrors,
                        vendorInformation: false,
                      }),
                    );
                  }}
                />
                <Button
                  label={t('profilePage:setNewEmailText')}
                  onClick={handleSubmit}
                  disable={isDisabledButton}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangeEmailForm;
