import React, {useEffect, useState} from 'react';
import {ETypeCommunication} from '../../models/enums/generals';
import {setCreateDealData} from '../../redux/actions/dealActions';
import {setCreateadData} from '../../redux/actions/adActions';
import {useTranslation} from 'react-i18next';
import {MapOptionType} from '../../models/enums/mapsOptionType';
import {useDispatch} from 'react-redux';
import {IAddresses} from '../../models/interfaces/IAddresses';
import truncateLongString from '../../utils/truncateLongString';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import './styles.scss';

type Props = {
  disabled?: boolean;
  optionsTypes?: string[];
  setSuggestionData?: any;
  setValue: (value: IAddresses) => void;
  typeLocation: ETypeCommunication;
  value: IAddresses | null;
};

export const LocationSearchInput: React.FC<Props> = ({
  disabled,
  optionsTypes: optionssTypes = [MapOptionType.Address],
  setValue,
  typeLocation,
  value,
}) => {
  const [address, setAddress] = useState<string>('');
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {placePredictions, getPlacePredictions, isPlacePredictionsLoading} =
    useGoogle({
      apiKey: process.env.REACT_APP_GCP_MAPS_KEY,
      options: {
        types: optionssTypes,
        input: address,
      },
    });

  useEffect(() => {
    if (value?.name) {
      setAddress(value?.name);
    }
  }, [value]);

  const handleChange = (evt: any) => {
    const value = evt.target.value;
    if (value.length > 2) {
      getPlacePredictions({input: value});
      setDropdownIsOpen(true);
    }
    setAddress(value);
  };

  const handleSelect = (value: string) => {
    setDropdownIsOpen(false);
    setAddress(value);
    setValue({
      id: new Date().getTime(),
      name: value,
    });

    if (typeLocation === ETypeCommunication.Ad) {
      dispatch(setCreateadData({location: value}));
    } else {
      dispatch(setCreateDealData({location: value}));
    }
  };

  return (
    <div>
      <input
        value={address}
        className={`autocomplete-field-input ${disabled ? 'disabled' : ''} `}
        disabled={disabled && disabled}
        onChange={evt => handleChange(evt)}
      />
      <div className="autocomplete-dropdown-container">
        {isPlacePredictionsLoading && (
          <span className="autocomplete-dropdown-item">
            {t('global:loading')}
          </span>
        )}
        {placePredictions &&
          dropdownIsOpen &&
          address &&
          placePredictions.map((suggestion: any, index: number) => (
            <span
              onClick={() => handleSelect(suggestion.description)}
              key={index}
              className="autocomplete-dropdown-item">
              {truncateLongString(suggestion.description, 42)}
            </span>
          ))}
      </div>
    </div>
  );
};
