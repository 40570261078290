import {ETypeView} from '../../models/enums/typeListData';
import {IConfirmationPopupState} from '../../models/interfaces/uiState';
import {IResultCommunity} from '../../models/api/community';

export const SET_COMMUNITIES = 'SET_COMMUNITIES';
export const SET_CONFIRMATION_POPUP = 'SET_CONFIRMATION_POPUP';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const SET_HIDE_CONFIRMATION_MODAL = 'SET_HIDE_CONFIRMATION_MODAL';
export const SET_HIDE_UNSAVED_MODAL = 'SET_HIDE_UNSAVED_MODAL';
export const SET_LIST_DATA = 'SET_LIST_DATA';
export const SET_TIME_ZONE_NAME = 'SET_TIME_ZONE_NAME';

interface ISetConfirmationPopup {
  type: typeof SET_CONFIRMATION_POPUP;
  payload: IConfirmationPopupState;
}

interface IListData {
  type: typeof SET_LIST_DATA;
  payload: string;
}

interface IHideConfirmation {
  type: typeof SET_HIDE_CONFIRMATION_MODAL;
  payload: boolean;
}

interface IHideUnsaved {
  type: typeof SET_HIDE_UNSAVED_MODAL;
  payload: boolean;
}

interface ICommunities {
  type: typeof SET_COMMUNITIES;
  payload: IResultCommunity[];
}

interface ICurrentView {
  type: typeof SET_CURRENT_VIEW;
  payload: ETypeView;
}

export interface ISetTimeZoneName {
  type: typeof SET_TIME_ZONE_NAME;
  payload: string;
}

export type UiActionTypes =
  | ICommunities
  | ICurrentView
  | IHideConfirmation
  | IHideUnsaved
  | IListData
  | ISetConfirmationPopup
  | ISetTimeZoneName;
