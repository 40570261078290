import React, {useState, useEffect} from 'react';
import {IDeal} from '../../../../../../models/interfaces/deals';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CalendarIcon} from '../../../../../../assets/icons/calendar.svg';
import {ReactComponent as LocationIcon} from '../../../../../../assets/icons/location.svg';
import PreviewImage from '../../../../../../components/PreviewImage';
import PreviewHeader from '../../../../../../components/PreviewHeader';
import PreviewPromoCode from '../../../../../../components/PreviewPromoCode';
import PreviewConditions from '../../../../../../components/PreviewConditions';
import useWindowDimensions from '../../../../../../hooks/useWindowsDimensions';
import PreviewQrCodeButton from '../../../../../../components/PreviewQrCodeButton';
import PreviewWebsiteButton from '../../../../../../components/PreviewWebsiteButton';
import './styles.scss';
import {useAnalytics} from '../../../../../../hooks/useAnalytics';
import {
  EAnalyticsCustomEventNames,
  EAnalyticsViewItemDetails,
} from '../../../../../../models/enums/analytics';
import {ETabsViews} from '../../../../../../models/enums/generals';
import {TypeListData} from '../../../../../../models/enums/typeListData';

type Props = {
  isPreview?: boolean;
  deal: IDeal;
  isPromotion: boolean;
  name: string;
};

const FullPreview: React.FC<Props> = ({
  isPreview = false,
  deal,
  isPromotion,
  name,
}) => {
  // Hooks
  const {t} = useTranslation('previewDeal');
  const {eventTrack} = useAnalytics();
  const {height, width} = useWindowDimensions();

  // Local States
  const [endDate, setEndDate] = useState<any>('');

  // Effects
  useEffect(() => {
    eventTrack(EAnalyticsCustomEventNames.ViewItemDeal, {
      eventParams: {
        track_id: EAnalyticsViewItemDetails.Deal,
        item_list_name: ETabsViews.Deals,
        item_list_id: `${deal.id}`,
        items: [
          {
            item_id: `${deal.id}`,
            item_name: deal.title,
            promotion_id: `${deal.promoType}`,
            item_category: TypeListData.Deal,
            location_id: deal.location || '',
          },
        ],
      },
    });
  }, []);

  useEffect(() => {
    if (deal.endDate) {
      const date = new Date(deal.endDate);
      setEndDate(
        `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
      );
    }
  }, [deal.endDate]);

  return (
    <div
      style={{height: height - 292, width: width - 1025}}
      className="preview-deal-full-preview">
      <PreviewHeader isPromotion={isPromotion} />
      <PreviewImage deal={deal} isPreview={isPreview} />

      {/** Content */}
      <div
        style={deal.conditions ? {marginTop: -55} : {}}
        className="preview-deal-full-preview-content-wrapper">
        {/** Content - Valid up to */}
        <div
          style={{width: width - 1425}}
          className="preview-deal-full-preview-content-header">
          <CalendarIcon />
          <span className="preview-deal-full-preview-content-header-text">
            {!deal.endDate
              ? t('contentHeaderDate')
              : `${t('previewDate')} ${endDate}`}
          </span>
        </div>
        {/** Core Content */}
        <div className="preview-deal-full-preview-content-core">
          <span className="preview-deal-full-preview-content-core-title">
            {deal.title ? deal.title : t('contentCoreTitle')}
          </span>

          <span className="preview-deal-full-preview-content-core-subtitle">
            {name}
          </span>

          {deal.location && (
            <span className="preview-deal-full-preview-content-core-address">
              <LocationIcon className="preview-deal-full-preview-content-core-icon" />
              {deal.location}
            </span>
          )}

          {deal.qrCodePreview && <PreviewQrCodeButton deal={deal} />}
          {/* Disable this button until authorizations for deals will be ready */}
          {deal.webSiteUrl && <PreviewWebsiteButton deal={deal} />}
          {deal.promotionCode && <PreviewPromoCode deal={deal} />}
        </div>
        {deal.conditions.length ? <PreviewConditions deal={deal} /> : null}
      </div>
    </div>
  );
};

export default FullPreview;
