import React, {useEffect, useState} from 'react';
import {daysBeforeTheEndDate} from '../../../../constants/list';
import {EColors} from '../../../../models/enums/colors';
import {EVisibility} from '../../../../models/enums/generals';
import {ITableItems} from '../../../../models/interfaces/table';
import {ListMenuOptions} from '../../../../models/enums/listMenuOptions';
import {ReactComponent as BlackElipsis} from '../../../../assets/icons/black-elipsis.svg';
import {ReactComponent as ExclamationCircleExpired} from '../../../../assets/icons/exclamation-circle-expired.svg';
import {setAdIdData} from '../../../../redux/actions/adActions';
import {TypeListData} from '../../../../models/enums/typeListData';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import DropdownAcions from '../DropdownActions/index';
import formatDate from '../../../../utils/formatDate';
import ReactTooltip from 'react-tooltip';
import {
  TableItemStatus,
  TableStatus,
} from '../../../../models/enums/tableDeals';
import './styles.scss';
import {UserStatus} from '../../../../models/enums/userStatus';

type Props = {
  actionEditItem?: (value: any) => void;
  itemsDescription: ITableItems[];
  setFirstLoader?: (value: boolean) => void;
  setReloadData?: (value: boolean) => void;
  typeAction?: TypeListData;
  value: any;
  userStatus?: boolean;
};

const Items: React.FC<Props> = ({
  actionEditItem,
  itemsDescription,
  setFirstLoader,
  setReloadData,
  typeAction,
  value,
  userStatus = false,
}) => {
  // Hooks
  const {t} = useTranslation();
  const dispatch = useDispatch();

  // States
  const [dropDown, setDropDown] = useState(false);
  const [menuOptions, setMenuOptions] = useState<
    {value: string; label: string}[]
  >([]);

  // Data
  const itemDate: any = value ? new Date(value.endDate) : new Date();
  const today: any = new Date();
  const differenceInTime = itemDate.getTime() - today.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  // Effects
  useEffect(() => {
    const options: {value: string; label: string}[] = [];

    for (const opt in ListMenuOptions) {
      if (typeof ListMenuOptions[opt] === 'number') {
        options.push({
          value: String(ListMenuOptions[opt]),
          label: String(opt),
        });
      }
    }
    setMenuOptions(options);
  }, []);

  // Handlers
  const handleDropdown = (show: EVisibility) => {
    if (show === EVisibility.Show) setDropDown(!dropDown);
  };

  const setAdToPreview = (id: number) => {
    if (setFirstLoader) {
      setFirstLoader(false);
    }
    dispatch(setAdIdData(id));
  };

  return (
    <tr className="body">
      {itemsDescription.map((item, index) => (
        <td
          key={index}
          className={`body-item ${
            index === 0 && TableItemStatus.Deleted !== value.status
              ? ' title-item pointer-item '
              : index === 0 && TableItemStatus.Deleted === value.status
              ? ' title-item pointer-item-disabled '
              : item.type === 'status' &&
                value[item.name] === TableItemStatus.Active
              ? ' color-green status-item '
              : item.type === 'status' &&
                value[item.name] === TableItemStatus.Scheduled
              ? ' color-blue status-item '
              : item.type === 'status' &&
                value[item.name] === TableItemStatus.Rejected
              ? ' color-red status-item '
              : item.type === 'status' &&
                value[item.name] === TableItemStatus.Expired
              ? ' color-gray-dark status-item '
              : item.type === 'status' &&
                value[item.name] === TableItemStatus.Pending
              ? ' color-orange status-item '
              : item.type === 'status' &&
                value[item.name] === TableItemStatus.UpdateRequested
              ? ' color-yellow status-item '
              : item.type === 'status' &&
                value[item.name] === TableItemStatus.Deleted
              ? ' color-gray status-item'
              : ''
          }
          `}
          onClick={() =>
            index === 0 &&
            TableItemStatus.Deleted !== value.status &&
            setAdToPreview(value.id)
          }>
          {item.type === 'info' &&
            (item.name === 'title'
              ? value[item.name].length > 15
                ? `${value[item.name].substring(0, 15)} ...`
                : value[item.name]
              : value[item.name])}
          {item.name === 'startDate' && formatDate(value[item.name])}
          {item.type === 'status' &&
            (userStatus
              ? UserStatus[Number(value[item.name])].toUpperCase()
              : TableStatus[Number(value[item.name])].toUpperCase())}
          {item.name === 'endDate' && (
            <div className="exclamation-circle-end-date-wrapper">
              {formatDate(value[item.name])}
              {TableItemStatus.Active === value.status &&
                Math.round(differenceInDays) <= daysBeforeTheEndDate &&
                differenceInDays >= 0 && (
                  <div>
                    <ExclamationCircleExpired
                      data-tip={
                        typeAction === TypeListData.Ad
                          ? t('createAd:alertAdExpiredSoon')
                          : t('dealsPage:alertDealExpiredSoon')
                      }
                      className="exclamation-circle-end-date-icon"
                    />
                    <ReactTooltip
                      place="bottom"
                      effect="solid"
                      className="exclamation-circle-end-date-tooltip"
                      arrowColor={EColors.colorDarkBlue}
                    />
                  </div>
                )}
            </div>
          )}

          {item.type === 'button' && (
            <div className="div-drop-down-action-list">
              {value.updatedAt && (
                <span
                  className="list-edited-text"
                  data-tip
                  data-for={`edited-text-${value.id}`}>
                  {t('global:edited')}
                  <ReactTooltip
                    id={`edited-text-${value.id}`}
                    place="bottom"
                    type="dark"
                    effect="solid"
                    html
                    className="tooltip"
                    arrowColor={EColors.colorBluePrimaryButtons}>
                    {value.updatedAt.substr(0, 10) +
                      ' at ' +
                      value.updatedAt.substr(11, 5)}
                  </ReactTooltip>
                </span>
              )}
              <BlackElipsis
                className={
                  TableItemStatus.Deleted === value.status ||
                  TableItemStatus.Expired === value.status
                    ? 'ellipsis-icon-action-list-disabled'
                    : 'ellipsis-icon-action-list'
                }
                onClick={
                  TableItemStatus.Deleted === value.status ||
                  TableItemStatus.Expired === value.status
                    ? () => handleDropdown(EVisibility.Hide)
                    : () => handleDropdown(EVisibility.Show)
                }
              />
              {dropDown && (
                <DropdownAcions
                  actionEditItem={actionEditItem}
                  menuOptions={menuOptions}
                  setDropDown={setDropDown}
                  setReloadData={setReloadData}
                  typeAction={typeAction}
                  value={value}
                />
              )}
            </div>
          )}
        </td>
      ))}
    </tr>
  );
};

export default Items;
