import React from 'react';
import './styles.scss';

type Props = {
    image: string;
    text: string;
}

const CommunityTag: React.FC<Props> = ({ image, text }) => {
  return (
    <div className='tag'>
        <img src={image} alt="icon-tag" />
        {text}
    </div>
  )
}

export default CommunityTag