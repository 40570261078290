import React from 'react';
import WideButton from '../../../../../components/WideButton';
import './styles.scss';

interface IProps {
  imgSrc: any;
  title: string;
  message: string;
  buttonLabel: string;
  goBack: () => void;
  imageSize: {width: number; height: number};
}

export const LoginError = ({
  imgSrc,
  title,
  message,
  buttonLabel,
  imageSize,
  goBack,
}: IProps) => {
  return (
    <div data-testid="login-error" className="login-error">
      <div className="close-button" onClick={goBack} />
      <div className="error-content-wrapper">
        <img
          src={imgSrc}
          width={imageSize.width}
          height={imageSize.height}
          alt={title}
        />
        <span className="text title">{title}</span>
        <span className="text message">{message}</span>
        <div className="button-container">
          <WideButton label={buttonLabel} onClick={goBack} disabled={false} />
        </div>
      </div>
    </div>
  );
};
