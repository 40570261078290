import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../../../redux';
import {ETabsViews} from '../../../../../../models/enums/generals';
import {ReactComponent as ChevronRightIcon} from '../../../../../../assets/icons/chevron-right.svg';
import {ReactComponent as ImageIcon} from '../../../../../../assets/icons/no-ad-image.svg';
import {ReactComponent as LocationIcon} from '../../../../../../assets/icons/location.svg';
import {ReactComponent as OptionsIcon} from '../../../../../../assets/icons/options.svg';
import {ReactComponent as ReactionsIcon} from '../../../../../../assets/icons/ad-preview-reaction-bottom-bar.svg';
import {removeBaseFromUrl} from '../../../../../../utils/validateUrl';
import {SUPPORTED_IMAGES} from '../../../../../../constants/createDeal';
import {TypeListData} from '../../../../../../models/enums/typeListData';
import {useAnalytics} from '../../../../../../hooks/useAnalytics';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  EAnalyticsCustomEventNames,
  EAnalyticsViewItemDetails,
} from '../../../../../../models/enums/analytics';
import truncateLongString from '../../../../../../utils/truncateLongString';
import './styles.scss';

type Props = {
  isPreview?: boolean;
  previewInList?: boolean;
};

const AdFullPreview: React.FC<Props> = ({isPreview = false}) => {
  // Local State
  const [isImage, setIsImage] = useState<boolean | undefined>();
  const [hadFiles, setHadFiles] = useState<boolean | undefined>();

  // Hooks
  const {t} = useTranslation('previewDeal');
  const {eventTrack} = useAnalytics();
  const ad = useSelector((state: AppState) => state.ad);
  const user = useSelector((state: AppState) => state.user);

  // Data
  const imageExtensions = SUPPORTED_IMAGES.split(',');

  useEffect(() => {
    if (isPreview && ad.files.length) {
      setHadFiles(true);
    } else if (!isPreview && ad.filePreview) {
      setHadFiles(true);
    } else {
      setHadFiles(false);
    }

    if (isPreview) {
      setIsImage(
        ad.files[0]?.fileType
          ? imageExtensions.some(el =>
              ad.files[0].fileType.includes(el.substring(1)),
            )
          : false,
      );
    } else {
      if (ad.files[0]?.type) {
        setIsImage(ad.files[0].type?.includes('image'));
      } else if (ad.files[0]?.name) {
        setIsImage(
          imageExtensions.some(el =>
            ad.files[0].name.includes(el.substring(1)),
          ),
        );
      }
    }
  }, [isPreview, ad.filePreview, ad.files]);

  // Effects
  useEffect(() => {
    eventTrack(EAnalyticsCustomEventNames.ViewItemAd, {
      eventParams: {
        track_id: EAnalyticsViewItemDetails.Ad,
        item_list_name: ETabsViews.Ads,
        item_list_id: `${ad.id}`,
        items: [
          {
            item_id: `${ad.id}`,
            item_name: ad.title,
            item_brand: ad.status,
            item_category: TypeListData.Ad,
            location_id: ad.location || '',
          },
        ],
      },
    });
  }, []);

  return (
    <div className="preview-ad-phone-preview">
      <div className="preview-ad-phone-preview-content">
        <div className="preview-ad-phone-preview-header">
          <div className="preview-ad-phone-preview-header-icon">
            <img src={user.profileImageUrl} alt="profile" />
          </div>
          <div className="preview-ad-phone-preview-header-content">
            <div className="preview-ad-phone-preview-header-content-title">
              {user.name}
            </div>
            <div className="preview-ad-phone-preview-header-content-subtitle">
              {t('sponsored')}
            </div>
          </div>
          <div className="preview-ad-phone-preview-header-options">
            <OptionsIcon />
          </div>
        </div>
        <div className="preview-ad-phone-preview-description">
          {ad.description}
        </div>
        <div className="preview-ad-phone-preview-image-wrapper">
          {isImage !== undefined && hadFiles ? (
            isImage ? (
              <img
                src={
                  isPreview && ad.files[0] ? ad.files[0].url : ad.filePreview
                }
                className="preview-ad-phone-preview-image-wrapper-image"
                alt="ad-preview"
              />
            ) : (
              <video
                className="preview-ad-phone-preview-image-wrapper-video"
                controls
                autoPlay>
                <source
                  src={
                    isPreview && ad.files[0] ? ad.files[0].url : ad.filePreview
                  }
                  type="video/mp4"
                />
              </video>
            )
          ) : (
            <div className="preview-ad-phone-preview-image-wrapper-without-image">
              <ImageIcon />
            </div>
          )}
          <div className="preview-ad-phone-preview-image-wrapper-bottom-content">
            <div className="preview-ad-phone-preview-image-wrapper-bottom-content-left">
              <div className="preview-ad-phone-preview-image-wrapper-bottom-content-headline">
                {ad.headline}
              </div>
              <div className="preview-ad-phone-preview-image-wrapper-bottom-content-url">
                {removeBaseFromUrl(truncateLongString(ad.buttonURL, 37))}
              </div>
            </div>
            <div className="preview-ad-phone-preview-image-wrapper-bottom-content-right">
              {ad.buttonLabel && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={ad.buttonURL}
                  className="preview-ad-phone-preview-image-wrapper-bottom-content-right-text">
                  <span>{ad.buttonLabel}</span> <ChevronRightIcon />
                </a>
              )}
            </div>
          </div>
        </div>
        {ad.location && (
          <span className="preview-ad-phone-address">
            <LocationIcon className="preview-ad-phone-address-icon" />
            {ad.location}
          </span>
        )}
        <div className="preview-ad-phone-preview-bottom-content">
          <div className="preview-ad-phone-preview-bottom-content-reactions">
            <ReactionsIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdFullPreview;
