import React from 'react';
import Button from '../Button';
import {useSteps} from '../../hooks/useSteps';
import {StoreType} from '../../models/enums/registerForm';
import {useTranslation} from 'react-i18next';
import {EColors} from '../../models/enums/colors';
import './styles.scss';

type Props = {
  className?: string;
  nextDisabled: boolean;
  onNext: () => void;
  setBackDisplay?: (value: StoreType) => void;
};

const PreviousNextButtons: React.FC<Props> = ({
  className = '',
  nextDisabled,
  onNext,
  setBackDisplay,
}) => {
  const {t} = useTranslation('register');
  const {decrementStep} = useSteps();

  const handlePreviousButton = () => {
    if (setBackDisplay) {
      setBackDisplay(StoreType.Unselected);
    } else {
      decrementStep();
    }
  };

  return (
    <div className={`register-bottom-buttons ${className}`}>
      <Button
        customStylesWrapper={{
          width: '160px',
          height: '44px',
          marginRight: '15px',
          border: `1px solid ${EColors.colorBlueRegisterSteps}`,
        }}
        customStylesText={{color: EColors.colorBlueRegisterSteps}}
        label={t('previousButton')}
        onClick={handlePreviousButton}
      />
      <Button
        disable={nextDisabled}
        customStylesWrapper={{
          width: '160px',
          height: '44px',
          background: nextDisabled
            ? EColors.colorGrayishBlue
            : EColors.colorBlueRegisterSteps,
          border: `1px solid ${
            nextDisabled
              ? EColors.colorGrayishBlue
              : EColors.colorBlueRegisterSteps
          }`,
        }}
        customStylesText={{color: EColors.colorWhite}}
        label={t('nextButton')}
        onClick={() => onNext()}
      />
    </div>
  );
};

export default PreviousNextButtons;
