import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ButtonUploadImage from '../../../../../../components/ButtonUploadImage';
import Subtitle from '../Subtitle';
import Title from '../Title';
import XIcon from '../../../../../../assets/icons/close-hover.svg';
import DefaultProfilePicture from '../../../../../../assets/images/DefaultProfilePicture.png';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../../../redux';
import {createImageFile} from '../../../../../../utils/createImageFile';
import './styles.css';

type Props = {
  profileImage: any;
  setProfileImage: (value: any) => void;
};

const ProfilePicture: React.FC<Props> = ({profileImage, setProfileImage}) => {
  // hooks
  const {t} = useTranslation('register');
  const {data} = useSelector((state: RootState) => state.register);

  // state
  const [profileImagePreview, setProfileImagePreview] = useState<any>(
    DefaultProfilePicture,
  );

  // effects
  useEffect(() => {
    if (data.profileImage && data.profileImage.length) {
      setProfileImagePreview(data.profileImage);

      const getImageFile = async () => {
        // convert image url to file
        try {
          const imageFile = await createImageFile(
            data.profileImage,
            'profileImage',
          );
          setProfileImage(imageFile);
        } catch (e) {
          console.log('error');
        }
      };
      getImageFile();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileImage) {
      const imageUrl = URL.createObjectURL(profileImage);
      setProfileImagePreview(imageUrl);
    }
  }, [profileImage]);

  // functions
  const removeProfilePicture = () => {
    setProfileImage(null);
    setProfileImagePreview(DefaultProfilePicture);
  };

  return (
    <>
      <Title text={t('profileImageTitle')} />
      <Subtitle text={t('profileImageSubtitle')} />
      <div className="business-images-files">
        <img
          className="business-images-profile-picture"
          src={profileImagePreview}
          width={80}
          height={80}
          alt="buisiness-profile"
        />
        {/*TODO:  This component should be reusable */}
        {profileImage && (
          <div
            onClick={() => removeProfilePicture()}
            className="business-images-close">
            <img src={XIcon} width={12} height={12} alt="close-icon" />
          </div>
        )}

        <div className="business-images-upload-button">
          <ButtonUploadImage
            acceptVideo={false}
            hideImageName={true}
            id={'upload-profile-image'}
            label={t('chooseFiles')}
            onClick={setProfileImage}
          />
        </div>
      </div>
    </>
  );
};

export default ProfilePicture;
