const validateEmail = (email: string) => {
  const isValidEmail = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  return isValidEmail && isValidEmail.length > 0;
};

export default validateEmail;

export const validURL = (str: string) => {
  if (!str) {
    return false;
  }

  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(str);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return /^\+[1-9]\d{0,}$/
    .test(phoneNumber);
}

export const validateNumber = (number: string) => {
  const isValid = number
    .match(
      /^[0-9]+$/
    );
    return isValid && isValid.length > 0;
}

export const validateAtLeastOneUppercase = (value: string) => {
  const isValid = value
    .match(
      /(.*?[A-Z])/g
    );
    return isValid && isValid.length > 0;
}

export const validateAtLeastOneNumber = (value: string) => {
  const isValid = value
    .match(
      /(.*?[0-9])/g
    );
    return isValid && isValid.length > 0;
}

export const validateAtLeastOneSpecialCharacter = (value: string) => {
  const isValid = value
    .match(
      /(.*?[#?!@/=$.%\]^[&*-])/g
    );
    return isValid && isValid.length > 0;
}