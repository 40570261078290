import React, {useEffect} from 'react';
import LoginForm, {IFormValues} from './components/LoginForm';
import {Formik} from 'formik';
import {Loader} from '../../components/Loader';
import {EColors} from '../../models/enums/colors';
import {useAuth} from '../../hooks/useAuth';
import {setStep} from '../../redux/actions/registerActions';
import {ErrorCodes} from '../../models/enums/errors';
import {EMenuViews} from '../../models/enums/generals';
import {useNavigate} from 'react-router';
import {useDispatch} from 'react-redux';
import {useAnalytics} from '../../hooks/useAnalytics';
import {useTranslation} from 'react-i18next';
import {IAuthenticateRequest} from '../../models/api/authenticate';
import {EAnalyticsCustomEventNames} from '../../models/enums/analytics';
import LoginArea from './components/LoginArea';
import LinkButton from '../../components/LinkButton';
import './styles.scss';
import pages from '../../constants/pages';

const LoginPage = () => {
  /* Hooks */
  const {t} = useTranslation('login');
  const {eventTrack} = useAnalytics();
  const {signIn, isLoading, error, errorCode, forgotPasswordRequestResetState} =
    useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* Data */
  const formInitialValues: IFormValues = {
    email: '',
    password: '',
  };
  const customStyleLinkButton = {
    color: EColors.colorWhite,
    marginTop: '10px',
    marginBottom: '30px',
  };
  const customStyleLinkButtonForgotPassword = {
    color: EColors.colorWhite,
    marginTop: '30px',
  };

  /* Effects */
  useEffect(() => {
    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {page_view: EMenuViews.LogIn},
    });
    forgotPasswordRequestResetState();
  }, []);

  /* Handlers */
  const handleLoginSuccess = () => {
    console.log('Login success? navigate to home');
  };

  const handleLogin = (values: IFormValues) => {
    if (isLoading || error) {
      return;
    }

    const credentials: IAuthenticateRequest = {
      username: values.email,
      password: values.password,
    };

    signIn(credentials, handleLoginSuccess);
  };

  const handleSignUp = () => {
    dispatch(setStep(1));
    navigate(pages.signUp);
  };
  const handleForgotPassword = () => {
    navigate(pages.forgotPassword);
  };

  return (
    <div data-testid={'login-page'} className="login-page">
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <LoginArea
          isOpacity={
            error &&
            errorCode !== ErrorCodes.userNotFound &&
            errorCode !== ErrorCodes.invalidCredentials &&
            errorCode !== ErrorCodes.invalidUserRole
          }>
          {!error ||
          !errorCode ||
          errorCode === ErrorCodes.userNotFound ||
          errorCode === ErrorCodes.invalidCredentials ||
          errorCode === ErrorCodes.invalidUserRole ? (
            <span className="title">{t('title')}</span>
          ) : null}
          <div className="form-container">
            <Formik initialValues={formInitialValues} onSubmit={handleLogin}>
              <LoginForm />
            </Formik>
          </div>
        </LoginArea>
        <LinkButton
          onClick={() => handleForgotPassword()}
          customStyle={customStyleLinkButtonForgotPassword}>
          <>{t('forgotPasswordTitle')}</>
        </LinkButton>
        <LinkButton
          onClick={() => handleSignUp()}
          customStyle={customStyleLinkButton}>
          <>
            {t('dontHaveAccount')}&nbsp;<strong>{t('signUp')}</strong>
          </>
        </LinkButton>
      </div>
    </div>
  );
};

export default LoginPage;
