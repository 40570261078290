import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {DEFAULT_TIME_ZONE_NAME} from '../../../../constants/dates';
import {EColors} from '../../../../models/enums/colors';
import {EPopupType} from '../../../../models/enums/ui';
import {Form, Formik} from 'formik';
import {IBusinessHours} from '../../../../models/interfaces/generals';
import {IOptionsTimeZone} from '../../../../models/interfaces/register';
import {isTimeRangeRepeated} from '../../../../utils/businessHoursValidation';
import {selectFieldStyles} from '../../../../constants/themes';
import {setRegisterData} from '../../../../redux/actions/registerActions';
import {timeZones, itemsDays} from '../../../../constants/register';
import {uniqueId} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useSteps} from '../../../../hooks/useSteps';
import {useTranslation} from 'react-i18next';
import {
  showPopup,
  setTimeZoneNameState,
} from '../../../../redux/actions/uiActions';
import BusinessDay from '../../../../components/BusinessDay';
import LinkButton from '../../../../components/LinkButton';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

export interface IBusinessHoursForm {
  timeZone: string;
  formIsValid: boolean;
}

const BusinessHourStep = () => {
  //Hooks
  const {incrementStep} = useSteps();
  const {register, ui} = useSelector((state: AppState) => state);
  const {t} = useTranslation('register');
  const dispatch = useDispatch();
  const stateRegister = register;

  //Data
  const timeZoneState = ui.timeZoneName;
  const initialValues: IBusinessHoursForm = {
    timeZone: timeZoneState || DEFAULT_TIME_ZONE_NAME,
    formIsValid: true,
  };

  //States
  const [businessHours, setBusinessHours] = useState<IBusinessHours[]>(
    stateRegister.data.businessHours,
  );
  const [currentDay, setCurrentDay] = useState(0);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [showDeleteHours, setShowDeleteHours] = useState<boolean>(false);
  const [timeZoneName, setTimeZoneName] = useState(timeZoneState);
  const [timeZoneOptions, setTimeZoneOptions] = useState<IOptionsTimeZone[]>(
    [],
  );

  //Effects
  useEffect(() => {
    getTimeZoneOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessHours.length) {
      isTimerangeCorrect(businessHours);
      setShowDeleteHours(true);
    } else {
      setShowDeleteHours(false);
      setIsDisabledButton(true);
    }
  }, [businessHours]);

  useEffect(() => {
    dispatch(setTimeZoneNameState(timeZoneName));
  }, [timeZoneName]);

  // Handlers - Functions
  const handleDeleteAllHours = () => {
    setBusinessHours([]);
    setShowDeleteHours(false);
  };

  const getTimeZoneOptions = () => {
    const items: IOptionsTimeZone[] = [];
    for (const [, utc] of Object.entries(timeZones)) {
      items.push({
        value: utc.id,
        label: utc.name,
      });
    }
    setTimeZoneOptions(items);
  };

  const isTimerangeCorrect = (businessHours: IBusinessHours[]) => {
    const filterObjectsCurrentDay = businessHours.filter(
      (e: IBusinessHours) => e.weekDay === currentDay,
    );

    if (isTimeRangeRepeated(filterObjectsCurrentDay)) {
      setIsDisabledButton(true);
      dispatch(
        showPopup(
          t('global:generalMessages:errorTimeRange'),
          3000,
          EPopupType.Error,
        ),
      );
    } else {
      setIsDisabledButton(false);
    }
  };

  return (
    <div className="business-hour-step-form">
      {showDeleteHours && (
        <LinkButton
          className="delete-all-hours-action"
          onClick={handleDeleteAllHours}>
          {t('hours:deleteAllHours')}
        </LinkButton>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          const {timeZone} = values;
          dispatch(
            setRegisterData({
              businessHours,
              timeZone,
            }),
          );
          incrementStep();
        }}>
        {({values, handleSubmit, setFieldValue}) => {
          const {timeZone} = values;

          return (
            <Form>
              {itemsDays().map((e, idx) => {
                return (
                  <BusinessDay
                    businessHours={businessHours}
                    day={e}
                    key={uniqueId()}
                    keyItemDay={idx}
                    setBusinessHours={setBusinessHours}
                    setChangeCurrentDay={setCurrentDay}
                  />
                );
              })}

              <SelectField
                items={timeZoneOptions}
                onChange={value => {
                  setTimeZoneName(value.value);
                  setFieldValue('timeZone', value.value);
                }}
                placeHolder={t('businessHoursTimezonePlaceholder')}
                label={t('businessHoursTimezoneTitle')}
                isEdit={true}
                customStylesComponent={selectFieldStyles.selectField}
                defaultOption={timeZone}
              />

              <div className="business-description-bottom-buttons">
                <LinkButton
                  onClick={() => incrementStep()}
                  customStyle={{color: EColors.colorBlueRegisterSteps}}>
                  {t('global:skip')}
                </LinkButton>
                <PreviousNextButtons
                  onNext={handleSubmit}
                  nextDisabled={isDisabledButton}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BusinessHourStep;
