import React, {useEffect} from 'react';
import Button from '../../../../components/Button';
import MyAddresses from '../../../../components/MyAddresses';
import {AppState} from '../../../../redux';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useTranslation} from 'react-i18next';
import {EMenuViews, ETabsViews} from '../../../../models/enums/generals';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {
  EPanelFormProfile,
  ProfileStoreType,
} from '../../../../models/enums/profile';
import {useDispatch, useSelector} from 'react-redux';
import {
  setHasErrors,
  setProfileData,
  setSelecteedAddress,
} from '../../../../redux/actions/profileActions';
import {
  ILocationData,
  IPanelFormProfile,
} from '../../../../models/interfaces/profile';
import './styles.scss';

type Props = {
  setShowPanelForm: (val: IPanelFormProfile) => void;
};

const BusinessLocation: React.FC<Props> = ({setShowPanelForm}) => {
  //Hooks
  const {eventTrack} = useAnalytics();
  const {t} = useTranslation('register');
  const dispatch = useDispatch();
  const stateProfile = useSelector((state: AppState) => state.profile);

  //Data
  const businessLocationData = stateProfile.data.businessLocations;

  // Effects
  useEffect(() => {
    dispatch(setProfileData({showSaveBtns: true}));
    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {
        current_tab_view: ETabsViews.BusinessLocation,
        page_view: EMenuViews.Profile,
        sub_page_view: EMenuViews.MyBusiness,
      },
    });
  }, []);

  // Handlers - Functions
  const handleEditLocation = (positionIdx: number) => {
    dispatch(setSelecteedAddress(positionIdx));
    setShowPanelForm({open: true, type: EPanelFormProfile.Location});
  };

  const handleSelectLocation = (positionIdx: number) => {
    const setMainLocation = businessLocationData.map(
      (elem: ILocationData, index: number) => {
        return {
          ...elem,
          primary: index === positionIdx ? true : false,
        };
      },
    );

    dispatch(
      setProfileData({
        businessLocationType: ProfileStoreType.Physical,
        businessLocations: setMainLocation,
      }),
    );
  };

  const handleRemoveLocation = (positionIdx: number) => {
    const locationsState = businessLocationData;
    locationsState.splice(positionIdx, 1);
    dispatch(
      setProfileData({
        businessLocations: locationsState,
      }),
    );
    if (locationsState.length === 0) {
      dispatch(
        setHasErrors({
          ...stateProfile.hasErrors,
          location: true,
        }),
      );
    }
  };

  return (
    <div className="business-location-list-wrapper">
      <MyAddresses
        handleEditLocation={handleEditLocation}
        handleRemoveLocation={handleRemoveLocation}
        handleSelectLocation={handleSelectLocation}
      />

      <div className="btn-add-new-location-wrapper">
        <Button
          label={t('profilePage:btnLocationAddNewLocation')}
          onClick={() => {
            dispatch(setSelecteedAddress(null));
            setShowPanelForm({open: true, type: EPanelFormProfile.Location});
          }}
        />
      </div>
    </div>
  );
};

export default BusinessLocation;
