import {IAuthenticateState} from '../../models/interfaces/authenticateState';

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const CLEAR_AUTH_USER = 'CLEAR_AUTH_USER';
export const SET_AUTH_IS_LOADING = 'SET_AUTH_IS_LOADING';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
interface ISetAuthUserAction {
  type: typeof SET_AUTH_USER;
  payload: IAuthenticateState;
}

interface IClearAuthUser {
  type: typeof CLEAR_AUTH_USER;
}

interface ISetAuthError {
  type: typeof SET_AUTH_ERROR;
  payload: string;
}

interface ISetAuthIsLoading {
  type: typeof SET_AUTH_IS_LOADING;
  payload: boolean;
}

interface IClearAuthErrors {
  type: typeof CLEAR_AUTH_ERRORS;
}

export type AuthUserActionTypes =
  | ISetAuthUserAction
  | IClearAuthUser
  | ISetAuthError
  | ISetAuthIsLoading
  | IClearAuthErrors;
