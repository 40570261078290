import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.scss';

interface IProps {
  customStyles?: {
    container?: React.CSSProperties;
    label?: React.CSSProperties;
    input?: React.CSSProperties;
  };
  disabled?: boolean;
  error: boolean;
  hideLabel?: boolean;
  id: string;
  isOptional?: boolean;
  label: string;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  placeHolder: string;
  required?: boolean;
  type?: string;
  value: string;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onInputChange: (e: any) => void;
  allowPaste?: boolean;
}

const InputText = ({
  customStyles = {
    container: {},
    input: {},
    label: {},
  },
  disabled = false,
  error,
  hideLabel = false,
  id,
  isOptional,
  label = '',
  maxLength = 20,
  minLength = 0,
  pattern = undefined,
  placeHolder,
  required = false,
  type = 'email',
  value,
  onBlur,
  onFocus,
  onInputChange,
  allowPaste = true
}: IProps) => {
  const {t} = useTranslation();

  return (
    <div className="input-text-wrapper" style={customStyles.container}>
      {!hideLabel && (
        <label
          className={'field-label'}
          htmlFor={id}
          style={customStyles.label}>
          {label ? label : placeHolder}
          {isOptional && (
            <span className="is-optional">{t('global:optional')}</span>
          )}
        </label>
      )}
      <input
        autoComplete={'off'}
        className={`field-input ${disabled ? 'disabled' : ''} ${
          error ? 'error' : ''
        }`}
        data-testid={'login-page-user-input'}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        minLength={minLength}
        name={id}
        onBlur={onBlur}
        onChange={onInputChange}
        onFocus={onFocus}
        pattern={pattern}
        placeholder={placeHolder}
        required={required}
        style={customStyles.input}
        type={type}
        value={value}
        onPaste={(e: any) => {
          if (!allowPaste) {
            e.preventDefault();
            return false;
          } else {
            return null;
          }
        }}
      />
    </div>
  );
};

export default InputText;
