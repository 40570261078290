import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {EMenuViews, ETabsViews} from '../../../../models/enums/generals';
import {ErrorTypes} from '../../../../models/enums/registerForm';
import {Form, Formik, FormikErrors} from 'formik';
import {HTTPS_TEXT} from '../../../../constants/urls';
import {isEmptyValue} from '../../../../utils/isEmpty';
import {TextField} from '../../../../components/TextField';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useCategories} from '../../../../hooks/useCategories';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validateUrl} from '../../../../utils/validateUrl';
import {
  getBusinessIdentification,
  setHasErrors,
  setProfileData,
} from '../../../../redux/actions/profileActions';
import CompleteTextField from '../../../../components/CompleteTextField';
import ErrorsValidations from '../../../../components/ErrorsValidations/index';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

export interface ICategoryOption {
  label: string;
  value: number;
}

export interface IBusinessInformationForm {
  businessDescription: string;
  businessName: string;
  categorySelected: ICategoryOption;
  community: number;
  formIsValid: boolean;
  subCategories: number[];
  webSiteUrl: string;
}

const BusinessInformation = () => {
  //Hooks
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation('register');
  const {eventTrack} = useAnalytics();
  const {ui, profile} = useSelector((state: AppState) => state);
  const {businessIdentification, data, hasErrors, errorCode, msgError} =
    profile;

  //Data
  const {
    businessDescription,
    businessName,
    categorySelected,
    community,
    subCategories,
    webSiteUrl,
  } = data;

  const initialValues: IBusinessInformationForm = {
    businessName,
    categorySelected,
    community,
    businessDescription,
    subCategories,
    formIsValid: true,
    webSiteUrl: webSiteUrl || HTTPS_TEXT,
  };

  //States
  const [businessNameValue, setBusinessName] = useState<string>(businessName);
  const [businessDescriptionValue, setBusinessDescriptionValue] =
    useState<string>(businessDescription);
  const [categories, setCategories] = useState<ICategoryOption[]>([]);
  const [communitiesOptions, setCommunitiesOptions] = useState<object[]>([]);
  const [communityValue, setCommunityValue] = useState<number | null>(null);
  const [firstLoader, setFirstLoader] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] =
    useState<ICategoryOption>(categorySelected);
  // Effects
  useEffect(() => {
    setFirstLoader(false);
    dispatch(setProfileData({showSaveBtns: true}));
    dispatch(getBusinessIdentification(i18n.resolvedLanguage));

    if (ui.communities && ui.communities.length > 0) {
      const options = ui.communities.map((elem: any) => {
        const object: any = {};

        object['value'] = elem.id;
        object['label'] = elem.name;

        return object;
      });
      setCommunitiesOptions(options);
      setFirstLoader(false);
    }

    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {
        current_tab_view: ETabsViews.BusinessInfo,
        page_view: EMenuViews.Profile,
        sub_page_view: EMenuViews.MyBusiness,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessIdentification.categories) {
      const {subCategoriesAsCategories} = useCategories(
        businessIdentification.categories,
      );
      const optionsArray: ICategoryOption[] = createArrayOfOptions(
        subCategoriesAsCategories,
      );
      setCategories(optionsArray);

      setSelectedCategory(
        optionsArray.filter(
          (item: ICategoryOption) =>
            item.value === profile.data.subCategories[0].id,
        )[0],
      );
    }
  }, [businessIdentification]);

  useEffect(() => {
    // subcategories options
    if (
      Object.keys(selectedCategory).length > 0 &&
      businessIdentification.categories
    ) {
      dispatch(
        setProfileData({
          categorySelected: selectedCategory,
          subCategories: [
            {
              id: selectedCategory.value,
              name: selectedCategory.label,
            },
          ],
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (!firstLoader) {
      dispatch(
        setProfileData({
          community: communityValue,
        }),
      );
    }
  }, [communityValue]);

  // functions
  const createArrayOfOptions = (values: any[]) => {
    const optionsArray: ICategoryOption[] = [];
    values.forEach((element: any) => {
      optionsArray.push({label: element.name, value: element.id});
    });
    return optionsArray;
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validate={(values: IBusinessInformationForm) => {
        const errors: FormikErrors<IBusinessInformationForm> = {};
        const {webSiteUrl} = values;

        // Mandatories
        if (isEmptyValue(businessNameValue) || isEmptyValue(webSiteUrl)) {
          errors.formIsValid = ErrorTypes.Required;
        } else {
          dispatch(
            setProfileData({
              businessName: businessNameValue,
              businessDescription: businessDescriptionValue,
            }),
          );
        }

        // WebsiteUrl
        if (!isEmptyValue(webSiteUrl) && !validateUrl(webSiteUrl)) {
          errors.webSiteUrl = ErrorTypes.InvalidUrlFormat;
        } else {
          dispatch(
            setProfileData({
              webSiteUrl,
            }),
          );
        }

        // Block General Buttom
        if (Object.entries(errors).length > 0) {
          dispatch(
            setHasErrors({
              ...hasErrors,
              information: true,
            }),
          );
        } else {
          dispatch(
            setHasErrors({
              ...hasErrors,
              information: false,
            }),
          );
        }

        return errors;
      }}
      onSubmit={() => {
        // TODO: We need this callback to avoid Formik errors
        console.log('Not Submit');
      }}>
      {({errors, handleBlur, handleChange, setFieldValue, touched, values}) => {
        const {webSiteUrl, community} = values;

        return (
          <Form>
            <div className="business-information-form">
              <div className="business-information-first-wrapper">
                <CompleteTextField
                  hideLabel={false}
                  id="businessName"
                  label={t('businessName')}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeholder={t('register:businessDescriptionPlaceholder')}
                  setValue={setBusinessName}
                  type="text"
                  value={businessNameValue}
                  withContentValidation={false}
                  withCounter={true}
                  errorMessage={
                    (ErrorTypes.BusinessNameInUse === errorCode && msgError) ||
                    ''
                  }
                  error={ErrorTypes.BusinessNameInUse === errorCode}
                />

                <SelectField
                  defaultOption={selectedCategory.value}
                  isEdit={true}
                  items={categories}
                  label={t('profilePage:businessCategoryFieldTitle')}
                  onChange={setSelectedCategory}
                  placeHolder={t('businessCategoryPlaceholder')}
                />

                <TextField
                  handleChange={handleChange}
                  id="webSiteUrl"
                  label={t('profilePage:businessWebsiteFieldTitle')}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeHolder={t('profilePage:businessWebsiteFieldPlaceholder')}
                  type={'text'}
                  value={webSiteUrl}
                />
                {errors.webSiteUrl === ErrorTypes.InvalidUrlFormat &&
                  touched.webSiteUrl && (
                    <ErrorsValidations type="InvalidUrlFormat" />
                  )}
              </div>
              <div className="business-information-second-wrapper">
                <CompleteTextField
                  hideLabel={false}
                  label={t('register:businessDescription')}
                  maxLength={180}
                  onBlur={handleBlur}
                  placeholder={t('register:businessDescriptionPlaceholder')}
                  setValue={setBusinessDescriptionValue}
                  type="textarea"
                  customStyles={{
                    generalContainer: {height: '155px'},
                    input: {height: '124px'},
                  }}
                  value={businessDescriptionValue}
                  withContentValidation={false}
                  withCounter={true}
                />

                <SelectField
                  defaultOption={community}
                  isEdit={true}
                  items={communitiesOptions}
                  label={t('profilePage:fieldCommunityTitle')}
                  placeHolder={t('profilePage:fieldCommunityPlaceholder')}
                  onChange={value => {
                    setFieldValue('community', value.value);
                    setCommunityValue(value.value);
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BusinessInformation;
