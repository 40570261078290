import React, {useEffect} from 'react';
import Button from '../../../../components/Button';
import FieldImage from './components/FieldImage';
import FieldTitle from '../../../../components/FieldTitle/FieldTitle';
import FieldHeadline from './components/FieldHeadline';
import FieldLocation from '../../../CreateDeal/components/CreateDealForm/components/FieldLocation';
import DurationField from '../../../../components/DurationField';
import FieldDescription from './components/FieldDescription';
import CommunitiesField from '../../../../components/CommunitiesField';
import CallToActionField from './components/CallToActionField';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import FormPackagesField from '../../../../components/FormPackagesField';
import {useAds} from '../../../../hooks/useAds';
import {EColors} from '../../../../models/enums/colors';
import {AppState} from '../../../../redux';
import {IPackage} from '../../../../models/api/packages';
import {ErrorCodes} from '../../../../models/enums/errors';
import {buttonsStyles} from '../../../../constants/themes';
import {useTranslation} from 'react-i18next';
import {setClearAdData} from '../../../../redux/actions/adActions';
import {useDispatch, useSelector} from 'react-redux';
import {
  ETypeCommunication,
  EVocabularyFields,
} from '../../../../models/enums/generals';
import {
  PackageType,
  TypeActionModal,
} from '../../../../models/enums/typeAction';
import 'react-dropdown/style.css';
import './styles.scss';

type Props = {
  currentPackageSelection: IPackage | null;
  id: number | null;
  isEditPage: boolean;
  packageList: IPackage[];
  setCurrentPackageSelection: (currentPackage: IPackage) => void;
};

const CreateAdForm: React.FC<Props> = ({
  currentPackageSelection,
  id,
  isEditPage,
  packageList,
  setCurrentPackageSelection,
}) => {
  // Hooks
  const {t} = useTranslation();
  const {ad, ui} = useSelector((state: AppState) => state);
  const {
    addressSelected,
    community,
    date,
    defaultDate,
    disabledButton,
    errorCode,
    errorMessage,
    handleFieldIsValid,
    image,
    isLoadingMotion,
    isLoadingSubmit,
    modalIsOpen,
    onCommunityChange,
    onDateChange,
    onDescriptionChange,
    onHeadlineChange,
    onTitleChange,
    onWeeksChange,
    setAddressSelected,
    setDisabledButton,
    setErrorCode,
    setImage,
    setIsLoadingMotion,
    setIsOpen,
    setShareLocation,
    shareLocation,
    submit,
    validatedFields,
    width,
  } = useAds({isEditPage});
  const dispatch = useDispatch();

  // Effects
  useEffect(() => {
    dispatch(setClearAdData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{width: width}} className="create-ad-form">
      <FieldTitle
        isValid={
          validatedFields.find(f => f.fieldId === EVocabularyFields.Title)
            ?.isValid
        }
        setIsValid={value => handleFieldIsValid(EVocabularyFields.Title, value)}
        setValue={onTitleChange}
        typeCommunication={ETypeCommunication.Ad}
        value={ad.title}
      />

      <FieldDescription
        isValid={
          validatedFields.find(f => f.fieldId === EVocabularyFields.Description)
            ?.isValid
        }
        setIsValid={value =>
          handleFieldIsValid(EVocabularyFields.Description, value)
        }
        setValue={onDescriptionChange}
        styles={{}}
        textAreaOptions={{rows: 2, cols: 30, wrap: 'hard'}}
        value={ad.description}
      />

      <FieldImage image={image} setImage={setImage} />

      <FieldHeadline
        setValue={onHeadlineChange}
        styles={{}}
        value={ad.headline}
      />

      <CallToActionField isEdit={isEditPage} />

      <FieldLocation
        addressSelected={addressSelected}
        error={errorCode === ErrorCodes.invalidLocation && errorMessage}
        setAddressSelected={setAddressSelected}
        setErrorCode={setErrorCode}
        setShareLocation={setShareLocation}
        shareLocation={shareLocation}
      />

      <CommunitiesField
        currentSelection={community}
        setSelected={onCommunityChange}
      />

      <FormPackagesField
        currentPackageSelection={currentPackageSelection}
        onSelect={setCurrentPackageSelection}
        packageList={packageList}
        packageType={PackageType.Ad}
      />

      <DurationField
        date={date}
        defaultDate={isEditPage ? defaultDate : date}
        disabledField={isEditPage}
        maxWeeks={1} //TODO: Update when there are more packages
        setDate={onDateChange}
        setWeeks={onWeeksChange}
        weeks={ad.weeks}
      />

      <div className="submit-button">
        <Button
          customStylesText={{color: EColors.colorWhite}}
          customStylesWrapper={
            disabledButton ? buttonsStyles.disabledButton : {}
          }
          disable={disabledButton}
          isLoading={isLoadingMotion}
          label={isEditPage ? t('global:save') : t('createAd:publishButton')}
          onClick={() => {
            if (ui.hideConfirmationModal) {
              setIsLoadingMotion(!isLoadingMotion);
              setDisabledButton(true);
              submit(currentPackageSelection, id);
            } else {
              setIsOpen(true);
            }
          }}
        />
      </div>

      {modalIsOpen && (
        <ModalConfirmation
          actionType={TypeActionModal.Confirm}
          isLoading={isLoadingSubmit}
          modalIsOpen={modalIsOpen}
          onConfirm={() => submit(currentPackageSelection, id)}
          setIsOpen={setIsOpen}
          subtitle={
            isEditPage
              ? t('createAd:confirmPublishMsgEdit')
              : t('createAd:confirmPublishMsg')
          }
          title={t('global:areYouSure')}
          type="confirm"
        />
      )}
    </div>
  );
};

export default CreateAdForm;
