import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {EMenuViews, ETabsViews} from '../../../../models/enums/generals';
import {EPanelFormProfile} from '../../../../models/enums/profile';
import {ErrorTypes} from '../../../../models/enums/registerForm';
import {Form, Formik, FormikErrors} from 'formik';
import {isEmptyValue} from '../../../../utils/isEmpty';
import {IPanelFormProfile} from '../../../../models/interfaces/profile';
import {TextField} from '../../../../components/TextField';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validatePhoneNumber} from '../../../../services/app/validations';
import {
  setHasErrors,
  setProfileData,
} from '../../../../redux/actions/profileActions';
import ErrorsValidations from '../../../../components/ErrorsValidations/index';
import PhoneNumber from '../../../Register/components/PhoneNumber';
import ReactCountryFlag from 'react-country-flag';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

type Props = {
  setShowPanelForm: (val: IPanelFormProfile) => void;
};

export interface IMyInformationForm {
  formIsValid: boolean;
  userCommunity: number | null;
  userCountryFlag: string;
  userFirstName: string;
  userLastName: string;
  userNewPassword: string;
  userPhoneCode: string;
  userPhoneNumber: string;
  userUsername: string;
}

const MyInformation: React.FC<Props> = ({setShowPanelForm}) => {
  //Hooks
  const dispatch = useDispatch();
  const stateProfile = useSelector((state: AppState) => state.profile);
  const {eventTrack} = useAnalytics();
  const {ui} = useSelector((state: AppState) => state);
  const {t} = useTranslation('register');

  //Data
  const {
    userCommunity,
    userCountryFlag,
    userFirstName,
    userLastName,
    userNewPassword,
    userPhoneCode,
    userPhoneNumber,
    userUsername,
  } = stateProfile.data;

  const initialValues: IMyInformationForm = {
    formIsValid: true,
    userCommunity,
    userCountryFlag,
    userFirstName,
    userLastName,
    userNewPassword,
    userPhoneCode,
    userPhoneNumber,
    userUsername,
  };

  //States
  const [communitiesOptions, setCommunitiesOptions] = useState<object[]>([]);
  const [countriesOptions, setCountriesOptions] = useState<object[]>([]);
  const [firstLoader, setFirstLoader] = useState<boolean>(true);
  const [phoneCodeValue, setPhoneCode] = useState<string>(userPhoneCode);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>(
    userPhoneNumber || phoneCodeValue,
  );
  const [communityValue, setCommunityValue] = useState<number | null>(null);
  const [countryFlagValue, setCountryFlagValue] = useState('US');

  //Effects
  useEffect(() => {
    dispatch(setProfileData({showSaveBtns: true}));
    setFirstLoader(false);

    if (ui.communities && ui.communities.length > 0) {
      const options = ui.communities.map((elem: any) => {
        const object: any = {};

        object['value'] = elem.id;
        object['label'] = elem.name;

        return object;
      });
      setCommunitiesOptions(options);
    }

    if (stateProfile.countries && stateProfile.countries.length > 0) {
      const options = stateProfile.countries?.map((elem: any) => {
        const object: any = {};

        object['value'] = elem.alpha2Code;
        object['label'] = (
          <ReactCountryFlag svg countryCode={elem.alpha2Code} />
        );
        object['phoneCode'] = elem.phoneCode;

        return object;
      });
      setCountriesOptions(options);
    }

    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {
        current_tab_view: ETabsViews.MyInformation,
        page_view: EMenuViews.Profile,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoader) {
      setPhoneNumberValue(`${phoneCodeValue}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCodeValue]);

  useEffect(() => {
    if (!firstLoader) {
      dispatch(
        setProfileData({
          userCommunity: communityValue,
        }),
      );
    }
  }, [communityValue]);

  useEffect(() => {
    if (!firstLoader) {
      dispatch(
        setProfileData({
          userCountryFlag: countryFlagValue,
        }),
      );
    }
  }, [countryFlagValue]);

  //Functions

  const handleChangePhoneNumber = (e: any) => {
    const isValid = e.target.validity.valid;
    const value = e.target.value;
    if (isValid) {
      setPhoneNumberValue(value);
    }
  };

  return (
    <div className="my-information-form">
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validate={(values: IMyInformationForm) => {
          const {userCountryFlag, userFirstName, userLastName} = values;
          const errors: FormikErrors<IMyInformationForm> = {};

          // Mandatories
          if (isEmptyValue(userFirstName) || isEmptyValue(userLastName)) {
            errors.formIsValid = ErrorTypes.Required;
          } else {
            dispatch(
              setProfileData({
                userFirstName,
                userLastName,
                userUsername,
              }),
            );
          }

          // PhoneNumber
          if (
            !isEmptyValue(phoneNumberValue) &&
            !validatePhoneNumber(phoneNumberValue)
          ) {
            errors.userPhoneNumber = ErrorTypes.InvalidPhoneNumber;
          } else {
            dispatch(
              setProfileData({
                userCountryFlag,
                userPhoneCode: phoneCodeValue,
                userPhoneNumber: phoneNumberValue,
              }),
            );
          }

          // Block General Buttom
          if (Object.entries(errors).length > 0) {
            dispatch(
              setHasErrors({
                ...stateProfile.hasErrors,
                vendorInformation: true,
              }),
            );
          } else {
            dispatch(setProfileData({showSaveBtns: true}));
            dispatch(
              setHasErrors({
                ...stateProfile.hasErrors,
                vendorInformation: false,
              }),
            );
          }

          return errors;
        }}
        onSubmit={() => {
          // TODO: We need this callback to avoid Formik errors
          console.log('Not Submit');
        }}>
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => {
          const {userCommunity, userCountryFlag, userFirstName, userLastName} =
            values;

          return (
            <Form>
              <div className="basic-info-wrapper">
                <TextField
                  id="userFirstName"
                  value={userFirstName}
                  type={'text'}
                  label={t('register:setUpYourAccountNameTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={150}
                />

                <TextField
                  id="userLastName"
                  value={userLastName}
                  type={'text'}
                  label={t('register:setUpYourAccountLastNameTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={150}
                />

                <div className="change-password-wrapper">
                  <TextField
                    disabled={true}
                    handleChange={handleChange}
                    id="userUsername"
                    label={t('setUpYourAccountEmailTitle')}
                    maxLength={150}
                    type={'email'}
                    value={stateProfile.data.userUsername}
                  />
                  <span
                    className="change-pass-text"
                    onClick={() =>
                      setShowPanelForm({
                        open: true,
                        type: EPanelFormProfile.Email,
                      })
                    }>
                    {t('profilePage:changeEmailText')}
                  </span>
                </div>

                <div className="change-password-wrapper">
                  <TextField
                    disabled={true}
                    handleChange={handleChange}
                    id="userNewPassword"
                    label={t('setUpYourAccountPasswordTitle')}
                    placeHolder={t('profilePage:fieldNewPasswordPlaceholder')}
                    type="password"
                    value={userNewPassword}
                  />
                  <span
                    className="change-pass-text"
                    onClick={() =>
                      setShowPanelForm({
                        open: true,
                        type: EPanelFormProfile.Password,
                      })
                    }>
                    {t('profilePage:changePasswordText')}
                  </span>
                </div>

                <PhoneNumber isOptional={true} title={t('phoneNumberField')}>
                  <div className="phone-number-wrapper">
                    <SelectField
                      items={countriesOptions}
                      onChange={value => {
                        setFieldValue('userCountryFlag', value.value);
                        setCountryFlagValue(value.value);
                        setPhoneCode(`+${value.phoneCode}`);
                      }}
                      placeHolder=""
                      label=""
                      isEdit={true}
                      defaultOption={userCountryFlag}
                    />

                    <TextField
                      id="userPhoneNumber"
                      value={phoneNumberValue}
                      type={'text'}
                      handleChange={e => handleChangePhoneNumber(e)}
                      onBlur={handleBlur}
                      errorElement={
                        errors.userPhoneNumber ===
                          ErrorTypes.InvalidPhoneNumber &&
                        touched.userPhoneNumber && (
                          <ErrorsValidations type="InvalidPhoneNumber" />
                        )
                      }
                      pattern="(\+)?([0-9]){0,}"
                    />
                  </div>
                </PhoneNumber>
              </div>

              <div className="social-info-wrapper">
                <SelectField
                  defaultOption={userCommunity || undefined}
                  isEdit={true}
                  items={communitiesOptions}
                  label={t('profilePage:fieldCommunityTitle')}
                  placeHolder={t('profilePage:fieldCommunityPlaceholder')}
                  onChange={value => {
                    setFieldValue('userCommunity', value.value);
                    setCommunityValue(value.value);
                  }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default MyInformation;
