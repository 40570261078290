import React from 'react';
import Logo from '../../assets/images/Logo.png';

interface IProps {
  width?: number;
  height?: number;
}

const AppLogo = ({width = 177, height = 71}: IProps) => {
  return (
    <div data-testid={'app-logo'}>
      <img src={Logo} width={width} height={height} alt="logo" />
    </div>
  );
};

export default AppLogo;
