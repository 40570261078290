import React, {useState, useCallback, useEffect} from 'react';
import {AppState} from '../../../../../../redux';
import {setCreateDealData} from '../../../../../../redux/actions/dealActions';
import {TextField} from '../../../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import FormField from '../../../../../../components/FormField/FormField';

const FieldOfferTag = () => {
  const {t} = useTranslation('createDeal');
  const dispatch = useDispatch();
  const {deal} = useSelector((state: AppState) => state);
  const [offerTag, setOfferTag] = useState<string>('');

  useEffect(() => {
    setOfferTag(deal.offerTag);
  }, [deal]);

  const tooltipHelp = {
    dataFor: 'offer-tag',
    show: true,
    text: t('fieldOfferTooltip'),
  };

  const handleChangeInterceptor = useCallback((e: any) => {
    dispatch(setCreateDealData({[e.target.name]: e.target.value}));
    setOfferTag(e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormField
      withBorder={true}
      title={t('fieldOfferTagTitle')}
      description={t('fieldOfferTagDescription')}
      tooltipFor={tooltipHelp.dataFor}
      tooltipShow={tooltipHelp.show}
      tooltipText={tooltipHelp.text}>
      <TextField
        id={'offerTag'}
        type={'text'}
        value={offerTag}
        handleChange={handleChangeInterceptor}
        maxLength={8}
        placeHolder={t('placeholderOfferTag')}
        hideLabel={true}
      />
    </FormField>
  );
};

export default FieldOfferTag;
