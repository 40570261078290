import {EGeneralDaraErrors} from '../models/enums/errors';
import {HH_MM_FORMAT} from '../constants/dates';
import {IBusinessHours} from '../models/interfaces/generals';
import moment from 'moment-timezone';

export const setNewMomentDate = (date: string | number | Date): string => {
  return moment(date).month(0).date(1).format();
};

export const isValidTimeRange = (
  openTime: string,
  closeTime: string,
): boolean => {
  return (
    openTime !== EGeneralDaraErrors.invalidDate &&
    closeTime !== EGeneralDaraErrors.invalidDate
  );
};

export const isOpenTimeShorter = (openTime: string, closeTime: string) => {
  const formatedOpenTime = moment(openTime).format(HH_MM_FORMAT);
  const formatedCloseTime = moment(closeTime).format(HH_MM_FORMAT);

  if (formatedOpenTime < formatedCloseTime) {
    return true;
  }
  return false;
};

export const isTimeRangeRepeated = (businessHours: IBusinessHours[]) => {
  const checkOverlap = (
    schedule1: IBusinessHours,
    schedule2: IBusinessHours,
  ): boolean => {
    const openTime1 = moment(schedule1.openTime).format(HH_MM_FORMAT);
    const closeTime1 = moment(schedule1.closeTime).format(HH_MM_FORMAT);
    const keyItem1 = schedule1.keyItem;

    const openTime2 = moment(schedule2.openTime).format(HH_MM_FORMAT);
    const closeTime2 = moment(schedule2.closeTime).format(HH_MM_FORMAT);
    const keyItem2 = schedule2.keyItem;

    return (
      keyItem1 !== keyItem2 &&
      openTime1 <= closeTime2 &&
      openTime2 <= closeTime1
    );
  };

  for (const schedule1 of businessHours) {
    if (businessHours.some(schedule2 => checkOverlap(schedule1, schedule2))) {
      return true;
    }
  }
  return false;
};

export const sortBusinessHours = (
  businessHours: IBusinessHours[],
  order: 'asc' | 'desc',
  sortBy: 'weekDay' | 'keyItem' | 'closeTime' | 'openTime',
  sortBy2?: 'weekDay' | 'keyItem' | 'closeTime' | 'openTime',
) => {
  return businessHours.sort((a: any, b: any) => {
    const comparison = sortBy2
      ? a[sortBy] !== b[sortBy]
        ? a[sortBy] > b[sortBy]
        : a[sortBy2] > b[sortBy2]
      : a[sortBy] > b[sortBy];
    return order === 'asc' ? (comparison ? 1 : -1) : comparison ? -1 : 1;
  });
};
