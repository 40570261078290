import {AxiosResponse} from 'axios';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericGet, genericPost, genericPut, genericDelete} from './genericAPI';
import {IBodyListMyDeals} from '../../models/api/deals';
import {IAd, ICreateAd, IUpdateAd} from '../../models/api/ads';

export const getAds = async (
  body: IBodyListMyDeals,
): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  return await genericPost(endpoints.ad.list, token, body);
};

export const createAd = async (
  body: ICreateAd,
): Promise<AxiosResponse<IAd>> => {
  const token = store.getState().auth.token;
  const data = parseAdToFormData(body, 'POST');
  return await genericPost(endpoints.ad.create, token, data, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

export const updateAd = async (
  body: IUpdateAd,
  id: number,
): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  const data = parseAdToFormData(body, 'PUT');

  return await genericPut(`${endpoints.ad.update}${id}`, token, data, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

export const getAdById = async (id: number): Promise<AxiosResponse<any>> => {
  const token = store.getState().auth.token;
  return await genericGet(`${endpoints.ad.getById}${id}`, token);
};
export const deleteAd = async (id: number): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  return await genericDelete(`${endpoints.ad.delete}${id}`, token);
};

const parseAdToFormData = (
  body: ICreateAd,
  method: 'PUT' | 'POST',
): FormData => {
  const data = new FormData();

  if (body.images) {
    body.images.forEach((image: any) => {
      data.append('Images', image);
    });
  }

  if (body.buttonLabel) data.append('ButtonLabel', body.buttonLabel);
  if (body.buttonURL) data.append('ButtonURL', body.buttonURL);
  if (body.communityId) data.append('CommunityId', body.communityId.toString());
  if (body.description) data.append('Description', body.description);
  if (body.headline) data.append('Headline', body.headline);
  if (body.location) data.append('Location', body.location);
  if (body.packageId) data.append('PackageId', body.packageId.toString());
  if (body.title) data.append('Title', body.title);
  if (method === 'POST') {
    if (body.startDate) data.append('StartDate', body.startDate);
    if (body.Weeks) data.append('Weeks', body.Weeks.toString());
  }

  return data;
};
