import React, {useEffect, useState} from 'react';
import EyeEnabled from '../../../assets/icons/eye-enabled.svg';
import EyeDisabled from '../../../assets/icons/eye-disabled.svg';
import {ITextFieldCustomStyles} from '../';
import ReactTooltip from 'react-tooltip';

import './styles.scss';

interface IProps {
  customStyles?: ITextFieldCustomStyles;
  disabled?: boolean;
  error: boolean;
  hideLabel?: boolean;
  id: string;
  label?: string;
  maxLength?: number;
  minLength?: number;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onInputChange: (e: any) => void;
  onKeyDown?: (e: any) => void;
  placeHolder: string;
  required?: boolean;
  showTooltip?: boolean;
  value: string | null;
}

const InputPassword = ({
  customStyles = {},
  disabled = false,
  error,
  hideLabel = true,
  id,
  label = '',
  maxLength = 20,
  minLength = 0,
  onBlur,
  onFocus,
  onInputChange,
  onKeyDown,
  placeHolder,
  required = false,
  showTooltip,
  value,
}: IProps) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showTooltip]);

  return (
    <>
      <div className="input-password-component" style={customStyles.container}>
        {!hideLabel && (
          <label
            className={'field-label'}
            htmlFor={id}
            style={customStyles.label}>
            {label ? label : placeHolder}
          </label>
        )}
        <div className="password-wrapper">
          <input
            id={id}
            name={id}
            type={showPassword ? 'text' : 'password'}
            className={`field-input ${disabled ? 'disabled' : ''} ${
              error ? 'error' : ''
            } ${showPassword && 'field-input-font'}`}
            data-testid={'login-page-user-input'}
            value={value ? value : ''}
            onChange={onInputChange}
            placeholder={placeHolder}
            maxLength={maxLength}
            minLength={minLength}
            required={required}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            style={customStyles.input}
            data-tip
            data-for={'caps-locked'}
            autoComplete={'off'}
            disabled={disabled}
          />
          {value && value.length > 0 && (
            <img
              className="password-eye"
              src={showPassword ? EyeDisabled : EyeEnabled}
              alt="eye"
              onClick={() => setShowPassword(!showPassword)}
              style={customStyles.img}
            />
          )}
        </div>
      </div>

      {showTooltip && (
        <ReactTooltip
          id={'caps-locked'}
          effect="solid"
          place="bottom"
          className="tooltip-position"
          event="focusin"
          eventOff="focusout">
          {'WARNING: CAPS locked is on'}
        </ReactTooltip>
      )}
    </>
  );
};

export default InputPassword;
