import {IResultCommunity} from '../api/community';

export enum EWidthScreens {
  ScreenTablet = 427,
}

export const manaGlobalObject: IResultCommunity = {
  cityName: 'global:international',
  description: 'global:manaGlobalDescription',
  id: null,
  name: 'global:manaGlobal',
};

export enum ETypeCommunication {
  Ad = 'AD',
  Deal = 'DEAL',
}

export enum EMenuViews {
  ConfirmEmail = '/confirmemail',
  CreateAd = '/create-ad',
  CreateDeal = '/create-deal',
  Home = '/home',
  LogIn = '/',
  MyBusiness = '/my-business',
  NewVerificationLink = '/new-verification-link',
  Profile = '/profile-edition',
  Register = '/sign-up',
  ViewAd = '/view-ad',
  ViewDeal = '/view-deal',
}

export enum ETabsViews {
  Ads = 'ads',
  BusinessContact = 'business-contact',
  BusinessHours = 'business-hours',
  BusinessImages = 'business-images',
  BusinessInfo = 'business-info',
  BusinessLocation = 'business-location',
  Deals = 'deals',
  MyBusinesses = 'my-businesses',
  MyInformation = 'my-information',
}

export enum EVisibility {
  Show = 'show',
  Hide = 'hide',
}

export enum EVocabularyFields {
  Title = 'title',
  Description = 'description',
}
