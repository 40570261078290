import React from 'react';
import './styles.scss';

type Props = {
  disabled: boolean;
  children: any;
};
const Disabled: React.FC<Props> = ({disabled, children}) => {
  if (disabled) {
    return <div className="disabled-component-wrapper">{children}</div>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
export default Disabled;
