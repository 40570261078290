import React, {useEffect} from 'react';
import {ETypeCommunication} from '../../../../../../models/enums/generals';
import {IAddresses} from '../../../../../../models/interfaces/IAddresses';
import {LocationSearchInput} from '../../../../../../components/LocationSearchInput';
import {useTranslation} from 'react-i18next';
import CheckBoxWithLabel from '../../../../../../components/CheckboxWithLabel';
import FormField from '../../../../../../components/FormField/FormField';
import MsgError from '../../../../../../components/MsgError';

type Props = {
  addressSelected: IAddresses | null;
  error?: string | false;
  shareLocation: boolean;
  typeLocation?: ETypeCommunication;
  setAddressSelected: (value: IAddresses | null) => void;
  setErrorCode?: (value: string) => void;
  setShareLocation: (newValue: boolean) => void;
};

const FieldLocation: React.FC<Props> = ({
  addressSelected,
  error,
  shareLocation,
  typeLocation = ETypeCommunication.Ad,
  setAddressSelected,
  setErrorCode,
  setShareLocation,
}: Props) => {
  const {t} = useTranslation('createDeal');

  useEffect(() => {
    if (setErrorCode) {
      setErrorCode('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressSelected]);

  return (
    <FormField
      title={t('fieldLocationTitle')}
      description={t('fieldLocationDescription')}>
      <CheckBoxWithLabel
        label={t('shared:checkShareLocation')}
        status={shareLocation}
        toggleStatus={() => setShareLocation(!shareLocation)}
      />
      <LocationSearchInput
        disabled={!shareLocation}
        setValue={setAddressSelected}
        typeLocation={typeLocation}
        value={addressSelected}
      />
      {error && <MsgError text={error} />}
    </FormField>
  );
};

export default FieldLocation;
