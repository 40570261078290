import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../../../redux';
import {EPanelFormProfile} from '../../../../../../models/enums/profile';
import {Form, Formik, FormikErrors} from 'formik';
import {isEmptyValue} from '../../../../../../utils/isEmpty';
import {imgPasswordFieldStyles} from '../../../../../../constants/themes';
import {IPanelFormProfile} from '../../../../../../models/interfaces/profile';
import {TextField} from '../../../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  validateAtLeastOneNumber,
  validateAtLeastOneSpecialCharacter,
  validateAtLeastOneUppercase,
} from '../../../../../../services/app/validations';
import {
  ErrorTypes,
  PasswordValidations,
} from '../../../../../../models/enums/registerForm';
import {
  sendProfileEditUserData,
  setHasErrors,
} from '../../../../../../redux/actions/profileActions';
import Button from '../../../../../../components/Button';
import ErrorsValidations from '../../../../../../components/ErrorsValidations/index';
import PasswordSecurity from '../../../../../../components/PasswordSecurity/index';
import './styles.scss';

type Props = {
  setShowPanelForm: (val: IPanelFormProfile) => void;
};

export interface IChangePasswordForm {
  formIsValid: boolean;
  userCurrentPassword: string;
  userNewPassword: string;
  userRepeatPassword: string;
}

const ChangePasswordForm: React.FC<Props> = ({setShowPanelForm}) => {
  //Hooks
  const dispatch = useDispatch();
  const stateProfile = useSelector((state: AppState) => state.profile);
  const {t} = useTranslation('register');

  const initialValues: IChangePasswordForm = {
    formIsValid: true,
    userCurrentPassword: '',
    userNewPassword: '',
    userRepeatPassword: '',
  };

  //States
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [isFirstLoader, setIsFirstLoader] = useState<boolean>(true);
  const [isInvalidPassword, setIsInvalidPassword] = useState<boolean>(false);
  const [showValidCharacters, setShowValidCharacters] =
    useState<boolean>(false);
  const [showValidNUmber, setShowValidNumber] = useState<boolean>(false);
  const [showValidSpecialCharacter, setShowValidSpecialCharacter] =
    useState<boolean>(false);
  const [showValidUppercase, setShowValidUppercase] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setIsFirstLoader(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirstLoader && stateProfile.errorCode) {
      if (stateProfile.errorCode === ErrorTypes.InvalidCredentials) {
        setErrorsAndPanelShown(true);
      } else if (stateProfile.errorCode === ErrorTypes.NoError) {
        setErrorsAndPanelShown(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProfile]);

  //Functions
  const validatePasswordConditionsCharacters = (value: string) => {
    if (value.length > 6 && value.length < 17) {
      setShowValidCharacters(true);
      return true;
    } else {
      setShowValidCharacters(false);
      return false;
    }
  };

  const validatePasswordConditionsUppercase = (value: string) => {
    if (validateAtLeastOneUppercase(value)) {
      setShowValidUppercase(true);
      return true;
    } else {
      setShowValidUppercase(false);
      return false;
    }
  };

  const validatePasswordConditionsNumber = (value: string) => {
    if (validateAtLeastOneNumber(value)) {
      setShowValidNumber(true);
      return true;
    } else {
      setShowValidNumber(false);
      return false;
    }
  };

  const validatePasswordConditionsSpecialCharacter = (value: string) => {
    if (validateAtLeastOneSpecialCharacter(value)) {
      setShowValidSpecialCharacter(true);
      return true;
    } else {
      setShowValidSpecialCharacter(false);
      return false;
    }
  };

  const setErrorsAndPanelShown = (error: boolean) => {
    if (error) {
      setIsInvalidPassword(true);
      setIsDisabledButton(true);
    } else {
      setIsInvalidPassword(false);
      setShowPanelForm({
        open: error,
        type: EPanelFormProfile.Password,
      });
      dispatch(
        setHasErrors({
          ...stateProfile.hasErrors,
          vendorInformation: error,
        }),
      );
    }
  };

  return (
    <div className="change-password-form">
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validate={(values: IChangePasswordForm) => {
          const {userNewPassword, userCurrentPassword, userRepeatPassword} =
            values;
          const errors: FormikErrors<IChangePasswordForm> = {};

          // Mandatories
          if (
            isEmptyValue(userNewPassword) ||
            isEmptyValue(userCurrentPassword) ||
            isEmptyValue(userRepeatPassword)
          ) {
            errors.formIsValid = ErrorTypes.Required;
          }

          // New Password
          if (
            !isEmptyValue(userNewPassword) &&
            !validatePasswordConditionsCharacters(userNewPassword)
          ) {
            errors.userNewPassword = PasswordValidations.Characters;
          }
          if (
            !isEmptyValue(userNewPassword) &&
            !validatePasswordConditionsUppercase(userNewPassword)
          ) {
            errors.userNewPassword = PasswordValidations.Uppercase;
          }
          if (
            !isEmptyValue(userNewPassword) &&
            !validatePasswordConditionsNumber(userNewPassword)
          ) {
            errors.userNewPassword = PasswordValidations.Number;
          }
          if (
            !isEmptyValue(userNewPassword) &&
            !validatePasswordConditionsSpecialCharacter(userNewPassword)
          ) {
            errors.userNewPassword = PasswordValidations.SpecialCharacter;
          }

          // Igual Passwords
          if (
            !isEmptyValue(userNewPassword) &&
            !isEmptyValue(userRepeatPassword) &&
            userNewPassword !== userRepeatPassword
          ) {
            errors.userRepeatPassword = ErrorTypes.DoesntMatchPassword;
          }

          // Block General Buttom
          if (Object.entries(errors).length > 0) {
            setIsDisabledButton(true);
          } else {
            setIsDisabledButton(false);
          }

          return errors;
        }}
        onSubmit={values => {
          const {userCurrentPassword, userNewPassword} = values;

          // Send Data
          dispatch(
            sendProfileEditUserData({
              oldPassword: userCurrentPassword,
              password: userNewPassword,
            }),
          );
        }}>
        {({errors, handleBlur, handleChange, handleSubmit, values}) => {
          const {userNewPassword, userCurrentPassword, userRepeatPassword} =
            values;

          return (
            <Form>
              <TextField
                customStyles={imgPasswordFieldStyles.password}
                errorElement={
                  isInvalidPassword && (
                    <ErrorsValidations type="InvalidCredentials" />
                  )
                }
                handleChange={handleChange}
                id="userCurrentPassword"
                label={t('profilePage:fieldCurrentPasswordTitle')}
                onBlur={handleBlur}
                required={true}
                type="password"
                value={userCurrentPassword}
              />

              <TextField
                customStyles={imgPasswordFieldStyles.password}
                handleChange={handleChange}
                id="userNewPassword"
                label={t('profilePage:fieldNewPasswordTitle')}
                maxLength={16}
                minLength={7}
                onBlur={handleBlur}
                required={true}
                type="password"
                value={userNewPassword}
              />
              {!isEmptyValue(userNewPassword) && (
                <PasswordSecurity
                  showValidCharacters={showValidCharacters}
                  showValidNumber={showValidNUmber}
                  showValidSpecialCharacter={showValidSpecialCharacter}
                  showValidUppercase={showValidUppercase}
                />
              )}

              <TextField
                customStyles={imgPasswordFieldStyles.password}
                errorElement={
                  errors.userRepeatPassword ===
                    ErrorTypes.DoesntMatchPassword && (
                    <ErrorsValidations type="DoesntMatchPassword" />
                  )
                }
                handleChange={handleChange}
                id="userRepeatPassword"
                label={t('profilePage:fieldRepeatPasswordTitle')}
                onBlur={handleBlur}
                required={true}
                type="password"
                value={userRepeatPassword}
              />

              <div className="change-password-buttons-container">
                <Button
                  label={t('global:cancel')}
                  onClick={setErrorsAndPanelShown}
                />
                <Button
                  label={t('profilePage:setNewPasswordText')}
                  onClick={handleSubmit}
                  disable={isDisabledButton}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangePasswordForm;
