export const SET_CREATE_AD_DATA = 'SET_CREATE_AD_DATA';
export const CLEAR_AD_DATA = 'CLEAR_AD_DATA';
export const SET_AD_ID = 'SET_AD_ID';

interface ISetAdData {
  type: typeof SET_CREATE_AD_DATA;
  payload: any;
}

interface IClearAdData {
  type: typeof CLEAR_AD_DATA;
}

interface ISetAdId {
  type: typeof SET_AD_ID;
  payload: any;
}


export type AdActionTypes = ISetAdData | IClearAdData | ISetAdId;
