import React from 'react';
import {useLocation} from 'react-router';
import {IPanelFormProfile} from '../../models/interfaces/profile';
import MyBusinesses from './components/MyBusinesses';
import MyProfile from './components/MyProfile';
import './styles.scss';

type Props = {
  setShowPanelForm: (val: IPanelFormProfile) => void;
};

const ProfileEditTabList: React.FC<Props> = ({setShowPanelForm}) => {
  // Hooks
  const location = useLocation();

  // Data
  const {pathname} = location;
  const params = pathname.split('/');
  const myBusinessPage = params[2];
  const userId = Number(params[3]);

  return (
    <div className="tap-panel-wrapper">
      {myBusinessPage && !isNaN(userId) ? (
        <MyBusinesses setShowPanelForm={setShowPanelForm} />
      ) : (
        <MyProfile setShowPanelForm={setShowPanelForm} />
      )}
    </div>
  );
};

export default ProfileEditTabList;
