import React from 'react';
import CommunityTag from './components/Tag';
import DefaultCommunityImage from '../../assets/images/DefaultCommunityPicture.png';
import {ICommunityTag} from '../../models/interfaces/profile';
import {useTranslation} from 'react-i18next';
import './styles.scss';

type Props = {
  cityName: string;
  dataTags: ICommunityTag[];
  description: string;
  id: number | null;
  isSelected: boolean;
  name: string;
  setCommunityId: (value: number | null) => void;
};

const Community: React.FC<Props> = ({
  cityName,
  dataTags,
  description,
  id,
  isSelected,
  name,
  setCommunityId,
}) => {
  const {t} = useTranslation();

  const handleClick = (id: number | null) => {
    setCommunityId(id);
  };

  return (
    <div className="communities-wrapper" onClick={() => handleClick(id)}>
      <div
        className={`community-container ${isSelected && 'selected-community'}`}>
        <div className="option-radio">
          <div className="radio">
            <span className="radius-selector-content" />
          </div>
          <img src={DefaultCommunityImage} alt="img-community" />
          <span className="title">{name}</span>
          <span className="city">{cityName}</span>
        </div>
        <div className="description">{description}</div>
        <div className="keywords">
          {name} {t('global:keywords')}
        </div>
        <div className="tags-content">
          {dataTags &&
            dataTags.map((e: ICommunityTag, idx: number) => (
              <CommunityTag key={idx} image={e.image} text={e.text} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Community;
