import React, {useEffect} from 'react';
import Home from './pages/Home';
import pages from './constants/pages';
import Register from './pages/Register';
import CreateAd from './pages/CreateAd';
import LoginPage from './pages/LoginPage';
import CreateDeal from './pages/CreateDeal';
import ReactModal from 'react-modal';
import RequireAuth from './components/RequireAuth';
import ConfirmEmail from './pages/Register/components/ConfirmEmail';
import ConfirmationPopup from './components/ConfirmationPopup';
import RequestNewVerificationLink from './pages/Register/components/NewVerificationLink';
import {useAuth} from './hooks/useAuth';
import {AppState} from './redux';
import {isLocalHost} from './utils/isLocalhost';
import {getCommunities} from './redux/actions/uiActions';
import {ProfileEdition} from './pages/ProfileEdition';
import {useTranslation} from 'react-i18next';
import {BrowserTracing} from '@sentry/tracing';
import {manaGlobalObject} from './models/enums/generals';
import {Routes, Route, Navigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as Sentry from '@sentry/react';
import './App.css';
import ContactUs from './pages/ContactUs';
import ForgotPassword from './pages/ForgotPassword';
import VerificationCode from './pages/ForgotPassword/components/VerificationCode';
import NewPassword from './pages/NewPassword';
import RegisterByInvitation from './pages/RegisterByInvitation';

const isEnvDev = process.env.NODE_ENV === 'development' && isLocalHost();
!isEnvDev &&
  Sentry.init({
    dsn: 'https://8058f6bc4c054cc5bfb5481794df18e4@o4503971508453376.ingest.sentry.io/4504016988733440',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });

ReactModal.setAppElement('#root');

const App: React.FC = () => {
  const {t} = useTranslation();
  const {isAuthenticated} = useAuth();
  const ui = useSelector((state: AppState) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCommunities({
        cityName: t(manaGlobalObject.cityName),
        description: manaGlobalObject?.description
          ? t(manaGlobalObject.description)
          : '',
        id: manaGlobalObject.id,
        name: t(manaGlobalObject.name),
      }),
    );
  }, []);

  return (
    <div data-testid="app" className="App">
      {ui.confirmationPopup.isVisible && (
        <ConfirmationPopup
          delay={ui.confirmationPopup.delay}
          text={ui.confirmationPopup.message}
          type={ui.confirmationPopup.type}
        />
      )}

      <Routes>
        <Route
          path={pages.root}
          element={
            isAuthenticated ? <Navigate to={pages.home} /> : <LoginPage />
          }
        />

        {!isAuthenticated && (
          <Route path={pages.signUp} element={<Register />} />
        )}

        {!isAuthenticated && (
          <Route path={pages.forgotPassword} element={<ForgotPassword />} />
        )}

        {!isAuthenticated && (
          <Route path={pages.verificationCode} element={<VerificationCode />} />
        )}

        {!isAuthenticated && (
          <Route path={pages.newPassword} element={<NewPassword />} />
        )}

        {!isAuthenticated && (
          <Route
            path={pages.registerByInvitation}
            element={<RegisterByInvitation />}
          />
        )}

        <Route
          path={pages.home}
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }>
          <Route
            path={pages.viewAdId}
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path={pages.viewDealId}
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path={pages.profileEdition}
          element={
            <RequireAuth>
              <ProfileEdition />
            </RequireAuth>
          }>
          <Route
            path={pages.myBusinessId}
            element={
              <RequireAuth>
                <ProfileEdition />
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path={pages.createDeal}
          element={
            <RequireAuth>
              <CreateDeal />
            </RequireAuth>
          }>
          <Route
            path={pages.id}
            element={
              <RequireAuth>
                <CreateDeal />
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path={pages.crateAd}
          element={
            <RequireAuth>
              <CreateAd />
            </RequireAuth>
          }>
          <Route
            path={pages.id}
            element={
              <RequireAuth>
                <CreateAd />
              </RequireAuth>
            }
          />
        </Route>

        <Route path={pages.confirmemail} element={<ConfirmEmail />} />

        <Route
          path={pages.newVerificationLink}
          element={<RequestNewVerificationLink />}
        />
        <Route path={pages.contactUs} element={<ContactUs />} />
      </Routes>
    </div>
  );
};

export default isEnvDev ? App : Sentry.withProfiler(App);
