import axios from 'axios';

export const defaultHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const axiosConfig = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL || ''}/api/`,
  headers: defaultHeaders,
});

// Interceptors config
axiosConfig.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    console.log(error?.response);
    return Promise.reject(error);
  },
);

export default axiosConfig;
