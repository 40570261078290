import React, {useEffect, useState} from 'react';
import {ReactComponent as ImageLogo} from '../../assets/images/register/mana-logo.svg';
import ProgressBar from '../../components/ProgressBar';
import {EColors} from '../../models/enums/colors';
import StepTitle from '../Register/components/StepTitle';
import {setRegisterData} from '../../redux/actions/registerActions';

import './styles.scss';
import {useLocation} from 'react-router';
import {useDispatch} from 'react-redux';
import {getBusinessPublicProfile} from '../../services/api/profileAPi';
import CreateAccountForm from './components/CreateAccountForm';
import {BusinessPublicProfileI} from '../../models/api/profile';
import {useTranslation} from 'react-i18next';

const RegisterByInvitation = () => {
  const [business, setBusiness] = useState<BusinessPublicProfileI>();
  const location = useLocation();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get('email');
  const businessId = searchParams.get('businessId');
  const token = searchParams.get('token');

  useEffect(() => {
    const getBusiness = async () => {
      if (businessId) {
        const {data} = await getBusinessPublicProfile(Number(businessId));
        setBusiness(data);
      }
    };

    getBusiness();
    dispatch(
      setRegisterData({
        username: email,
      }),
    );
  }, [email]);

  return (
    <div className="register-page">
      <div className="business-section">
        <ImageLogo className="logo" />
        <span className="invited-to-text">{t('invitedTo')}</span>

        {business && (
          <>
            <img
              className="image"
              height={'105px'}
              width={'103px'}
              src={business.profileImageUrl}
            />

            <span className="business-name">{business.name}</span>
          </>
        )}
      </div>

      <div className="form-section">
        <div className="form-content-fields">
          <ProgressBar
            bgColor={EColors.colorBlueCornflower}
            height={8}
            progress={100}
          />

          <StepTitle
            title={'Start by setting up your account'}
            subtitle={
              'Become a part of our community by creating a vendors account. This will allow you to promote your business, create deals for your customers and much more.'
            }
          />
          {token && businessId && (
            <CreateAccountForm token={token} businessId={businessId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterByInvitation;
