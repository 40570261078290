import dashboard from '../assets/icons/dashboard.svg';
import contactUs from '../assets/icons/contact-us.svg';
import {EMenuOptions} from '../models/enums/menu';
import {IMenuOptions} from '../models/interfaces/menuOptions';

export const menuItems: IMenuOptions[] = [
  {
    label: 'menu:communications',
    icon: dashboard,
    url: EMenuOptions.Dashboard,
    showItem: true,
  },
  {
    label: 'menu:contactus',
    icon: contactUs,
    url: EMenuOptions.ContactUs,
    showItem: true,
  },
];
