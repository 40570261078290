import React from 'react';
import './styles.scss';
import {ReactComponent as ImageIcon} from '../../assets/icons/without-image.svg';

const WithoutImage: React.FC = () => {
  return (
    <div className="without-image">
      <ImageIcon />
    </div>
  );
};

export default WithoutImage;
