import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {EMenuViews, ETabsViews} from '../../../../models/enums/generals';
import {Form, Formik, FormikErrors} from 'formik';
import {IBusinessSocialMedia} from '../../../../models/interfaces/reduxStates';
import {isEmptyValue} from '../../../../utils/isEmpty';
import {TextField} from '../../../../components/TextField';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validateUrl} from '../../../../utils/validateUrl';
import {
  ErrorTypes,
  SocialMediaType,
} from '../../../../models/enums/registerForm';
import {
  setHasErrors,
  setProfileData,
} from '../../../../redux/actions/profileActions';
import validateEmail, {
  validatePhoneNumber,
} from '../../../../services/app/validations';
import ErrorsValidations from '../../../../components/ErrorsValidations/index';
import PhoneNumber from '../../../Register/components/PhoneNumber';
import ReactCountryFlag from 'react-country-flag';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

export interface IBusinessContactInfoForm {
  contactCountryCode: string;
  contactCountryFlag: string;
  contactEmail: string;
  contactLastname: string;
  contactName: string;
  contactPhoneNumber: string;
  contactWebSiteUrl: string;
  formIsValid: boolean;
  socialMediaFb: string;
  socialMediaIn: string;
  socialMediaTw: string;
}

const BusinessContactInfo = () => {
  //Hooks
  const dispatch = useDispatch();
  const stateProfile = useSelector((state: AppState) => state.profile);
  const {t} = useTranslation('register');
  const {eventTrack} = useAnalytics();

  //Data
  const {
    businessSocialMedia,
    contactCountryCode,
    contactCountryFlag,
    contactEmail,
    contactLastname,
    contactName,
    contactPhoneNumber,
    contactWebSiteUrl,
  } = stateProfile.data;

  const getSocialMediaLinks = (data: IBusinessSocialMedia[]) => {
    let facebookLink = '';
    let instagramLink = '';
    let twitterLink = '';
    data.map(d => {
      if (d.socialMediaType === SocialMediaType.Facebook) {
        facebookLink = d.link;
      } else if (d.socialMediaType === SocialMediaType.Instagram) {
        instagramLink = d.link;
      } else if (d.socialMediaType === SocialMediaType.Twitter) {
        twitterLink = d.link;
      }
    });
    return {
      facebook: facebookLink,
      instagram: instagramLink,
      twitter: twitterLink,
    };
  };

  const initialValues: IBusinessContactInfoForm = {
    contactCountryCode,
    contactCountryFlag,
    contactEmail,
    contactLastname,
    contactName,
    contactPhoneNumber,
    contactWebSiteUrl,
    formIsValid: true,
    socialMediaFb: getSocialMediaLinks(businessSocialMedia).facebook,
    socialMediaIn: getSocialMediaLinks(businessSocialMedia).instagram,
    socialMediaTw: getSocialMediaLinks(businessSocialMedia).twitter,
  };

  // States
  const [countriesOptions, setCountriesOptions] = useState<object[]>([]);
  const [countryFlagValue, setCountryFlagValue] = useState('US');
  const [firstLoader, setFirstLoader] = useState<boolean>(true);
  const [phoneCodeValue, setPhoneCode] = useState<string>(contactCountryCode);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>(
    contactPhoneNumber || phoneCodeValue,
  );

  //Effects
  useEffect(() => {
    setFirstLoader(false);
    dispatch(setProfileData({showSaveBtns: true}));
    if (stateProfile.countries && stateProfile.countries.length > 0) {
      const options = stateProfile.countries?.map((elem: any) => {
        const object: any = {};

        object['value'] = elem.alpha2Code;
        object['label'] = (
          <ReactCountryFlag svg countryCode={elem.alpha2Code} />
        );
        object['phoneCode'] = elem.phoneCode;

        return object;
      });
      setCountriesOptions(options);
    }

    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {
        current_tab_view: ETabsViews.BusinessContact,
        page_view: EMenuViews.Profile,
        sub_page_view: EMenuViews.MyBusiness,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoader) {
      setPhoneNumberValue(`${phoneCodeValue}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCodeValue]);

  useEffect(() => {
    if (!firstLoader) {
      dispatch(
        setProfileData({
          contactCountryFlag: countryFlagValue,
        }),
      );
    }
  }, [countryFlagValue]);

  //Functions
  const handleChangePhoneNumber = (e: any) => {
    const isValid = e.target.validity.valid;
    const value = e.target.value;
    if (isValid) {
      setPhoneNumberValue(value);
    }
  };

  return (
    <div className="business-contact-info-form">
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validate={(values: IBusinessContactInfoForm) => {
          const {
            contactCountryFlag,
            contactEmail,
            contactLastname,
            contactName,
            contactWebSiteUrl,
            socialMediaFb,
            socialMediaIn,
            socialMediaTw,
          } = values;

          const errors: FormikErrors<IBusinessContactInfoForm> = {};

          // Mandatories
          if (isEmptyValue(contactName) || isEmptyValue(contactLastname)) {
            errors.formIsValid = ErrorTypes.Required;
          } else {
            dispatch(
              setProfileData({
                contactName,
                contactLastname,
              }),
            );
          }

          // Email
          if (!isEmptyValue(contactEmail) && !validateEmail(contactEmail)) {
            errors.contactEmail = ErrorTypes.InvalidEmailFormat;
          } else {
            dispatch(
              setProfileData({
                contactEmail,
              }),
            );
          }

          // Website
          if (
            !isEmptyValue(contactWebSiteUrl) &&
            !validateUrl(contactWebSiteUrl)
          ) {
            errors.contactWebSiteUrl = ErrorTypes.InvalidUrlFormat;
          } else {
            dispatch(
              setProfileData({
                contactWebSiteUrl,
              }),
            );
          }

          // PhoneNumber
          if (
            !isEmptyValue(phoneNumberValue) &&
            !validatePhoneNumber(phoneNumberValue)
          ) {
            errors.contactPhoneNumber = ErrorTypes.InvalidPhoneNumber;
          } else {
            dispatch(
              setProfileData({
                contactCountryFlag,
                contactCountryCode: phoneCodeValue,
                contactPhoneNumber: phoneNumberValue,
              }),
            );
          }

          // SocialMedia
          if (!isEmptyValue(socialMediaFb) && !validateUrl(socialMediaFb)) {
            errors.socialMediaFb = ErrorTypes.InvalidUrlFormat;
          }
          if (!isEmptyValue(socialMediaIn) && !validateUrl(socialMediaIn)) {
            errors.socialMediaIn = ErrorTypes.InvalidUrlFormat;
          }
          if (!isEmptyValue(socialMediaTw) && !validateUrl(socialMediaTw)) {
            errors.socialMediaTw = ErrorTypes.InvalidUrlFormat;
          }
          if (
            !errors.socialMediaFb &&
            !errors.socialMediaTw &&
            !errors.socialMediaIn
          ) {
            dispatch(
              setProfileData({
                businessSocialMedia: [
                  {
                    id: businessSocialMedia[0]?.id,
                    socialMediaType: SocialMediaType.Facebook,
                    link: socialMediaFb,
                  },
                  {
                    id: businessSocialMedia[1]?.id,
                    socialMediaType: SocialMediaType.Instagram,
                    link: socialMediaIn,
                  },
                  {
                    id: businessSocialMedia[2]?.id,
                    socialMediaType: SocialMediaType.Twitter,
                    link: socialMediaTw,
                  },
                ],
              }),
            );
          }

          // Block General Buttom
          if (Object.entries(errors).length > 0) {
            dispatch(
              setHasErrors({
                ...stateProfile.hasErrors,
                contactInfo: true,
              }),
            );
          } else {
            dispatch(
              setHasErrors({
                ...stateProfile.hasErrors,
                contactInfo: false,
              }),
            );
          }

          return errors;
        }}
        onSubmit={() => {
          // TODO: We need this callback to avoid Formik errors
          console.log('Not Submit');
        }}>
        {({
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          touched,
          values,
        }) => {
          const {
            contactCountryFlag,
            contactEmail,
            contactLastname,
            contactName,
            contactWebSiteUrl,
            socialMediaFb,
            socialMediaIn,
            socialMediaTw,
          } = values;

          return (
            <Form>
              <div className="contact-info-first-wrapper">
                <TextField
                  id="contactName"
                  value={contactName}
                  type={'text'}
                  label={t('register:setUpYourAccountNameTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={150}
                />

                <TextField
                  id="contactLastname"
                  value={contactLastname}
                  type={'text'}
                  label={t('register:setUpYourAccountLastNameTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={150}
                />

                <TextField
                  errorElement={
                    errors.contactEmail === ErrorTypes.InvalidEmailFormat &&
                    touched.contactEmail && (
                      <ErrorsValidations type="InvalidEmailFormat" />
                    )
                  }
                  handleChange={handleChange}
                  id="contactEmail"
                  label={t('businessContactInfoEmailTitle')}
                  maxLength={150}
                  onBlur={handleBlur}
                  type={'email'}
                  value={contactEmail}
                />

                <TextField
                  errorElement={
                    errors.contactWebSiteUrl === ErrorTypes.InvalidUrlFormat &&
                    touched.contactWebSiteUrl && (
                      <ErrorsValidations type="InvalidUrlFormat" />
                    )
                  }
                  handleChange={handleChange}
                  id="contactWebSiteUrl"
                  isOptional={true}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeHolder={t('profilePage:businessWebsiteFieldTitle')}
                  type={'text'}
                  value={contactWebSiteUrl}
                />

                <PhoneNumber isOptional={true} title={t('phoneNumberField')}>
                  <div className="phone-number-wrapper">
                    <SelectField
                      items={countriesOptions}
                      onChange={value => {
                        setFieldValue('contactCountryFlag', value.value);
                        setCountryFlagValue(value.value);
                        setPhoneCode(`+${value.phoneCode}`);
                      }}
                      placeHolder=""
                      label=""
                      isEdit={true}
                      defaultOption={contactCountryFlag}
                    />

                    <TextField
                      id="contactPhoneNumber"
                      value={phoneNumberValue}
                      type={'text'}
                      handleChange={e => handleChangePhoneNumber(e)}
                      onBlur={handleBlur}
                      errorElement={
                        errors.contactPhoneNumber ===
                          ErrorTypes.InvalidPhoneNumber &&
                        touched.contactPhoneNumber && (
                          <ErrorsValidations type="InvalidPhoneNumber" />
                        )
                      }
                      pattern="(\+)?([0-9]){0,}"
                    />
                  </div>
                </PhoneNumber>
              </div>
              <div className="contact-info-second-wrapper">
                <TextField
                  errorElement={
                    errors.socialMediaFb === ErrorTypes.InvalidUrlFormat &&
                    touched.socialMediaFb && (
                      <ErrorsValidations type="InvalidUrlFormat" />
                    )
                  }
                  handleChange={handleChange}
                  id="socialMediaFb"
                  isOptional={true}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeHolder={t('profilePage:fieldFacebookTitle')}
                  type={'text'}
                  value={socialMediaFb}
                />

                <TextField
                  errorElement={
                    errors.socialMediaIn === ErrorTypes.InvalidUrlFormat &&
                    touched.socialMediaIn && (
                      <ErrorsValidations type="InvalidUrlFormat" />
                    )
                  }
                  handleChange={handleChange}
                  id="socialMediaIn"
                  isOptional={true}
                  label={t('profilePage:fieldInstagramTitle')}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeHolder={t('profilePage:fieldInstagramPlaceholder')}
                  type={'text'}
                  value={socialMediaIn}
                />

                <TextField
                  errorElement={
                    errors.socialMediaTw === ErrorTypes.InvalidUrlFormat &&
                    touched.socialMediaTw && (
                      <ErrorsValidations type="InvalidUrlFormat" />
                    )
                  }
                  handleChange={handleChange}
                  id="socialMediaTw"
                  isOptional={true}
                  label={t('profilePage:fieldTwitterTitle')}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeHolder={t('profilePage:fieldTwitterPlaceholder')}
                  type={'text'}
                  value={socialMediaTw}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BusinessContactInfo;
