import {IRegisterState} from '../../models/interfaces/reduxStates';
import {
  CLEAR_REGISTER_DATA,
  GET_BUSINESS_IDENTIFICATION_SUCCESS,
  GET_COMMUNITIES_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_FAIL,
  GET_REQUEST,
  SET_ERROR_CODE,
  SET_REGISTER_DATA_COORDENATES,
  SET_REGISTER_DATA_SUGGESTIONS,
  SET_REGISTER_DATA,
  SET_STEP,
  SUCCESS_REGISTER_DATA,
} from '../types/registerTypes';

const initialState: IRegisterState = {
  businessGeographicCoordenates: [],
  businessIdentification: {},
  businessMapSuggestions: [],
  communities: [],
  countries: [],
  data: {
    birthdate: '',
    // step 1
    countryFlag: 'USA',
    lastname: '',
    name: '',
    password: '',
    phoneCode: '+1',
    phoneNumber: '',
    roleName: '',
    username: '', // email
    // step 2
    businessName: '',
    categorySelected: {}, // no send
    subCategories: [],
    subCategorySelected: {}, // no send
    // step 3
    businessDescription: '',
    // step 4
    images: [], // business images
    profileImage: '',
    profileImageUrl: '',
    // step 5
    // TODO: physical store keys?
    businessLocations: [
      {
        location: {
          addressLine1: '',
          apartmentSuit: '',
          cityId: '',
          cityName: '',
          country: '',
          googlePlaceId: '',
          latitude: '',
          longitude: '',
          neighborhood: '',
          state: '',
          streetAddress: '',
          zipCode: '',
        },
        primary: true,
      },
    ],
    businessLocationType: 1,
    countryAlpha2Code: 'US',
    webSiteUrl: '',
    // step 6
    community: 0,
    // step 7
    businessHours: [], // TODO: type value (businessHours)
    // step 8
    businessSocialMedia: [], // TODO: type value (BusinessSocialMedia)
    contactCountryCode: '+1',
    contactCountryFlag: 'US',
    contactEmail: '',
    contactPhoneNumber: '',
    contactWebSiteUrl: '',
    timeZone: 'UTC-5',
  },
  errorCode: '',
  loading: false,
  msgError: '',
  step: 1,
  success: false,
};

const registerReducer = (state = initialState, action: any): IRegisterState => {
  switch (action.type) {
    // GENERAL
    case GET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_FAIL:
      return {
        ...state,
        loading: false,
        msgError: action.payload,
      };

    case SET_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
      };

    case SET_STEP:
      return {
        ...state,
        step: action.payload,
        success: false,
      };

    // REGISTER DATA
    case SET_REGISTER_DATA:
      return {
        ...state,
        data: {...state.data, ...action.payload},
      };

    case SET_REGISTER_DATA_COORDENATES:
      return {
        ...state,
        businessGeographicCoordenates: action.payload,
      };

    case SET_REGISTER_DATA_SUGGESTIONS:
      return {
        ...state,
        businessMapSuggestions: action.payload,
      };

    case SUCCESS_REGISTER_DATA:
      return {
        ...state,
        success: !state.success,
      };

    case CLEAR_REGISTER_DATA:
      return initialState;

    // BUSINESS IDENTIFICATION
    case GET_BUSINESS_IDENTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        businessIdentification: action.payload,
      };

    // COUNTRIES
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };

    // COMMUNITIES
    case GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        communities: action.payload,
      };

    default:
      return state;
  }
};

export default registerReducer;
