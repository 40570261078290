import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ButtonUploadImage from '../../../../../../components/ButtonUploadImage';
import Subtitle from '../Subtitle';
import Title from '../Title';
import DefaultBusinessImage from '../../../../../../assets/images/DefaultBusinessImage.png';
import {BUSINESS_IMAGES_LIMIT} from '../../../../../../constants/register';
import XIcon from '../../../../../../assets/icons/close-hover.svg';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../../../redux';
import './styles.scss';

type Props = {
  images: any[];
  setImages: (value: any) => void;
};

const BusinessImages: React.FC<Props> = ({images, setImages}) => {
  // hooks
  const {t} = useTranslation('register');
  const {data} = useSelector((state: RootState) => state.register);

  // state
  const [imagesPreview, setImagesPreview] = useState<any>([]);

  // effects
  useEffect(() => {
    if (data.images) {
      setImagesPreview(data.images);
      setImages(data.images);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // functions
  const addImage = (value: any) => {
    setImages([...images, URL.createObjectURL(value)]);
    setImagesPreview([...imagesPreview, URL.createObjectURL(value)]);
  };

  const removeImage = (index: number) => {
    imagesPreview.splice(index, 1);
    images.splice(index, 1);
    setImagesPreview([...imagesPreview]);
    setImages([...images]);
  };

  return (
    <>
      <Title text={t('businessImagesTitle')} />
      <Subtitle text={t('businessImagesSubtitle')} />
      <div className="business-images-files">
        {imagesPreview.length < 1 ? (
          <img
            className="business-image"
            src={DefaultBusinessImage}
            width={160}
            height={160}
            alt="bussiness"
          />
        ) : (
          imagesPreview.map((image: any, index: number) => (
            <div key={index} className="business-image-wrapper">
              <img
                className="business-image"
                src={image}
                width={160}
                height={160}
                alt="business-preview"
              />
              <div
                onClick={() => removeImage(index)}
                className="business-images-close">
                <img src={XIcon} width={12} height={12} alt="business-close" />
              </div>
            </div>
          ))
        )}
        {images.length < BUSINESS_IMAGES_LIMIT && (
          <ButtonUploadImage
            acceptVideo={false}
            hideImageName={true}
            id={'upload-business-image'}
            label={t('chooseFiles')}
            onClick={addImage}
          />
        )}
      </div>
    </>
  );
};

export default BusinessImages;
