export const SET_CREATE_DEAL_DATA = 'SET_CREATE_DEAL_DATA';
export const SET_DEAL_ID = 'SET_DEAL_ID';
export const CLEAR_DEAL_DATA = 'CLEAR_DEAL_DATA';

interface ISetCreateDealData {
  type: typeof SET_CREATE_DEAL_DATA;
  payload: any;
}
interface ISetDealIdData {
  type: typeof SET_DEAL_ID;
  payload: any;
}

interface IClearDealData {
  type: typeof CLEAR_DEAL_DATA;
}

export type DealActionTypes = ISetCreateDealData | IClearDealData | ISetDealIdData;
