import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.scss';
import {ReactComponent as ChevronDown} from '../../../../assets/icons/chevron-down.svg';

type Props = {
  onClick: (value: boolean) => void;
  isEditPage: boolean;
};

const HeaderPage: React.FC<Props> = ({onClick, isEditPage}) => {
  const {t} = useTranslation('createDeal');
  return (
    <div className="create-deal-header-page">
      <ChevronDown
        className="create-deal-header-page-icon-chevron"
        width={16}
        height={16}
        onClick={() => onClick(true)}
      />
      <span className="create-deal-header-page-text">
        {isEditPage ? t('titleEdit') : t('title')}
      </span>
    </div>
  );
};

export default HeaderPage;
