import React, {useEffect} from 'react';
import {AppState} from '../../redux';
import {EAnalyticsCustomEventNames} from '../../models/enums/analytics';
import {EMenuViews, ETabsViews} from '../../models/enums/generals';
import {setProfileData} from '../../redux/actions/profileActions';
import {useAnalytics} from '../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import './styles.scss';

const MyBusiness = () => {
  const {data} = useSelector((state: AppState) => state.profile);
  const {eventTrack} = useAnalytics();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setProfileData({showSaveBtns: false}));
    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {
        current_tab_view: ETabsViews.MyBusinesses,
        page_view: EMenuViews.Profile,
      },
    });
  }, []);

  return (
    <div className="my-businesses-wrapper">
      <div className="card-business">
        <img src={data.profileImage} alt="img-business" />
        <div className="content-business-card">
          <span className="business-name">{data.businessName}</span>
          <span
            className="view-detail"
            onClick={() => navigate(`my-business/${data.businessId}`)}>
            {t('profilePage:editActionText')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MyBusiness;
