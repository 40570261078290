import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import {AppState} from '../../redux';

const RequireAuth = ({children}: {children: JSX.Element}) => {
  const auth = useSelector((state: AppState) => state?.auth);
  const location = useLocation();

  if (!auth || !auth.token || !auth.isAuthenticated) {
    return <Navigate to="/" state={{from: location}} />;
  }

  return children;
};

export default RequireAuth;
