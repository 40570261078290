export enum EAnalyticsCustomEventNames {
  ConfirmEmail = 'confirm_email',
  CreateAd = 'create_ad',
  CreateDeal = 'create_deal',
  DeleteAd = 'delete_ad',
  DeleteDeal = 'delete_deal',
  EditAd = 'edit_ad',
  EditBusinesses = 'edit_businesses',
  EditDeal = 'edit_deal',
  EditMyInformation = 'edit_my_information',
  NewVerificationLink = 'new_verification_link',
  PageView = 'page_view',
  Register = 'sign_up',
  ViewItemAd = 'view_item_ad',
  ViewItemDeal = 'view_item_deal',
  ViewItemListAd = 'view_item_list_ad',
  ViewItemListDeal = 'view_item_list_deal',
}

export enum EAnalyticsLoginMethod {
  LogInMethod = 'login_mana_vendor',
  LogOutMethod = 'logout_mana_vendor',
}

export enum EAnalyticsRegisterSteps {
  BusinessCommunity = 'business_community',
  BusinessContact = 'business_contact',
  BusinessDescription = 'business_description',
  BusinessHours = 'business_hours',
  BusinessImages = 'business_images',
  BusinessInformation = 'business_information',
  BusinessOnlineStore = 'business_online_store',
  BusinessPhysicalStore = 'business_physical_store',
  BusinessTypeStore = 'business_type_store',
  ConfirmYourEmail = 'confirm_your_email',
  UserInformation = 'user_information',
}

export enum EAnalyticsExploreList {
  Ads = 'explore_ads',
  Deals = 'explore_deals',
}

export enum EAnalyticsViewItemDetails {
  Ad = 'view_item_details_ad',
  Deal = 'view_item_details_deal',
}
