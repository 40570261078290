export default {
  login: {
    authErrorMessage: 'Incorrect email or password',
    buttonSend: 'Send',
    defaultErrorMessage:
      'We are experiencing an internal issue.\nPlease try again in a few minutes.',
    defaultErrorTitle: 'Something went wrong',
    didntReceiveTheCode: 'Didn’t receive the code?',
    dontHaveAccount: 'Don’t have an account?',
    email: 'Email',
    errorNetwork: "We're having trouble connecting to the network",
    forgotPasswordTitle: 'Forgot password',
    login: 'Login',
    newPassword: 'New password',
    password: 'Password',
    phoneOrEmail: 'Phone or email',
    resend: 'Resend',
    signUp: 'Sign up',
    title: 'Sign in',
    unpluggedErrorMessage: 'Please check your internet\nsettings and try again',
    unpluggedErrorTitle: 'No internet connection',
    updateFailed: 'Update failed',
    updatePasswordFailed: 'An error occurred updating the password',
    updatePasswordSuccessfully: 'Password updated successfully',
    updateSuccessfully: 'Update successfully',
    verificationCode: 'Verification code',
  },
  global: {
    actions: 'Actions',
    ad: 'ad',
    areYouSure: 'Are you sure?',
    cancel: 'Cancel',
    close: 'Close',
    community: 'Community',
    characters: 'characters',
    confirm: 'Confirm',
    confirmBlock: 'My ad blocker is disabled',
    create: 'Create',
    deal: 'deal',
    delete: 'Delete',
    descriptionBlock:
      'You will not be able to create ads if you have an active ad blocker. Please disable it or whitelist Mana before continuing.',
    discartChanges: 'Discard changes',
    dontShowAgain: 'Don’t show this again',
    edit: 'Edit',
    edited: '(Edited)',
    florida: 'Florida',
    free: 'Free',
    helpfulTips: 'helpful tips.',
    international: 'International',
    invalidFormat: 'Invalid format',
    loading: 'Loading...',
    logout: 'Log out',
    manaGlobal: 'Mana Global',
    manaGlobalDescription: 'Urban revitalization and community building',
    oops: 'Oops!',
    optional: 'Optional',
    product: 'Product',
    retry: 'Retry',
    save: 'Save',
    saveChanges: 'Save changes',
    selectOption: 'Select an option',
    service: 'Service',
    skip: 'Skip',
    titleBlock: 'Heads up!',
    tryAgain: 'Try again',
    understood: 'Understood',
    usa: 'USA',
    generalMessages: {
      errorTimeRange:
        'It is not possible to transpose the same time range for one day.',
      errorUpdate: 'An error occurred updating the information',
      successForwardedEmail: 'Forwarded email',
      successInformationSaved: 'Information saved successfully',
      successUpdate: 'Updated successfully',
    },
  },
  userProfile: {
    greetingUser: 'Hello',
  },
  dealsPage: {
    addButton: 'Add',
    alertDealExpiredSoon: 'Your deal will expire soon.',
    createDescription:
      'Offer discounts, free\n goods, or other special\n benefits on the\n Marketplace section.',
    deleteDealSubtitle:
      'Are you sure you want to permanently delete this deal?',
    deleteDealTitle: 'Delete deal',
    dropdownDealItem: 'New Deal',
    dropdownPromotionItem: 'New Promotion',
    headerActions: 'Actions',
    headerEndsOn: 'Ends on',
    headerFrom: 'From',
    headerStatus: 'Status',
    headerTitle: 'Title',
    headerType: 'Type',
    noResultText1: 'No deals or promotions',
    noResultText2: 'You haven’t created any deals or promotions yet.',
    noResultText3: 'Click on “Create” to start!',
    packageFieldDescription:
      'Select a tier to define the prioritazion and duration of your deal in the Mana App.',
    packageImpressions: '\nYour deal will be shown ',
    statusActive: 'Active',
    statusExpired: 'Expired',
    statusScheduled: 'Scheduled',
    title: 'Deals',
    titleSingular: 'Deal',
    total: 'total',
    typeDeal: 'Deal',
    typePromotion: 'Promotion',
  },
  createDeal: {
    areYouSure: 'Are you sure?',
    buttonFiles: 'Choose files',
    confirmPublishMsg:
      'This deal will be sent for approval once you confirm this action',
    confirmPublishMsgEdit:
      'This deal will be updated once you confirm \nthis action.',
    durationFrom: 'From',
    durationPlaceholder: 'MM-DD-YYYY',
    durationTo: 'To',
    errorImageSize: 'File exceeded the maximum size',
    errorImageWeight: 'File exceeded the maximum weight',
    errorMessageUrl: 'URL is not valid. Format should be: “https://...”',
    fieldCodeDescription:
      'Enter your promo code here. The maximum length of  a promo code is 20 characters.',
    fieldCodeTitle: 'Promo Code',
    fieldConditionsDescription:
      'This condition list will inform the user how the deal works. \nInclude all the details you think are vital for it’s usage.\nThe maximum length per condition is 150 characters.',
    fieldConditionsTitle: 'Conditions (optional)',
    fieldDealTypeDescription: 'Choose the type of deal you want to create.',
    fieldDealTypeTitle: 'Deal Type',
    fieldDurationDescription:
      'Specify a start and end date for your deal.\nIf needed you can schedule them for a future release date.',
    fieldDurationTitle: 'Duration',
    fieldImageDescription:
      'Choose an image in jpg or png.\n Minimum recommended size is 640 x 480. Maximum weight is 10MB.',
    fieldImageTitle: 'Banner image',
    fieldLocationDescription:
      'Add your business location so customers can find you easily.',
    fieldLocationTitle: 'Location (optional)',
    fieldOfferTagDescription:
      'If needed you can add an offer tag to your coupon.\nThe maximum length permitted is 8 characters.',
    fieldOfferTagTitle: 'Offer tag (optional)',
    fieldOfferTooltip:
      'Offer tags are an effective way to \ncatch customers attention. \nSome common examples are: \n<b>"30% OFF", "2x1" , "Only $XX", \n"%Sale", "$XX Saved", "Big Sale", \n"Sale", "Discount"</b>.',
    fieldPromoCodePlaceholder: 'Add a promo code',
    fieldQrDescription:
      'Your QR Code will be generated automatically. Make sure it can be scanned before publishing the deal.',
    fieldQrPlaceholder: 'Add an URL or text',
    fieldQrTitle: 'Introduce an URL or text',
    fieldTitleDescription:
      'Choose a title, this should reflect what your deal is about.',
    fieldTitleTitle: 'Title',
    fieldVisibilityDescription:
      'Choose your audience.\nThis will define the type of users that can see your deal.',
    fieldVisibilityTitle: 'Audience',
    fieldWebsiteDescription:
      'Add your business location so customers can find you easily.',
    fieldWebsiteTitle: 'Website (optional)',
    headerPageTooltipMessage:
      'With deals you can offer discounts, free goods, \nor other special benefits to your customers through our app. All the deals you create\n will be available on our Marketplace section.',
    newCondition: 'Add new condition',
    placeholderOfferTag: 'Add an offer tag',
    placeholderTitle: 'Add a title',
    placeholderWebsite: 'Add a website URL',
    previewDealInformationalMessage:
      'Please note this view can change depending on the phone screen size',
    promoNoCode: 'Promotion (No code)',
    publishButton: 'Create Deal',
    success: 'New deal successfully created',
    successDelete: 'Deal successfully deleted',
    successEdit: 'Deal successfully edited',
    title: 'Create new deal',
    titleEdit: 'Edit deal',
    titlePromotion: 'Create new promotion',
    typeCode: 'Promo Code',
    typeQr: 'QR Code',
    typeQrAndCode: 'QR Code and Promo Code',
    unsavedChanges: 'Unsaved Changes',
    unsavedChangesMsg:
      'Are you sure you want to leave? If you do, \n progress you’ve made will be lost',
    visibilityPlaceholder: 'Please select an audience',
  },
  previewDeal: {
    buttonQrCode: 'See QR code',
    buttonWebsite: 'Go to website',
    contentCoreTitle: 'Title',
    contentHeaderDate: 'Valid up to MM-DD-YYYY',
    headerTitle: 'Deal details',
    headerTitlePromotion: 'Promotion details',
    previewDate: 'Valid up to',
    previewDealDetails: 'Details of this offer',
    sponsored: 'Sponsored',
    typeCode: 'Promo Code',
    typeOfDeal: 'Type of deal',
    typeQr: 'QR Code',
    typeQrAndCode: 'Qr Code and Promo Code',
    useCode: 'Use the code',
  },
  createAd: {
    alertAdExpiredSoon: 'Your ad will expire soon.',
    callToActionFieldDescription:
      'Add a call to action (CTA) to empower customers to take certain actions.',
    callToActionFieldLabel: 'Button label',
    callToActionFieldPlaceholder: 'Please select an option',
    callToActionFieldTitle: 'Call to action',
    callToActionFieldUrlLabel: 'Website URL',
    callToActionFieldUrlPlaceholder: 'Add a website URL',
    confirmPublishMsg:
      'This ad will be published on our app \nonce you confirm this action.',
    confirmPublishMsgEdit:
      'This ad will be updated once you confirm \nthis action.',
    deleteAdSubtitle: 'Are you sure you want to permanently delete this ad?',
    deleteAdTitle: 'Delete Ad',
    fieldCommunityDescription:
      'Choose the Community where you want to publish your ad.',
    fieldCommunityTitle: 'Community',
    fieldDescriptionDescription:
      'Add a description to grab customers attention by highlighting your products or services. We provide some ',
    fieldDescriptionPlaceholder: 'Add a description',
    fieldDescriptionTitle: 'Description',
    fieldDurationDescription:
      'Specify a start date and the amount of weeks you want this ad to run.',
    fieldDurationTitle: 'Duration',
    fieldHeadlineDescription:
      'A headline provides a way to entice your audience to click on your ad. ',
    fieldHeadlinePlaceholder: 'Add a headline',
    fieldHeadlineTitle: 'Headline (Optional)',
    fieldImageDescription:
      'Choose an image in jpg or png.\n Minimum recommended size is 640 x 480. Maximum weight is 10MB.',
    fieldTitleDescription:
      'Add a title for your ads to easily find them on your list. Visible only to you.',
    fieldTitlePlaceholder: 'Add a title',
    fieldTitleTitle: 'Title',
    labelApplyNow: 'Apply now',
    labelBookNow: 'Book now',
    labelBuyNow: 'Buy now',
    labelContactNow: 'Contact us',
    labelDonateNow: 'Donate now',
    labelDownload: 'Download',
    publishButton: 'Create Ad',
    success: 'New ad successfully created',
    successDelete: 'Ad successfully deleted',
    title: 'Create new ad',
    titleEdit: 'Edit ad',
  },
  shared: {
    checkingLanguage: 'Checking for inapropiate language...',
    checkShareLocation: 'I want to display my location',
    fixedDurationWeek: '\nFixed duration of {{duration}} week.',
    fixedDurationWeeks: '\nFixed duration of {{duration}} weeks.',
    free: 'Free',
    inapropiateVocavulary: 'This contains inappropriate language',
    moreThanTwice: ' times a day to each user',
    once: ' once a day to each user',
    packageFieldTitle: 'Budget & Duration',
    startDate: 'Start date',
    twice: ' twice a day to each user',
    vocavularyOk: 'This content seems safe!',
    weeks: 'Weeks',
  },
  ads: {
    createDescription:
      'Promote your brand,\n products or other kind\n of services on the\n Home section.',
    headerPackage: 'Package',
    noAdsResult1: 'No ads',
    noAdsResult2: 'You haven’t created any ads yet.',
    noAdsResult3: 'Click on “Create” to start!',
    packageFieldDescription:
      'Select a tier to define the prioritazion and duration of your ad in the Mana App.',
    packageImpressions: '\nYour ad will be shown ',
    successEdit: 'Ad successfully edited',
    title: 'Ads',
    titleSingular: 'Ad',
  },
  menu: {
    communications: 'Communications',
    contactus: 'Contact us ',
    profileEdition: 'Go to Profile page',
  },
  tableItems: {},
  register: {
    agreeEulaText: 'EULA',
    agreePrivacyPolicyEula: 'By clicking Start, you agree to our',
    agreePrivacyPolicyEulaAndText: 'and',
    agreePrivacyPolicyEulaTermsText: 'terms.',
    agreePrivacyPolicyText: 'Privacy Policy',
    businessCategory: 'Category',
    businessCategoryPlaceholder: 'Please select a category',
    businessContactInfoEmailTitle: 'Contact Email',
    businessContactInfoSocialMediaPlaceholderUrl: 'url:',
    businessContactInfoSocialMediaPlaceholderUsername: 'username',
    businessContactInfoSocialMediaTitle: 'Social Media (Optional)',
    businessContactInfoWebsiteTitle: 'Website (Optional)',
    businessDescription: 'Business description',
    businessDescriptionPlaceholder: 'Add a description',
    businessHoursClosed: 'Closed',
    businessHoursFriday: 'Friday',
    businessHoursMonday: 'Monday',
    businessHoursOpen: 'Open',
    businessHoursSaturday: 'Saturday',
    businessHoursSunday: 'Sunday',
    businessHoursThursday: 'Thursday',
    businessHoursTimePlaceholder: '--:-- --',
    businessHoursTimezonePlaceholder: 'Please select a Time Zone',
    businessHoursTimezoneTitle: 'Time Zone',
    businessHoursTuesday: 'Tuesday',
    businessHoursWednesday: 'Wednesday',
    businessImagesSubtitle:
      'Your photos may appear in your profile page. \n Minimum recommended size is 640 x 480 px. Maximum weight is 10MB.',
    businessImagesTitle: 'Business Images',
    businessName: 'Business name',
    businessOnlineStepWebsiteTitle: 'Website URL',
    businessPhysicalStepAddressPlaceholder: 'Enter address',
    businessPhysicalStepAddressTitle: 'Street Address',
    businessPhysicalStepCityPlaceholder: 'Enter location city',
    businessPhysicalStepCityTitle: 'City',
    businessPhysicalStepCountryPlaceholder: 'Select a country',
    businessPhysicalStepCountryTitle: 'Country',
    businessPhysicalStepLocationTitle: 'Location',
    businessPhysicalStepStatePlceholder: 'Enter state',
    businessPhysicalStepStateTitle: 'State',
    businessPhysicalStepZipcodePlaceholder: 'Enter zipcode',
    businessPhysicalStepZipcodeTitle: 'Zipcode',
    businessSubcategory: 'Subcategory',
    businessSubcategoryPlaceholder: 'Please select a Subcategory',
    chooseFiles: 'Choose files',
    communityHelpText: 'You can always switch between Communities later on.',
    confirmYourEmail:
      'In order to officially become a vendor of the Mana App we need to verify your email.',
    dataSentSuccessfully: 'Data sent successfully',
    errorValidationsDoesntLocationExist: 'Location doesn’t exist',
    errorValidationsDoesntMatchPasswords: 'The passwords don’t match',
    errorValidationsDoesntMatchEmail: 'The emails don’t match',
    errorValidationsDoesntMatchZipcode: 'Zipcode doesn’t match the location',
    errorValidationsEmailAlreadyAssociate:
      'This email is already associated with an account',
    errorValidationsEmailFormat: 'Invalid email format',
    errorValidationsPhoneNumberFormat: 'Invalid phone number',
    errorValidationsUrlFormat:
      'URL is not valid. Format should be: “https://....”',
    errorValidationsZipcodeFormat: 'Zipcode format is invalid',
    haveAnAccountQuestion: 'Already have an account?',
    nextButton: 'Next',
    optionsStoreTypeOnlineOption: 'No',
    optionsStoreTypePhysicalOption: 'Yes',
    phoneNumberField: 'Phone Number',
    previewButton: 'Preview',
    previousButton: 'Previous',
    profileImageSubtitle:
      'Choose an image in jpg or png. \n Minimum recommended size is 640 x 480 px. Maximum weight is 10MB.',
    profileImageTitle: 'Profile Image',
    requestNewLinkBtn: 'Request new link',
    requestNewVerificationLink:
      'We couldn’t complete the verification process. Please request a new verification link.',
    resendEmailButton: 'Resend email',
    sendEmailButton: 'Send email',
    sentEmailButton: 'Sent',
    setUpYourAccountEmailTitle: 'Email',
    setUpYourAccountLastNameTitle: 'Last name',
    setUpYourAccountNameTitle: 'First name',
    setUpYourAccountPasswordTitle: 'Password',
    setUpYourAccountStartButton: 'Start',
    signIn: 'Sign in',
    step1Subtitle:
      'Become a part of our community by creating a vendors account. This will allow you to promote your business, create deals for your customers and much more.',
    step1Title: 'Start by setting up your account',
    step2Subtitle:
      'Let customers know what products and services you offer by telling us more about your business.',
    step2Title: 'Build your business profile',
    step3Subtitle:
      'Encourage customers to learn about what your business does and what makes it unique by adding a description to your profile.',
    step3Title: 'Add a description for your business',
    step4Subtitle:
      'Allow potential customers peek inside your business by adding pictures of your products, services and the outside or inside of your business.',
    step4Title: 'Add photos of your business',
    step5OnlineTitle: 'Add your online store information',
    step5PhysicalSubtitle:
      'The location will be visible on your profile so customers can find you easily. If your business has more than one location, be sure to add it. But don’t forget to set one as your main address!',
    step5PhysicalTitle: 'Add a location for your physical store',
    step5Subtitle: 'Let customers know where and how to find you.',
    step5Title: 'Do you have a physical store?',
    step6Subtitle:
      'Specify when you’re open for business so customers can know when to visit or call.',
    step6Title: 'Add business hours',
    step7Subtitle:
      'Give customers the option to contact you by adding your contact information to your profile.',
    step7Title: 'Add your contact info',
    step8Subtitle: 'You’ve succesfully completed the registration process!',
    step8Title: 'Confirm your email',
    stepCommunitySubtitle: 'Let customers know where and how to find you.',
    stepCommunityTitle: 'Select your Community',
    verifyAccountDescriptionFirstPart:
      'In order to officially become a vendor of the Mana App we need to verify your account. You’ll receive an email with an update at ',
    verifyAccountDescriptionSecondPart: 'as soon as possible.',
    verifyAccountDescriptionThirdPart:
      'Please take into consideration that this action might take up to 5 business days.',
    verifyAccountSubTitle:
      'You’ve succesfully completed the registration process!',
    verifyAccountTitle: 'Thank you!',
    invitedTo: 'You have been invited to:',
    userCreated: 'User created!',
  },
  profilePage: {
    addBusinessPhotosDescription: 'Add photos of your business',
    addPictures: 'Add pictures',
    badgeAuthorizationText:
      'This business is currently being reviewed by our team. You should hear from us soon!',
    bannerImageTitle: 'Banner Image',
    btnLocationAddAddress: 'Add address',
    btnLocationAddNewLocation: 'Add a new location',
    btnLocationClearAddress: 'Clear fields',
    btnLocationEditAddress: 'Edit address',
    businessCategoryFieldTitle: 'Category',
    businessCommunityFieldPlaceholder: 'Select a community',
    businessOnlineStore: 'Online store',
    businessPhysicalStore: 'Physical store',
    businessSubcategoryFieldTitle: 'Subcategory',
    businessTypeFieldTitle: 'Business type',
    businessWebsiteFieldPlaceholder: 'https://',
    businessWebsiteFieldTitle: 'Website URL',
    changeBannerPicture: 'Change banner picture',
    changePasswordText: 'Change password',
    changeEmailText: 'Change email',
    changeUserData: 'Change user',
    changeProfilePicture: 'Change profile picture',
    createNewLocationTitle: 'Create new location',
    editActionText: 'View & edit',
    editLocationTitle: 'Edit location',
    fieldCommunityPlaceholder: 'Please select a community',
    fieldCommunityPlaceholderLocation: 'Select this location’s community',
    fieldCommunityTitle: 'My Community',
    fieldCurrentPasswordTitle: 'Current password',
    fieldCurrentEmailTitle: 'Current email',
    fieldFacebookPlaceholder: 'Enter Facebook Page URL',
    fieldFacebookTitle: 'Facebook Page',
    fieldInstagramPlaceholder: 'Enter Instagram URL',
    fieldInstagramTitle: 'Instagram Profile',
    fieldNewPasswordPlaceholder: '*********************',
    fieldNewPasswordTitle: 'New password',
    fieldNewEmailTitle: 'New email',
    fieldRepeatPasswordTitle: 'Repeat new password',
    fieldRepeatEmailTitle: 'Repeat new email',
    fieldTwitterPlaceholder: 'Enter Twitter URL',
    fieldTwitterTitle: 'Twitter Profile',
    fullnameFieldTitle: 'Full Name',
    infoAction: 'My Profile',
    infoActionText: 'My profile',
    infoTitle: 'Account',
    locationFieldNicknamePlaceholder: 'Enter location nickname',
    locationFieldTableFullAddress: 'Full address',
    locationFieldTableMainLocation: 'Main location',
    locationFieldTableNickname: 'Location nickname',
    mainLocationText: 'Main location',
    miami: 'Miami',
    modalConfirmationDescription:
      'Our admin team will send you an email once the new information is approved.',
    modalConfirmationTitle: 'Changes saved',
    myAddressesDescription: 'Select one as your main location',
    myAddressesTitle: 'My addresses',
    roleFieldTitle: 'Role',
    selectAsMainText: 'Select as main',
    setNewPasswordText: 'Set new password',
    setNewEmailText: 'Set new email',
    tabMybusinesses: 'My businesses',
    tabMybusinessesContactInfo: 'Contact info.',
    tabMybusinessesHours: 'Business hours',
    tabMybusinessesImages: 'Business images',
    tabMybusinessesInfo: 'Business info.',
    tabMybusinessesLocation: 'Location info',
    tabMyinformation: 'My information',
    tabMyUsers: 'Users',
    btnMyUsersAddNewUser: 'Add user',
    titlePage: 'Profile page',
  },
  errors: {
    errorProcessingFile: 'An error occurred while processing the file',
    invalidPassword: 'Invalid password',
    enterValidEmail: 'Please enter valid email',
    emailRequired: 'Email address is required',
    roleRequired: 'Role is required',
    internalError: 'Internal error',
  },
  hours: {
    businessAddHours: 'Add a second timerange',
    deleteAllHours: 'Delete all hours',
    replicateHours: 'Replicate for all week',
  },
  contactUs: {
    title: 'Contact us',
    select: 'Select',
    reason: 'Reason',
    addNote: 'Add Note',
    writeANote: 'Write a note',
    submit: 'Submit',
    other: 'Other',
    dataSentSuccessfully: 'data sent successfully',
    iNeedHelpAccount: 'I need help with my account',
    reportNSFW18PlusUserContentMessage:
      'Report NSFW/18+ users, content or messages',
    reportChildSexualExploitation: 'Report child sexual exploitation',
    reportViolationCodeConduct: 'Report a violation code of conduct',
    reportBug: 'Report bug',
    reportContentPolicyViolation: 'Report content policy violation',
    reportSuicideSelfHarm: 'Report suicide/self-harm',
    copyrightInfrigementDMCA: 'Copyright infringement/DMCA',
    copyrightCounterNotice: 'Copyright counter notice',
    investigations: 'Investigations',
    mobileHelp: 'Mobile help',
    purchasesInquiries: 'Purchases Inquiries',
    problemsAdsDeals: 'Problems with ads/deals',
    purchaseProblemTicketFairy: 'Purchase problem with ticket fairy',
    trademarkRequest: 'Trademark request',
    inquiriesRelatedYourPrivacyRights:
      'Inquiries related to your privacy rights',
    otherHelp: 'Other help',
  },
  business: {
    deleteUserTitle: 'Delete user',
    deleteUserSubtitle:
      'Are you sure you want to permanently delete this user?',
    addNewUser: 'Add New User',
    invitationWillBeSendToTheEmail: 'Invitation will be send to the email',
    userSuccessfullyAdded: 'User successfully added',
    userSuccessfullyDeleted: 'User successfully deleted',
    buttonSend: 'Send',
    buttonCancel: 'Cancel',
    email: 'Email',
    fieldTableName: 'Name',
    fieldTableRole: 'Role',
    fieldTableEmail: 'Email',
    fieldTableStatus: 'Status',
    fieldTableActions: 'Actions',
  },
};
