import React, {useEffect, useState} from 'react';
import XIcon from '../../../../../../assets/icons/remove-image.svg';
import Title from '../Title';
import Subtitle from '../Subtitle';
import ButtonUploadImage from '../../../../../../components/ButtonUploadImage';
import DefaultBusinessImage from '../../../../../../assets/images/DefaultBusinessImage.png';
import {RootState} from '../../../../../../redux';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  setHasErrors,
  setProfileData,
} from '../../../../../../redux/actions/profileActions';
import {BUSINESS_IMAGES_LIMIT} from '../../../../../../constants/register';
import './styles.scss';

type Props = {
  images: any[];
  labelBtnAdd?: string;
  setImages: (value: any) => void;
};

const BusinessImages: React.FC<Props> = ({images, labelBtnAdd, setImages}) => {
  // Hooks
  const {t} = useTranslation('register');
  const {data, hasErrors} = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  // State
  const [imagesPreview, setImagesPreview] = useState<any>([]);

  // Effects
  useEffect(() => {
    if (data.images.length > 0) {
      setImagesPreview(data.images);
      setImages(data.images);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // functions
  const addImage = (value: any) => {
    setImages([
      ...images,
      {
        id: 0,
        url: URL.createObjectURL(value),
        delete: false,
      },
    ]);
    setImagesPreview([
      ...imagesPreview,
      {
        id: 0,
        url: URL.createObjectURL(value),
        delete: false,
      },
    ]);

    dispatch(
      setProfileData({
        images: [
          ...images,
          {
            id: 0,
            url: URL.createObjectURL(value),
            delete: false,
          },
        ],
        newImagesForGallery: [
          ...images,
          {
            id: 0,
            url: URL.createObjectURL(value),
            delete: false,
          },
        ],
      }),
    );
    dispatch(
      setHasErrors({
        ...hasErrors,
        pictures: false,
      }),
    );
  };

  const removeImage = (index: number) => {
    const imageObjectPreview = imagesPreview[index];

    if (imageObjectPreview.id === 0) {
      images.splice(index, 1);
      imagesPreview.splice(index, 1);
    } else {
      images[index].delete = true;
      imageObjectPreview.delete = true;
    }

    setImagesPreview([...imagesPreview]);
    setImages([...images]);
    dispatch(
      setProfileData({
        images: [...images],
        modifyImageFiles: images.filter(e => e.id !== 0),
        newImagesForGallery: [...images],
      }),
    );

    // Block General Buttom
    if (imagesPreview.length < 1) {
      dispatch(
        setHasErrors({
          ...hasErrors,
          pictures: true,
        }),
      );
    }
  };

  return (
    <div className="images-pictures-wrapper">
      <Title text={t('businessImagesTitle')} />
      <Subtitle text={t('businessImagesSubtitle')} />
      <div className="business-images-files">
        {imagesPreview.length < 1 ? (
          <img
            className="business-image"
            src={DefaultBusinessImage}
            width={160}
            height={160}
            alt="bussiness"
          />
        ) : (
          imagesPreview.map(
            (image: any, index: number) =>
              !image.delete && (
                <div key={index} className="business-image-wrapper">
                  <img
                    className="business-image"
                    src={image.url}
                    width={160}
                    height={160}
                    alt="business-preview"
                  />
                  <div
                    onClick={() => removeImage(index)}
                    className="business-images-close">
                    <img
                      src={XIcon}
                      width={12}
                      height={12}
                      alt="business-close"
                    />
                  </div>
                </div>
              ),
          )
        )}
        {images.filter(e => !e.delete).length < BUSINESS_IMAGES_LIMIT && (
          <div className="business-images-upload-button">
            <ButtonUploadImage
              acceptVideo={false}
              hideImageName={true}
              id={'upload-business-image'}
              label={labelBtnAdd || t('chooseFiles')}
              onClick={addImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessImages;
