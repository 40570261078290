import {useDispatch} from 'react-redux';
import {
  IAddUserToBusinessRequest,
  IDeleteUserFromBusinessRequest,
  IGetBusinessUsersRequest,
} from '../models/interfaces/business';
import {
  createUsersByBusinessRequest,
  deleteUsersByBusinessRequest,
  getUsersByBusinessRequest,
} from '../redux/actions/businessActions';
type IUseBusiness = {
  getUsersByBusiness: (params: IGetBusinessUsersRequest) => void;
  createBusinessUser: (params: IAddUserToBusinessRequest) => void;
  deleteBusinessUser: (params: IDeleteUserFromBusinessRequest) => void;
};

const useBusiness = (): IUseBusiness => {
  const dispatch = useDispatch();

  const getUsersByBusiness = (params: IGetBusinessUsersRequest) => {
    dispatch(getUsersByBusinessRequest(params));
  };
  const createBusinessUser = (params: IAddUserToBusinessRequest) => {
    dispatch(createUsersByBusinessRequest(params));
  };

  const deleteBusinessUser = (params: IDeleteUserFromBusinessRequest) => {
    dispatch(deleteUsersByBusinessRequest(params));
  };

  return {
    getUsersByBusiness,
    createBusinessUser,
    deleteBusinessUser,
  };
};

export default useBusiness;
