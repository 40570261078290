import {DEFAULT_TIME_ZONE_NAME} from '../../constants/dates';
import {EPopupType} from '../../models/enums/ui';
import {ErrorTypes} from '../../models/enums/registerForm';
import {getBusinessData, profileApi} from '../../services/api/profileAPi';
import {IBusinessHourProfile} from '../../models/api/profile';
import {IBusinessHours} from '../../models/interfaces/generals';
import {IEditUserProfile} from '../../models/interfaces/user';
import {IUserInformation} from '../../models/api/userInformation';
import {registerApi} from '../../services/api/registerAPI';
import {showPopup} from './uiActions';
import {sortBusinessHours} from '../../utils/businessHoursValidation';
import {t} from 'i18next';
import {uniqueId} from 'lodash';
import {
  IProfileBody,
  IProfileFieldErrors,
  IProfileLocation,
} from '../../models/interfaces/profile';
import {
  CLEAR_PROFILE_DATA,
  GET_BUSINESS_IDENTIFICATION_SUCCESS,
  GET_COMMUNITIES_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_FAIL,
  GET_REQUEST,
  SET_CURRENT_LOCATION,
  SET_ERROR_CODE,
  SET_HAS_ERROR,
  SET_PENDING_PROFILE_AUTHORIZATION,
  SET_PROFILE_DATA_COORDENATES,
  SET_PROFILE_DATA,
  SET_SELECTED_ADDRESS,
  SUCCESS_PROFILE_DATA,
} from '../types/profileTypes';
import moment from 'moment-timezone';
import useBusinessHours from '../../hooks/useBusinessHours';

export const setProfileData = (object: any) => ({
  type: SET_PROFILE_DATA,
  payload: object,
});

export const setProfileDataCoordenates = (object: any) => ({
  type: SET_PROFILE_DATA_COORDENATES,
  payload: object,
});

export const getBusinessIdentificationSuccess = (data: any) => ({
  type: GET_BUSINESS_IDENTIFICATION_SUCCESS,
  payload: data,
});

export const getCountriesSuccess = (data: any) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: data,
});

export const getCommunitiesSuccess = (data: any) => ({
  type: GET_COMMUNITIES_SUCCESS,
  payload: data,
});

export const sendSuccessProfileData = () => ({
  type: SUCCESS_PROFILE_DATA,
});

export const clearProfileData = () => ({
  type: CLEAR_PROFILE_DATA,
});

export const getRequest = () => ({
  type: GET_REQUEST,
});

export const getFail = (data: any) => ({
  type: GET_FAIL,
  payload: data,
});

export const setErrorCode = (code: any) => ({
  type: SET_ERROR_CODE,
  payload: code,
});

export const setIsPendingAuth = (isPending: boolean) => ({
  type: SET_PENDING_PROFILE_AUTHORIZATION,
  payload: isPending,
});

export const setHasErrors = (error: IProfileFieldErrors) => ({
  type: SET_HAS_ERROR,
  payload: error,
});

export const setCurrentLocation = (location: IProfileLocation) => ({
  type: SET_CURRENT_LOCATION,
  payload: location,
});

export const setSelecteedAddress = (selected: number | null) => ({
  type: SET_SELECTED_ADDRESS,
  payload: selected,
});

// Business identification
export const getBusinessIdentification =
  (languageCode: string) => async (dispatch: any) => {
    dispatch(getRequest());
    try {
      const response: any = await registerApi.businessIdentification(
        languageCode,
      );
      if (response) {
        dispatch(getBusinessIdentificationSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getFail('Network Error'));
    }
  };

// Countries
export const getCountries = () => async (dispatch: any) => {
  dispatch(getRequest());
  try {
    const response: any = await registerApi.countries();
    if (response) {
      dispatch(getCountriesSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getFail('Network Error'));
  }
};

// Communities
export const getCommunities = () => async (dispatch: any) => {
  dispatch(getRequest());
  try {
    const response: any = await profileApi.communities();
    if (response) {
      dispatch(getCommunitiesSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getFail('Network Error'));
  }
};

export const getProfileData =
  (user: IUserInformation) => async (dispatch: any) => {
    dispatch(getRequest());
    const {unifyBusinessHours} = useBusinessHours();
    try {
      if (user.businessId) {
        const {data} = await getBusinessData(user.businessId);

        if (data) {
          const convertUtcToTimezone: IBusinessHourProfile[] =
            sortBusinessHours(data.businessHours, 'asc', 'closeTime').map(
              (hours: IBusinessHours) => {
                return {
                  closeTime: moment(hours.closeTime).format(),
                  id: hours.id.toString(),
                  keyItem: uniqueId(),
                  openTime: moment(hours.openTime).format(),
                  weekDay: hours.weekDay,
                };
              },
            );
          const unifyBusinessHoursFormat =
            unifyBusinessHours(convertUtcToTimezone);

          dispatch(
            setProfileData({
              businessDescription: data.description,
              businessHours: unifyBusinessHoursFormat,
              businessId: data.id,
              businessLocations: data.locations,
              businessLocationType: data.businessLocationType,
              businessName: data.name,
              businessSocialMedia: data.businessSocialMedia,
              community: data.communityId,
              contactBirthdate: data.businessContactPerson?.birthdate,
              contactCountryFlag:
                data.businessContactPerson?.phoneNumberCountryCode,
              contactEmail: data.businessContactPerson?.email,
              contactId: data.businessContactPerson?.id,
              contactLastname: data.businessContactPerson?.lastname,
              contactName: data.businessContactPerson?.name,
              contactPhoneNumber: data.businessContactPerson?.phoneNumber,
              contactTitle: data.businessContactPerson?.title,
              contactWebSiteUrl: data.businessContactPerson?.contactWebSiteUrl,
              countryId: data.countryId,
              coverImage: data.coverImageUrl,
              coverImageUrl: data.coverImageUrl,
              images: data.files,
              newCoverImage: data.coverImageUrl,
              newProfileImage: data.profileImageUrl,
              profileImage: data.profileImageUrl,
              profileImageUrl: data.profileImageUrl,
              subCategories: data.subCategories,
              timeZone: data.timeZone || DEFAULT_TIME_ZONE_NAME,
              userCommunity: user.communityId,
              userCountryFlag: user.phoneNumberCountryCode,
              userFirstName: user.name,
              userLastName: user.lastname,
              userPhoneCode: '+1',
              userPhoneNumber: user.phoneNumber,
              userProfileImage: user.profileImageUrl,
              userRole: user.roles[0],
              userUsername: user.email,
              webSiteUrl: data.webSiteUrl,
            }),
          );

          //Block View
          dispatch(setIsPendingAuth(data.updatePending));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

// Send Profile Edit Data (Business)
export const sendProfileEditBusinessData =
  (data: IProfileBody) => async (dispatch: any) => {
    dispatch(getRequest());
    try {
      const response: any = await profileApi.sendProfileData(data);
      if (response) {
        dispatch(getFail(''));
        dispatch(setErrorCode(ErrorTypes.NoError));
        dispatch(sendSuccessProfileData());
      }
    } catch (error: any) {
      console.log(error);
      dispatch(getFail(error.message));
      dispatch(setErrorCode(error.errorCode));
    }
  };

// Send Profile Edit Data (User)
export const sendProfileEditUserData =
  (data: IEditUserProfile) => async (dispatch: any) => {
    try {
      const response: any = await profileApi.sendProfileUserData(data);
      if (response) {
        await dispatch(setErrorCode(ErrorTypes.NoError));
        dispatch(getFail(''));
        dispatch(sendSuccessProfileData());

        if (data.userEmail) {
          dispatch(setProfileData({userUsername: data.userEmail}));
        }

        dispatch(
          showPopup(
            t('global:generalMessages:successUpdate'),
            3000,
            EPopupType.Success,
          ),
        );
      }
    } catch (error: any) {
      await dispatch(setErrorCode(error.errorCode));
      dispatch(getFail(error.message));
      dispatch(
        showPopup(
          t('global:generalMessages:errorUpdate'),
          3000,
          EPopupType.Error,
        ),
      );
    }
  };
