import React, {useEffect} from 'react';
import {Formik} from 'formik';
import {Loader} from '../../components/Loader';
import {RootState} from '../../redux';
import {TextField} from '../../components/TextField';
import {useAuth} from '../../hooks/useAuth';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validatePassword} from '../../utils/validations';
import ErrorsValidations from '../../components/ErrorsValidations/index';
import LoginArea from '../LoginPage/components/LoginArea';
import pages from '../../constants/pages';
import WideButton from '../../components/WideButton';
import './styles.scss';

const NewPassword = () => {
  const {
    smsSent,
    emailSent,
    phoneNumberRequest,
    emailRequest,
    forgotPasswordPhoneCode,
    isValidCode,
  } = useSelector((store: RootState) => store.user);
  const {t} = useTranslation('login');

  const navigate = useNavigate();
  const {isLoading, error, resetPasswordByPhone, resetPasswordByEmailCode} =
    useAuth();
  useEffect(() => {
    if (!isValidCode) {
      navigate(pages.root);
    }
  }, []);

  const handlePress = (values: any) => {
    if (phoneNumberRequest.length) {
      const data = {
        code: forgotPasswordPhoneCode,
        password: values.password,
        phoneNumber: phoneNumberRequest,
      };
      resetPasswordByPhone(data);
    } else if (emailRequest.length) {
      const data = {
        code: forgotPasswordPhoneCode,
        password: values.password,
        email: emailRequest,
      };

      resetPasswordByEmailCode(data);
    }
  };

  useEffect(() => {
    if (!smsSent && !emailSent) {
      navigate(pages.root);
    }
  }, [smsSent, emailSent]);
  return (
    <div data-testid={'new-password-page'} className="new-password-page">
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <LoginArea isOpacity={error}>
          <span className="title">{t('newPassword')}</span>

          <div className="form-container">
            <Formik
              validationSchema={validatePassword}
              initialValues={{password: ''}}
              onSubmit={handlePress}>
              {({isValid, values, submitForm, handleChange, errors}) => {
                return (
                  <div className="container">
                    <TextField
                      id="password"
                      type="password"
                      hideLabel={true}
                      value={values.password || ''}
                      handleChange={e => handleChange(e)}
                      error={!isValid}
                      placeHolder={t('login:newPassword')}
                      required={true}
                      maxLength={50}
                      customStyles={customStyles}
                    />
                    {errors.password && (
                      <ErrorsValidations type="InvalidCredentials" />
                    )}
                    <div className="bottom-container">
                      <WideButton
                        label={t('login:buttonSend')}
                        onClick={submitForm}
                        disabled={!isValid}
                      />
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </LoginArea>
      </div>
    </div>
  );
};

const customStyles = {
  container: {
    marginBottom: 26,
  },
};
export default NewPassword;
