import React, {useState, useEffect} from 'react';
import {DEFAULT_DATE_FORMAT} from '../../constants/dates';
import {useTranslation} from 'react-i18next';
import DatePicker, {registerLocale} from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import moment from 'moment';
registerLocale('en', en);
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

type Props = {
  setterDate: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  label: string;
  defaultDate?: Date | null;
};

const Calendar: React.FC<Props> = ({
  setterDate,
  label,
  minDate,
  maxDate,
  defaultDate,
}: Props) => {
  const {t} = useTranslation('createDeal');
  const [date, setDate] = useState<any>(null);

  useEffect(() => {
    setDate(defaultDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setterDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const getDisabledDateClass = (date: Date) => {
    if (minDate) {
      return moment(date).local() < moment(minDate).local()
        ? 'disabled-date'
        : '';
    }
    if (maxDate) {
      return date.getTime() >= maxDate.getTime() ? 'disabled-date' : '';
    }
    return '';
  };

  return (
    <div className="calendar-wrapper">
      <div className="calendar-from">
        <span className="calendar-text">{label}</span>
        <DatePicker
          placeholderText={t('durationPlaceholder')}
          locale="en"
          useWeekdaysShort={true}
          wrapperClassName="date-picker"
          selected={defaultDate}
          onChange={date => setDate(date)}
          dayClassName={date => getDisabledDateClass(date)}
          dateFormat={DEFAULT_DATE_FORMAT}
        />
      </div>
    </div>
  );
};

export default Calendar;
