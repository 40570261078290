import {DEFAULT_TIME_ZONE_NAME} from '../../constants/dates';
import {IProfileState} from '../../models/interfaces/reduxStates';
import {
  CLEAR_PROFILE_DATA,
  GET_BUSINESS_IDENTIFICATION_SUCCESS,
  GET_COMMUNITIES_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_FAIL,
  GET_REQUEST,
  SET_CURRENT_LOCATION,
  SET_ERROR_CODE,
  SET_HAS_ERROR,
  SET_PENDING_PROFILE_AUTHORIZATION,
  SET_PROFILE_DATA_COORDENATES,
  SET_PROFILE_DATA,
  SET_SELECTED_ADDRESS,
  SUCCESS_PROFILE_DATA,
} from '../types/profileTypes';

const initialState: IProfileState = {
  businessGeographicCoordenates: [],
  businessIdentification: [],
  communities: [],
  countries: [],
  currentLocation: {},
  data: {
    // Information (Vendor)
    userCommunity: null,
    userCountryFlag: 'US',
    userFirstName: '',
    userLastName: '',
    userNewPassword: '',
    userPhoneCode: '+1',
    userPhoneNumber: '',
    userProfileImage: '',
    userRole: '',
    userUsername: '',
    // B Information
    businessDescription: '',
    businessId: 0,
    businessName: '',
    categorySelected: {label: '', value: 0}, // no send
    community: 0,
    subCategories: [],
    subCategorySelected: {}, // no send
    webSiteUrl: '',
    // B Pictures
    coverImage: '',
    coverImageUrl: '',
    images: [],
    modifyImageFiles: [],
    newCoverImage: '',
    newImagesForGallery: [],
    newProfileImage: '',
    profileImage: '',
    profileImageUrl: '',
    // B Locations
    businessLocations: [],
    businessLocationType: '',
    countryAlpha2Code: 'US',
    countryId: 0,
    // B Hours
    businessHours: [],
    // B Contact Info
    businessSocialMedia: [],
    contactBirthdate: '',
    contactCountryCode: '+1',
    contactCountryFlag: 'US',
    contactEmail: '',
    contactId: 0,
    contactLastname: '',
    contactName: '',
    contactPhoneNumber: '',
    contactTitle: '',
    contactWebSiteUrl: '',
    timeZone: DEFAULT_TIME_ZONE_NAME,
    // Validations
    showSaveBtns: false,
  },
  errorCode: '',
  hasErrors: {
    contactInfo: false,
    hours: false,
    information: false,
    location: false,
    pictures: false,
    profilePicture: false,
    vendorInformation: false,
  },
  isPendingAuth: false,
  loading: false,
  msgError: '',
  selectedAddress: 0,
  success: false,
};

const profileReducer = (state = initialState, action: any): IProfileState => {
  switch (action.type) {
    // GENERAL

    case GET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_FAIL:
      return {
        ...state,
        loading: false,
        msgError: action.payload,
      };

    case SET_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
      };

    case SET_HAS_ERROR:
      return {
        ...state,
        hasErrors: action.payload,
      };

    case SET_PENDING_PROFILE_AUTHORIZATION:
      return {
        ...state,
        isPendingAuth: action.payload,
      };

    // PROFILE DATA

    case SET_PROFILE_DATA:
      return {
        ...state,
        data: {...state.data, ...action.payload},
      };

    case SET_PROFILE_DATA_COORDENATES:
      return {
        ...state,
        businessGeographicCoordenates: action.payload,
      };

    case SUCCESS_PROFILE_DATA:
      return {
        ...state,
        success: !state.success,
      };

    case CLEAR_PROFILE_DATA:
      return initialState;

    // B IDENTIFICATION

    case GET_BUSINESS_IDENTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        businessIdentification: action.payload,
      };

    // COUNTRIES

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };

    // COMMUNITIES

    case GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        communities: action.payload,
      };

    // LOCATIONS

    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    case SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };

    default:
      return state;
  }
};

export default profileReducer;
