import React, {useState} from 'react';
import {EBusinessWeekDay} from '../../models/enums/businessHours';
import {EGeneralDaraErrors} from '../../models/enums/errors';
import {HH_MM_A_FORMAT} from '../../constants/dates';
import {IBusinessHours} from '../../models/interfaces/generals';
import {ReactComponent as RemoveHour} from '../../assets/icons/delete-item.svg';
import {useTranslation} from 'react-i18next';
import {
  sortBusinessHours,
  setNewMomentDate,
} from '../../utils/businessHoursValidation';
import DatePicker from 'react-datepicker';
import './styles.scss';

type Props = {
  businessHours: IBusinessHours[];
  defaultValueCloseTime: string;
  defaultValueOpenTime: string;
  idRangetime: number | string;
  keyItemDay: EBusinessWeekDay;
  keyItemHour: string;
  setBusinessHours: (value: IBusinessHours[]) => void;
  setChangeCurrentDay: (day: EBusinessWeekDay) => void;
  setChecked: (value: boolean) => void;
  setIsChangedHour?: (changed: boolean) => void;
};

const BusinessHour: React.FC<Props> = ({
  businessHours,
  defaultValueCloseTime,
  defaultValueOpenTime,
  idRangetime,
  keyItemDay,
  keyItemHour,
  setBusinessHours,
  setChangeCurrentDay,
  setChecked,
  setIsChangedHour,
}) => {
  // Hooks
  const {t} = useTranslation('register');

  // States
  const [openTime, setOpenTime] = useState<string>(defaultValueOpenTime);
  const [closeTime, setCloseTime] = useState<string>(defaultValueCloseTime);

  // Functions
  const handleDeleteTimerange = () => {
    const newBusinessHours: IBusinessHours[] = businessHours.filter(
      (hour: IBusinessHours) => hour.keyItem !== keyItemHour,
    );
    if (
      !newBusinessHours.some((e: IBusinessHours) => e.weekDay === keyItemDay)
    ) {
      setChecked(false);
    }
    setBusinessHours(newBusinessHours);
  };

  const handleEditTimerange = (
    closeTime: string,
    keyItem: string,
    openTime: string,
    weekDay: number,
  ) => {
    const baseStructure: IBusinessHours = {
      closeTime,
      id: idRangetime,
      keyItem,
      openTime,
      weekDay,
    };

    const filterObject: IBusinessHours[] = businessHours.filter(
      (hour: IBusinessHours) => {
        return hour.keyItem !== keyItem;
      },
    );

    const sortObject = sortBusinessHours(
      [...filterObject, baseStructure],
      'asc',
      'weekDay',
      'openTime',
    );

    setBusinessHours(sortObject);
  };

  const handleChange = (datePicker: string, type: 'open' | 'close') => {
    const editOpenTime = type === 'open' ? datePicker : openTime;
    const editCloseTime = type === 'close' ? datePicker : closeTime;

    setChangeCurrentDay(keyItemDay);
    handleEditTimerange(editCloseTime, keyItemHour, editOpenTime, keyItemDay);
  };

  return (
    <div className="business-inputs-hours-container">
      <DatePicker
        placeholderText={t('businessHoursTimePlaceholder')}
        locale="en"
        selected={
          openTime !== EGeneralDaraErrors.invalidDate
            ? new Date(openTime)
            : null
        }
        onChange={date => {
          if (date) {
            const convertToTimezone = setNewMomentDate(date);

            setOpenTime(convertToTimezone);
            handleChange(convertToTimezone, 'open');
            {
              setIsChangedHour && setIsChangedHour(true);
            }
          }
        }}
        dateFormat={HH_MM_A_FORMAT}
        showTimeInput
        showTimeSelect
        showTimeSelectOnly
        className="datepicker-hours"
      />
      <p className="business-inputs-separator">-</p>
      <DatePicker
        placeholderText={t('businessHoursTimePlaceholder')}
        locale="en"
        selected={
          closeTime !== EGeneralDaraErrors.invalidDate
            ? new Date(closeTime)
            : null
        }
        onChange={date => {
          if (date) {
            const convertToTimezone = setNewMomentDate(date);

            setCloseTime(convertToTimezone);
            handleChange(convertToTimezone, 'close');
            {
              setIsChangedHour && setIsChangedHour(true);
            }
          }
        }}
        dateFormat={HH_MM_A_FORMAT}
        showTimeInput
        showTimeSelect
        showTimeSelectOnly
        className="datepicker-hours"
      />
      <RemoveHour
        className="business-icon-remove-hour"
        onClick={handleDeleteTimerange}
      />
    </div>
  );
};

export default BusinessHour;
