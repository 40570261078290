import React, {useState, useEffect} from 'react';
import MsgError from '../../../../components/MsgError';
import SelectField from '../../../../components/SelectField';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ErrorTypes} from '../../../../models/enums/registerForm';
import {TextField} from '../../../../components/TextField';
import {EColors} from '../../../../models/enums/colors';
import {RootState} from '../../../../redux';
import {useSteps} from '../../../../hooks/useSteps';
import {
  getBusinessIdentification,
  getFail,
  setErrorCode,
  setRegisterData,
} from '../../../../redux/actions/registerActions';
import './styles.css';
import {useCategories} from '../../../../hooks/useCategories';

interface ICategoryOption {
  label: string;
  value: number;
}

//TODO: Isolate, logic and static values in order todo the component smaller
const customStyle = {
  wideButton: {
    width: '100%',
  },
  inputField: {
    generalContainer: {
      width: '100%',
    },
    container: {
      marginBottom: 32,
    },
    input: {
      height: '38px',
    },
  },
  selectField: {
    generalContainer: {
      marginBottom: '0',
      width: '85px',
    },
  },
  linkButton: {
    color: EColors.colorDarkBlue,
    marginTop: '49px',
  },
};

const BusinessIdentificationStep = () => {
  // hooks
  const dispatch = useDispatch();
  const {t} = useTranslation('register');
  const {i18n} = useTranslation();
  const {businessIdentification} = useSelector(
    (store: RootState) => store.register,
  );
  const {msgError, errorCode, data} = useSelector(
    (store: RootState) => store.register,
  );
  const {businessName, categorySelected} = data;
  const {incrementStep} = useSteps();

  // state
  const [businessNameValue, setBusinessName] = useState<string>(businessName);
  const [categories, setCategories] = useState<ICategoryOption[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<ICategoryOption>(categorySelected);

  // effects
  useEffect(() => {
    // get categories
    dispatch(getBusinessIdentification(i18n.resolvedLanguage));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // categories options
    if (businessIdentification.categories) {
      const {subCategoriesAsCategories} = useCategories(
        businessIdentification.categories,
      );
      const optionsArray: ICategoryOption[] = createArrayOfOptions(
        subCategoriesAsCategories,
      );
      setCategories(optionsArray);
    }
  }, [businessIdentification]);

  // functions
  const createArrayOfOptions = (values: any[]) => {
    const optionsArray: ICategoryOption[] = [];
    values.forEach((element: any) => {
      optionsArray.push({label: element.name, value: element.id});
    });
    return optionsArray;
  };

  const handleNextButton = () => {
    dispatch(getFail(''));
    dispatch(setErrorCode(''));
    dispatch(
      setRegisterData({
        businessName: businessNameValue,
        categorySelected: selectedCategory,
        subCategories: [selectedCategory.value],
      }),
    );
    incrementStep();
  };

  const handleBusinessName = (event: any) => {
    setBusinessName(event.target.value);
  };

  return (
    <div className="business-identification-step-form">
      <TextField
        placeHolder={t('businessName')}
        id="businessName"
        value={businessNameValue}
        type={'text'}
        handleChange={handleBusinessName}
        customStyles={customStyle.inputField}
        errorElement={
          ErrorTypes.BusinessNameInUse === errorCode && (
            <div className="business-identification-error-container">
              <MsgError text={msgError} />
            </div>
          )
        }
        error={ErrorTypes.BusinessNameInUse === errorCode}
      />
      <SelectField
        onChange={setSelectedCategory}
        items={categories}
        placeHolder={t('businessCategoryPlaceholder')}
        label={t('businessCategory')}
        defaultOption={selectedCategory.value}
        isEdit={true}
      />

      <PreviousNextButtons
        onNext={handleNextButton}
        nextDisabled={!businessNameValue || !selectedCategory.value}
      />
    </div>
  );
};

export default BusinessIdentificationStep;
