import React from 'react';
import {AppState} from '../../redux';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import FormField from '../FormField/FormField';
import RadioButton from '../RadioButton';

interface IProps {
  currentSelection: number | null;
  setSelected: (selection: number | null) => void;
}

const CommunitiesField: React.FC<IProps> = ({
  currentSelection,
  setSelected,
}) => {
  // Hooks
  const {t} = useTranslation();
  const {communities} = useSelector((state: AppState) => state.ui);

  return (
    <FormField
      title={t('createAd:fieldCommunityTitle')}
      description={t('createAd:fieldCommunityDescription')}>
      <div className="packages-list">
        {communities.map((item, index: number) => (
          <RadioButton
            customStyle={{marginBottom: 26, marginLeft: 22}}
            key={index}
            label={<span>{item.name}</span>}
            onClick={() => setSelected(item.id)}
            selected={currentSelection === item.id}
          />
        ))}
      </div>
    </FormField>
  );
};

export default CommunitiesField;
