import {
  IaddContactusRequest,
  IResetPasswordByEmailCode,
  IResetPasswordByPhone,
} from '../../models/api/authenticate';
import {IUserInformation} from '../../models/api/userInformation';
import {
  forgotPasswordRequestCodeByEmail,
  forgotPasswordRequestCodeByPhone,
  getUserInformation,
  getUserPublicProfileService,
  resetPasswordByEmailCode,
  resetPasswordByPhone,
  setContactusRequest,
  validEmailCode,
  validPhoneCode,
} from '../../services/api/userAPI';
import {
  CLEAR_USER,
  FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_FAIL,
  FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_REQUEST,
  FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_SUCCESS,
  FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS,
  FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN,
  FORGOT_PASSWORD_PHONE_REQUEST_CODE,
  FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS,
  FORGOT_PASSWORD_REQUEST_CODE_FAIL,
  FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE,
  FORGOT_PASSWORD_SET_PHONE_CODE,
  SET_USER,
  UserActionTypes,
} from '../types/userTypes';
import {getProfileData} from './profileActions';
import {showPopup} from './uiActions';
import {t} from 'i18next';
import {isEmptyObject} from '../../utils/helper';
import {setBusinessVendors} from './businessActions';

export const clearUser = (): UserActionTypes => ({
  type: CLEAR_USER,
});

export const loadUserInformation = () => {
  return async (dispatch: any) => {
    try {
      const result = await getUserInformation();
      if (result && result.data) {
        dispatch(setUser(result.data));
        dispatch(getProfileData(result.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getUserPublicProfile = (userId: string) => {
  return async (dispatch: any) => {
    try {
      const response = await getUserPublicProfileService(userId);
      if (response && response.data) {
        dispatch(setBusinessVendors(response.data.businessVendors));
      }
      
     } catch (error) {
      console.log(error);
    }
  };
};
export const addContactusRequest = (data: IaddContactusRequest) => {
  return async (dispatch: any) => {
    try {
      await setContactusRequest(data);
      dispatch(showPopup(t('contactUs:dataSentSuccessfully')));
    } catch (error) {
      console.log(error);
    }
  };
};
export const forgotPasswordRequestCodeByPhoneService = (
  phoneNumber: string,
) => {
  return async (dispatch: any) => {
    try {
      const response = await forgotPasswordRequestCodeByPhone({
        phoneNumber,
      });
      if (isEmptyObject(response)) {
        dispatch(forgotPasswordByPhoneRequestCodeSuccess());
      } else {
        dispatch(checkValidPhoneCodeFail('strings.COMMON.errorNetwork'));
      }
    } catch (error: any) {
      dispatch(checkValidPhoneCodeFail('strings.COMMON.errorNetwork'));
    }
  };
};

export const forgotPasswordRequestCodeByEmailService = (userEmail: string) => {
  return async (dispatch: any) => {
    try {
      const response = await forgotPasswordRequestCodeByEmail({
        userEmail,
      });
      if (isEmptyObject(response)) {
        dispatch(forgotPasswordByEmailSuccess());
      } else {
        dispatch(forgotPasswordByRequestCodeFail(t('login:errorNetwork')));
      }
    } catch (error: any) {
      dispatch(forgotPasswordByRequestCodeFail(t('login:errorNetwork')));
    }
  };
};

export const validPhoneCodeService = (phoneNumber: string, code: string) => {
  return async (dispatch: any) => {
    try {
      const response = await validPhoneCode({phoneNumber, code});
      if (isEmptyObject(response)) {
        dispatch(checkValidPhoneCodeSuccess());
        return;
      } else {
        dispatch(checkValidPhoneCodeFail(t('login:errorNetwork')));
      }
    } catch (error: any) {
      dispatch(checkValidPhoneCodeFail(t('login:errorNetwork')));
    }
  };
};
export const validEmailCodeService = (email: string, code: string) => {
  return async (dispatch: any) => {
    try {
      const response = await validEmailCode({email, code});
      if (isEmptyObject(response)) {
        dispatch(checkValidPhoneCodeSuccess());
        return;
      }
    } catch (error: any) {
      console.log(error);
      dispatch(checkValidPhoneCodeFail(t('login:errorNetwork')));
    }
  };
};
export const resetPasswordByEmailCodeService = (
  data: IResetPasswordByEmailCode,
) => {
  return async (dispatch: any) => {
    try {
      const response = await resetPasswordByEmailCode(data);
      if (isEmptyObject(response)) {
        dispatch(showPopup(t('login:updatePasswordSuccessfully')));
        dispatch(forgotPasswordCodeResetStateAction());

        return;
      } else {
        dispatch(showPopup(t('login:updatePasswordFailed')));
      }
    } catch (error: any) {
      dispatch(showPopup(t('login:updatePasswordFailed')));
    }
  };
};
export const resetPasswordByPhoneService = (data: IResetPasswordByPhone) => {
  return async (dispatch: any) => {
    try {
      const response = await resetPasswordByPhone(data);
      if (isEmptyObject(response)) {
        dispatch(showPopup(t('login:updatePasswordSuccessfully')));
        dispatch(forgotPasswordCodeResetStateAction());
        return;
      } else {
        dispatch(showPopup(t('login:updatePasswordFailed')));
      }
    } catch (error: any) {
      dispatch(showPopup(t('login:updatePasswordFailed')));
    }
  };
};

export const setUser = (payload: IUserInformation): UserActionTypes => ({
  type: SET_USER,
  payload,
});

export const forgotPasswordByPhoneRequestCodeInit = (phone: string) => ({
  type: FORGOT_PASSWORD_PHONE_REQUEST_CODE,
  payload: phone,
});

export const forgotPasswordByEmailRequestCodeInit = (userEmail: string) => ({
  type: FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN,
  payload: userEmail,
});

export const forgotPasswordByPhoneRequestCodeSuccess = () => ({
  type: FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS,
});

export const forgotPasswordByEmailSuccess = () => ({
  type: FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS,
});

export const forgotPasswordByRequestCodeFail = (errors: any) => ({
  type: FORGOT_PASSWORD_REQUEST_CODE_FAIL,
  payload: errors,
});

export const forgotPasswordCodeResetStateAction = () => ({
  type: FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE,
});

export const forgotPasswordByPhoneSetCodeAction = (code: string) => ({
  type: FORGOT_PASSWORD_SET_PHONE_CODE,
  payload: code,
});

export const checkValidPhoneCodeRequest = () => ({
  type: FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_REQUEST,
});

export const checkValidPhoneCodeSuccess = () => ({
  type: FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_SUCCESS,
});

export const checkValidPhoneCodeFail = (errors: string) => ({
  type: FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_FAIL,
  payload: errors,
});
