export enum EProfileTypeTabs {
  MyInformation = 1,
  MyBusinesses,
}

export enum ProfileStoreType {
  Physical = 1,
  Online,
}

export enum EProfileActionConfirmBtn {
  Send = 1,
  Resend,
}

export enum EProfileTypeImage {
  Profile = 1,
  Cover,
  UserProfile,
}

export enum EPanelFormProfile {
  None = 0,
  Password,
  Location,
  Email,
}
