import React from 'react';
import {IDeal} from '../../../../../../models/interfaces/deals';
import {useTranslation} from 'react-i18next';
import ConditionsList from '../ConditionsList';
import FormField from '../../../../../../components/FormField/FormField';

type Props = {
  deal: IDeal;
};

const FieldConditions: React.FC<Props> = ({deal}: Props) => {
  const {t} = useTranslation('createDeal');
  return (
    <FormField
      title={t('fieldConditionsTitle')}
      description={t('fieldConditionsDescription')}>
      <ConditionsList deal={deal} />
    </FormField>
  );
};

export default FieldConditions;
