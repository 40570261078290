import React from 'react';
import {EColors} from '../../models/enums/colors';
import {ReactComponent as Close} from '../../assets/icons/close.svg';
import Modal from 'react-modal';
import './styles.scss';

type Props = {
  modalIsOpen: boolean;
  setIsOpen: (value: boolean) => void;
  customStyles?: object;
  children: React.ReactNode;
};

const ModalWrapper: React.FC<Props> = ({
  modalIsOpen,
  setIsOpen,
  customStyles,
  children,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: EColors.colorWhite,
      borderRadius: '16px',
      outline: 'none',
      ...customStyles,
    },
    overlay: {
      background: 'rgba(23, 23, 32, 0.4)',
      zIndex: 2000,
    },
  };

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={styles}>
      <div className="modal-confirmation-wrapper">
        <div onClick={closeModal} className="modal-confirmation-close-icon">
          <Close />
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalWrapper;
