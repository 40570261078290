import React from 'react';
import Marker from './components/Marker';
import Map from './components/Map';
import {Wrapper} from '@googlemaps/react-wrapper';

type Props = {
  center: google.maps.LatLngLiteral;
};

const LocationMap: React.FC<Props> = ({center}) => {
  return (
    <Wrapper apiKey={process.env.REACT_APP_GCP_MAPS_KEY || ''}>
      <Map center={center} zoom={12}>
        <Marker position={center} />
      </Map>
    </Wrapper>
  );
};

export default LocationMap;
