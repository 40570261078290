import React from 'react';
import {ReactComponent as IconHelpCircle} from '../../assets/icons/help-circle.svg';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

type Props = {
  children?: React.ReactNode;
  description?: string;
  styles?: {children: object};
  title: string;
  tooltipFor?: string;
  tooltipShow?: boolean;
  tooltipText?: string;
  underlinedLinkLabel?: string;
  underlinedLinkOnClick?: any;
  withBorder?: boolean;
};

const FormField: React.FC<Props> = ({
  children,
  description,
  styles = {children: {}},
  title,
  tooltipFor,
  tooltipShow = false,
  tooltipText,
  underlinedLinkLabel,
  underlinedLinkOnClick,
  withBorder = true,
}: Props) => {
  return (
    <div className={`form-field ${withBorder ? 'with-border' : ''}`}>
      <span className="form-title">
        {title}
        {tooltipShow && (
          <>
            <IconHelpCircle
              width={15}
              height={15}
              data-tip
              data-for={tooltipFor}
            />
            <ReactTooltip
              id={tooltipFor}
              place="bottom"
              type="dark"
              effect="solid"
              backgroundColor="black"
              html>
              {tooltipText}
            </ReactTooltip>
          </>
        )}
      </span>
      <span className="form-description">
        {description}
        {underlinedLinkLabel && underlinedLinkOnClick && (
          <span
            onClick={underlinedLinkOnClick}
            className="form-underlined-link">
            {underlinedLinkLabel}
          </span>
        )}
      </span>
      <div className={'form-children'} style={styles.children}>
        {children && children}
      </div>
    </div>
  );
};

export default FormField;
