import React, {useEffect, useState, useCallback} from 'react';
import Table from '../../components/Table';
import Button from '../../components/Button';
import InfoPage from '../../components/InfoPage';
import LeftMenu from './components/LeftMenu';
import NoResults from '../../components/Table/components/NoResults';
import CardPreview from '../CreateDeal/components/CreateDealPreview/components/CardPreview';
import FullPreview from '../CreateDeal/components/CreateDealPreview/components/FullPreview';
import ModalWrapper from '../../components/Modal';
import AdFullPreview from '../CreateDeal/components/CreateAdPreview/components/AdFullPreview';
import ModalOptionsCreate from './components/ModalOptionsCreate';
import {Item} from 'firebase/analytics';
import {IResult} from '../../models/api/deals';
import {EColors} from '../../models/enums/colors';
import {AppState} from '../../redux';
import {useAnalytics} from '../../hooks/useAnalytics';
import {getCountries} from '../../redux/actions/profileActions';
import {useTranslation} from 'react-i18next';
import {getAdById, getAds} from '../../services/api/adsAPI';
import {modalPreviewStyles} from '../../constants/themes';
import {loadUserInformation} from '../../redux/actions/userActions';
import {adsItems, dealsItems} from '../../constants/list';
import {getDealById, getDeals} from '../../services/api/dealsAPI';
import {ETypeView, TypeListData} from '../../models/enums/typeListData';
import {useLocation, useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentView, setListData} from '../../redux/actions/uiActions';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {ReactComponent as NoAdsResultIcon} from '../../assets/icons/no-ads.svg';
import {ReactComponent as NoDealsResultIcon} from '../../assets/icons/no-deals.svg';
import {
  setClearDealData,
  setCreateDealData,
} from '../../redux/actions/dealActions';
import {
  EAnalyticsCustomEventNames,
  EAnalyticsExploreList,
} from '../../models/enums/analytics';
import {
  EMenuViews,
  ETabsViews,
  ETypeCommunication,
} from '../../models/enums/generals';
import {
  setAdIdData,
  setClearAdData,
  setCreateadData,
} from '../../redux/actions/adActions';
import './styles.scss';

const Home = () => {
  // Hooks
  const {t} = useTranslation();
  const {eventTrack} = useAnalytics();
  const {deal, ad, user, ui} = useSelector((state: AppState) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const forceUpdate = useCallback(() => updateState({}), []);

  // State
  const [, updateState] = useState<any>(); // force update
  const [ads, setAds] = useState<IResult[] | []>([]);
  const [deals, setDeals] = useState<IResult[] | []>([]);
  const [firstLoader, setFirstLoader] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [tabSelected, setTabSelected] = useState<number>(
    ui.typeListData === TypeListData.Ad ? 0 : 1,
  );

  // Data
  const {pathname} = location;
  const params = pathname.split('/');
  const pathPreview = params[2];
  const idPreview = parseInt(params[3]);
  const tabs = [
    {name: t('ads:title'), length: ads.length},
    {name: t('dealsPage:title'), length: deals.length},
  ];

  // Effects
  useEffect(() => {
    dispatch(loadUserInformation());
    if (!pathPreview && !idPreview) {
      if (ui.typeListData === TypeListData.Ad) {
        setTabSelected(0);
      } else {
        setTabSelected(1);
      }
    }

    if (pathPreview && idPreview) {
      if (pathPreview === 'view-ad') {
        dispatch(setClearDealData());
        dispatch(setListData(TypeListData.Ad));
        setTabSelected(0);
        previewDataRequest(idPreview, TypeListData.Ad);
      } else {
        dispatch(setClearAdData());
        dispatch(setListData(TypeListData.Deal));
        setTabSelected(1);
        previewDataRequest(idPreview, TypeListData.Deal);
      }
    }
    dispatch(getCountries());
    dispatch(setCurrentView(ETypeView.Home));
  }, []);

  useEffect(() => {
    const getAllDeals = async () => {
      setIsLoading(true);
      try {
        const result = await getDeals({
          page: 0,
          perPage: 1000,
        });
        if (result.data) {
          setDeals(result.data.results);
        }
        setIsLoading(false);
        setReloadData(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setReloadData(false);
      }
    };

    const getAllAds = async () => {
      setIsLoading(true);
      try {
        const result = await getAds({
          page: 0,
          perPage: 1000,
        });
        if (result.data) {
          setAds(result.data.results);
        }
        setIsLoading(false);
        setReloadData(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setReloadData(false);
      }
    };

    getAllDeals();
    getAllAds();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData, ui.typeListData]);

  // Modal with indivudual data
  useEffect(() => {
    const {id} = ad;
    const getById = async (id: number) => {
      if (isLoadingModal) {
        return;
      }
      setIsLoadingModal(true);

      try {
        if (!firstLoader) {
          if (tabSelected === 0) {
            const result = await getAdById(id);
            if (result.data) {
              dispatch(
                setCreateadData({
                  ...result.data,
                  id,
                  files: result.data.images,
                }),
              );
              setShowPreviewModal(true);
            }
          } else if (tabSelected === 1) {
            const result = await getDealById(id);
            if (result.data) {
              dispatch(
                setCreateDealData({
                  ...result.data,
                  id,
                  files: result.data.files,
                }),
              );
              setShowPreviewModal(true);
            }
          }
        }
      } finally {
        setIsLoadingModal(false);
      }
    };

    if (id) {
      getById(id);
    } else {
      dispatch(setAdIdData(0));
      if (showPreviewModal) {
        sortData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, ad.id]);

  useEffect(() => {
    sortData();

    if (tabSelected === 0 && ads) {
      handleEventTrack(ETypeCommunication.Ad);
    } else if (tabSelected === 1 && deals) {
      handleEventTrack(ETypeCommunication.Deal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  // Handles-Functions
  const handleCloseModal = () => {
    setShowPreviewModal(!showPreviewModal);
    dispatch(setClearAdData());
    dispatch(setClearDealData());
  };

  const sortData = () => {
    if (tabSelected === 0) {
      setAds(ads.sort((a: any, b: any) => (a.id < b.id ? 1 : -1)));
    } else {
      setDeals(deals.sort((a: any, b: any) => (a.id < b.id ? 1 : -1)));
    }
  };

  const clickOnEditDealItem = (id: number) => {
    navigate(`/create-deal/${id}`);
  };

  const clickOnEditAdItem = (id: number) => {
    navigate(`/create-ad/${id}`);
  };

  const previewDataRequest = async (id: number, typeData: TypeListData) => {
    if (typeData === TypeListData.Ad) {
      const result = await getAdById(id);
      if (result.data) {
        const images = result.data?.images || [];
        dispatch(setCreateadData({...result.data, id, files: images}));
        setShowPreviewModal(true);
        eventTrack(EAnalyticsCustomEventNames.PageView, {
          view: {page_view: EMenuViews.ViewAd, id_param: id},
        });
      }
    } else {
      const result = await getDealById(id);
      if (result.data) {
        dispatch(setCreateDealData({...result.data, id}));
        setShowPreviewModal(true);
        eventTrack(EAnalyticsCustomEventNames.PageView, {
          view: {page_view: EMenuViews.ViewDeal, id_param: id},
        });
      }
    }
  };

  const handleEventTrack = (typeTab: ETypeCommunication) => {
    const data = typeTab === ETypeCommunication.Ad ? ads : deals;
    const isAds = typeTab === ETypeCommunication.Ad;
    const items = data.map(
      e =>
        ({
          item_category: isAds ? TypeListData.Ad : TypeListData.Deal,
          item_id: `${e.id}`,
          item_list_name: isAds ? ETabsViews.Ads : ETabsViews.Deals,
          item_name: e.title,
          item_list_id: `${e.id}`,
          item_brand: `${e.status}`,
        } as Item),
    );

    eventTrack(EAnalyticsCustomEventNames.ViewItemListAd, {
      eventParams: {
        item_list_name: isAds ? ETabsViews.Ads : ETabsViews.Deals,
        item_list_id: isAds
          ? EAnalyticsExploreList.Ads
          : EAnalyticsExploreList.Deals,
        items: items,
      },
    });
  };

  return (
    <div
      data-testid={'deals-page'}
      className="deals-page"
      id="layout"
      onClick={forceUpdate}>
      <LeftMenu />

      <div id="main">
        <InfoPage title={tabs[tabSelected].name} />
        <Tabs
          selectedTabClassName="tab-selected"
          onSelect={(tabIndex: any) => setTabSelected(tabIndex)}
          selectedIndex={tabSelected}>
          <TabList className="row-tab-list">
            {tabs.map((tab, index) => (
              <Tab key={index}>
                <span className="textTab">{tab.name}</span>
                <span className="pointSeparator">·</span>
                <span className="textQuantity">
                  {tab.length} {t('dealsPage:total')}
                </span>
              </Tab>
            ))}
            <div className="create-button-position">
              <Button
                label={t('global:create')}
                onClick={() => setIsOpen(true)}
                customStylesWrapper={{
                  backgroundColor: EColors.colorBluePrimaryButtons,
                  border: `solid 1px ${EColors.colorBluePrimaryButtons}`,
                }}
                customStylesText={{color: EColors.colorWhite}}
              />
            </div>
          </TabList>

          {/* Ads */}
          <TabPanel>
            <Table
              data={ads}
              itemsDescription={adsItems}
              setData={setAds}
              isLoading={isLoading}
              actionEditItem={clickOnEditAdItem}
              typeAction={TypeListData.Ad}
              setFirstLoader={setFirstLoader}
              noResults={
                <NoResults
                  icon={<NoAdsResultIcon width={47} height={44} />}
                  label1={t('ads:noAdsResult1')}
                  label2={t('ads:noAdsResult2')}
                  label3={t('ads:noAdsResult3')}
                />
              }
              setReloadData={setReloadData}
            />

            <ModalWrapper
              customStyles={modalPreviewStyles}
              setIsOpen={handleCloseModal}
              modalIsOpen={showPreviewModal}>
              <div className="list-ads-preview-modal-content">
                <span className="list-ads-preview-modal-title">{ad.title}</span>
                <AdFullPreview isPreview={true} />
              </div>
            </ModalWrapper>
          </TabPanel>

          {/* Deals */}
          <TabPanel>
            <Table
              data={deals}
              itemsDescription={dealsItems}
              setData={setDeals}
              isLoading={isLoading}
              actionEditItem={clickOnEditDealItem}
              typeAction={TypeListData.Deal}
              setFirstLoader={setFirstLoader}
              noResults={
                <NoResults
                  icon={<NoDealsResultIcon width={47} height={44} />}
                  label1={t('dealsPage:noResultText1')}
                  label2={t('dealsPage:noResultText2')}
                  label3={t('dealsPage:noResultText3')}
                />
              }
              setReloadData={setReloadData}
            />

            <ModalWrapper
              customStyles={modalPreviewStyles}
              setIsOpen={handleCloseModal}
              modalIsOpen={showPreviewModal}>
              <div className="list-ads-preview-modal-content">
                <span className="list-ads-preview-modal-title">
                  {deal.title}
                </span>
                <div className="deal-preview-container">
                  <CardPreview deal={deal} isPreview={true} name={user.name} />
                  <FullPreview
                    deal={deal}
                    isPreview={true}
                    isPromotion={false}
                    name={user.name}
                  />
                </div>
              </div>
            </ModalWrapper>
          </TabPanel>
        </Tabs>

        <ModalOptionsCreate modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
};

export default Home;
