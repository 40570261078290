import React, {useState} from 'react';
import MsgError from '../MsgError';
import getUploadedFileDimensions from '../../utils/getUploadedFileDimensions';
import { useTranslation } from 'react-i18next';
import { checkExtension } from '../../utils/checkExtension';
import { ReactComponent as CameraUpload } from '../../assets/icons/camera-profile.svg';
import { IMAGE_MAX_WEIGHT, SUPPORTED_IMAGES } from '../../constants/createDeal';
import './styles.scss';

type Props = {
  onClick: (value: any) => void;
};

const IconSimpleUploadImage: React.FC<Props> = ({ onClick }) => {

  const {t} = useTranslation();
  const [error, setError] = useState<string>('');

  const handleAddImage = ({target: {files}}: any) => {
    
    const loadedImage = files[0];
    const validExtensions = SUPPORTED_IMAGES.split(',');
    const isExtensionValid = checkExtension(loadedImage.name, validExtensions);

    if (isExtensionValid) {
      getUploadedFileDimensions(loadedImage).then(() => {
        if (loadedImage.size >= IMAGE_MAX_WEIGHT) {
          setError(t('createDeal:errorImageWeight'));
          onClick('');
        } else {
          setError('');
          onClick(loadedImage);
        }
      });
    } else {
      onClick('');
      setError(t('global:invalidFormat'));
    }
  };

  return (
    <>
      {error && <MsgError text={error} />}
      <UploadControl
        onChange={handleAddImage}
        accept={SUPPORTED_IMAGES}
      >
        <CameraUpload/>
      </UploadControl>
    </>
  );
};

const UploadControl = ({
  children,
  onChange,
  disabled,
  accept,
}: any) => {
  return (
    <label className="business-images-upload-icon">
      <input
        accept={accept}
        disabled={disabled}
        style={{display: 'none'}}
        multiple
        type="file"
        onChange={
          disabled
            ? () => {
                console.log('disabled');
              }
            : onChange
        }
      />
      {children}
    </label>
  );
};

export default IconSimpleUploadImage;