import React from 'react';
import AdFullPreview from './components/AdFullPreview';
import pixelCalculator from '../../../../utils/pixelCalculator';
import {useTranslation} from 'react-i18next';
import './styles.scss';

const CreateAdPreview: React.FC = () => {
  const {t} = useTranslation('createDeal');
  const {innerHeight: height} = window;
  const width = pixelCalculator({axis: 'x', px: 944});

  return (
    <div style={{width: width}} className="create-deal-preview">
      <div className="create-deal-preview-row">
        <AdFullPreview />
      </div>
      {height > 880 && (
        <span className="create-deal-preview-message">
          {t('previewDealInformationalMessage')}
        </span>
      )}
    </div>
  );
};

export default CreateAdPreview;
