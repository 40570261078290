import React from 'react';
import {EColors} from '../../models/enums/colors';
import {IDeal} from '../../models/interfaces/deals';
import {useTranslation} from 'react-i18next';
import Button from '../Button';
import useWindowDimensions from '../../hooks/useWindowsDimensions';

type Props = {
  deal: IDeal;
};

const PreviewQrCodeButton: React.FC<Props> = ({deal}) => {
  const {height} = useWindowDimensions();
  const {t} = useTranslation('previewDeal');
  return (
    <Button
      label={t('buttonQrCode')}
      type="qr"
      onClick={() => console.log(deal.qrCode)}
      customStylesWrapper={
        height < 719
          ? {
              height: 32,
              width: 190,
              backgroundColor: EColors.colorBluePrimaryButtons,
              marginBottom: 10,
              marginTop: 10,
              border: `1px solid ${EColors.colorBluePrimaryButtons}`,
            }
          : {
              height: 52,
              width: 254,
              backgroundColor: EColors.colorBluePrimaryButtons,
              marginBottom: 10,
              border: `1px solid ${EColors.colorBluePrimaryButtons}`,
            }
      }
      customStylesText={
        height < 719
          ? {fontSize: 13, lineHeight: 22, color: EColors.colorWhite}
          : {fontSize: 18, lineHeight: 24, color: EColors.colorWhite}
      }
    />
  );
};

export default PreviewQrCodeButton;
