import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './locale/en';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nextOptions = {
  resources: {
    en,
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(i18nextOptions);

export default i18next;
