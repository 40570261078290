import React from 'react';
import Select, {components, StylesConfig} from 'react-select';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import {ReactComponent as Tick} from '../../assets/icons/tick.svg';
import {EColors} from '../../models/enums/colors';
import './styles.scss';

const customStyles: StylesConfig<any, false, any> = {
  control: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused
      ? EColors.colorWhiteCultured
      : EColors.colorWhite,
    border: 'border: 1px solid',
    borderColor: state.isFocused
      ? EColors.colorDarkBlue
      : EColors.colorGraySilver,
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {borderColor: 'none'},
    height: 36,
    width: '100%',
  }),
  indicatorSeparator: () => ({display: 'none'}),
  menuList: base => ({
    ...base,
    maxHeight: 220,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  option: () => ({border: '1px solid red'}),
};

interface IProps {
  items: any[];
  onChange: (newValue: any) => void;
  placeHolder: string;
  label: string;
  defaultOption?: number | string;
  isEdit?: boolean;
  customStylesComponent?: {generalContainer: object};
  nameSelected?: string;
  disabled?: boolean;
}

const SelectField = ({
  items,
  placeHolder,
  label,
  onChange,
  defaultOption,
  isEdit,
  customStylesComponent,
  nameSelected,
  disabled,
}: IProps) => {
  const Placeholder = (props: any) => {
    return <components.Placeholder {...props} />;
  };

  const CustomIndicator = () => {
    return <ChevronDown className="" width={11} height={11} />;
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <CustomIndicator />
      </components.DropdownIndicator>
    );
  };

  const Option = (props: any) => {
    const {
      data: {label},
      isSelected,
      innerProps,
    } = props;
    return (
      <div className="option-item-container">
        <span
          className={`option-item ${isSelected ? 'selected' : ''}`}
          onClick={innerProps.onClick}>
          {label}
          {isSelected && (
            <Tick
              className="selected-icon"
              width={11}
              fill={EColors.colorDarkBlue}
            />
          )}
        </span>
      </div>
    );
  };

  return (
    <div
      className="select-component"
      style={customStylesComponent && customStylesComponent.generalContainer}>
      <span className={'select-label'}>{label}</span>
      {defaultOption === '' || !isEdit ? (
        <Select
          components={{Placeholder, DropdownIndicator, Option}}
          styles={customStyles}
          onChange={onChange}
          options={items}
          placeholder={placeHolder}
          inputValue={nameSelected}
          isDisabled={disabled || false}
        />
      ) : (
        <Select
          components={{Placeholder, DropdownIndicator, Option}}
          styles={customStyles}
          onChange={onChange}
          options={items}
          placeholder={placeHolder}
          inputValue={nameSelected}
          value={items.find(option => option.value === defaultOption)}
          isDisabled={disabled || false}
        />
      )}
    </div>
  );
};

export default SelectField;
