import {AxiosResponse} from 'axios';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericPost, genericGet, genericPut, genericDelete} from './genericAPI';
import {
  IBodyListMyDeals,
  ICreateDeal,
  IResponseListMyDeals,
} from '../../models/api/deals';

export const getDeals = async (
  body: IBodyListMyDeals,
): Promise<AxiosResponse<IResponseListMyDeals>> => {
  const token = store.getState().auth.token;
  return await genericPost(endpoints.deal.listMyDeals, token, body);
};

export const postDeal = async (body: ICreateDeal): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  const data = parsePostToFormData(body);
  return await genericPost(endpoints.deal.create, token, data, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

export const getDealById = async (id: number): Promise<AxiosResponse<any>> => {
  const token = store.getState().auth.token;
  return await genericGet(`${endpoints.deal.getById}${id}`, token);
};

const parsePostToFormData = (body: ICreateDeal): FormData => {
  const data = new FormData();

  if (body.images) {
    body.images.forEach((image: any) => {
      data.append('images', image);
    });
  }

  if (body.conditions) {
    body.conditions.forEach(condition => {
      data.append('Conditions[]', condition);
    });
  }

  if (body.communityId) data.append('CommunityId', body.communityId.toString());
  if (body.endDate) data.append('EndDate', body.endDate);
  if (body.location) data.append('Location', body.location);
  if (body.offerTag) data.append('OfferTag', body.offerTag);
  if (body.packageId) data.append('PackageId', body.packageId.toString());
  if (body.promotionCode) data.append('PromotionCode', body.promotionCode);
  if (body.qrCode) data.append('QRCode', body.qrCode);
  if (body.qrCodeValue) data.append('QrCodeValue', body.qrCodeValue.toString());
  if (body.startDate) data.append('StartDate', body.startDate);
  if (body.title) data.append('Title', body.title);
  if (body.vendorId) data.append('VendorId', body.vendorId);
  if (body.visibility) data.append('Visibility', body.visibility.toString());
  if (body.webSiteUrl) data.append('webSiteUrl', body.webSiteUrl);

  data.append('IsPromotionWithoutCode', body.isPromotionWithoutCode.toString());

  return data;
};

export const putDeal = async (
  id: number,
  body: ICreateDeal,
): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  const data = parsePostToFormData(body);
  return await genericPut(`${endpoints.deal.update}${id}`, token, data, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

export const deleteDeal = async (
  id: number
): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  return await genericDelete(`${endpoints.deal.delete}${id}`, token);
};
