export const createImageFile = async (
  imageUrl: string,
  imageName: string,
): Promise<any> => {
  return await get(imageUrl, imageName);
};

const get = async (imageUrl: string, imageName: string) => {
  const response = await fetch(imageUrl);
  const data = await response.blob();
  return new File([data], imageName, {
    type: data.type || 'image/jpeg',
  });
};
