const getUploadedFileDimensions = (file: any) =>
  new Promise((resolve, reject) => {
    try {
      if (file.type.includes('image')) {
        const img = new Image();

        img.onload = () => {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);

          return resolve({width, height});
        };

        img.src = window.URL.createObjectURL(file);
      } else if (file.type.includes('video')) {
        return resolve({});
      }
    } catch (exception) {
      return reject(exception);
    }
  });

export default getUploadedFileDimensions;
