import {IResultCommunity} from '../../models/api/community';
import {ETypeView} from '../../models/enums/typeListData';
import {uiApi} from '../../services/api/uiAPI';
import {EPopupType} from '../../models/enums/ui';
import {
  SET_COMMUNITIES,
  SET_CONFIRMATION_POPUP,
  SET_CURRENT_VIEW,
  SET_HIDE_CONFIRMATION_MODAL,
  SET_HIDE_UNSAVED_MODAL,
  SET_LIST_DATA,
  SET_TIME_ZONE_NAME,
  UiActionTypes,
} from '../types/uiTypes';

export const showPopup = (
  message: string,
  delay = 3000,
  type?: EPopupType,
): UiActionTypes => ({
  type: SET_CONFIRMATION_POPUP,
  payload: {delay: delay, message: message, isVisible: true, type},
});

export const hidePopup = (): UiActionTypes => ({
  type: SET_CONFIRMATION_POPUP,
  payload: {delay: 2000, message: '', isVisible: false},
});

export const setListData = (type: string): UiActionTypes => ({
  type: SET_LIST_DATA,
  payload: type,
});

export const setHideConfirmationModal = (show: boolean): UiActionTypes => ({
  type: SET_HIDE_CONFIRMATION_MODAL,
  payload: show,
});

export const setHideUnsavedModal = (show: boolean): UiActionTypes => ({
  type: SET_HIDE_UNSAVED_MODAL,
  payload: show,
});

export const getCommunitiesSuccess = (data: IResultCommunity[]) => ({
  type: SET_COMMUNITIES,
  payload: data,
});

export const setCurrentView = (view: ETypeView) => ({
  type: SET_CURRENT_VIEW,
  payload: view,
});

export const setTimeZoneNameState = (timeZone: string) => ({
  type: SET_TIME_ZONE_NAME,
  payload: timeZone,
});

export const getCommunities =
  (manaGlobalObject: IResultCommunity) => async (dispatch: any) => {
    try {
      const response: any = await uiApi.communities();
      if (response && response.data) {
        dispatch(getCommunitiesSuccess([...response.data, manaGlobalObject]));
      }
    } catch (error: any) {
      console.log(error);
    }
  };
