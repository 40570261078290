import {DEFAULT_TIME_ZONE_NAME} from '../constants/dates';

export const isValidTimeZone = (text: string): boolean => {
  if (text && text.length) {
    const pattern = /^(?!UTC|$).+/;
    return pattern.test(text);
  }
  return false;
};

export const getDefaultTimeZone = (timeZone: string) => {
  if (isValidTimeZone(timeZone)) {
    return timeZone;
  }
  return DEFAULT_TIME_ZONE_NAME;
};
