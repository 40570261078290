import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import './styles.scss';

const ItemSkeleton: React.FC = () => {
  return (
    <tbody className="skeleton-tbody">
      <tr className="body">
        <td className="body-item title-item">
          <Skeleton width={120} />
        </td>
        <td className="body-item">
          <Skeleton width={60} />
        </td>
        <td className="body-item">
          <Skeleton width={60} />
        </td>
        <td className="body-item">
          <div className="exclamation-circle-end-date-wrapper">
            <Skeleton width={60} />
          </div>
        </td>
        <td className="body-item status-item ">
          <Skeleton width={60} />
        </td>

        <td className="body-item">
          <Skeleton width={60} />
        </td>
      </tr>
    </tbody>
  );
};

export default ItemSkeleton;
