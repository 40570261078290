import React, {useState, useCallback, useEffect} from 'react';
import {AppState} from '../../../../../../redux';
import {setCreateDealData} from '../../../../../../redux/actions/dealActions';
import {TypeAction} from '../../../../../../models/enums/typeAction';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validateUrl} from '../../../../../../utils/validateUrl';
import CompleteTextField from '../../../../../../components/CompleteTextField';
import FormField from '../../../../../../components/FormField/FormField';

interface IProps {
  setFieldIsValid: (value: boolean) => void;
  setWebsite: (value: string) => void;
  website: string;
  typeProcess: string;
}

const FieldWebsite: React.FC<IProps> = ({
  setFieldIsValid,
  website,
  setWebsite,
  typeProcess,
}) => {
  const {t} = useTranslation('createDeal');
  const {deal} = useSelector((state: AppState) => state);

  const dispatch = useDispatch();

  const [webSiteIsValid, setWebSiteIsValid] = useState<boolean>(true);
  const dealWebSite = deal.webSiteUrl === null ? '' : deal.webSiteUrl;

  useEffect(() => {
    if (dealWebSite.length === 0 || typeProcess === TypeAction.Add) {
      setWebsite(dealWebSite);
    } else setWebsite(dealWebSite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal]);

  useEffect(() => {
    setFieldIsValid(webSiteIsValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSiteIsValid]);

  const handleChangeInterceptor = useCallback((value: string) => {
    dispatch(setCreateDealData({webSiteUrl: value}));
    setWebsite(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormField
      withBorder={true}
      title={t('fieldWebsiteTitle')}
      description={t('fieldWebsiteDescription')}>
      <CompleteTextField
        error={!webSiteIsValid}
        errorMessage={t('errorMessageUrl')}
        hideLabel
        id={'webSiteUrl'}
        maxLength={130}
        onBlur={() => {
          setWebSiteIsValid(website === '' || validateUrl(website));
        }}
        placeholder={t('placeholderWebsite')}
        setValue={handleChangeInterceptor}
        value={website}
        withContentValidation={false}
        withCounter={false}
      />
    </FormField>
  );
};

export default FieldWebsite;
