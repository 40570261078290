export enum ELoadingMotionColors {
  Blue = 'blue',
  White = 'white',
}

export const EColors = {
  colorBlueArsenic: '#3b3b4a',
  colorBlueCornflower: '#5d9fea',
  colorBluePrimaryButtons: '#0a72ed',
  colorBlueRegisterSteps: '#0078ff',
  colorDarkBlue: '#171720',
  colorGray: '#9c9c9c',
  colorGrayishBlue: '#dcdcde',
  colorGrayLight: '#ededed',
  colorGraySilver: '#747479',
  colorGreenSuccess: '#36b37e',
  colorWhite: '#ffffff',
  colorWhiteCultured: '#f6f6f6',
  colorBackLight:'#242629'
};
