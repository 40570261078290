import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITextFieldCustomStyles } from '../../../../components/TextField';
import './styles.scss';

type Props = {
    children?: React.ReactNode;
    customStyles?: ITextFieldCustomStyles;
    isOptional?: boolean;
    title: string;
  };

const PhoneNumber: React.FC<Props> = ({ 
  children, 
  customStyles,
  isOptional,
  title, 
}: Props) => {

  const { t } = useTranslation();

  return (
    <div className='form-number-phone-field' style={customStyles?.generalContainer}>
        <span className='form-number-phone-title'>
          {title}
          { isOptional && <span className='is-optional'>{ t('global:optional') }</span> }
        </span>
        <div className={'create-deal-form-children'} style={customStyles?.container}>
        {children && children}
      </div>
    </div>
  )
}

export default PhoneNumber;