import React, {useEffect} from 'react';
import {EPopupType} from '../../models/enums/ui';
import {hidePopup} from '../../redux/actions/uiActions';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-white.svg';
import {ReactComponent as CrossIcon} from '../../assets/icons/cross-circle.svg';
import {useDispatch} from 'react-redux';
import './styles.scss';

interface IProps {
  delay: number;
  text: string;
  type?: EPopupType;
}

const ConfirmationPopup = ({
  delay,
  text,
  type = EPopupType.Success,
}: IProps) => {
  const dispatch = useDispatch();
  const isError = type === EPopupType.Error;

  useEffect(() => {
    setTimeout(() => dispatch(hidePopup()), delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`confirmation-popup ${isError && 'error'}`}
      onClick={() => {
        dispatch(hidePopup());
      }}>
      <div className="confirmation-popup-content">
        {!isError ? (
          <CheckIcon width={20} height={20} />
        ) : (
          <CrossIcon width={20} height={20} />
        )}
        <span className="confirmation-popup-text">{text}</span>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
