import {AxiosResponse} from 'axios';
import {capitalize} from '../../utils/capitalize';
import {genericGet, genericPost} from './genericAPI';
import {IEditUserProfile} from '../../models/interfaces/user';
import {IProfileBody} from '../../models/interfaces/profile';
import {BusinessPublicProfileI, IProfileResult} from '../../models/api/profile';
import {store} from '../../redux';
import endpoints from './endpoints';

const communities = (): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  return genericGet(`${endpoints.communities.list}`, token);
};

export const getBusinessData = async (
  id: number,
): Promise<AxiosResponse<IProfileResult>> => {
  const token = store.getState().auth.token;
  return await genericGet(`${endpoints.business.business}/${id}`, token);
};

export const getBusinessPublicProfile = async (
  id: number,
): Promise<AxiosResponse<BusinessPublicProfileI>> => {
  const token = store.getState().auth.token;
  return await genericGet(`${endpoints.business.publicProfile}/${id}`, token);
};

const sendProfileData = (data: IProfileBody): Promise<AxiosResponse> => {
  data.businessHoursJson = JSON.stringify(data.businessHours);
  data.businessLocationsJson = JSON.stringify(data.businessLocations);
  data.businessSocialMediaJson = JSON.stringify(data.businessSocialMedia);
  data.subCategoriesJson = JSON.stringify(data.subCategories);
  data.filesJson = JSON.stringify(data.images);

  const fdata = parsePostToFormData(data);
  const token = store.getState().auth.token;

  return genericPost(`${endpoints.profile.profileData}`, token, fdata, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

const sendProfileUserData = (
  data: IEditUserProfile,
): Promise<AxiosResponse> => {
  const fdata = parseUserToFormData(data);
  const token = store.getState().auth.token;

  return genericPost(`${endpoints.profile.profileUserData}`, token, fdata, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

const parsePostToFormData = (body: IProfileBody): FormData => {
  const data = new FormData();

  if (body.id) {
    data.append('Id', body.id.toString());
  }

  if (body.businessName) {
    data.append('Name', body.businessName);
  }

  if (body.businessDescription) {
    data.append('Description', body.businessDescription);
  }

  if (body.subCategoriesJson) {
    data.append('SubCategoriesJson', body.subCategoriesJson);
  }

  if (body.subCategories) {
    body.subCategories.forEach((subCategory: any) => {
      data.append('SubCategories[]', subCategory);
    });
  }

  if (body.contactId) {
    data.append('BusinessContactPerson.Id', body.contactId.toString());
  }

  if (body.contactEmail) {
    data.append('BusinessContactPerson.Email', body.contactEmail);
  }

  if (body.contactName) {
    data.append('BusinessContactPerson.Name', body.contactName);
  }

  if (body.contactLastname) {
    data.append('BusinessContactPerson.Lastname', body.contactLastname);
  }

  if (body.contactPhoneNumber) {
    data.append('BusinessContactPerson.PhoneNumber', body.contactPhoneNumber);
  }

  if (body.contactPhoneNumberCountryCode) {
    data.append(
      'BusinessContactPerson.PhoneNumberCountryCode',
      body.contactPhoneNumberCountryCode,
    );
  }

  if (body.contactBirthdate) {
    data.append('BusinessContactPerson.Birthdate', body.contactBirthdate);
  }

  if (body.contactTitle) {
    data.append('BusinessContactPerson.Title', body.contactTitle);
  }

  if (body.contactWebSiteUrl) {
    data.append(
      'BusinessContactPerson.ContactWebSiteUrl',
      body.contactWebSiteUrl,
    );
  }

  if (body.businessLocationsJson) {
    data.append('LocationsJson', body.businessLocationsJson);
  }

  if (body.businessLocations) {
    body.businessLocations.forEach((location: any) => {
      data.append('Locations[]', location);
    });
  }

  if (body.profileImageUrl) {
    data.append('ProfileImageUrl', body.profileImageUrl);
  }

  if (body.coverImageUrl) {
    data.append('CoverImageUrl', body.coverImageUrl);
  }

  if (body.businessLocationType) {
    data.append('BusinessLocationType', body.businessLocationType.toString());
  }

  if (body.webSiteUrl) {
    data.append('WebSiteUrl', body.webSiteUrl);
  }

  if (body.filesJson) {
    data.append('FilesJson', body.filesJson);
  }

  if (body.images) {
    for (let i = 0; i < body.images.length; i++) {
      data.append('Files[]', body.images[i]);
    }
  }

  if (body.businessHoursJson) {
    data.append('BusinessHoursJson', body.businessHoursJson);
  }

  if (body.businessHours) {
    body.businessHours.forEach((hours: any) => {
      data.append('BusinessHours[]', hours);
    });
  }

  if (body.businessSocialMediaJson) {
    data.append('BusinessSocialMediaJson', body.businessSocialMediaJson);
  }

  if (body.businessSocialMedia) {
    body.businessSocialMedia.forEach((socialMedia: any) => {
      data.append('BusinessSocialMedia[]', socialMedia);
    });
  }

  if (body.communityId) {
    data.append('CommunityId', body.communityId.toString());
  }

  if (body.newImagesForGallery) {
    for (let i = 0; i < body.newImagesForGallery.length; i++) {
      data.append('NewImagesForGallery', body.newImagesForGallery[i]);
    }
  }

  if (body.newProfileImage) {
    data.append('NewProfileImage', body.newProfileImage);
  }

  if (body.newCoverImage) {
    data.append('NewCoverImage', body.newCoverImage);
  }

  if (body.countryId) {
    data.append('CountryId', body.countryId.toString());
  }

  if (body.timeZone) {
    data.append('TimeZone', body.timeZone);
  }

  return data;
};

const parseUserToFormData = (body: IEditUserProfile): FormData => {
  const data = new FormData();

  Object.entries(body).map(object => {
    if (object[1]) {
      data.append(
        capitalize(object[0]),
        isNaN(object[1]) ? object[1] : object[1].toString(),
      );
    }
  });

  return data;
};

export const profileApi = {
  communities,
  getBusinessData,
  sendProfileData,
  sendProfileUserData,
};
