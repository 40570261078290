import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../redux';
import {setStep} from '../redux/actions/registerActions';

export const useSteps = () => {
  const {step} = useSelector((state: RootState) => state.register);
  const dispatch = useDispatch();

  const incrementStep = () => {
    dispatch(setStep(step + 1));
  };

  const decrementStep = () => {
    if (step > 1) {
      dispatch(setStep(step - 1));
    }
  };

  const resetStep = () => {
    dispatch(setStep(1));
  };

  return {
    incrementStep,
    decrementStep,
    resetStep,
  };
};
