import React from 'react';
import './styles.scss';

type Props = {
  icon: any;
  label1: string;
  label2?: string;
  label3?: string;
};
const NoResults: React.FC<Props> = ({icon, label1, label2, label3}) => {
  return (
    <div className="no-results">
      <div className="no-results-icon-position">{icon}</div>
      <span className="label-1">{label1}</span>
      <span className="label-2">{label2}</span>
      <span className="label-3">{label3}</span>
    </div>
  );
};

export default NoResults;
