import React from 'react';
import {ELoadingMotionColors} from '../../models/enums/colors';
import './styles.scss';

type IProps = {
  color?: ELoadingMotionColors;
  customStyles?: React.CSSProperties;
};

const LoaderAnimate: React.FC<IProps> = ({
  color = ELoadingMotionColors.Blue,
  customStyles,
}) => {
  return (
    <div className="loader-animate-wrapper">
      <div className={`loader ${color}`} style={customStyles}></div>
    </div>
  );
};

export default LoaderAnimate;
