import React from 'react';
import './styles.scss';

type Props = {
  bgColor: string;
  progress: number;
  height: number;
}

const ProgressBar: React.FC<Props> = ({ bgColor, progress, height }) => {

  return (
    <div className='create-account-progress-bar-parent'>
      <span className='create-account-progress-bar-title'>
        Create account
      </span>
      <span className='parent-bar' style={{height: `${height}px`}}>
        <div className='progress' style={{width: `${progress}%`, backgroundColor: bgColor}}></div>
      </span>
    </div>
  )
};

export default ProgressBar;
