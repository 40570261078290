import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {Form, Formik, FormikErrors} from 'formik';
import {isEmptyValue} from '../../../../utils/isEmpty';
import {TextField} from '../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useSteps} from '../../../../hooks/useSteps';
import {useTranslation} from 'react-i18next';
import {validateUrl} from '../../../../utils/validateUrl';
import {
  getCountries,
  getFail,
  setErrorCode,
  setRegisterData,
} from '../../../../redux/actions/registerActions';
import {
  ErrorTypes,
  SocialMediaType,
} from '../../../../models/enums/registerForm';
import validateEmail, {
  validatePhoneNumber,
} from '../../../../services/app/validations';
import ErrorsValidations from '../../../../components/ErrorsValidations/index';
import PhoneNumber from '../PhoneNumber';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import ReactCountryFlag from 'react-country-flag';
import SelectField from '../../../../components/SelectField';
import SocialMedia from '../SocialMediaForm';
import SocialMediaFb from '../SocialMediaForm/components/Facebook';
import SocialMediaIn from '../SocialMediaForm/components/Instagram';
import SocialMediaTw from '../SocialMediaForm/components/Twitter';
import './styles.scss';

export interface IBusinessContactInfoForm {
  contactEmail: string;
  website: string;
  countryFlag: string;
  countryCode: string;
  countryPhoneNumber: string;
  socialMediaFb: string;
  socialMediaIn: string;
  socialMediaTw: string;
  formIsValid: boolean;
}

const customStyle = {
  inputField: {
    phoneNumber: {
      generalContainer: {
        width: '100%',
        marginLeft: '10px',
      },
      container: {
        marginBottom: 0,
      },
      input: {
        height: '38px',
      },
    },
    socialMedia: {
      generalContainer: {
        width: '100%',
      },
    },
  },
  selectField: {
    generalContainer: {
      marginBottom: '0',
      width: '85px',
    },
  },
};

const BusinessContactInfoStep = () => {
  //Hooks
  const dispatch = useDispatch();
  const {t} = useTranslation('register');
  const stateRegister = useSelector((state: AppState) => state.register);
  const {incrementStep} = useSteps();

  //Data
  const {
    contactEmail,
    contactPhoneNumber,
    contactWebSiteUrl,
    contactCountryFlag,
    businessSocialMedia,
    contactCountryCode,
  } = stateRegister.data;
  const initialValues: IBusinessContactInfoForm = {
    contactEmail,
    website: contactWebSiteUrl,
    countryFlag: contactCountryFlag,
    countryCode: contactCountryCode,
    countryPhoneNumber: contactPhoneNumber,
    socialMediaFb: businessSocialMedia[0] ? businessSocialMedia[0].link : '',
    socialMediaIn: businessSocialMedia[1] ? businessSocialMedia[1].link : '',
    socialMediaTw: businessSocialMedia[2] ? businessSocialMedia[2].link : '',
    formIsValid: true,
  };

  //States
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [firstLoader, setFirstLoader] = useState<boolean>(true);
  const [countries, setCountries] = useState<[]>([]);
  const [phoneCode, setPhoneCode] = useState<string>(contactCountryCode);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>(
    !contactPhoneNumber ? phoneCode : contactPhoneNumber,
  );
  const [countriesOptions, setCountriesOptions] = useState<object[]>([]);

  //Effects
  useEffect(() => {
    dispatch(getCountries());
    setFirstLoader(false);
    if (!isEmptyValue(stateRegister.data.contactEmail)) {
      setIsDisabledButton(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get countries
    if (stateRegister.countries) {
      setCountries(stateRegister.countries);
    }
  }, [stateRegister.countries]);

  useEffect(() => {
    // setCountriesOptions
    if (countries && countries.length > 0) {
      const options = countries?.map((elem: any) => {
        const object: any = {};

        object['value'] = elem.alpha2Code;
        object['label'] = (
          <ReactCountryFlag svg countryCode={elem.alpha2Code} />
        );
        object['phoneCode'] = elem.phoneCode;

        return object;
      });
      setCountriesOptions(options);
    }
  }, [countries]);

  useEffect(() => {
    if (!firstLoader) {
      setPhoneNumberValue(`${phoneCode}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCode]);

  //Functions
  const handleChangePhoneNumber = (e: any) => {
    const isValid = e.target.validity.valid;
    const value = e.target.value;
    if (isValid) {
      setPhoneNumberValue(value);
    }
  };

  return (
    <div className="create-account-step-form">
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validate={(values: IBusinessContactInfoForm) => {
          const {contactEmail, website} = values;
          const errors: FormikErrors<IBusinessContactInfoForm> = {};

          // Mandatories
          if (isEmptyValue(contactEmail)) {
            errors.formIsValid = ErrorTypes.Required;
          }

          // Email
          if (!isEmptyValue(contactEmail) && !validateEmail(contactEmail)) {
            errors.contactEmail = ErrorTypes.InvalidEmailFormat;
          }

          // PhoneNumber
          if (
            !isEmptyValue(phoneNumberValue) &&
            !validatePhoneNumber(phoneNumberValue)
          ) {
            errors.countryPhoneNumber = ErrorTypes.InvalidPhoneNumber;
          }

          // Website
          if (!isEmptyValue(website) && !validateUrl(website)) {
            errors.website = ErrorTypes.InvalidUrlFormat;
          }

          // Button
          if (Object.keys(errors).length === 0) {
            setIsDisabledButton(false);
          } else {
            setIsDisabledButton(true);
          }

          return errors;
        }}
        onSubmit={values => {
          const {
            contactEmail,
            countryFlag,
            website,
            socialMediaFb,
            socialMediaIn,
            socialMediaTw,
            countryCode,
          } = values;

          dispatch(getFail(''));
          dispatch(setErrorCode(''));
          dispatch(
            setRegisterData({
              contactEmail,
              contactCountryFlag: countryFlag,
              contactCountryCode: countryCode,
              contactPhoneNumber: phoneNumberValue,
              contactWebSiteUrl: website,
              businessSocialMedia: [
                {
                  socialMediaType: SocialMediaType.Facebook,
                  link: socialMediaFb,
                },
                {
                  socialMediaType: SocialMediaType.Instagram,
                  link: socialMediaIn,
                },
                {
                  socialMediaType: SocialMediaType.Twitter,
                  link: socialMediaTw,
                },
              ],
            }),
          );
          incrementStep();
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          const {
            contactEmail,
            countryFlag,
            website,
            socialMediaFb,
            socialMediaIn,
            socialMediaTw,
          } = values;

          return (
            <Form>
              <TextField
                id="contactEmail"
                value={contactEmail}
                type={'email'}
                label={t('businessContactInfoEmailTitle')}
                handleChange={handleChange}
                onBlur={handleBlur}
                maxLength={150}
              />
              {errors.contactEmail === ErrorTypes.InvalidEmailFormat &&
                touched.contactEmail && (
                  <ErrorsValidations type="InvalidEmailFormat" />
                )}

              <PhoneNumber title={t('phoneNumberField')}>
                <div className="phone-number-wrapper">
                  <SelectField
                    items={countriesOptions}
                    onChange={value => {
                      setFieldValue('countryFlag', value.value);
                      setPhoneCode(`+${value.phoneCode}`);
                    }}
                    placeHolder=""
                    label=""
                    isEdit={true}
                    customStylesComponent={customStyle.selectField}
                    defaultOption={countryFlag}
                  />

                  <TextField
                    id="countryPhoneNumber"
                    value={phoneNumberValue}
                    type={'text'}
                    handleChange={e => handleChangePhoneNumber(e)}
                    onBlur={handleBlur}
                    customStyles={customStyle.inputField.phoneNumber}
                    errorElement={
                      errors.countryPhoneNumber ===
                        ErrorTypes.InvalidPhoneNumber &&
                      touched.countryPhoneNumber && (
                        <ErrorsValidations type="InvalidPhoneNumber" />
                      )
                    }
                    pattern="(\+)?([0-9]){0,}"
                  />
                </div>
              </PhoneNumber>

              <TextField
                className="field-container"
                id="website"
                value={website}
                type={'text'}
                label={t('businessContactInfoWebsiteTitle')}
                handleChange={handleChange}
                onBlur={handleBlur}
                maxLength={65}
              />
              {errors.website === ErrorTypes.InvalidUrlFormat &&
                touched.website && (
                  <ErrorsValidations type="InvalidUrlFormat" />
                )}

              <SocialMedia>
                <SocialMediaFb title={t('businessContactInfoSocialMediaTitle')}>
                  <TextField
                    customStyles={customStyle.inputField.socialMedia}
                    handleChange={handleChange}
                    hideLabel
                    id="socialMediaFb"
                    maxLength={65}
                    placeHolder={t(
                      'businessContactInfoSocialMediaPlaceholderUsername',
                    )}
                    onBlur={handleBlur}
                    type={'text'}
                    value={socialMediaFb}
                  />
                </SocialMediaFb>

                <SocialMediaIn>
                  <TextField
                    customStyles={customStyle.inputField.socialMedia}
                    handleChange={handleChange}
                    hideLabel
                    id="socialMediaIn"
                    maxLength={65}
                    placeHolder={t(
                      'businessContactInfoSocialMediaPlaceholderUsername',
                    )}
                    type={'text'}
                    value={socialMediaIn}
                  />
                </SocialMediaIn>

                <SocialMediaTw>
                  <TextField
                    customStyles={customStyle.inputField.socialMedia}
                    handleChange={handleChange}
                    hideLabel
                    id="socialMediaTw"
                    maxLength={65}
                    placeHolder={t(
                      'businessContactInfoSocialMediaPlaceholderUsername',
                    )}
                    type={'text'}
                    value={socialMediaTw}
                  />
                </SocialMediaTw>
              </SocialMedia>

              <PreviousNextButtons
                className="previous-next-buttons-container"
                onNext={handleSubmit}
                nextDisabled={isDisabledButton}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BusinessContactInfoStep;
