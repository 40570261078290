import {AxiosResponse} from 'axios';
import {
  IaddContactusRequest,
  IResetPasswordByEmailCode,
  IResetPasswordByPhone,
} from '../../models/api/authenticate';
import {
  IUserInformation,
  IValidEmailCode,
  IValidPhoneCode,
} from '../../models/api/userInformation';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericGet, genericPost} from './genericAPI';

export const getUserInformation = async (): Promise<
  AxiosResponse<IUserInformation>
> => {
  const token = store.getState().auth.token;
  return await genericGet(endpoints.auth.userInformation, token);
};

export const forgotPasswordRequestCodeByPhone = (data: {
  phoneNumber: string;
}): Promise<any> => {
  return genericPost(endpoints.auth.forgotPasswordByPhone, '', data, {});
};

export const forgotPasswordRequestCodeByEmail = (data: {
  userEmail: string;
}): Promise<any> => {
  const uri = `${
    endpoints.auth.forgotPasswordByEmail
  }?userEmail=${encodeURIComponent(data.userEmail)}`;
  return genericPost(uri, null);
};
export const validEmailCode = (data: IValidEmailCode): Promise<any> => {
  return genericPost(endpoints.auth.validResetPasswordCode, '', data, {});
};

export const validPhoneCode = (data: IValidPhoneCode): Promise<any> => {
  return genericPost(endpoints.auth.validPhoneCode, '', data, {});
};
export const resetPasswordByEmailCode = (
  data: IResetPasswordByEmailCode,
): Promise<any> => {
  return genericPost(endpoints.auth.resetPasswordByCode, '', data, {});
};
export const resetPasswordByPhone = (
  data: IResetPasswordByPhone,
): Promise<any> => {
  return genericPost(endpoints.auth.forgotPasswordByPhone, '', data, {});
};

export const setContactusRequest = async (
  data: IaddContactusRequest,
): Promise<any> => {
  const token = store.getState().auth.token;
  return await genericPost(
    endpoints.user.userAddContactusRequest,
    token,
    data,
    {},
  );
};

export const getUserPublicProfileService = async (
  userId: string,
): Promise<AxiosResponse<any>> => {
  const token = store.getState().auth.token;
  return await genericGet(
    `${endpoints.user.userPublicProfile}?userId=${userId}`,
    token,
  );
};
