import {SocialMediaType} from '../models/enums/registerForm';
import {
  FACEBOOK_URL_DOMAIN,
  TWITTER_URL_DOMAIN,
  INSTAGRAM_URL_DOMAIN,
} from '../constants/urls';

export const getSocialMediaURL = (type: SocialMediaType, username: string) => {
  switch (type) {
    case SocialMediaType.Facebook:
      return username ? encodeURI(FACEBOOK_URL_DOMAIN + username) : '';

    case SocialMediaType.Instagram:
      return username ? encodeURI(INSTAGRAM_URL_DOMAIN + username) : '';

    case SocialMediaType.Twitter:
      return username ? encodeURI(TWITTER_URL_DOMAIN + username) : '';
  }
};
