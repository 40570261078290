import { t } from 'i18next';
import * as Yup from 'yup';

export const validatePassword = Yup.object().shape({
    password: Yup.string()
        .required('passwordRequired')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
            `${t('errors:invalidPassword')}`, //8 Characters, One Uppercase, One Lowercase, One Number
        )
        .matches(/[^A-Za-z 0-9]/g, `${t('errors:invalidPassword')}`) //Special character
        .max(16),
});