import {IBusinessHours} from '../models/interfaces/generals';
import {
  sortBusinessHours,
  isValidTimeRange,
} from '../utils/businessHoursValidation';
import {uniqueId} from 'lodash';
import {
  DEFAULT_TIME_ZONE_NAME,
  FIRST_HOUR_DAY,
  HH_MM_SS_FORMAT,
  LAST_HOUR_DAY,
} from '../constants/dates';
import moment from 'moment-timezone';

enum EHourType {
  Close = 1,
  Open,
}

const useBusinessHours = (timeZoneName = DEFAULT_TIME_ZONE_NAME) => {
  const unifyBusinessHours = (businessHours: IBusinessHours[]) => {
    const unifiedBusinessHours: IBusinessHours[] = [];

    sortBusinessHours(businessHours, 'asc', 'weekDay').forEach((hour, idx) => {
      const allowDays = businessHours[idx + 1]
        ? businessHours[idx + 1].openTime
        : businessHours[0].openTime;
      const currentCloseTime = moment(hour.closeTime).format(HH_MM_SS_FORMAT);
      const currentOpenTime = moment(hour.openTime).format(HH_MM_SS_FORMAT);
      const nextOpenTime =
        allowDays && moment(allowDays).format(HH_MM_SS_FORMAT);

      if (
        nextOpenTime &&
        currentCloseTime.includes(LAST_HOUR_DAY) &&
        nextOpenTime.includes(FIRST_HOUR_DAY)
      ) {
        unifiedBusinessHours.push({
          closeTime: businessHours[idx + 1]
            ? businessHours[idx + 1].closeTime
            : businessHours[0].closeTime,
          id: 0,
          keyItem: hour.keyItem,
          openTime: hour.openTime,
          weekDay: hour.weekDay,
        });
      } else if (
        !currentCloseTime.includes(LAST_HOUR_DAY) &&
        !currentOpenTime.includes(FIRST_HOUR_DAY)
      ) {
        unifiedBusinessHours.push({
          closeTime: hour.closeTime,
          id: hour.id,
          keyItem: hour.keyItem,
          openTime: hour.openTime,
          weekDay: hour.weekDay,
        });
      }
    });
    return unifiedBusinessHours;
  };

  const splitBusinessHours = (businessHours: IBusinessHours[]) => {
    const splittedBusinessHours: IBusinessHours[] = [];
    const convertToCorrectUtcObject: IBusinessHours[] =
      convertHoursToCorrectUTC(businessHours);

    sortBusinessHours(convertToCorrectUtcObject, 'asc', 'weekDay').forEach(
      businessHour => {
        const {closeTime, id, keyItem, openTime, weekDay} = businessHour;

        const currentStartTime = moment.utc(openTime).format(HH_MM_SS_FORMAT);
        const currentEndTime = moment.utc(closeTime).format(HH_MM_SS_FORMAT);
        const subtractCloseDay = weekDay === 6 ? weekDay - 2 : weekDay - 1;

        const basicHoursObj: IBusinessHours = {
          closeTime: configureRealDay(closeTime, subtractCloseDay),
          id,
          keyItem: uniqueId(),
          openTime: configureRealDay(openTime, weekDay - 1),
          weekDay,
        };

        switch (true) {
          case currentStartTime === FIRST_HOUR_DAY &&
            currentEndTime === LAST_HOUR_DAY:
            splittedBusinessHours.push({
              ...basicHoursObj,
              keyItem,
            });
            break;

          case currentStartTime < currentEndTime:
            splittedBusinessHours.push(basicHoursObj);
            break;

          case currentStartTime > currentEndTime:
            // 23:59:59
            splittedBusinessHours.push({
              ...basicHoursObj,
              closeTime: toMilisecondsFormat(
                closeTime,
                EHourType.Close,
                weekDay - 1,
              ),
              openTime: configureRealDay(openTime, weekDay - 1),
            });

            // 00:00:00
            splittedBusinessHours.push({
              ...basicHoursObj,
              closeTime: configureRealDay(closeTime, weekDay),
              openTime: toMilisecondsFormat(openTime, EHourType.Open, weekDay),
              weekDay: weekDay === 6 ? 0 : weekDay + 1,
            });
            break;
        }
      },
    );
    return splittedBusinessHours;
  };

  const convertHoursToCorrectUTC = (businessHour: IBusinessHours[]) => {
    const cleanInvalidTimes = businessHour.filter(hours =>
      isValidTimeRange(hours.openTime, hours.closeTime),
    );

    return cleanInvalidTimes.map((hour: IBusinessHours) => ({
      ...hour,
      closeTime: modifyUtcToTimeZoneHour(
        hour.closeTime,
        getTimeZoneDifference(),
      ),
      openTime: modifyUtcToTimeZoneHour(hour.openTime, getTimeZoneDifference()),
    }));
  };

  const toMilisecondsFormat = (
    date: string,
    type: EHourType,
    days: number,
  ): string => {
    const isOpen = type === EHourType.Open;
    return moment(date)
      .clone()
      .utc()
      .add(days, 'day')
      .hours(isOpen ? 0 : 23)
      .minutes(isOpen ? 0 : 59)
      .seconds(isOpen ? 0 : 59)
      .milliseconds(0)
      .format();
  };

  const configureRealDay = (date: string, days: number): string => {
    return moment(date).clone().utc().add(days, 'day').format();
  };

  const modifyUtcToTimeZoneHour = (
    date: string,
    difference: number,
    format?: string | undefined,
  ) => {
    const currentTzDifference = parseInt(
      moment(date).format('Z').substring(0, 3),
    );
    const timeDifference = Math.abs(difference) - Math.abs(currentTzDifference);
    return moment(date)
      .utc()
      .add(difference + timeDifference, 'hours')
      .format(format);
  };

  const getTimeZoneDifference = () => {
    const difference = moment.tz(timeZoneName).format('Z');
    return parseInt(difference.substring(0, 3));
  };

  return {unifyBusinessHours, splitBusinessHours};
};

export default useBusinessHours;
