import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  IMAGE_MAX_WEIGHT,
  SUPPORTED_IMAGES,
  SUPPORTED_MULTIMEDIA,
} from '../../constants/createDeal';
import {checkExtension} from '../../utils/checkExtension';
import getUploadedFileDimensions from '../../utils/getUploadedFileDimensions';
import MsgError from '../MsgError';
import './styles.scss';

type Props = {
  acceptVideo?: boolean;
  customStylesText?: object;
  customStylesWrapper?: object;
  hideImageName?: boolean;
  id?: string;
  label: string;
  onClick: (value: any) => void;
};

const ButtonUploadImage: React.FC<Props> = ({
  acceptVideo = true,
  customStylesText,
  customStylesWrapper,
  hideImageName = false,
  id,
  label,
  onClick,
}) => {
  const {t} = useTranslation();
  const [imageName, setImageName] = useState<string | null>(null);
  const [error, setError] = useState<string>('');
  const multimediaFiles = acceptVideo ? SUPPORTED_MULTIMEDIA : SUPPORTED_IMAGES;

  const handleAddImage = ({target: {files}}: any) => {
    const loadedFile = files[0];
    const validExtensions = multimediaFiles.split(',');

    if (loadedFile.name) {
      const isExtensionValid = checkExtension(loadedFile.name, validExtensions);

      if (isExtensionValid) {
        getUploadedFileDimensions(loadedFile).then(() => {
          if (loadedFile.size >= IMAGE_MAX_WEIGHT) {
            setImageName('');
            setError(t('createDeal:errorImageWeight'));
            onClick('');
          } else {
            setError('');
            setImageName(loadedFile.name);
            onClick(loadedFile);
          }
        });
      } else {
        setImageName('');
        onClick('');
        setError(t('global:invalidFormat'));
      }
    } else {
      setImageName('');
      onClick('');
      setError(t('errors:errorProcessingFile'));
    }
  };

  return (
    <div
      className="button-upload-image-wrapper"
      style={customStylesWrapper && customStylesWrapper}>
      {imageName && !hideImageName && (
        <span className="button-upload-image-name">{imageName}</span>
      )}
      {error && <MsgError text={error} />}
      <UploadControl onChange={handleAddImage} accept={multimediaFiles} id={id}>
        <span
          className="button-upload-image-text"
          style={customStylesText && customStylesText}>
          {label}
        </span>
      </UploadControl>
    </div>
  );
};

const UploadControl = ({
  children,
  value,
  id,
  onChange,
  disabled,
  accept,
}: any) => {
  return (
    <label
      htmlFor={id ? id : 'contained-button-file'}
      className="button-upload-image-button-wrapper">
      <input
        value={value}
        accept={accept}
        disabled={disabled}
        style={{display: 'none'}}
        id={id ? id : 'contained-button-file'}
        multiple
        type="file"
        onChange={
          disabled
            ? () => {
                console.log('disabled');
              }
            : onChange
        }
      />
      {children}
    </label>
  );
};

export default ButtonUploadImage;
