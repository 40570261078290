import {
  AdActionTypes,
  CLEAR_AD_DATA,
  SET_CREATE_AD_DATA,
  SET_AD_ID
} from '../types/adTypes';

export const setCreateadData = (payload: object): AdActionTypes => ({
  type: SET_CREATE_AD_DATA,
  payload,
});

export const setClearAdData = (): AdActionTypes => ({
  type: CLEAR_AD_DATA,
});

export const setAdIdData = (payload: number): AdActionTypes => ({
  type: SET_AD_ID,
  payload
});

