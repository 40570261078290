import React from 'react';
import { AppState } from '../../redux';
import { ETypeView } from '../../models/enums/typeListData';
import { useSelector } from 'react-redux';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import './styles.scss';

type Props = {
  item?: string;
  item2?: string;
  title?: string;
};
const InfoPage: React.FC<Props> = ({ item, item2, title }) => {

  const { ui } = useSelector((state: AppState) => state);

  return (
    <div 
      data-testid="info-page" 
      className={`info-page ${ ui.currentView === ETypeView.Profile && 'info-page-profile' }`}
    >
      {title && <span className="text">{title}</span>}
      {item && (
        <div>
          <ChevronDown
            className="create-deal-info-page-icon"
            width={11}
            height={11}
          />
          <span className={`${!item2 ? 'active-text' : 'inactive-text'}`}>{item}</span>
        </div>
      )}
      {item2 && (
        <div>
          <ChevronDown
            className="create-deal-info-page-icon"
            width={11}
            height={11}
          />
          <span className={`active-text`}>{item2}</span>
        </div>
      )}
    </div>
  );
};

export default InfoPage;
