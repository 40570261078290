import {AxiosResponse} from 'axios';
import {
  IAuthenticate,
  IAuthenticateRequest,
} from '../../models/api/authenticate';
import endpoints from './endpoints';
import {genericPost} from './genericAPI';

export const authenticate = async (
  credentials: IAuthenticateRequest,
): Promise<AxiosResponse<IAuthenticate>> => {
  const timeout = 5000; //ms
  return await genericPost(endpoints.auth.authenticate, null, credentials, {
    timeout,
  });
};
