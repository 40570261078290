import React, {useEffect} from 'react';
import Button from '../../../../components/Button';
import useDeals from '../../../../hooks/useDeals';
import FieldImage from './components/FieldImage';
import FieldTitle from '../../../../components/FieldTitle/FieldTitle';
import FieldWebsite from './components/FieldWebsite';
import FieldDealType from './components/FieldDealType';
import FieldOfferTag from './components/FieldOfferTag';
import FieldLocation from './components/FieldLocation';
import DurationField from '../../../../components/DurationField';
import FieldConditions from './components/FieldConditions';
import CommunitiesField from '../../../../components/CommunitiesField';
import FormPackagesField from '../../../../components/FormPackagesField';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import {AppState} from '../../../../redux';
import {DealType} from '../../../../models/enums/dealType';
import {IPackage} from '../../../../models/api/packages';
import {ErrorCodes} from '../../../../models/enums/errors';
import {DealPromoType} from '../../../../models/enums/dealPromoType';
import {buttonsStyles} from '../../../../constants/themes';
import {useTranslation} from 'react-i18next';
import {ETypeCommunication} from '../../../../models/enums/generals';
import {useSelector} from 'react-redux';
import {
  PackageType,
  TypeAction,
  TypeActionModal,
} from '../../../../models/enums/typeAction';
import {
  setClearDealData,
  setCreateDealData,
} from '../../../../redux/actions/dealActions';
import 'react-dropdown/style.css';
import './styles.scss';

type Props = {
  id?: number | null;
  isEditPage: boolean;
  packageList: IPackage[];
  currentPackageSelection: IPackage | null;
  setCurrentPackageSelection: (currentPackage: IPackage) => void;
};

const CreateDealForm: React.FC<Props> = ({
  id,
  isEditPage,
  packageList,
  currentPackageSelection,
  setCurrentPackageSelection,
}) => {
  // Hooks
  const {t} = useTranslation('createDeal');
  const {user, ui} = useSelector((state: AppState) => state);
  const {
    addressSelected,
    community,
    contentValidations,
    date,
    deal,
    defaultDate,
    disabledButton,
    dispatch,
    errorCode,
    errorMessage,
    handleSetCommunity,
    handleSetPackage,
    image,
    isLoadingMotion,
    isLoadingSubmit,
    modalIsOpen,
    onDateChange,
    onLocationChange,
    promoCodeValue,
    promoQrValue,
    promoType,
    qrRef,
    setContentValidations,
    setDisabledButton,
    setErrorCode,
    setImage,
    setIsLoadingMotion,
    setIsOpen,
    setPromoCodeValue,
    setPromoQrValue,
    setPromoType,
    setShareLocation,
    setTitle,
    setWebsite,
    setWebSiteIsValid,
    setWeeks,
    shareLocation,
    submit,
    title,
    typeProcess,
    website,
    weeks,
    width,
  } = useDeals({
    currentPackageSelection,
    isEditPage,
    id,
  });

  // Effects
  useEffect(() => {
    if (!isEditPage) {
      dispatch(setClearDealData());
      dispatch(setCreateDealData({visibility: 1}));
      dispatch(setCreateDealData({vendorId: user.id}));
      dispatch(setCreateDealData({id: new Date().getTime()}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPackageSelection) {
      handleSetPackage(currentPackageSelection?.id || 1);
    }
  }, [currentPackageSelection]);

  return (
    <div style={{width: width}} className="create-deal-form">
      <FieldImage image={image} setImage={setImage} />

      <FieldTitle
        isValid={contentValidations.title}
        setIsValid={isValid => {
          const validations = {...contentValidations};
          validations.title = isValid;
          setContentValidations(validations);
        }}
        setValue={setTitle}
        typeCommunication={ETypeCommunication.Deal}
        value={title}
      />

      <FieldDealType
        DealType={DealType}
        disableRadioButtons={isEditPage}
        promoCodeValue={promoCodeValue}
        promoQrValue={promoQrValue}
        promotWithoutCode={deal.isPromotionWithoutCode}
        promoType={promoType ? promoType : 0}
        qrRef={qrRef}
        setPromoCodeValue={setPromoCodeValue}
        setPromoQrValue={setPromoQrValue}
        setPromoType={setPromoType}
      />

      <FieldLocation
        addressSelected={addressSelected}
        error={errorCode === ErrorCodes.invalidLocation && errorMessage}
        setAddressSelected={onLocationChange}
        setErrorCode={setErrorCode}
        setShareLocation={setShareLocation}
        shareLocation={shareLocation}
      />

      <FieldWebsite
        setFieldIsValid={isValid => setWebSiteIsValid(isValid)}
        website={website === null ? '' : website}
        setWebsite={setWebsite}
        typeProcess={typeProcess}
      />

      <FieldConditions deal={deal} />

      {(promoType === DealPromoType.QRCode ||
        promoType === DealPromoType.PromoCode ||
        promoType === DealPromoType.QRAndPromoCode) && <FieldOfferTag />}

      <CommunitiesField
        currentSelection={community}
        setSelected={handleSetCommunity}
      />

      <FormPackagesField
        packageType={PackageType.Deal}
        packageList={packageList}
        currentPackageSelection={currentPackageSelection}
        onSelect={setCurrentPackageSelection}
      />

      <DurationField
        date={date}
        defaultDate={typeProcess === TypeAction.Edit ? defaultDate : date}
        disabledField={isEditPage}
        maxWeeks={1} //TODO: Set it from package when there are not free packages
        setDate={date => onDateChange(date)}
        setWeeks={w => setWeeks(w)}
        weeks={weeks}
      />

      <div className="submit-button">
        <Button
          disable={disabledButton}
          customStylesWrapper={
            disabledButton ? buttonsStyles.disabledButton : {}
          }
          isLoading={isLoadingMotion}
          label={isEditPage ? t('global:save') : t('createDeal:publishButton')}
          onClick={() => {
            if (ui.hideConfirmationModal) {
              setDisabledButton(true);
              setIsLoadingMotion(!isLoadingMotion);
              submit(id);
            } else {
              setIsOpen(true);
            }
          }}
        />
      </div>

      {modalIsOpen && (
        <ModalConfirmation
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          onConfirm={() => submit(id)}
          title={t('createDeal:areYouSure')}
          subtitle={
            isEditPage
              ? t('createDeal:confirmPublishMsgEdit')
              : t('createDeal:confirmPublishMsg')
          }
          type="confirm"
          isLoading={isLoadingSubmit}
          actionType={TypeActionModal.Confirm}
        />
      )}
    </div>
  );
};

export default CreateDealForm;
