export enum EMenuOptions {
  Dashboard = '/home',
  Reports = '/reports',
  BusinessDirectory = '/business-directory',
  ContactUs = '/contact-us',
}

export enum EMenuToggle {
  Open = 'open',
  Closed = 'closed',
}
