import React, {ReactElement} from 'react';
import {EColors} from '../../models/enums/colors';
import './styles.scss';

interface ICustomTabProps {
  children: ReactElement;
  isSelected: boolean;
}

const ProfileEditionPanelTab = ({children, isSelected}: ICustomTabProps) => {
  return (
    <div className={`tab ${isSelected ? 'border' : ''}`}>
      <span
        className={`tab-label`}
        style={
          isSelected
            ? {color: EColors.colorBlueRegisterSteps}
            : {color: EColors.colorBlueArsenic}
        }>
        {children}
      </span>
    </div>
  );
};

export default ProfileEditionPanelTab;
