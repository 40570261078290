import React, {FC, useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {DEFAULT_ROLE_NAME, legalLinks} from '../../../../constants/register';
import {Form, Formik, FormikErrors} from 'formik';
import {formBasicStyles} from '../../../../constants/themes';
import {isEmptyValue} from '../../../../utils/isEmpty';
import {redirectToExternalLink} from '../../../../utils/redirectToExternalLink';
import {registerApi} from '../../../../services/api/registerAPI';
import {showPopup} from '../../../../redux/actions/uiActions';
import {TextField} from '../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {
  getCountries,
  getFail,
  sendRegisterData,
  setErrorCode,
} from '../../../../redux/actions/registerActions';
import validateEmail, {
  validateAtLeastOneNumber,
  validateAtLeastOneSpecialCharacter,
  validateAtLeastOneUppercase,
  validatePhoneNumber,
} from '../../../../services/app/validations';
import {
  ErrorTypes,
  PasswordValidations,
} from '../../../../models/enums/registerForm';
import ErrorsValidations from '../../../../components/ErrorsValidations/index';
import PasswordSecurity from '../../../../components/PasswordSecurity/index';
import PhoneNumber from '../../../Register/components/PhoneNumber';
import ReactCountryFlag from 'react-country-flag';
import SelectField from '../../../../components/SelectField';
import WideButton from '../../../../components/WideButton';
import './styles.scss';

export interface ICreateAccountForm {
  name: string;
  lastname: string;
  email: string;
  password: string;
  countryFlag: string;
  countryPhoneCode: string;
  countryPhoneNumber: string;
  formIsValid: boolean;
}

interface CreateAccountFormProps {
  token: string;
  businessId: string;
}

const CreateAccountForm: FC<CreateAccountFormProps> = ({token, businessId}) => {
  //Hooks
  const dispatch = useDispatch();
  const {t} = useTranslation('register');
  const navigate = useNavigate();
  const stateRegister = useSelector((state: AppState) => state.register);

  //Data
  const {
    name,
    lastname,
    username,
    password,
    countryFlag,
    phoneNumber,
    phoneCode,
  } = stateRegister.data;
  const initialValues: ICreateAccountForm = {
    name: name,
    lastname: lastname,
    email: username,
    password,
    countryFlag,
    countryPhoneNumber: phoneNumber,
    countryPhoneCode: phoneCode,
    formIsValid: true,
  };

  //States
  const [countries, setCountries] = useState<[]>([]);
  const [countriesOptions, setCountriesOptions] = useState<object[]>([]);
  const [firstLoader, setFirstLoader] = useState<boolean>(true);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [phoneCodeValue, setPhoneCodeValue] = useState<string>(phoneCode);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>(
    !phoneNumber ? phoneCode : phoneNumber,
  );
  const [showValidCharacters, setShowValidCharacters] =
    useState<boolean>(false);
  const [showValidNUmber, setShowValidNumber] = useState<boolean>(false);
  const [showValidSpecialCharacter, setShowValidSpecialCharacter] =
    useState<boolean>(false);
  const [showValidUppercase, setShowValidUppercase] = useState<boolean>(false);

  //Effects
  useEffect(() => {
    dispatch(getCountries());
    setFirstLoader(false);
    if (!isEmptyValue(stateRegister.data.username)) {
      setIsDisabledButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get countries
    if (stateRegister.countries) {
      setCountries(stateRegister.countries);
    }
  }, [stateRegister.countries]);

  useEffect(() => {
    // setCountriesOptions
    if (countries && countries.length > 0) {
      const options = countries?.map((elem: any) => {
        const object: any = {};

        object['value'] = elem.alpha3Code;
        object['label'] = (
          <ReactCountryFlag svg countryCode={elem.alpha2Code} />
        );
        object['phoneCode'] = elem.phoneCode;

        return object;
      });
      setCountriesOptions(options);
    }
  }, [countries]);

  useEffect(() => {
    if (!firstLoader) {
      setPhoneNumberValue(`${phoneCodeValue}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCodeValue]);

  const validateUserEmail = async (email: string) => {
    let returnValue = false;

    try {
      const response: any = await registerApi.userEmail(
        encodeURIComponent(email),
      );
      if (response) {
        dispatch(getFail(''));
        returnValue = true;
      }
    } catch (error: any) {
      dispatch(getFail(error.message));
      returnValue = false;
    }
    return returnValue;
  };

  const validatePasswordConditionsCharacters = (value: string) => {
    if (value.length > 6 && value.length < 17) {
      setShowValidCharacters(true);
      return true;
    } else {
      setShowValidCharacters(false);
      return false;
    }
  };

  const validatePasswordConditionsUppercase = (value: string) => {
    if (validateAtLeastOneUppercase(value)) {
      setShowValidUppercase(true);
      return true;
    } else {
      setShowValidUppercase(false);
      return false;
    }
  };

  const validatePasswordConditionsNumber = (value: string) => {
    if (validateAtLeastOneNumber(value)) {
      setShowValidNumber(true);
      return true;
    } else {
      setShowValidNumber(false);
      return false;
    }
  };

  const validatePasswordConditionsSpecialCharacter = (value: string) => {
    if (validateAtLeastOneSpecialCharacter(value)) {
      setShowValidSpecialCharacter(true);
      return true;
    } else {
      setShowValidSpecialCharacter(false);
      return false;
    }
  };

  const handleChangePhoneNumber = (e: any) => {
    const isValid = e.target.validity.valid;
    const value = e.target.value;
    if (isValid) {
      setPhoneNumberValue(value);
    }
  };

  useEffect(() => {
    if (!stateRegister.loading && stateRegister.success) {
      dispatch(showPopup(t('userCreated')));
      navigate('/');
    }
  }, [stateRegister]);

  return (
    <div className="create-account-step-form">
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validate={async (values: ICreateAccountForm) => {
          const {name, lastname, email, password} = values;
          const errors: FormikErrors<ICreateAccountForm> = {};

          // Mandatories
          if (
            isEmptyValue(name) ||
            isEmptyValue(lastname) ||
            isEmptyValue(email) ||
            isEmptyValue(password)
          ) {
            errors.formIsValid = ErrorTypes.Required;
          }

          // Email
          if (!isEmptyValue(email) && !validateEmail(email)) {
            errors.email = ErrorTypes.InvalidEmailFormat;
          }
          if (!isEmptyValue(email) && !(await validateUserEmail(email))) {
            errors.email = ErrorTypes.EmailAlreadyAssociated;
          }

          // PhoneNumber
          if (
            !isEmptyValue(phoneNumberValue) &&
            !validatePhoneNumber(phoneNumberValue)
          ) {
            errors.countryPhoneNumber = ErrorTypes.InvalidPhoneNumber;
          }

          // Password
          if (
            !isEmptyValue(password) &&
            !validatePasswordConditionsCharacters(password)
          ) {
            errors.password = PasswordValidations.Characters;
          }
          if (
            !isEmptyValue(password) &&
            !validatePasswordConditionsUppercase(password)
          ) {
            errors.password = PasswordValidations.Uppercase;
          }
          if (
            !isEmptyValue(password) &&
            !validatePasswordConditionsNumber(password)
          ) {
            errors.password = PasswordValidations.Number;
          }
          if (
            !isEmptyValue(password) &&
            !validatePasswordConditionsSpecialCharacter(password)
          ) {
            errors.password = PasswordValidations.SpecialCharacter;
          }

          // Button
          if (Object.keys(errors).length === 0) {
            setIsDisabledButton(false);
          } else {
            setIsDisabledButton(true);
          }

          return errors;
        }}
        onSubmit={values => {
          const {name, lastname, email, password} = values;

          dispatch(getFail(''));
          dispatch(setErrorCode(''));
          dispatch(
            sendRegisterData({
              roleName: DEFAULT_ROLE_NAME,
              username: email,
              password: password,
              name: name,
              lastname: lastname,
              phoneNumber: phoneNumberValue,
              invitationBusinessId: businessId,
              invitationToken: token,
            } as any),
          );
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          const {name, lastname, email, password, countryFlag} = values;

          return (
            <Form>
              <TextField
                id="name"
                value={name}
                type={'text'}
                label={t('setUpYourAccountNameTitle')}
                handleChange={handleChange}
                maxLength={65}
              />

              <div className="field-container">
                <TextField
                  id="lastname"
                  value={lastname}
                  type={'text'}
                  label={t('setUpYourAccountLastNameTitle')}
                  handleChange={handleChange}
                  maxLength={65}
                />
              </div>

              <div className="field-container">
                <TextField
                  id="email"
                  value={email}
                  type={'email'}
                  label={t('setUpYourAccountEmailTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={150}
                />
                {errors.email === ErrorTypes.InvalidEmailFormat &&
                  touched.email && (
                    <ErrorsValidations type="InvalidEmailFormat" />
                  )}
                {errors.email === ErrorTypes.EmailAlreadyAssociated &&
                  touched.email && (
                    <ErrorsValidations type="EmailAlreadyAssociated" />
                  )}
              </div>

              <div className="field-container">
                <TextField
                  id="password"
                  value={password}
                  type="password"
                  label={t('setUpYourAccountPasswordTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={16}
                  minLength={7}
                  required={true}
                  customStyles={{
                    img: {
                      float: 'right',
                      marginRight: '10px',
                      left: '0',
                    },
                  }}
                />
                {!isEmptyValue(password) && (
                  <PasswordSecurity
                    showValidCharacters={showValidCharacters}
                    showValidNumber={showValidNUmber}
                    showValidSpecialCharacter={showValidSpecialCharacter}
                    showValidUppercase={showValidUppercase}
                  />
                )}
              </div>

              <PhoneNumber title={t('phoneNumberField')}>
                <div className="phone-number-wrapper">
                  <SelectField
                    items={countriesOptions}
                    onChange={value => {
                      setFieldValue('countryFlag', value.value);
                      setPhoneCodeValue(`+${value.phoneCode}`);
                    }}
                    placeHolder=""
                    label=""
                    isEdit={true}
                    customStylesComponent={formBasicStyles.selectField}
                    defaultOption={countryFlag}
                  />

                  <TextField
                    id="countryPhoneNumber"
                    value={phoneNumberValue}
                    type={'text'}
                    handleChange={e => handleChangePhoneNumber(e)}
                    onBlur={handleBlur}
                    customStyles={formBasicStyles.inputField}
                    errorElement={
                      errors.countryPhoneNumber ===
                        ErrorTypes.InvalidPhoneNumber &&
                      touched.countryPhoneNumber && (
                        <ErrorsValidations type="InvalidPhoneNumber" />
                      )
                    }
                    pattern="(\+)?([0-9]){0,}"
                  />
                </div>
              </PhoneNumber>

              <div className="bottom-container">
                <WideButton
                  label={t('setUpYourAccountStartButton')}
                  onClick={handleSubmit}
                  disabled={isDisabledButton}
                  customStyle={
                    isDisabledButton
                      ? formBasicStyles.wideButton.disabled
                      : formBasicStyles.wideButton.enabled
                  }
                />
              </div>

              <span className="privacy-policy-eula-text">
                {t('agreePrivacyPolicyEula')}&nbsp;
                <span
                  onClick={() =>
                    redirectToExternalLink(legalLinks.privacyPolicy)
                  }
                  className="underline-text">
                  {t('agreePrivacyPolicyText')}
                </span>
                &nbsp;
                {t('agreePrivacyPolicyEulaAndText')}&nbsp;
                <span
                  onClick={() =>
                    redirectToExternalLink(legalLinks.termsAndConditions)
                  }
                  className="underline-text">
                  {t('agreeEulaText')}
                </span>
                &nbsp;
                {t('agreePrivacyPolicyEulaTermsText')}
              </span>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAccountForm;
