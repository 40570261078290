import React, {useState, useEffect} from 'react';
import {
  EAnalyticsCustomEventNames,
  EAnalyticsViewItemDetails,
} from '../../../../../../models/enums/analytics';
import {ReactComponent as Calendar} from '../../../../../../assets/icons/calendar.svg';
import {ReactComponent as Ticket} from '../../../../../../assets/icons/ticket.svg';
import {ReactComponent as Shop} from '../../../../../../assets/icons/shop.svg';
import {useTranslation} from 'react-i18next';
import {useAnalytics} from '../../../../../../hooks/useAnalytics';
import {TypeListData} from '../../../../../../models/enums/typeListData';
import {ETabsViews} from '../../../../../../models/enums/generals';
import {IDeal} from '../../../../../../models/interfaces/deals';
import truncateLongString from '../../../../../../utils/truncateLongString';
import WithoutImage from '../../../../../../components/WithoutImage';
import OfferTag from '../../../../../../components/OfferTag';
import './styles.scss';

type Props = {
  deal: IDeal;
  isPreview?: boolean;
  name: string;
};

const CardPreview: React.FC<Props> = ({deal, name}) => {
  // Hooks
  const {t} = useTranslation('previewDeal');
  const {eventTrack} = useAnalytics();

  // Local State
  const [endDate, setEndDate] = useState<any>('');
  const [type, setType] = useState<any>('');

  // Effects
  useEffect(() => {
    eventTrack(EAnalyticsCustomEventNames.ViewItemDeal, {
      eventParams: {
        track_id: EAnalyticsViewItemDetails.Deal,
        item_list_name: ETabsViews.Deals,
        item_list_id: `${deal.id}`,
        items: [
          {
            item_id: `${deal.id}`,
            item_name: deal.title,
            promotion_id: `${deal.promoType}`,
            item_category: TypeListData.Deal,
            location_id: deal.location || '',
          },
        ],
      },
    });
  }, []);

  useEffect(() => {
    if (deal.endDate) {
      const date = new Date(deal.endDate);
      setEndDate(
        `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
      );
    }
  }, [deal.endDate]);

  useEffect(() => {
    switch (deal.type) {
      case 1:
        setType(t('typeQr'));
        break;
      case 2:
        setType(t('typeCode'));
        break;
      case 3:
        setType(t('typeQrAndCode'));
        break;

      default:
        setType(t('typeOfDeal'));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal.type]);

  return (
    <div className="preview-deal-card-preview">
      <div className="preview-deal-card-preview-image-wrapper">
        {deal.offerTag && <OfferTag text={deal.offerTag} />}

        {deal.imagePreview || deal.files.length ? (
          <img
            src={deal.imagePreview || deal.files[0].url}
            className="preview-deal-card-preview-image"
            alt="deal-preview"
          />
        ) : (
          <WithoutImage />
        )}
      </div>

      <div className="preview-deal-card-preview-content-wrapper">
        <div className="preview-deal-card-preview-content-header">
          <Shop />
          <span className="preview-deal-card-preview-content-header-text">
            {truncateLongString(name, 10)}
          </span>
          <span
            style={{marginRight: 5}}
            className="preview-deal-card-preview-content-header-text">
            ·
          </span>
          <Ticket />
          <span className="preview-deal-card-preview-content-header-text">
            {truncateLongString(type, 7)}
          </span>
        </div>

        <div className="preview-deal-card-preview-content-core">
          <span className="preview-deal-card-preview-content-core-title">
            {deal.title ? deal.title : t('contentCoreTitle')}
          </span>
        </div>

        <div className="preview-deal-card-preview-content-core-address">
          <Calendar className="preview-deal-card-preview-content-core-icon" />
          <span>
            {!deal.endDate
              ? t('contentHeaderDate')
              : `${t('previewDate')} ${endDate}`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardPreview;
