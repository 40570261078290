import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../../../redux';
import {EPanelFormProfile} from '../../../../../../models/enums/profile';
import {ErrorTypes} from '../../../../../../models/enums/registerForm';
import {Form, Formik, FormikErrors} from 'formik';
import {ICountries} from '../../../../../../models/interfaces/country';
import {IDropDownOptions} from '../../../../../../models/interfaces/generals';
import {isEmptyValue} from '../../../../../../utils/isEmpty';
import {showPopup} from '../../../../../../redux/actions/uiActions';
import {TextField} from '../../../../../../components/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validateNumber} from '../../../../../../services/app/validations';
import {
  setHasErrors,
  setProfileData,
  setSelecteedAddress,
} from '../../../../../../redux/actions/profileActions';
import {
  ILocationData,
  IPanelFormProfile,
} from '../../../../../../models/interfaces/profile';
import Button from '../../../../../../components/Button';
import ErrorsValidations from '../../../../../../components/ErrorsValidations/index';
import Geocode from 'react-geocode';
import SelectField from '../../../../../../components/SelectField';
import './styles.scss';

type Props = {
  setShowPanelForm: (val: IPanelFormProfile) => void;
};

export interface IBusinessPhysicalForm {
  city: string;
  country: string;
  formIsValid?: boolean;
  nickName: string;
  state: string;
  streetAddress: string;
  zipCode: string;
}

const LocationForm: React.FC<Props> = ({setShowPanelForm}) => {
  //Hooks
  const dispatch = useDispatch();
  const {profile: stateProfile} = useSelector((state: AppState) => state);
  const {t} = useTranslation('register');

  //States
  const [countriesOptions, setCountriesOptions] = useState<object[]>([]);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [latData, setLatData] = useState<number>(0);
  const [lngData, setLngData] = useState<number>(0);

  //Data
  const businessLocationData = stateProfile.data.businessLocations;
  const businessCurrentLocation = stateProfile.selectedAddress;
  const isSelectedLocation =
    typeof businessCurrentLocation === 'number' &&
    businessLocationData[businessCurrentLocation];

  const initialValues: IBusinessPhysicalForm = {
    city: isSelectedLocation ? isSelectedLocation.location.cityName : '',
    country: isSelectedLocation ? isSelectedLocation.location.country : 'US',
    nickName: isSelectedLocation ? isSelectedLocation.nickName : '',
    state: isSelectedLocation ? isSelectedLocation.location.state : '',
    streetAddress: isSelectedLocation
      ? isSelectedLocation.location.streetAddress
      : '',
    zipCode: isSelectedLocation ? isSelectedLocation.location.zipCode : '',
  };

  //Effects
  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GCP_MAPS_KEY || '');
    Geocode.setLanguage('en');
    Geocode.setRegion('en');

    if (stateProfile.countries && stateProfile.countries.length) {
      const options: IDropDownOptions[] = stateProfile.countries?.map(
        (country: ICountries) => ({
          value: country.alpha2Code,
          label: country.translations?.[0].value,
        }),
      );
      setCountriesOptions(options);
    }

    if (isSelectedLocation) {
      const {cityName, country, state, streetAddress, zipCode} =
        isSelectedLocation.location;
      const address = `${country}, ${cityName}, ${state}, ${zipCode}, ${streetAddress}`;
      getGeographicPosition(address);
      setIsDisabledButton(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers - Functions
  const getGeographicPosition = async (address: string) => {
    await Geocode.fromAddress(address).then(
      response => {
        const {lat, lng} = response.results[0].geometry.location;

        setLatData(lat);
        setLngData(lng);
      },
      error => {
        console.error(error);
      },
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={true}
      validateOnChange={false}
      validate={(values: IBusinessPhysicalForm) => {
        const {city, country, state, streetAddress, zipCode} = values;
        const errors: FormikErrors<IBusinessPhysicalForm> = {};

        // onChange Coordenates
        if (!isEmptyValue(city) || !isEmptyValue(state)) {
          const address = `${country}, ${city}, ${state}, ${zipCode}, ${streetAddress}`;
          getGeographicPosition(address);
        }

        // Mandatories
        if (
          isEmptyValue(city) ||
          isEmptyValue(country) ||
          isEmptyValue(state) ||
          isEmptyValue(streetAddress)
        ) {
          errors.formIsValid = ErrorTypes.Required;
        }

        // ZipCode
        if (!isEmptyValue(zipCode) && !validateNumber(zipCode)) {
          errors.zipCode = ErrorTypes.InvalidZipcodeFormat;
        }

        // Block General Buttom
        if (Object.entries(errors).length > 0) {
          setIsDisabledButton(true);
        } else {
          setIsDisabledButton(false);
        }

        return errors;
      }}
      onSubmit={values => {
        const {city, country, nickName, state, streetAddress, zipCode} = values;
        let finalLocationsData = businessLocationData;

        if (isSelectedLocation) {
          finalLocationsData = businessLocationData.filter(
            (e: ILocationData) => e.id !== isSelectedLocation.id,
          );
        }

        dispatch(
          setProfileData({
            businessLocations: [
              ...finalLocationsData,
              {
                id: isSelectedLocation?.id || 0,
                location: {
                  apartmentSuit: '',
                  cityId: '',
                  cityName: city,
                  country,
                  googlePlaceId: '',
                  latitude: latData,
                  longitude: lngData,
                  neighborhood: '',
                  state,
                  streetAddress,
                  zipCode,
                },
                nickName,
                primary: isSelectedLocation?.primary || false,
              },
            ],
          }),
        );
        setShowPanelForm({open: false, type: EPanelFormProfile.Location});
        dispatch(
          showPopup(t('global:generalMessages:successInformationSaved')),
        );
      }}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        values,
      }) => {
        const {city, country, nickName, state, streetAddress, zipCode} = values;

        return (
          <Form>
            <div className="business-location-form">
              <div className="location-section">
                <TextField
                  handleChange={handleChange}
                  id="nickName"
                  label={t('profilePage:locationFieldTableNickname')}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeHolder={t(
                    'profilePage:locationFieldNicknamePlaceholder',
                  )}
                  type={'text'}
                  value={nickName}
                />

                <SelectField
                  items={countriesOptions}
                  onChange={value => {
                    setFieldValue('country', value.value);
                    setFieldTouched('country', value.value);
                  }}
                  placeHolder={t(
                    'register:businessPhysicalStepCountryPlaceholder',
                  )}
                  label={t('businessPhysicalStepCountryTitle')}
                  isEdit={true}
                  defaultOption={country}
                />

                <TextField
                  handleChange={handleChange}
                  id="streetAddress"
                  label={t('businessPhysicalStepAddressTitle')}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeHolder={t('businessPhysicalStepAddressPlaceholder')}
                  type={'text'}
                  value={streetAddress}
                />

                <TextField
                  handleChange={handleChange}
                  id="city"
                  label={t('businessPhysicalStepCityTitle')}
                  maxLength={65}
                  onBlur={handleBlur}
                  placeHolder={t('businessPhysicalStepCityPlaceholder')}
                  type={'text'}
                  value={city}
                />

                <div className="distribute-two-fields-wrapper">
                  <TextField
                    handleChange={handleChange}
                    id="state"
                    label={t('businessPhysicalStepStateTitle')}
                    maxLength={65}
                    onBlur={handleBlur}
                    placeHolder={t('businessPhysicalStepStatePlceholder')}
                    type={'text'}
                    value={state}
                  />

                  <TextField
                    errorElement={
                      errors.zipCode === ErrorTypes.InvalidZipcodeFormat && (
                        <ErrorsValidations type="InvalidZipcodeFormat" />
                      )
                    }
                    handleChange={handleChange}
                    id="zipCode"
                    label={t('businessPhysicalStepZipcodeTitle')}
                    maxLength={65}
                    onBlur={handleBlur}
                    placeHolder={t('businessPhysicalStepZipcodePlaceholder')}
                    type={'text'}
                    value={zipCode}
                  />
                </div>
              </div>

              <div className="location-buttons-container">
                <Button
                  label={t('global:cancel')}
                  onClick={() => {
                    dispatch(setSelecteedAddress(null));
                    setShowPanelForm({
                      open: false,
                      type: EPanelFormProfile.Location,
                    });
                    dispatch(
                      setHasErrors({
                        ...stateProfile.hasErrors,
                        location: false,
                      }),
                    );
                  }}
                />
                <Button
                  label={
                    isSelectedLocation
                      ? t('profilePage:btnLocationEditAddress')
                      : t('profilePage:btnLocationAddAddress')
                  }
                  onClick={handleSubmit}
                  disable={isDisabledButton}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LocationForm;
