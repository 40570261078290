interface Endpoints {
  auth: AuthEndpoints;
  deal: DealEndpoints;
  neighborhood: LocationEndpoints;
  ad: AdEndpoints;
  package: PackageEndpoints;
  contentValidation: ContentValidationEndpoints;
  business: IBusiness;
  countries: ICountries;
  validateUserEmail: IValidateUserEmail;
  sendRegisterData: ISendRegisterData;
  profile: ISendProfileData;
  communities: ICommunities;
  user: IUser;
}

interface AuthEndpoints {
  authenticate: string;
  userInformation: string;
  forgotPasswordByPhone: string;
  forgotPasswordByEmail: string;
  validResetPasswordCode: string;
  validPhoneCode: string;
  resetPasswordByPhone: string;
  resetPasswordByCode: string;
}

interface DealEndpoints {
  listMyDeals: string;
  create: string;
  getById: string;
  update: string;
  delete: string;
}

interface LocationEndpoints {
  neighborhood: string;
}

interface PackageEndpoints {
  list: string;
}

interface ContentValidationEndpoints {
  vocavularyCheck: string;
  listMyAds: string;
}
interface AdEndpoints {
  create: string;
  getById: string;
  list: string;
  delete: string;
  update: string;
}

interface IBusiness {
  business: string;
  businessIdentification: string;
  listBusinessUsers: string;
  addUserToBusiness: string;
  deleteUserFromBusiness: string;
  publicProfile: string;
}

interface ICountries {
  countries: string;
}

interface IValidateUserEmail {
  userEmail: string;
}

interface ISendRegisterData {
  registerData: string;
  confirmEmail: string;
  resendEmail: string;
}

interface ISendProfileData {
  profileData: string;
  profileUserData: string;
}
interface ICommunities {
  list: string;
}
interface IUser {
  userAddContactusRequest: string;
  userPublicProfile: string;
}

const endpoints: Endpoints = {
  auth: {
    authenticate: 'authenticate-business',
    userInformation: 'user-information',
    forgotPasswordByPhone: 'forgot-password-by-phone',
    forgotPasswordByEmail: 'forgot-password-by-code',
    validResetPasswordCode: 'validate-reset-password-code',
    validPhoneCode: 'validate-phone-code',
    resetPasswordByPhone: 'reset-password-by-phone',
    resetPasswordByCode: 'reset-password-by-code',
  },
  deal: {
    listMyDeals: 'deal/list-mydeals',
    create: 'deal/create',
    getById: 'deal/',
    update: 'deal/update/',
    delete: 'deal/delete/',
  },
  neighborhood: {
    neighborhood: 'neighborhood',
  },
  ad: {
    create: 'ad/create',
    getById: 'ad/',
    list: 'ad/list-myads',
    delete: 'ad/delete/',
    update: 'ad/update/',
  },
  package: {
    list: 'package',
  },
  contentValidation: {
    vocavularyCheck: 'vocabularyCheck',
    listMyAds: 'ad/list-myads',
  },
  business: {
    business: 'business',
    businessIdentification: 'business/identification',
    listBusinessUsers: 'business/list-business-users',
    addUserToBusiness: 'business/add-user-to-business',
    deleteUserFromBusiness: 'business/delete-user-from-business',
    publicProfile: 'business/public-profile',
  },
  countries: {
    countries: 'country/list-countries',
  },
  validateUserEmail: {
    userEmail: 'validate-user-email?userEmail=',
  },
  sendRegisterData: {
    registerData: 'register',
    confirmEmail: 'confirm-email',
    resendEmail: 'resend-confirm-email',
  },
  profile: {
    profileData: 'business/update-business',
    profileUserData: 'user/edit-user-profile',
  },
  communities: {
    list: 'community/list-communities',
  },
  user: {
    userAddContactusRequest: 'user/add-contactus-request',
    userPublicProfile: 'user/user-public-profile',
  },
};

export default endpoints;
