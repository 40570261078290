import React from 'react';
import './styles.scss';

type Props = {
  icon: any;
  title: string;
  description: string;
};
const CardOption: React.FC<Props> = ({icon, title, description}) => {
  return (
    <div className="card-option-wrapper">
      <div className="card-option-icon">{icon}</div>
      <span className="card-option-title">{title}</span>
      <span className="card-option-description">{description}</span>
    </div>
  );
};

export default CardOption;
