import React from 'react';
import {IDeal} from '../../models/interfaces/deals';
import OfferTag from '../OfferTag';
import WithoutImage from '../WithoutImage';
import useWindowDimensions from '../../hooks/useWindowsDimensions';
import './styles.scss';

type Props = {
  isPreview?: boolean;
  deal: IDeal;
};

const PreviewImage: React.FC<Props> = ({deal}) => {
  // Data
  const {height} = useWindowDimensions();

  return (
    <div className="preview-image-wrapper">
      {deal.offerTag && (
        <OfferTag
          text={deal.offerTag}
          customStyles={
            height > 880
              ? {
                  width: 81,
                  left: 20,
                  top: 20,
                }
              : {
                  width: 61,
                  left: 8,
                  top: 8,
                }
          }
        />
      )}

      {deal.imagePreview || deal.files.length ? (
        <img
          src={deal.imagePreview || deal.files[0].url}
          className="preview-image"
          alt="deal-preview"
        />
      ) : (
        <WithoutImage />
      )}
    </div>
  );
};

export default PreviewImage;
