import React from 'react';
import {ReactComponent as GrayChevronDown} from '../../../../assets/icons/chevron-down.svg';
import {ReactComponent as BlackChevronDown} from '../../../../assets/icons/black-chevron-down.svg';
import {ETableFieldAlignment} from '../../../../models/enums/tableDeals';
import './styles.scss';

type Props = {
  position: ETableFieldAlignment;
  label: string;
  name: string;
  currentFilter: string;
  ascendingOrder: boolean;
  manageOrder: any;
  anyLabel?: boolean;
};

const HeaderItem: React.FC<Props> = ({
  position,
  label,
  name,
  currentFilter,
  ascendingOrder,
  manageOrder,
  anyLabel = false,
}) => {
  const handleClickItem = () => {
    manageOrder(name);
  };

  return (
    <th
      className={`head-item ${
        position === ETableFieldAlignment.Left
          ? 'rounded-left'
          : 'head-item-center'
      } ${position === ETableFieldAlignment.Right && 'rounded-right'}`}>
      <div
        className={`${
          position === ETableFieldAlignment.Left
            ? 'flex-row-start'
            : 'flex-row-center'
        }`}>
        <span
          onClick={handleClickItem}
          className={label === 'Actions' ? 'cursor-normal' : 'cursor-pointer'}>
          {label}
        </span>
        {!anyLabel && (
          <div className="flex-col">
            {name === currentFilter && ascendingOrder ? (
              <BlackChevronDown className="rotate" width={8} height={4} />
            ) : (
              <GrayChevronDown className="rotate" width={8} height={4} />
            )}
            {name === currentFilter && !ascendingOrder ? (
              <BlackChevronDown width={8} height={4} />
            ) : (
              <GrayChevronDown width={8} height={4} />
            )}
          </div>
        )}
      </div>
    </th>
  );
};

export default HeaderItem;
