import React from 'react';
import './styles.scss';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import truncateLongString from '../../utils/truncateLongString';
import {IDeal} from '../../models/interfaces/deals';
import useWindowDimensions from '../../hooks/useWindowsDimensions';

type Props = {
  deal: IDeal;
};
const PreviewConditions: React.FC<Props> = ({deal}) => {
  const {t} = useTranslation('previewDeal');
  const {height} = useWindowDimensions();

  return (
    <div className="preview-details">
      <div className="preview-details-title">
        <span className="preview-details-text">{t('previewDealDetails')}</span>
        <ChevronDown
          className="preview-details-icon"
          width={height < 719 ? 10 : 17}
          height={height < 719 ? 10 : 17}
        />
      </div>
      {height > 719 && (
        <div className="preview-details-items">
          {deal.conditions.map(item => (
            <span key={item.id} className="preview-details-item">
              ■ {truncateLongString(item.condition, 29)}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default PreviewConditions;
