import React from 'react';
import Loading from '../../assets/images/loading.gif';
import './styles.scss';

export const Loader = () => {
  return (
    <div className="loading">
      <img src={Loading} width={30} alt="loading" />
    </div>
  );
};
