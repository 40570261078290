export const isPhoneNumber = (value: string): boolean => {
    const regexPhoneNumber = /^\+(?:[0-9]?){6,14}[0-9]$/;
    return regexPhoneNumber.test(value);
  };
  
  export const isEmail = (value: string): boolean => {
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(String(value).toLowerCase());
  };

  export const isEmptyObject = (obj: object) => Object.keys(obj).length === 0;
