import React, {useEffect} from 'react';
import {adCallToActionsLabel} from '../../../../../../constants/generals';
import {AppState} from '../../../../../../redux';
import {useAds} from '../../../../../../hooks/useAds';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validateUrl} from '../../../../../../utils/validateUrl';
import CompleteTextField from '../../../../../../components/CompleteTextField';
import FormField from '../../../../../../components/FormField/FormField';
import SelectField from '../../../../../../components/SelectField';

interface IProps {
  isEdit?: boolean;
}

const CallToActionField: React.FC<IProps> = ({isEdit}) => {
  // Hooks
  const {t} = useTranslation('createAd');
  const {ad} = useSelector((state: AppState) => state);
  const {
    error,
    handleChangeInterceptor,
    handleSelectChangeInterceptor,
    setError,
    setTitle,
    title,
  } = useAds({});

  // Effects
  useEffect(() => {
    setTitle(ad.buttonURL);
    handleChangeInterceptor(ad.buttonURL);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormField
      title={t('callToActionFieldTitle')}
      description={t('callToActionFieldDescription')}>
      <SelectField
        onChange={handleSelectChangeInterceptor}
        items={adCallToActionsLabel(t)}
        placeHolder={t('callToActionFieldPlaceholder')}
        label={t('callToActionFieldLabel')}
        defaultOption={ad.buttonLabel}
        isEdit={isEdit}
      />

      <CompleteTextField
        id={'title'}
        type={'text'}
        value={ad.buttonURL}
        setValue={handleChangeInterceptor}
        maxLength={90}
        placeholder={t('callToActionFieldUrlPlaceholder')}
        label={t('callToActionFieldUrlLabel')}
        hideLabel={false}
        customStyles={{}}
        withContentValidation={false}
        withCounter={false}
        onBlur={() => {
          if (title) {
            setError(!validateUrl(title));
          }
        }}
        error={error}
        errorMessage={t('createDeal:errorMessageUrl')}
      />
    </FormField>
  );
};

export default CallToActionField;
