import React, {useState} from 'react';
import AppLogo from '../../../../components/AppLogo';
import AppSmallLogo from '../../../../components/AppSmallLogo';
import Content from './components/Content';
import Toggle from './components/Toggle';
import UserInfo from './components/UserInfo';
import {logoutUser} from '../../../../redux/actions/authActions';
import {ReactComponent as LogoutIcon} from '../../../../assets/icons/logout.svg';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import './styles.scss';

const LeftMenu: React.FC = () => {
  const {t} = useTranslation();
  const [menuIsOpen, setMenuIsOpen] = useState<'open' | 'closed'>('closed');
  const dispatch = useDispatch();

  return (
    <div id="left" className={menuIsOpen}>
      <div className={`sidebar ${menuIsOpen}`}>
        <div className="left-menu-logo">
          {menuIsOpen === 'open' ? (
            <AppLogo width={99.5} height={40} />
          ) : (
            <AppSmallLogo width={40} height={40} />
          )}
        </div>
        <Toggle menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
        <Content menuIsOpen={menuIsOpen} />
        <div className="left-menu-bottom">
          <UserInfo menuIsOpen={menuIsOpen} />
          <div className="logout" onClick={() => dispatch(logoutUser())}>
            <LogoutIcon />
            {menuIsOpen === 'open' && (
              <span className="logout-button-text">{t('global:logout')}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
