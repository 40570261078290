import React, { 
    ReactElement, 
    useEffect, 
    useRef, 
    useState 
} from 'react';
import './styles.scss';

type Props = {
    center: google.maps.LatLngLiteral;
    zoom: number;
    children: ReactElement;
};

const Map: React.FC<Props> = ({ center, zoom, children }) => {
  
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);

    useEffect(() => {
        if (ref.current) {
            setMap(new window.google.maps.Map(ref.current, {
                fullscreenControl: false,
            }));
        }
    }, []);

    if (map) {
        map.setCenter(center);
        map.setZoom(zoom);
    }

  return (
    <div 
        ref={ref} 
        id="map" 
        className='map-container'
    >
        { 
            React.Children.map( 
                children, (child: ReactElement) => 
                    React.cloneElement(child, { map })
            ) 
        }
    </div>
  );
};

export default Map;