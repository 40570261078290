import React from 'react';
import './styles.scss';
import {t} from 'i18next';

type Props = {
  maxLength: number;
  showCounterWord?: boolean;
  string: string;
  style?: object;
};

const CharacterCounter: React.FC<Props> = ({
  maxLength,
  showCounterWord,
  string,
  style,
}) => {
  return (
    <div className="character-counter-wrapper" style={style ? style : {}}>
      <span className="character-counter-text">{`${
        string.length
      } / ${maxLength} ${
        (showCounterWord && t('global:characters')) || ''
      }`}</span>
    </div>
  );
};

export default CharacterCounter;
