import {DEFAULT_TIME_ZONE_NAME} from '../../constants/dates';
import {EPopupType} from '../../models/enums/ui';
import {ETypeView, TypeListData} from '../../models/enums/typeListData';
import {IUIState} from '../../models/interfaces/uiState';
import {
  SET_COMMUNITIES,
  SET_CONFIRMATION_POPUP,
  SET_CURRENT_VIEW,
  SET_HIDE_CONFIRMATION_MODAL,
  SET_HIDE_UNSAVED_MODAL,
  SET_LIST_DATA,
  SET_TIME_ZONE_NAME,
  UiActionTypes,
} from '../types/uiTypes';

const initialState: IUIState = {
  confirmationPopup: {
    isVisible: false,
    delay: 1000,
    message: '',
    type: EPopupType.Success,
  },
  communities: [],
  currentView: ETypeView.Register,
  hideConfirmationModal: false,
  hideUnsavedModal: false,
  timeZoneName: DEFAULT_TIME_ZONE_NAME,
  typeListData: TypeListData.Ad,
};

const uiReducer = (state = initialState, action: UiActionTypes): IUIState => {
  switch (action.type) {
    case SET_CONFIRMATION_POPUP:
      return {
        ...state,
        confirmationPopup: action.payload,
      };

    case SET_LIST_DATA:
      return {
        ...state,
        typeListData: action.payload,
      };

    case SET_HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        hideConfirmationModal: action.payload,
      };

    case SET_HIDE_UNSAVED_MODAL:
      return {
        ...state,
        hideUnsavedModal: action.payload,
      };

    case SET_COMMUNITIES:
      return {
        ...state,
        communities: action.payload,
      };

    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.payload,
      };

    case SET_TIME_ZONE_NAME:
      return {
        ...state,
        timeZoneName: action.payload,
      };

    default:
      return state;
  }
};

export default uiReducer;
