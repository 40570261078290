import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {ErrorTypes, StoreType} from '../../../../models/enums/registerForm';
import {Form, Formik, FormikErrors} from 'formik';
import {ICountries} from '../../../../models/interfaces/country';
import {IDropDownOptions} from '../../../../models/interfaces/generals';
import {IProfileLocation} from '../../../../models/interfaces/profile';
import {isEmptyValue} from '../../../../utils/isEmpty';
import {TextField} from '../../../../components/TextField';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useSteps} from '../../../../hooks/useSteps';
import {useTranslation} from 'react-i18next';
import {validateNumber} from '../../../../services/app/validations';
import {
  getCountries,
  setRegisterData,
} from '../../../../redux/actions/registerActions';
import {
  EAnalyticsCustomEventNames,
  EAnalyticsRegisterSteps,
} from '../../../../models/enums/analytics';
import ErrorsValidations from '../../../../components/ErrorsValidations/index';
import Geocode from 'react-geocode';
import LocationMap from '../../../../components/LocationMap';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

type Props = {
  setBackDisplay?: (value: StoreType) => void;
};

export interface IBusinessPhysicalForm {
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  formIsValid: boolean;
}

const customStyle = {
  inputField: {
    stateField: {
      generalContainer: {
        marginRight: '16px',
      },
    },
  },
};

const BusinessPhysicalStep: React.FC<Props> = ({setBackDisplay}) => {
  //Hooks
  const dispatch = useDispatch();
  const stateRegister = useSelector((state: AppState) => state.register);
  const {t} = useTranslation('register');
  const {incrementStep} = useSteps();
  const {eventTrack} = useAnalytics();

  //Data
  const {country, streetAddress, cityName, state, zipCode} =
    stateRegister.data.businessLocations[0].location;

  const initialValues: IBusinessPhysicalForm = {
    country: stateRegister.data.countryAlpha2Code,
    streetAddress,
    city: cityName,
    state,
    zipCode,
    formIsValid: false,
  };

  //States
  const [countries, setcountries] = useState<[]>([]);
  const [currentLocationObj, setCurrentLocationObj] =
    useState<IProfileLocation>();
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [latData, setLatData] = useState<number>(0);
  const [lngData, setLngData] = useState<number>(0);

  //Effects
  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GCP_MAPS_KEY || '');
    Geocode.setLanguage('en');
    Geocode.setRegion('en');

    dispatch(getCountries());

    if (stateRegister.countries) {
      setcountries(stateRegister.countries);
    }

    if (cityName || state) {
      const address = `${country}, ${cityName}, ${state}, ${zipCode}, ${streetAddress}`;
      getGeographicPosition(address);
    }

    if (!isEmptyValue(country)) {
      setIsDisabledButton(false);
    }

    eventTrack(EAnalyticsCustomEventNames.Register, {
      register: {step: EAnalyticsRegisterSteps.BusinessPhysicalStore},
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentLocationObj?.cityName || currentLocationObj?.state) {
      const {cityName, country, state, streetAddress, zipCode} =
        currentLocationObj;

      const address = `${country}, ${cityName}, ${state}, ${zipCode}, ${streetAddress}`;
      getGeographicPosition(address);
    }
  }, [currentLocationObj]);

  //Functions
  const options: IDropDownOptions[] = countries?.map((country: ICountries) => ({
    value: country.alpha2Code,
    label: country.translations?.[0].value,
  }));

  const getGeographicPosition = async (address: string) => {
    await Geocode.fromAddress(address).then(
      response => {
        const {lat, lng} = response.results[0].geometry.location;

        setLatData(lat);
        setLngData(lng);
      },
      error => {
        console.error(error);
      },
    );
  };

  return (
    <div className="business-physical-step-form">
      <Formik
        initialValues={initialValues}
        validateOnBlur={true}
        validateOnChange={false}
        validate={(values: IBusinessPhysicalForm) => {
          const {city, country, state, streetAddress, zipCode} = values;
          const errors: FormikErrors<IBusinessPhysicalForm> = {};

          if (!isEmptyValue(city) || !isEmptyValue(state)) {
            setCurrentLocationObj({
              cityName: city,
              country,
              state,
              streetAddress,
              zipCode,
            });
          }

          // Mandatories
          if (
            isEmptyValue(streetAddress) ||
            isEmptyValue(city) ||
            isEmptyValue(state)
          ) {
            errors.formIsValid = ErrorTypes.Required;
          }

          // ZipCode
          if (!isEmptyValue(zipCode) && !validateNumber(zipCode)) {
            errors.zipCode = ErrorTypes.InvalidZipcodeFormat;
          }

          // Button
          if (Object.keys(errors).length === 0) {
            setIsDisabledButton(false);
          } else {
            setIsDisabledButton(true);
          }

          return errors;
        }}
        onSubmit={values => {
          const {country, streetAddress, city, state, zipCode} = values;

          dispatch(
            setRegisterData({
              businessLocationType: 1,
              businessLocations: [
                {
                  location: {
                    streetAddress,
                    apartmentSuit: '',
                    zipCode,
                    cityId: '',
                    googlePlaceId: '',
                    latitude: latData,
                    longitude: lngData,
                    country,
                    state,
                    cityName: city,
                    neighborhood: '',
                  },
                  primary: true,
                },
              ],
              countryAlpha2Code: country,
              webSiteUrl: '',
            }),
          );
          incrementStep();
        }}>
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => {
          const {country, streetAddress, city, state, zipCode} = values;

          return (
            <Form>
              <SelectField
                items={options}
                onChange={value => {
                  setFieldValue('country', value.value);
                  setFieldTouched('country', value.value);
                }}
                placeHolder={t(
                  'register:businessPhysicalStepCountryPlaceholder',
                )}
                label={t('businessPhysicalStepCountryTitle')}
                isEdit={true}
                defaultOption={country}
              />

              <div className="field-container">
                <TextField
                  id="streetAddress"
                  value={streetAddress}
                  type={'text'}
                  label={t('businessPhysicalStepAddressTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={65}
                />
              </div>

              <div className="field-container">
                <TextField
                  id="city"
                  value={city}
                  type={'text'}
                  label={t('businessPhysicalStepCityTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={65}
                />
              </div>

              <div className="field-container-state-zipcode">
                <TextField
                  id="state"
                  value={state}
                  type={'text'}
                  label={t('businessPhysicalStepStateTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={65}
                  customStyles={customStyle.inputField.stateField}
                />

                <TextField
                  id="zipCode"
                  value={zipCode}
                  type={'text'}
                  label={t('businessPhysicalStepZipcodeTitle')}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={65}
                  errorElement={
                    errors.zipCode === ErrorTypes.InvalidZipcodeFormat && (
                      <ErrorsValidations type="InvalidZipcodeFormat" />
                    )
                  }
                />
              </div>

              <div className="field-container">
                <LocationMap
                  center={{
                    lat: latData,
                    lng: lngData,
                  }}
                />
              </div>

              <div className="previous-next-buttons-container">
                <PreviousNextButtons
                  onNext={handleSubmit}
                  nextDisabled={isDisabledButton}
                  setBackDisplay={setBackDisplay}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BusinessPhysicalStep;
