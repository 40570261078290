export default {
  root: '/',
  home: '/home',
  contactUs: '/contact-us',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  verificationCode: '/verification-code',
  newPassword: '/new-password',
  viewAdId: ':view-ad/:id',
  viewDealId: ':view-deal/:id',
  profileEdition: '/profile-edition',
  myBusinessId: ':my-business/:id',
  createDeal: '/create-deal',
  id: ':id',
  crateAd: '/create-ad',
  confirmemail: '/confirmemail/:userId/:verificationToken',
  newVerificationLink: '/new-verification-link',
  registerByInvitation: '/register-by-invitation',
};
