import React, {useEffect} from 'react';
import {DealPromoType} from '../../../../../../models/enums/dealPromoType';
import {setCreateDealData} from '../../../../../../redux/actions/dealActions';
import {TextField} from '../../../../../../components/TextField';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import DealTypeSelector from '../DealTypeSelector';
import Disabled from '../../../../../../components/DisabledComponent';
import QRCode from 'react-qr-code';
import FormField from '../../../../../../components/FormField/FormField';

type Props = {
  DealType: any;
  promoType: DealPromoType | null;
  setPromoType: (value: DealPromoType) => void;
  promoQrValue: string;
  setPromoQrValue: (value: string) => void;
  promoCodeValue: string;
  setPromoCodeValue: (value: string) => void;
  qrRef: any;
  disableRadioButtons: boolean;
  promotWithoutCode: boolean;
};

const FieldDealType: React.FC<Props> = ({
  DealType,
  promoType,
  setPromoType,
  promoQrValue,
  setPromoQrValue,
  promoCodeValue,
  setPromoCodeValue,
  qrRef,
  disableRadioButtons,
  promotWithoutCode,
}: Props) => {
  const {t} = useTranslation('createDeal');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setCreateDealData({
        type: DealType.Deal,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setCreateDealData({promotionCode: promoCodeValue}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodeValue]);

  const handleSetPromoType = (promoType: DealPromoType) => {
    setPromoType(promoType);
  };

  return (
    <>
      <FormField
        title={t('fieldDealTypeTitle')}
        description={t('fieldDealTypeDescription')}>
        <Disabled disabled={disableRadioButtons}>
          <DealTypeSelector
            currentSelection={promoType}
            setSelected={handleSetPromoType}
            promotWithoutCode={promotWithoutCode}
          />
        </Disabled>
        {(promoType === DealPromoType.QRCode ||
          promoType === DealPromoType.QRAndPromoCode) && (
          <FormField
            withBorder={false}
            title={t('fieldQrTitle')}
            description={t('fieldQrDescription')}>
            <TextField
              id={'qr-code'}
              type={'text'}
              value={promoQrValue}
              handleChange={e => setPromoQrValue(e.target.value)}
              maxLength={130}
              placeHolder={t('fieldQrPlaceholder')}
              hideLabel={true}
              customStyles={{input: {marginBottom: 16}}}
            />
            <div
              id="qr-code"
              ref={qrRef}
              style={promoQrValue === '' ? {opacity: 0.4} : {}}>
              <QRCode value={promoQrValue} size={184} />
            </div>
          </FormField>
        )}
        {(promoType === DealPromoType.PromoCode ||
          promoType === DealPromoType.QRAndPromoCode) && (
          <FormField
            withBorder={false}
            title={t('fieldCodeTitle')}
            description={t('fieldCodeDescription')}>
            <TextField
              id={'promo-code'}
              type={'text'}
              value={promoCodeValue}
              handleChange={e => setPromoCodeValue(e.target.value)}
              maxLength={130}
              placeHolder={t('fieldPromoCodePlaceholder')}
              hideLabel={true}
              customStyles={{}}
            />
            <div></div>
          </FormField>
        )}
      </FormField>
    </>
  );
};

export default FieldDealType;
