import React, {useCallback, useState} from 'react';
import {Form, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {TextField} from '../../../../components/TextField';
import WideButton from '../../../../components/WideButton';
import {LoginErrorMessage} from './LoginErrorMessage';
import validateEmail from '../../../../services/app/validations';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../../redux';
import {ErrorCodes} from '../../../../models/enums/errors';
import {LoginError} from './LoginError';
import WifiOff from '../../../../assets/icons/wifi-off.svg';
import AlertTriangle from '../../../../assets/icons/alert-triangle.svg';
import {
  clearAuthErrors,
  clearAuthUser,
} from '../../../../redux/actions/authActions';
import './styles.scss';

export interface IFormValues {
  email: string;
  password: string;
}

const LoginForm = () => {
  const {t} = useTranslation();
  const {error, errorCode} = useSelector((state: AppState) => state.auth);
  const formik = useFormikContext<IFormValues>();
  const dispatch = useDispatch();

  const [emailLabelIsHidden, setEmailLabelIsHidden] = useState<boolean>(
    formik?.values.email.length === 0,
  );
  const [passwordLabelIsHidden, setPasswordLabelIsHidden] = useState<boolean>(
    formik?.values.password.length === 0,
  );

  const handleChangeInterceptor = useCallback(
    (e: any) => {
      if (error) dispatch(clearAuthErrors());
      formik.handleChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error],
  );

  const submitIsValid = () => {
    return (
      formik && validateEmail(formik.values.email) && formik.values.password
    );
  };

  return (
    <div data-testid="login-form" className="login-form">
      {error &&
      errorCode !== ErrorCodes.userNotFound &&
      errorCode !== ErrorCodes.invalidCredentials &&
      errorCode !== ErrorCodes.invalidUserRole ? (
        <LoginError
          goBack={() => dispatch(clearAuthUser())}
          imgSrc={
            errorCode === ErrorCodes.networkError ? WifiOff : AlertTriangle
          }
          title={
            errorCode === ErrorCodes.networkError
              ? t('login:unpluggedErrorTitle')
              : t('login:defaultErrorTitle')
          }
          message={
            errorCode === ErrorCodes.networkError
              ? t('login:unpluggedErrorMessage')
              : t('login:defaultErrorMessage')
          }
          buttonLabel={
            errorCode === ErrorCodes.networkError
              ? t('global:tryAgain')
              : t('global:retry')
          }
          imageSize={{
            width: errorCode === ErrorCodes.networkError ? 40 : 40,
            height: errorCode === ErrorCodes.networkError ? 40 : 40,
          }}
        />
      ) : (
        <>
          <LoginErrorMessage />
          <Form>
            <TextField
              id="email"
              type="email"
              hideLabel={emailLabelIsHidden}
              value={formik?.values.email || ''}
              handleChange={handleChangeInterceptor}
              onFocus={() => setEmailLabelIsHidden(false)}
              onBlur={() =>
                setEmailLabelIsHidden(formik?.values.email.length === 0)
              }
              error={!formik?.isValid}
              placeHolder={t('login:email')}
              required={true}
              maxLength={50}
              customStyles={{
                container: {
                  marginBottom: 26,
                },
              }}
            />
            <TextField
              id="password"
              type="password"
              value={formik?.values.password || ''}
              handleChange={handleChangeInterceptor}
              onFocus={() => setPasswordLabelIsHidden(false)}
              onBlur={() =>
                setPasswordLabelIsHidden(formik?.values.password.length === 0)
              }
              error={!formik?.isValid}
              hideLabel={passwordLabelIsHidden}
              placeHolder={t('login:password')}
              maxLength={16}
              minLength={8}
              required={true}
              customStyles={{
                container: {
                  marginBottom: 38,
                },
              }}
            />
            <div className="bottom-container">
              <WideButton
                label={t('login:login')}
                onClick={formik?.submitForm}
                disabled={!submitIsValid()}
              />
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default LoginForm;
