import {
  IAddUserToBusinessRequest,
  IDeleteUserFromBusinessRequest,
  IGetBusinessUsersRequest,
} from '../../models/interfaces/business';
import {businessApi} from '../../services/api/businessAPI';
import {
  BusinessActionTypes,
  REMOVE_BUSINESS_USER,
  SET_BUSINESS_VENDOR_USER,
  SET_BUSSINESS_USERS,
} from '../types/businessTypes';
import {showPopup} from './uiActions';
import {t} from 'i18next';
import {EPopupType} from '../../models/enums/ui';

export const setBusinessVendors = (payload: any): BusinessActionTypes => ({
  type: SET_BUSINESS_VENDOR_USER,
  payload,
});
export const removeBusinessUser = (UserId: string): BusinessActionTypes => ({
  type: REMOVE_BUSINESS_USER,
  payload: UserId,
});
export const setBusinessUsers = (payload: {
  page: number;
  usersBusiness: any[];
}): BusinessActionTypes => ({
  type: SET_BUSSINESS_USERS,
  payload,
});

export const getUsersByBusinessRequest = (data: IGetBusinessUsersRequest) => {
  return async (dispatch: any) => {
    try {
      const response = await businessApi.getBusinessUsers(data);
      if (response.data) {
        dispatch(
          setBusinessUsers({
            page: response.data.currentPage,
            usersBusiness: response.data.results,
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const createUsersByBusinessRequest = (
  data: IAddUserToBusinessRequest,
) => {
  return async (dispatch: any) => {
    try {
      await businessApi.createBusinessUser(data);
      dispatch(showPopup(t('business:userSuccessfullyAdded')));
    } catch (error: any) {
      dispatch(
        showPopup(
          t(error.message ? error.message : t('error:internalError')),
          3000,
          EPopupType.Error,
        ),
      );
    }
  };
};
export const deleteUsersByBusinessRequest = (
  data: IDeleteUserFromBusinessRequest,
) => {
  return async (dispatch: any) => {
    try {
      await businessApi.deleteBusinessUser(data);
      dispatch(removeBusinessUser(data.UserId));
      dispatch(showPopup(t('business:userSuccessfullyDeleted')));
    } catch (error: any) {
      dispatch(
        showPopup(
          t(error.message ? error.message : t('error:internalError')),
          3000,
          EPopupType.Error,
        ),
      );
    }
  };
};
