import React from 'react';
import {EColors} from '../../models/enums/colors';
import {Loader} from '../Loader';
import {ReactComponent as AlertTriangle} from '../../assets/icons/alert-triangle.svg';
import {ReactComponent as Blocker} from '../../assets/icons/blocker.svg';
import {ReactComponent as Check} from '../../assets/icons/check-circle.svg';
import {ReactComponent as Close} from '../../assets/icons/close.svg';
import {ReactComponent as TrashAlt} from '../../assets/icons/trash-alt.svg';
import {TypeActionModal} from '../../models/enums/typeAction';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  setHideConfirmationModal,
  setHideUnsavedModal,
} from '../../redux/actions/uiActions';
import Button from '../Button';
import Modal from 'react-modal';
import './styles.scss';

type Props = {
  actionType?: TypeActionModal;
  confirmProfileAuth?: boolean;
  isCloseInOutsideClick?: boolean;
  isLoading?: boolean;
  disableButtonCancel?: boolean;
  onConfirmText?: string;
  onConfirmTextStyle?: object;
  modalIsOpen: boolean;
  subtitle: string;
  title: string;
  type: 'confirm' | 'alert' | 'delete' | 'blocker';
  onConfirm: () => void;
  setIsOpen: (value: boolean) => void;
};

const ModalConfirmation: React.FC<Props> = ({
  actionType,
  confirmProfileAuth,
  disableButtonCancel,
  isCloseInOutsideClick = true,
  isLoading,
  modalIsOpen,
  onConfirm,
  onConfirmText,
  onConfirmTextStyle,
  setIsOpen,
  subtitle,
  title,
  type,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleShowAgain = (e: any) => {
    if (actionType === TypeActionModal.Confirm) {
      dispatch(setHideConfirmationModal(e.target.checked));
    } else {
      dispatch(setHideUnsavedModal(e.target.checked));
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={isCloseInOutsideClick}
      style={customStyles}>
      {isLoading && <Loader />}
      <div
        style={{width: confirmProfileAuth ? 390 : 309, height: 285}}
        className="modal-confirmation-wrapper">
        <div onClick={closeModal} className="modal-confirmation-close-icon">
          <Close style={{marginTop: 22}} />
        </div>

        <div className="modal-confirmation-icon">
          {type === 'blocker' ? (
            <Blocker width={30} height={30} />
          ) : type === 'alert' ? (
            <AlertTriangle width={30} height={30} />
          ) : type === 'delete' ? (
            <TrashAlt width={30} height={30} />
          ) : (
            <Check width={36} height={36} />
          )}
        </div>

        <div className="modal-confirmation-title">{title}</div>

        <div
          className={`modal-confirmation-message ${
            confirmProfileAuth && 'soft-desc-text'
          }`}>
          {subtitle}
        </div>

        {actionType && (
          <div className="modal-dont-show-again">
            <input
              type="checkbox"
              name="dont-show-again"
              onClick={e => handleShowAgain(e)}
            />
            <span className="text">{t('global:dontShowAgain')}</span>
          </div>
        )}

        <div className="modal-confirmation-buttons">
          {!confirmProfileAuth && (
            <>
              {!disableButtonCancel && (
                <div className="modal-confirmation-button-cancel">
                  <Button label={t('global:cancel')} onClick={closeModal} />
                </div>
              )}
              <div
                className={
                  disableButtonCancel
                    ? 'modal-confirmation-button-confirm-no-margin'
                    : 'modal-confirmation-button-confirm'
                }>
                <Button
                  customStylesText={{color: EColors.colorWhite}}
                  customStylesWrapper={
                    onConfirmTextStyle
                      ? onConfirmTextStyle
                      : {
                          backgroundColor: EColors.colorBluePrimaryButtons,
                          border: 'none',
                          height: 48,
                        }
                  }
                  isLoading={isLoading}
                  label={onConfirmText ? onConfirmText : t('global:confirm')}
                  onClick={onConfirm}
                />
              </div>
            </>
          )}
          {confirmProfileAuth && (
            <div className="profile-edition-buttons-wrapper">
              <Button
                customStylesText={{color: EColors.colorWhite}}
                customStylesWrapper={{
                  backgroundColor: EColors.colorBluePrimaryButtons,
                  border: 'none',
                  height: 52,
                  width: 317,
                  marginTop: 30,
                }}
                isLoading={isLoading}
                label={t('global:understood')}
                onClick={closeModal}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: EColors.colorWhite,
    borderRadius: '16px',
    outline: 'none',
  },
  overlay: {
    background: 'rgba(23, 23, 32, 0.4)',
    zIndex: 2000,
  },
};

export default ModalConfirmation;
