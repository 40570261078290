export const SET_LIST_BUSINESS_USERS = 'SET_LIST_BUSINESS_USERS';
export const CLEAR_BUSINESS_STATE = 'CLEAR_BUSINESS_STATE';
export const SET_BUSINESS_VENDOR_USER = 'SET_BUSINESS_VENDOR_USER';
export const SET_BUSSINESS_USERS = 'SET_BUSSINESS_USERS';
export const REMOVE_BUSINESS_USER = 'REMOVE_BUSINESS_USER';

interface ISetListBusinessUsers {
  type: typeof SET_LIST_BUSINESS_USERS;
  payload: any;
}
interface ICleanBusinessState {
  type: typeof CLEAR_BUSINESS_STATE;
}

interface ISetBusinessVendorUser {
  type: typeof SET_BUSINESS_VENDOR_USER;
  payload: any;
}
interface IRemoveBusinessUser {
  type: typeof REMOVE_BUSINESS_USER;
  payload: string;
}
interface ISetBusinessUsers {
  type: typeof SET_BUSSINESS_USERS;
  payload: {
    page: number;
    usersBusiness: any[];
  };
}

export type BusinessActionTypes =
  | ISetListBusinessUsers
  | ISetBusinessVendorUser
  | ISetBusinessUsers
  | IRemoveBusinessUser
  | ICleanBusinessState;
