import React from 'react';
import './styles.scss';
import ExclamationCircle from '../../assets/icons/exclamation-circle.svg';

type Props = {
  text: string;
};
const MsgError: React.FC<Props> = ({text}) => {
  return (
    <div className="msg-error">
      <img className="msg-error-icon" src={ExclamationCircle} alt="error" />
      <span className="msg-error-text">{text}</span>
    </div>
  );
};

export default MsgError;
