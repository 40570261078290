import React, {useEffect, useState} from 'react';
import ArtIcon from '../../../../assets/icons/community-modern.svg';
import Community from '../../../../components/Community';
import ModernIcon from '../../../../assets/icons/community-modern.svg';
import FashionIcon from '../../../../assets/icons/community-fashion.svg';
import EclecticIcon from '../../../../assets/icons/community-shopping.svg';
import ShoppingIcon from '../../../../assets/icons/community-shopping.svg';
import NightlifeIcon from '../../../../assets/icons/community-fashion.svg';
import GlobalIcon from '../../../../assets/icons/community-global.svg';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import {useSteps} from '../../../../hooks/useSteps';
import {RootState} from '../../../../redux';
import {useTranslation} from 'react-i18next';
import {
  getFail,
  setErrorCode,
  setRegisterData,
} from '../../../../redux/actions/registerActions';
import {IResultCommunity} from '../../../../models/api/community';
import {IDataCommunityTags} from '../../../../models/interfaces/profile';
import {useDispatch, useSelector} from 'react-redux';
import './styles.scss';

const BusinessCommunities = () => {
  // Hooks
  const {t} = useTranslation('register');
  const {register, ui} = useSelector((state: RootState) => state);
  const {data} = register;
  const dispatch = useDispatch();
  const {incrementStep} = useSteps();

  // Sates
  const [communityId, setCommunityId] = useState<number | null>(
    data?.community || null,
  );
  const [selectedItem, setSelectedItem] = useState<number | null>(
    data?.community || null,
  );

  // Data
  const communityTags: IDataCommunityTags[] = [
    {
      flagler: [
        {
          image: ArtIcon,
          text: 'Art',
        },
        {
          image: NightlifeIcon,
          text: 'Nightlife',
        },
        {
          image: EclecticIcon,
          text: 'Eclectic',
        },
      ],
      wynwood: [
        {
          image: ShoppingIcon,
          text: 'Shopping',
        },
        {
          image: FashionIcon,
          text: 'Fashion',
        },
        {
          image: ModernIcon,
          text: 'Modern',
        },
      ],
      global: [
        {
          image: GlobalIcon,
          text: 'Global',
        },
      ],
    },
  ];

  // Effects
  useEffect(() => {
    setSelectedItem(communityId);
  }, [communityId]);

  // Handlers - Functions
  const handleSubmit = () => {
    dispatch(getFail(''));
    dispatch(setErrorCode(''));
    dispatch(
      setRegisterData({
        community: communityId,
      }),
    );
    incrementStep();
  };

  return (
    <div className="communities-form">
      {ui.communities.map((e: IResultCommunity, idx: number) => (
        <Community
          cityName={e.cityName}
          dataTags={Object.values(communityTags[0])[idx]}
          description={e.description || ''}
          id={e.id}
          isSelected={selectedItem === e.id}
          key={idx + 1}
          name={e.name}
          setCommunityId={setCommunityId}
        />
      ))}

      <span className="text-help">{t('communityHelpText')}</span>

      <PreviousNextButtons onNext={handleSubmit} nextDisabled={false} />
    </div>
  );
};

export default BusinessCommunities;
