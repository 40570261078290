import React from 'react';
import {ErrorTypes} from '../../models/enums/registerForm';
import {ITextFieldCustomStyles} from '../TextField/index';
import {useTranslation} from 'react-i18next';
import Error from '../../assets/icons/error.svg';
import './styles.scss';

type Props = {
  type: string;
  customStyles?: ITextFieldCustomStyles;
};

const ErrorsValidations: React.FC<Props> = ({type, customStyles}) => {
  const {t} = useTranslation('register');
  return (
    <div
      data-testid="login-error-message"
      className="login-error-message"
      style={customStyles?.generalContainer}>
      <img src={Error} alt="error" className="icon" />
      <span className="error-text">
        {type === ErrorTypes.InvalidEmailFormat
          ? t('errorValidationsEmailFormat')
          : type === ErrorTypes.EmailAlreadyAssociated
          ? t('errorValidationsEmailAlreadyAssociate')
          : type === ErrorTypes.InvalidPhoneNumber
          ? t('errorValidationsPhoneNumberFormat')
          : type === ErrorTypes.InvalidUrlFormat
          ? t('errorValidationsUrlFormat')
          : type === ErrorTypes.InvalidZipcodeFormat
          ? t('errorValidationsZipcodeFormat')
          : type === ErrorTypes.DoesntMatchZipcode
          ? t('errorValidationsDoesntMatchZipcode')
          : type === ErrorTypes.LocationDoesntExist
          ? t('errorValidationsDoesntLocationExist')
          : type === ErrorTypes.DoesntMatchPassword
          ? t('errorValidationsDoesntMatchPasswords')
          : type === ErrorTypes.DoesntMatchEmail
          ? t('errorValidationsDoesntMatchEmail')
          : type === ErrorTypes.InvalidCredentials
          ? t('errors:invalidPassword')
          : type === ErrorTypes.UsernameInUse
          ? t('errorValidationsEmailAlreadyAssociate')
          : null}
      </span>
    </div>
  );
};

export default ErrorsValidations;
