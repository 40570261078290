import React from 'react';
import {freePackageObject} from '../../constants/generals';
import {IPackage} from '../../models/api/packages';
import {PackageType} from '../../models/enums/typeAction';
import {useTranslation} from 'react-i18next';
import FormField from '../FormField/FormField';
import Package from './Package';

interface IProps {
  packageType: PackageType;
  packageList: IPackage[];
  currentPackageSelection: IPackage | null;
  onSelect: (selected: IPackage) => void;
}

const FormPackagesField = ({
  packageType,
  packageList,
  currentPackageSelection,
  onSelect,
}: IProps) => {
  const {t} = useTranslation();

  const validatePackage = packageList.filter(
    pkg => pkg.packageType === packageType,
  );

  return (
    <FormField
      title={t('shared:packageFieldTitle')}
      description={
        packageType === PackageType.Deal
          ? t('dealsPage:packageFieldDescription')
          : t('ads:packageFieldDescription')
      }>
      <div className="packages-list">
        {packageList.length > 0 &&
          packageList.map((item, index: number) => {
            if (item.packageType === packageType) {
              return (
                <Package
                  packageType={packageType}
                  data={item}
                  key={index}
                  onSelect={() => onSelect(item)}
                  selected={
                    packageList.length === 1 ||
                    (currentPackageSelection !== null &&
                      item.id === currentPackageSelection.id)
                  }
                />
              );
            }
          })}

        {validatePackage.length === 0 && (
          <Package
            data={freePackageObject(packageType)}
            key={1}
            onSelect={() => onSelect(freePackageObject(packageType))}
            packageType={packageType}
            selected
          />
        )}

        {!packageList.length && (
          <Package
            packageType={packageType}
            data={freePackageObject(packageType)}
            onSelect={() => onSelect(freePackageObject(packageType))}
            selected
          />
        )}
      </div>
    </FormField>
  );
};

export default FormPackagesField;
