import React from 'react';
import {AppState} from '../../../../../../redux';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import './styles.scss';

type Props = {
  menuIsOpen: 'open' | 'closed';
};

const UserInfo: React.FC<Props> = ({menuIsOpen}) => {

  const navigate = useNavigate();
  const {t} = useTranslation();
  const {name, email, profileImageUrl} = useSelector(
    (state: AppState) => state.user,
  );

  return (
    <div 
      className="user-info"
      onClick={() => navigate('/profile-edition')}
    >
      <img
        className="user-info-image"
        width={41.8}
        height={41.8}
        src={profileImageUrl}
        alt="user-info"
        data-tip={t('menu:profileEdition')}
        data-for={t('menu:profileEdition')}
      />
      {menuIsOpen === 'open' && (
        <div className="user-info-text">
          <span className="user-info-name">{name}</span>
          <span className="user-info-contact">{email}</span>
        </div>
      )}
    </div>
  );
};

export default UserInfo;
