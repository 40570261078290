import React from 'react';
import AppLogo from '../../../../components/AppLogo';
import pixelCalculator from '../../../../utils/pixelCalculator';
import './styles.scss';

interface IProps {
  children: any;
  isOpacity: boolean;
}

const LoginArea = ({children, isOpacity}: IProps) => {
  return (
    <div data-testid={'login-area'} className={`login-area-container`}>
      {isOpacity && <div className="overlay" />}
      <div className="logo-container">
        <AppLogo
          width={pixelCalculator({px: 177, axis: 'y'})}
          height={pixelCalculator({px: 71.73, axis: 'y'})}
        />
        ;
      </div>
      <div className="login-area">{children}</div>
    </div>
  );
};

export default LoginArea;
