import React from 'react';
import {AppState} from '../../redux';
import {EColors} from '../../models/enums/colors';
import {ETypeView} from '../../models/enums/typeListData';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import {ReactComponent as QuestionIcon} from '../../assets/icons/question.svg';
import {useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

type Props = {
  onClickBack: (value: boolean) => void;
  title: string;
  tooltipDescription?: string;
};

const HeaderPage: React.FC<Props> = ({
  onClickBack,
  title,
  tooltipDescription,
}) => {
  const {ui} = useSelector((state: AppState) => state);

  return (
    <div
      className={`header-page ${
        ui.currentView === ETypeView.Profile && 'profile-page-header-wrapper'
      }`}>
      <ChevronDown
        className=" header-page-icon-chevron"
        width={16}
        height={16}
        onClick={() => onClickBack(true)}
      />
      <span className="header-page-text">{title}</span>
      {tooltipDescription && (
        <>
          <QuestionIcon
            className=" header-page-icon-question"
            width={19}
            height={19}
            data-tip={tooltipDescription}
          />
          <ReactTooltip
            place="bottom"
            effect="solid"
            className=" header-page-tooltip"
            arrowColor={EColors.colorDarkBlue}
          />
        </>
      )}
    </div>
  );
};

export default HeaderPage;
