import React from 'react';
import {contactUs} from '../../../../constants/contactUs';
import {EColors} from '../../../../models/enums/colors';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button';
import CompleteTextField from '../../../../components/CompleteTextField';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

export interface IFormValues {
  subject: string;
  message: string;
}

const ContactUsForm: React.FC = () => {
  const formik = useFormikContext<IFormValues>();
  const {t} = useTranslation();

  const handleChangeSubject = (data: any) => {
    formik.setFieldValue('subject', data.value);
  };

  const handleChangeMessage = (message: any) => {
    formik.setFieldValue('message', message);
  };

  const submitIsValid = () => {
    if (!formik || !formik.values) {
      return false;
    }
    return formik?.values.subject && formik?.values.message;
  };

  return (
    <div className="container">
      <span className="title">{t('contactUs:title')}</span>
      <div className="container-form">
        <SelectField
          items={contactUs.map(c => ({
            label: t(`contactUs:${c.label}`),
            value: c.id,
          }))}
          label={t('contactUs:reason')}
          isEdit={true}
          placeHolder={t('contactUs:select')}
          defaultOption={formik?.values.subject}
          onChange={handleChangeSubject}
        />
        <span className="addNote">{t('contactUs:addNote')}</span>
        <CompleteTextField
          customStyles={{
            generalContainer: {height: '160px'},
            input: {height: '150px'},
          }}
          maxLength={1000}
          placeholder={t('contactUs:writeANote')}
          setValue={handleChangeMessage}
          showCounterWord
          type="textarea"
          value={formik?.values.message}
          withContentValidation={true}
          withCounter={true}
        />
        <div className="button">
          <Button
            label={t('contactUs:submit')}
            onClick={formik.handleSubmit}
            disable={!submitIsValid()}
            customStylesWrapper={{
              backgroundColor: EColors.colorBluePrimaryButtons,
              border: `solid 1px ${EColors.colorBluePrimaryButtons}`,
            }}
            customStylesText={{color: EColors.colorWhite}}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
