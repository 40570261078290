import React, {useEffect} from 'react';
import {setCreateDealData} from '../../../../../../redux/actions/dealActions';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import ButtonUploadImage from '../../../../../../components/ButtonUploadImage';
import FormField from '../../../../../../components/FormField/FormField';

type Props = {
  image: any;
  setImage: (value: any) => void;
};

const FieldImage: React.FC<Props> = ({image, setImage}: Props) => {
  const {t} = useTranslation('createDeal');
  const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      const imageURL = URL.createObjectURL(image);
      dispatch(
        setCreateDealData({
          images: [image],
          imagePreview: imageURL,
        }),
      );
    } else {
      dispatch(
        setCreateDealData({
          images: [],
          imagePreview: '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <FormField
      title={t('fieldImageTitle')}
      description={t('fieldImageDescription')}>
      <ButtonUploadImage
        acceptVideo={false}
        label={t('buttonFiles')}
        onClick={setImage}
      />
    </FormField>
  );
};

export default FieldImage;
