export const checkExtension = (value: string, validExtensions: string[]) => {
  const splitString = value.split('.');
  const extensionString = splitString[splitString.length - 1];
  let isValid = false;
  validExtensions.forEach(e => {
    if (e === `.${extensionString}`) {
      isValid = true;
    }
  });
  return isValid;
};
