import React, {useEffect, useState} from 'react';
import Title from '../Title';
import Subtitle from '../Subtitle';
import ButtonUploadImage from '../../../../../../components/ButtonUploadImage';
import DefaultProfilePicture from '../../../../../../assets/images/DefaultProfilePicture.png';
import {RootState} from '../../../../../../redux';
import {useTranslation} from 'react-i18next';
import {createImageFile} from '../../../../../../utils/createImageFile';
import {useDispatch, useSelector} from 'react-redux';
import {
  setHasErrors,
  setProfileData,
} from '../../../../../../redux/actions/profileActions';
import './styles.scss';

type Props = {
  labelBtnAdd: string;
  profileImage: any;
  setProfileImage: (value: any) => void;
};

const ProfilePicture: React.FC<Props> = ({
  profileImage,
  labelBtnAdd,
  setProfileImage,
}) => {
  // Hooks
  const {t} = useTranslation('register');
  const {data, hasErrors} = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  // state
  const [profileImagePreview, setProfileImagePreview] = useState<any>(
    data?.newProfileImage || DefaultProfilePicture,
  );

  // effects
  useEffect(() => {
    if (data.profileImage && data.profileImage.length) {
      setProfileImagePreview(data.profileImage);
      getImageFile();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.profileImage) {
      setProfileImagePreview(data.profileImage);
    } else {
      setProfileImagePreview(DefaultProfilePicture);
    }
  }, [data.profileImage]);

  useEffect(() => {
    if (profileImage) {
      const imageUrl = URL.createObjectURL(profileImage);
      setProfileImagePreview(imageUrl);

      dispatch(
        setProfileData({
          profileImage: imageUrl,
          newProfileImage: imageUrl,
        }),
      );

      dispatch(
        setHasErrors({
          ...hasErrors,
          profilePicture: false,
        }),
      );
    }
  }, [profileImage]);

  // Handlers - Functions
  const getImageFile = async () => {
    try {
      const imageFile = await createImageFile(
        data.profileImage,
        'profileImage',
      );
      setProfileImage(imageFile);
    } catch (e) {
      console.log('error');
    }
  };

  return (
    <div className="profile-picture-wrapper">
      <Title text={t('profileImageTitle')} />
      <Subtitle text={t('profileImageSubtitle')} />
      <div className="business-images-files">
        <img
          className="business-images-profile-picture"
          src={profileImagePreview}
          width={80}
          height={80}
          alt="buisiness-profile"
        />

        <div className="business-images-upload-button">
          <ButtonUploadImage
            acceptVideo={false}
            hideImageName={true}
            id={'upload-profile-image'}
            label={labelBtnAdd || t('chooseFiles')}
            onClick={setProfileImage}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilePicture;
