import {HEIGHT_DESIGN_SCREEN, WIDTH_DESIGN_SCREEN} from '../constants/design';
import {IPixelCalculator} from '../models/interfaces/design';


const pixelCalculator = (params: IPixelCalculator) => {
  
  const {innerWidth: width, innerHeight: height} = window;
  let percentDesign,
    b,
    c = 0;

  if (params.axis === 'y') {
    percentDesign = (params.px * 100) / HEIGHT_DESIGN_SCREEN;
    b = percentDesign / 100;
    c = b * height;
  } else {
    percentDesign = (params.px * 100) / WIDTH_DESIGN_SCREEN;
    b = percentDesign / 100;
    c = b * width;
  }

  return c;
};

export default pixelCalculator;
