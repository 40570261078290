import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IPanelFormProfile} from '../../../../models/interfaces/profile';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import MyBusiness from '../../../MyBusiness';
import MyInformation from '../../../../pages/ProfileEdition/components/MyInformation';
import ProfileEditionPanelTab from '../../../ProfileEditPanelTab';

type Props = {
  setShowPanelForm: (val: IPanelFormProfile) => void;
};

const MyProfile: React.FC<Props> = ({setShowPanelForm}) => {
  // Hooks
  const {t} = useTranslation();

  // States
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList className="tab-list">
        <Tab className="tab-component">
          <ProfileEditionPanelTab isSelected={tabIndex === 0}>
            {t('profilePage:tabMyinformation')}
          </ProfileEditionPanelTab>
        </Tab>

        <Tab className="tab-component">
          <ProfileEditionPanelTab isSelected={tabIndex === 1}>
            {t('profilePage:tabMybusinesses')}
          </ProfileEditionPanelTab>
        </Tab>
      </TabList>

      <TabPanel>
        <MyInformation setShowPanelForm={setShowPanelForm} />
      </TabPanel>

      <TabPanel>
        <MyBusiness />
      </TabPanel>
    </Tabs>
  );
};

export default MyProfile;
