import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {createImageFile} from '../../../../utils/createImageFile';
import {EColors} from '../../../../models/enums/colors';
import {EPopupType} from '../../../../models/enums/ui';
import {ErrorTypes} from '../../../../models/enums/registerForm';
import {getSocialMediaURL} from '../../../../utils/getSocialMediaURL';
import {getUuid} from '../../../../utils/getUuid';
import {IBusinessHours} from '../../../../models/interfaces/generals';
import {IBusinessSocialMedia} from '../../../../models/interfaces/reduxStates';
import {IResendVerifyEmail} from '../../../../models/interfaces/register';
import {registerApi} from '../../../../services/api/registerAPI';
import {showPopup} from '../../../../redux/actions/uiActions';
import {useDispatch, useSelector} from 'react-redux';
import {useSteps} from '../../../../hooks/useSteps';
import {useTranslation} from 'react-i18next';
import {
  getFail,
  sendRegisterData,
  setErrorCode,
  setStep,
} from '../../../../redux/actions/registerActions';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import useBusinessHours from '../../../../hooks/useBusinessHours';
import './styles.scss';

const styles = {
  enabledButton: {
    backgroundColor: EColors.colorBlueRegisterSteps,
    border: 'none',
    height: 46,
    width: 160,
  },
  disabledButton: {
    backgroundColor: EColors.colorGreenSuccess,
    border: 'none',
    cursor: 'not-allowed',
    height: 46,
    opacity: 0.6,
    width: 160,
  },
  textColor: {
    color: EColors.colorWhite,
  },
};

const AccountVerificationStep = () => {
  // Hooks
  const {decrementStep} = useSteps();
  const {t} = useTranslation('register');
  const dispatch = useDispatch();
  const {register, ui} = useSelector((state: AppState) => state);
  const {splitBusinessHours} = useBusinessHours(ui.timeZoneName);
  const stateRegister = register;

  // States
  const [hours, setHours] = useState<IBusinessHours[] | []>([]);
  const [imagesFile, setImagesFile] = useState<any>([]);
  const [profileImageFile, setProfileImageFile] = useState<any>('');
  const [sentButton, setSentButton] = useState<boolean>(false);
  const [socialMedia, setSocialMedia] = useState<IBusinessSocialMedia[]>([]);

  // Effects
  useEffect(() => {
    // Convert to file (Images)
    const files: any[] = [];
    stateRegister.data.images.forEach((image: any) => {
      const getImageFile = async () => {
        try {
          const file = await createImageFile(
            image,
            `businessImage-${getUuid()}`,
          );
          files.push(file);
        } catch (e) {
          console.log('error');
        }
      };

      getImageFile();
    });
    setImagesFile(files);

    // Convert to file (ProfileImage)
    if (stateRegister.data.profileImage) {
      const getProfileImageFile = async () => {
        try {
          const file = await createImageFile(
            stateRegister.data.profileImage,
            'profileImage',
          );

          setProfileImageFile(file);
        } catch (e) {
          console.log('error');
        }
      };
      getProfileImageFile();
    }

    // Hours
    const hoursFormatted: IBusinessHours[] = [];
    const splitedObject = splitBusinessHours(stateRegister.data.businessHours);
    splitedObject.length &&
      splitedObject.forEach((hour: IBusinessHours) => {
        if (hour.openTime && hour.closeTime) {
          const hourFormatted: IBusinessHours = {
            closeTime: hour.closeTime,
            id: hour.id,
            openTime: hour.openTime,
            weekDay: hour.weekDay,
          };
          hoursFormatted.push(hourFormatted);
        }
      });
    setHours(hoursFormatted);

    // Social Media
    const socialMediaURLFormated = stateRegister.data.businessSocialMedia.map(
      (socialMedia: IBusinessSocialMedia) => ({
        ...socialMedia,
        link: getSocialMediaURL(socialMedia.socialMediaType, socialMedia.link),
      }),
    );
    setSocialMedia(socialMediaURLFormated);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateRegister.success) {
      localStorage.setItem('registerUsername', stateRegister.data.username);
      dispatch(showPopup(t('dataSentSuccessfully')));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRegister.success]);

  useEffect(() => {
    switch (stateRegister.errorCode) {
      case ErrorTypes.UsernameInUse:
        dispatch(setStep(1));
        break;

      case ErrorTypes.BusinessNameInUse:
        dispatch(setStep(2));
        break;

      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRegister.errorCode]);

  // Fuctions
  const handleSendData = () => {
    if (!sentButton) {
      const {data} = stateRegister;
      const filterData = [data].map(
        ({
          username,
          password,
          phoneNumber,
          countryFlag,
          name,
          lastname,
          roleName,
          businessName,
          businessDescription,
          businessLocationType,
          countryAlpha2Code,
          webSiteUrl,
          contactEmail,
          contactPhoneNumber,
          contactCountryFlag,
          contactWebSiteUrl,
          subCategories,
          businessLocations,
          timeZone,
          community,
        }) => {
          return {
            username,
            password,
            phoneNumber,
            phoneNumberCountryCode: countryFlag,
            name,
            lastname,
            roleName,
            businessName,
            businessDescription,
            businessLocationType,
            countryAlpha2Code,
            webSiteUrl,
            contactEmail,
            contactName: name,
            contactLastname: lastname,
            contactPhoneNumber,
            contactPhoneNumberCountryCode: contactCountryFlag,
            contactWebSiteUrl,
            subCategories,
            images: imagesFile,
            profileImage: profileImageFile,
            businessLocations,
            businessHours: hours,
            businessSocialMedia: socialMedia,
            timeZone,
            community,
            acceptedToC: true,
          };
        },
      );

      // Send Data
      dispatch(sendRegisterData(filterData[0]));
      setSentButton(!sentButton);
    } else {
      return;
    }
  };
  const handleResendEmail = async (body: IResendVerifyEmail) => {
    try {
      const response: any = await registerApi.resendVerifyEmail(body);
      if (response) {
        dispatch(
          showPopup(
            t('global:generalMessages:successForwardedEmail'),
            3000,
            EPopupType.Success,
          ),
        );
        dispatch(getFail(''));
        dispatch(setErrorCode(''));
      }
    } catch (error: any) {
      dispatch(showPopup(error.message, 3000, EPopupType.Error));
      dispatch(getFail(error.message));
      dispatch(setErrorCode(error.errorCode));
    }
  };

  return (
    <div className="account-verification-step-form">
      <span className="account-verfication-step-description">
        {t('confirmYourEmail')}
      </span>

      <div className="account-verification-step-button">
        <Button
          customStylesWrapper={{
            width: '160px',
            height: '44px',
            marginRight: '15px',
            border: `1px solid ${EColors.colorBlueRegisterSteps}`,
          }}
          customStylesText={{color: EColors.colorBlueRegisterSteps}}
          label={t('previewButton')}
          onClick={() => {
            decrementStep();
          }}
        />
        {sentButton && (
          <LinkButton
            onClick={() =>
              handleResendEmail({email: stateRegister.data.username})
            }
            customStyle={{marginLeft: '33px', marginRight: '33px'}}>
            {t('resendEmailButton')}
          </LinkButton>
        )}
        <Button
          label={sentButton ? t('sentEmailButton') : t('sendEmailButton')}
          onClick={handleSendData}
          customStylesWrapper={
            sentButton ? styles.disabledButton : styles.enabledButton
          }
          customStylesText={styles.textColor}
        />
      </div>
    </div>
  );
};

export default AccountVerificationStep;
