import {AxiosRequestConfig, AxiosResponse} from 'axios';
import axiosConfig from './axiosConfig';

const genericGet = (
  url: string,
  token: string | null,
  requestConfig: AxiosRequestConfig = {},
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = token ? withToken(token, requestConfig) : {...requestConfig};
    return axiosConfig
      .get(url, config)
      .then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        if (error.response?.data) {
          reject(error.response.data);
        }
        reject(error);
      });
  });
};

const genericPost = (
  url: string,
  token: string | null,
  data: any = null,
  requestConfig: AxiosRequestConfig = {},
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = token ? withToken(token, requestConfig) : {...requestConfig};
    return axiosConfig
      .post(url, data, config)
      .then(response => {
        if (response && response.data) {
          resolve(response.data);
        }
        resolve({});
      })
      .catch(error => {
        if (error.response?.data) {
          reject(error.response.data);
        }

        reject(error);
      });
  });
};

const genericPut = (
  url: string,
  token: string | null,
  data: any = null,
  requestConfig: AxiosRequestConfig = {},
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = token ? withToken(token, requestConfig) : {...requestConfig};
    return axiosConfig
      .put(url, data, config)
      .then(response => {
        if (response && response.data) {
          resolve(response.data);
        }
        resolve({});
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
};

const genericDelete = (
  url: string,
  token: string | null,
  requestConfig: AxiosRequestConfig = {},
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = token ? withToken(token, requestConfig) : {...requestConfig};
    return axiosConfig
      .delete(url, config)
      .then((response: AxiosResponse) => {
        if (response && response.data) {
          resolve(response.data);
        }
        resolve({});
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
};

const withToken = (
  token: string,
  requestConfig: AxiosRequestConfig = {},
): AxiosRequestConfig => {
  if (!token) {
    return requestConfig;
  }

  const result = {...requestConfig};
  result.headers = {...result.headers, authorization: `Bearer ${token}`};
  return result;
};

export {genericGet, genericPost, genericPut, genericDelete};
