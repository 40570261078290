import React, {useEffect, useState} from 'react';
import AccountVerificationStep from './components/AccountVerificationStep';
import BusinessCommunities from './components/BusinessCommunities';
import BusinessContactInfoStep from './components/BusinessContactInfoStep';
import BusinessDescriptionStep from './components/BusinessDescriptionStep';
import BusinessHoursStep from './components/BusinessHoursStep';
import BusinessIdentificationStep from './components/BusinessIdentificationStep';
import BusinessImagesStep from './components/BusinessImagesStep';
import BussinessStoreTypeStep from './components/BusinessStoreTypeStep';
import CreateAccountStep from './components/CreateAccountStep';
import ImageStep1 from './../../assets/images/register/step-1.svg';
import ImageStep2 from './../../assets/images/register/step-2.svg';
import ImageStep3 from './../../assets/images/register/step-3.svg';
import ImageStep4 from './../../assets/images/register/step-4.svg';
import ImageStep5 from './../../assets/images/register/step-5.svg';
import ImageStep5Online from './../../assets/images/register/step-5-online.svg';
import ImageStep6 from './../../assets/images/register/step-6.svg';
import ImageStep7 from './../../assets/images/register/step-7.svg';
import ImageStep8 from './../../assets/images/register/step-8.svg';
import ProgressBar from '../../components/ProgressBar';
import StepTitle from './components/StepTitle';
import {DEFAULT_ROLE_NAME, NUMBER_OF_STEPS} from '../../constants/register';
import {EColors} from '../../models/enums/colors';
import {EMenuViews} from '../../models/enums/generals';
import {ETypeView} from '../../models/enums/typeListData';
import {ReactComponent as ImageLogo} from '../../assets/images/register/mana-logo.svg';
import {RootState} from '../../redux';
import {setCurrentView} from '../../redux/actions/uiActions';
import {StoreType} from '../../models/enums/registerForm';
import {useAnalytics} from '../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  clearRegisterData,
  setRegisterData,
} from '../../redux/actions/registerActions';
import {
  EAnalyticsCustomEventNames,
  EAnalyticsRegisterSteps,
} from '../../models/enums/analytics';
import './styles.scss';

const Register = () => {
  // hooks
  const {t} = useTranslation('register');
  const {step} = useSelector((store: RootState) => store.register);
  const {eventTrack} = useAnalytics();
  const dispatch = useDispatch();

  // state
  const [image, setImage] = useState<JSX.Element>(<ImageStep1 />);
  const [progress, setProgress] = useState<number>(0);
  const [stepComponent, setStepComponent] = useState<JSX.Element>();
  const [storeType, setStoreType] = useState<StoreType>(StoreType.Unselected);
  const [subtitle, setSubtitle] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  // Effects
  useEffect(() => {
    if (step === 1) {
      dispatch(clearRegisterData());
    }
    dispatch(setRegisterData({roleName: DEFAULT_ROLE_NAME}));
    dispatch(setCurrentView(ETypeView.Register));
    handleEventTrack(EAnalyticsCustomEventNames.PageView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storeType === StoreType.Online) {
      setImage(<ImageStep5Online />);
    } else {
      setImage(<ImageStep5 />);
    }
  }, [storeType]);

  useEffect(() => {
    //TODO: Improve this logic.
    const z = 100 / NUMBER_OF_STEPS;
    setProgress(z * step);
    switch (step) {
      case 1:
        setImage(<ImageStep1 />);
        setTitle(t('step1Title'));
        setSubtitle(t('step1Subtitle'));
        setStepComponent(<CreateAccountStep />);
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.UserInformation,
        );
        break;

      case 2:
        setImage(<ImageStep2 />);
        setTitle(t('step2Title'));
        setSubtitle(t('step2Subtitle'));
        setStepComponent(<BusinessIdentificationStep />);
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.BusinessInformation,
        );
        break;

      case 3:
        setImage(<ImageStep3 />);
        setTitle(t('step3Title'));
        setSubtitle(t('step3Subtitle'));
        setStepComponent(<BusinessDescriptionStep />);
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.BusinessDescription,
        );
        break;

      case 4:
        setImage(<ImageStep4 />);
        setTitle(t('step4Title'));
        setSubtitle(t('step4Subtitle'));
        setStepComponent(<BusinessImagesStep />);
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.BusinessImages,
        );
        break;

      case 5:
        setImage(<ImageStep5 />);
        setStepComponent(
          <BussinessStoreTypeStep
            setTitle={setTitle}
            setSubtitle={setSubtitle}
            setStoreType={setStoreType}
          />,
        );
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.BusinessTypeStore,
        );
        break;

      case 6:
        setImage(<ImageStep6 />);
        setTitle(t('stepCommunityTitle'));
        setSubtitle(t('stepCommunitySubtitle'));
        setStepComponent(<BusinessCommunities />);
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.BusinessCommunity,
        );
        break;

      case 7:
        setImage(<ImageStep6 />);
        setTitle(t('step6Title'));
        setSubtitle(t('step6Subtitle'));
        setStepComponent(<BusinessHoursStep />);
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.BusinessHours,
        );
        break;

      case 8:
        setImage(<ImageStep7 />);
        setTitle(t('step7Title'));
        setSubtitle(t('step7Subtitle'));
        setStepComponent(<BusinessContactInfoStep />);
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.BusinessContact,
        );
        break;

      case 9:
        setImage(<ImageStep8 />);
        setTitle(t('step8Title'));
        setSubtitle(t('step8Subtitle'));
        setStepComponent(<AccountVerificationStep />);
        handleEventTrack(
          EAnalyticsCustomEventNames.Register,
          EAnalyticsRegisterSteps.ConfirmYourEmail,
        );
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleEventTrack = (
    customEvent: EAnalyticsCustomEventNames,
    step?: EAnalyticsRegisterSteps,
  ) => {
    if (customEvent === EAnalyticsCustomEventNames.Register && step) {
      eventTrack(EAnalyticsCustomEventNames.Register, {
        register: {step},
      });
    } else {
      eventTrack(EAnalyticsCustomEventNames.PageView, {
        view: {page_view: EMenuViews.Register},
      });
    }
  };

  return (
    <div className="register-page">
      <div
        className="image-section"
        style={{backgroundImage: `url(${image.type})`}}>
        <ImageLogo />
      </div>

      <div className="form-section">
        <div className="form-content-fields">
          <ProgressBar
            bgColor={EColors.colorBlueCornflower}
            progress={progress}
            height={8}
          />

          <StepTitle title={title} subtitle={subtitle} />

          {stepComponent}
        </div>
      </div>
    </div>
  );
};

export default Register;
