import {IDeal} from '../../models/interfaces/deals';
import {
  CLEAR_DEAL_DATA,
  DealActionTypes,
  SET_CREATE_DEAL_DATA,
  SET_DEAL_ID,
} from '../types/dealTypes';

const initialState: IDeal = {
  communityId: null,
  conditions: [],
  endDate: '',
  files: [],
  id: 0,
  imagePreview: '',
  images: [],
  isPromotionWithoutCode: false,
  location: '',
  offerTag: '',
  packageId: 0,
  promotionCode: '',
  promoType: 0,
  qrCode: '',
  qrCodePreview: '',
  qrCodeValue: '',
  startDate: '',
  title: '',
  type: 0,
  vendorId: '',
  visibility: 0,
  webSiteUrl: '',
};

const dealReducer = (state = initialState, action: DealActionTypes): IDeal => {
  switch (action.type) {
    case SET_CREATE_DEAL_DATA:
      return {...state, ...action.payload};
    case SET_DEAL_ID:
      return {...state, id: action.payload};
    case CLEAR_DEAL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default dealReducer;
