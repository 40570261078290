import { IUserInformation } from '../../models/api/userInformation';
import { CLEAR_USER, FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_FAIL, FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_REQUEST, FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_SUCCESS, FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS, FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN, FORGOT_PASSWORD_PHONE_REQUEST_CODE, FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS, FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE, FORGOT_PASSWORD_SET_PHONE_CODE, SET_USER, UserActionTypes } from '../types/userTypes';

const initialState: IUserInformation = {
  birthdate: '',
  buildingId: 0,
  businessId: 0,
  communityId: null,
  email: '',
  id: '',
  isGuest: true,
  lastname: '',
  name: '',
  phoneNumber: '',
  phoneNumberCountryCode: 'USA',
  profileImageUrl: '',
  roles: [],
  status: null,
  forgotPasswordErrors: '',
  smsSent: false,
  emailSent: false,
  loading: false,
  phoneNumberRequest: '',
  emailRequest: '',
  isValidCode: false,
  forgotPasswordPhoneCode: ''
};

const userReducer = (
  state = initialState,
  action: UserActionTypes,
): IUserInformation => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    case CLEAR_USER:
      return initialState;
    case FORGOT_PASSWORD_PHONE_REQUEST_CODE:
      return {
        ...state,
        forgotPasswordErrors: '',
        smsSent: false,
        loading: true,
        phoneNumberRequest: action.payload,
      };
    case FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN:
      return {
        ...state,
        forgotPasswordErrors: '',
        emailSent: false,
        loading: true,
        emailRequest: action.payload,
      };
    case FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS:
      return {
        ...state,
        smsSent: true,
        loading: false,
        forgotPasswordErrors: '',
      };
    case FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS:
      return {
        ...state,
        emailSent: true,
        loading: false,
        forgotPasswordErrors: '',
      };

    case FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE:
      return {
        ...state,
        forgotPasswordErrors: '',
        smsSent: false,
        emailSent: false,
        loading: false,
        phoneNumberRequest: '',
        emailRequest: '',
        isValidCode: false,
        forgotPasswordPhoneCode: " "
      };
    case FORGOT_PASSWORD_SET_PHONE_CODE:
      return {
        ...state,
        isValidCode: false,
        forgotPasswordPhoneCode: action.payload,
      };
    case FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_REQUEST:
      return {
        ...state,
        isValidCode: false,
        loading: true,
      };
    case FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_SUCCESS:
      return {
        ...state,
        isValidCode: true,
        loading: false,
        forgotPasswordErrors: '',
      };
    case FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_FAIL:
      return {
        ...state,
        isValidCode: false,
        loading: false,
        forgotPasswordErrors: action.payload,
      };


    default:
      return state;
  }
};

export default userReducer;
