import {IAd} from '../../models/api/ads';
import {
  AdActionTypes,
  CLEAR_AD_DATA,
  SET_CREATE_AD_DATA,
  SET_AD_ID,
} from '../types/adTypes';

const initialState: IAd = {
  buttonLabel: '',
  buttonURL: '',
  communityId: null,
  description: '',
  endDate: '',
  headline: '',
  id: 0,
  imagePreview: '',
  images: [],
  filePreview: '',
  files: [],
  location: '',
  packageDescription: '',
  packageId: 0,
  packageName: '',
  startDate: '',
  status: '',
  title: '',
  vendorId: 0,
  vendorName: '',
  weeks: 1,
};

const adReducer = (state = initialState, action: AdActionTypes): IAd => {
  switch (action.type) {
    case SET_CREATE_AD_DATA:
      return {...state, ...action.payload};
    case SET_AD_ID:
      return {...state, id: action.payload};
    case CLEAR_AD_DATA:
      return initialState;
    default:
      return state;
  }
};

export default adReducer;
