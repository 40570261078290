import React, { useEffect } from 'react';
import RadioButton from '../../../../../../components/RadioButton';
import {DealPromoType} from '../../../../../../models/enums/dealPromoType';
import {useTranslation} from 'react-i18next';
import './styles.scss';

interface IProps {
  setSelected: (selection: DealPromoType) => void;
  currentSelection: DealPromoType | null; //Update to enum
  promotWithoutCode: boolean;
}

const DealTypeSelector = ({setSelected, currentSelection, promotWithoutCode}: IProps) => {

  const {t} = useTranslation('createDeal');

  useEffect(() => {
    if (currentSelection === 0) {
      setSelected(DealPromoType.PromoNoCode)
    }
  }, []);
  
  return (
    <div className="deal-type-selector">
      <RadioButton
        selected={currentSelection === DealPromoType.QRCode}
        onClick={() => setSelected(DealPromoType.QRCode)}
        label={<span>{ t('typeQr') }</span>}
        customStyle={{marginBottom: 34}}
      />
      <RadioButton
        selected={currentSelection === DealPromoType.PromoCode}
        onClick={() => setSelected(DealPromoType.PromoCode)}
        label={<span>{ t('typeCode') }</span>}
        customStyle={{marginBottom: 34}}
      />
      <RadioButton
        selected={currentSelection === DealPromoType.QRAndPromoCode}
        onClick={() => setSelected(DealPromoType.QRAndPromoCode)}
        label={<span>{ t('typeQrAndCode') }</span>}
        customStyle={{marginBottom: 34}}
      />
      <RadioButton
        selected={ promotWithoutCode ? true : currentSelection === DealPromoType.PromoNoCode }
        onClick={() => setSelected(DealPromoType.PromoNoCode)}
        label={<span>{ t('promoNoCode')}</span> }
        customStyle={{marginBottom: 34}}
      />
    </div>
  );
};

export default DealTypeSelector;
