import React, {useEffect, useState} from 'react';
import {EColors} from '../../models/enums/colors';
import {useDetectAdBlock} from 'adblock-detect-react';
import {useTranslation} from 'react-i18next';
import ModalConfirmation from '../ModalConfirmation';

type Props = {
  children: React.ReactNode;
};

const Blocker: React.FC<Props> = ({children}) => {
  const adBlockDetected = useDetectAdBlock();
  const {t} = useTranslation();
  const [isBlocked, setisBlocked] = useState(false);

  const closeModal = () => {
    setisBlocked(false);
  };

  useEffect(() => {
    if (adBlockDetected) {
      setisBlocked(true);
    }
  }, [adBlockDetected]);

  return (
    <>
      <ModalConfirmation
        modalIsOpen={isBlocked}
        setIsOpen={closeModal}
        onConfirm={closeModal}
        title={t('global:titleBlock')}
        disableButtonCancel={true}
        onConfirmText={t('global:confirmBlock')}
        onConfirmTextStyle={customStyles.textStyle}
        subtitle={t('global:descriptionBlock')}
        type="blocker"
      />

      {children}
    </>
  );
};

export default Blocker;
const customStyles = {
  textStyle: {
    backgroundColor: EColors.colorBluePrimaryButtons,
    border: 'none',
    width: '100%',
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
  },
};
