import React from 'react';
import './styles.scss';

type Props = {
  children?: React.ReactNode;
  customStyles?: object;
};

const SocialMedia: React.FC<Props> = ({ children, customStyles }: Props) => {

  return (
    <div className='social-media-form' style={customStyles && customStyles}>
        {children && children}
    </div>
  )
}

export default SocialMedia;
