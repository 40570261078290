import React from 'react';
import {ReactComponent as CheckValidation} from '../../assets/icons/check-validation.svg';
import './styles.scss';

type Props = {
  showValidCharacters: boolean;
  showValidUppercase: boolean;
  showValidNumber: boolean;
  showValidSpecialCharacter: boolean;
};

const PasswordSecurity: React.FC<Props> = ({
  showValidCharacters,
  showValidUppercase,
  showValidNumber,
  showValidSpecialCharacter,
}) => {
  return (
    <div className="password-security-container">
      {showValidCharacters ? (
        <span className="valid-characters">
          <CheckValidation className="check-validation" /> 7-16 characters
        </span>
      ) : (
        <span className="invalid-characters">7-16 characters</span>
      )}
      {showValidUppercase ? (
        <span className="valid-upparcase">
          <CheckValidation className="check-validation" /> 1 uppercase
        </span>
      ) : (
        <span className="invalid-upparcase">1 uppercase</span>
      )}
      {showValidNumber ? (
        <span className="valid-number">
          <CheckValidation className="check-validation" /> 1 number
        </span>
      ) : (
        <span className="invalid-number">1 number</span>
      )}
      {showValidSpecialCharacter ? (
        <span className="valid-special-character">
          <CheckValidation className="check-validation" /> 1 special character
        </span>
      ) : (
        <span className="invalid-special-character">1 special character</span>
      )}
    </div>
  );
};

export default PasswordSecurity;
