import {IResult} from '../models/api/deals';
import {DealType} from '../models/enums/dealType';
import {TableStatus} from '../models/enums/tableDeals';

export const sortResults = (
  key: string,
  order: string,
  setter: any,
  results?: IResult[] | null,
) => {
  let sorted: any = [];

  if (order === 'upwards') {
    sorted =
      results &&
      results.sort((a: any, b: any) => {
        if (key === 'type') {
          return DealType[a[key]].toUpperCase() < DealType[b[key]].toUpperCase()
            ? 1
            : -1;
        } else if (key === 'status') {
          return TableStatus[a[key]].toUpperCase() <
            TableStatus[b[key]].toUpperCase()
            ? 1
            : -1;
        } else if (key === 'endDate' || key === 'startDate') {
          return new Date(a[key]) < new Date(b[key]) ? 1 : -1;
        } else {
          return a[key].toUpperCase() < b[key].toUpperCase() ? 1 : -1;
        }
      });
  } else {
    sorted =
      results &&
      results.sort((a: any, b: any) => {
        if (key === 'type') {
          return DealType[a[key]].toUpperCase() > DealType[b[key]].toUpperCase()
            ? 1
            : -1;
        } else if (key === 'status') {
          return TableStatus[a[key]].toUpperCase() >
            TableStatus[b[key]].toUpperCase()
            ? 1
            : -1;
        } else if (key === 'endDate' || key === 'startDate') {
          return new Date(a[key]) > new Date(b[key]) ? 1 : -1;
        } else {
          return a[key] > b[key] ? 1 : -1;
        }
      });
  }
  setter(sorted);
};
