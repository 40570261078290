import React, {useState, useEffect} from 'react';
import InfoPage from '../../components/InfoPage';
import HeaderPage from './components/HeaderPage';
import CreateDealForm from './components/CreateDealForm';
import CreateDealPreview from './components/CreateDealPreview';
import ModalConfirmation from '../../components/ModalConfirmation';
import useWindowDimensions from '../../hooks/useWindowsDimensions';
import {AppState} from '../../redux';
import {IPackage} from '../../models/api/packages';
import {getDealById} from '../../services/api/dealsAPI';
import {getPackages} from '../../services/api/packageAPI';
import {useTranslation} from 'react-i18next';
import {TypeActionModal} from '../../models/enums/typeAction';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  setClearDealData,
  setCreateDealData,
} from '../../redux/actions/dealActions';
import './styles.scss';

const CreateDeal = () => {
  // Hooks
  const {t} = useTranslation('createDeal');
  const {id}: any = useParams();
  const {ui} = useSelector((state: AppState) => state);
  const {height} = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State
  const [isEditPage, setIsEditPage] = useState<boolean>(false);
  const [isPromotion, setIsPromotion] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [packageList, setPackageList] = useState<IPackage[]>([]);
  const [dealIdToEdit, setDealIdToEdit] = useState<number | null>(null);
  const [currentPackageSelection, setCurrentPackageSelection] =
    useState<IPackage | null>(null);

  // Effects
  useEffect(() => {
    getAllPackages();
  }, []);

  useEffect(() => {
    if (id) {
      setIsEditPage(true);
      setDealIdToEdit(id);
      const getDeal = async () => {
        try {
          const result = await getDealById(id);
          if (result.data) {
            // Package
            if (result.data.packageId) {
              const packageId = result.data.packageId;
              const packageData = packageList;
              const filterPackage = packageData.find(
                tier => tier.id === packageId,
              );

              if (filterPackage) {
                setCurrentPackageSelection(filterPackage);
              }
            }

            // Set all data
            dispatch(setCreateDealData(result.data));
            setIsPromotion(result.data?.isPromotionWithoutCode || false);

            // QR preview
            if (result.data.qrCode) {
              dispatch(setCreateDealData({qrCodePreview: result.data.qrCode}));
            }

            // Image preview
            if (result.data.files) {
              dispatch(
                setCreateDealData({imagePreview: result.data.files[0].url}),
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      getDeal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, packageList]);

  // Handlebars - Functions
  const getAllPackages = async () => {
    try {
      const result = await getPackages();
      if (result.data.length > 0) {
        setPackageList(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = () => {
    dispatch(setClearDealData());
    navigate('/home');
  };

  return (
    <div className="create-deal-page">
      <div className="create-deal-page-header-wrapper">
        {height > 719 ? (
          <InfoPage
            title={t('dealsPage:title')}
            item={isEditPage ? t('titleEdit') : t('title')}
          />
        ) : (
          <div style={{marginTop: 72}}></div>
        )}
        <HeaderPage
          onClick={ui.hideUnsavedModal ? goBack : setIsOpen}
          isEditPage={isEditPage}
        />
      </div>
      <div
        style={{height: height - 108}}
        className="create-deal-page-content-wrapper">
        <CreateDealForm
          id={dealIdToEdit}
          isEditPage={isEditPage}
          packageList={packageList}
          currentPackageSelection={currentPackageSelection}
          setCurrentPackageSelection={setCurrentPackageSelection}
        />
        <CreateDealPreview isPromotion={isPromotion} />
      </div>
      {modalIsOpen && (
        <ModalConfirmation
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          onConfirm={goBack}
          title={t('unsavedChanges')}
          subtitle={t('unsavedChangesMsg')}
          type="alert"
          actionType={TypeActionModal.Unsaved}
        />
      )}
    </div>
  );
};

export default CreateDeal;
