import React from 'react';
import './styles.scss';
import {ReactComponent as BlackChevronDown} from '../../../../../../assets/icons/black-chevron-down.svg';
type Props = {
  menuIsOpen: 'open' | 'closed';
  setMenuIsOpen: (value: 'open' | 'closed') => void;
};

const Toggle: React.FC<Props> = ({menuIsOpen, setMenuIsOpen}) => {
  const handleToggle = () => {
    if (menuIsOpen === 'closed') {
      setMenuIsOpen('open');
    } else {
      setMenuIsOpen('closed');
    }
  };
  return (
    <div className="left-menu-toggle" onClick={() => handleToggle()}>
      <BlackChevronDown
        className={
          menuIsOpen === 'open'
            ? 'left-menu-toggle-icon-left'
            : 'left-menu-toggle-icon-right'
        }
        width={8}
        height={8}
      />
    </div>
  );
};

export default Toggle;
