import React, {useEffect, useState} from 'react';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {EMenuViews, ETabsViews} from '../../../../models/enums/generals';
import {setProfileData} from '../../../../redux/actions/profileActions';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import BusinessImages from './components/BusinessImages';
import CoverPicture from '../../../../components/CoverPicture';
import ProfilePicture from './components/ProfilePicture';
import './styles.scss';

const BusinessImagesProfile = () => {
  // Hooks
  const {t} = useTranslation();
  const {eventTrack} = useAnalytics();
  const dispatch = useDispatch();

  // state
  const [images, setImages] = useState<any>([]);
  const [profileImage, setProfileImage] = useState<any>();

  useEffect(() => {
    dispatch(setProfileData({showSaveBtns: true}));
    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {
        current_tab_view: ETabsViews.BusinessImages,
        page_view: EMenuViews.Profile,
        sub_page_view: EMenuViews.MyBusiness,
      },
    });
  }, []);

  return (
    <div className="business-images-wrapper">
      <ProfilePicture
        labelBtnAdd={t('profilePage:changeProfilePicture')}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
      />

      <CoverPicture />

      <BusinessImages
        images={images}
        labelBtnAdd={t('profilePage:addPictures')}
        setImages={setImages}
      />
    </div>
  );
};

export default BusinessImagesProfile;
