import {AxiosResponse} from 'axios';
import {IPackage} from '../../models/api/packages';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericGet} from './genericAPI';

export const getPackages = async (): Promise<AxiosResponse<IPackage[]>> => {
  const token = store.getState().auth.token;
  return await genericGet(endpoints.package.list, token);
};
