import {ETableLUsersHeads, ETableTypeHeads} from '../models/enums/tableDeals';
import {IActionOptions} from '../models/interfaces/list';
import {ITableItems} from '../models/interfaces/table';

export const daysBeforeTheEndDate = 2;

export const dealsItems = [
  {name: 'title', label: 'dealsPage:headerTitle', type: ETableTypeHeads.Info},
  {name: 'packageName', label: 'ads:headerPackage', type: ETableTypeHeads.Info},
  {
    name: 'startDate',
    label: 'dealsPage:headerFrom',
    type: ETableTypeHeads.Date,
  },
  {
    name: 'endDate',
    label: 'dealsPage:headerEndsOn',
    type: ETableTypeHeads.Date,
  },
  {
    name: 'status',
    label: 'dealsPage:headerStatus',
    type: ETableTypeHeads.Status,
  },
  {
    name: 'options',
    label: 'dealsPage:headerActions',
    type: ETableTypeHeads.Button,
  },
];

export const adsItems = [
  {name: 'title', label: 'dealsPage:headerTitle', type: ETableTypeHeads.Info},
  {
    name: 'packageName',
    label: 'ads:headerPackage',
    type: ETableTypeHeads.Info,
  },
  {
    name: 'startDate',
    label: 'dealsPage:headerFrom',
    type: ETableTypeHeads.Date,
  },
  {
    name: 'endDate',
    label: 'dealsPage:headerEndsOn',
    type: ETableTypeHeads.Date,
  },
  {
    name: 'status',
    label: 'dealsPage:headerStatus',
    type: ETableTypeHeads.Status,
  },
  {
    name: 'options',
    label: 'dealsPage:headerActions',
    type: ETableTypeHeads.Button,
  },
];

export const actionsOptions: IActionOptions[] = [
  {name: 'global:edit', value: 'edit'},
  {name: 'global:delete', value: 'delete'},
];

export const optionsHeadTableBusinessUsers: ITableItems[] = [
  {
    label: 'business:fieldTableName',
    name: ETableLUsersHeads.Name,
    type: ETableTypeHeads.Info,
  },
  {
    label: 'business:fieldTableRole',
    name: ETableLUsersHeads.Role,
    type: ETableTypeHeads.Info,
  },
  {
    label: 'business:fieldTableEmail',
    name: ETableLUsersHeads.Email,
    type: ETableTypeHeads.Info,
  },
  {
    label: 'business:fieldTableStatus',
    name: ETableLUsersHeads.Status,
    type: ETableTypeHeads.Status,
  },
  {
    label: 'business:fieldTableActions',
    name: ETableLUsersHeads.Status,
    type: ETableTypeHeads.Button,
  },
];
