import endpoints from './endpoints';
import {AxiosResponse} from 'axios';
import {genericGet, genericPost} from './genericAPI';
import {
  IConfirmEmail,
  IRegisterBody,
  IResendVerifyEmail,
} from '../../models/interfaces/register';

const businessIdentification = (
  languageCode: string,
): Promise<AxiosResponse> => {
  return genericGet(
    `${endpoints.business.businessIdentification}/${languageCode}`,
    '',
    {},
  );
};

const communities = (): Promise<AxiosResponse> => {
  return genericGet(`${endpoints.communities.list}`, '');
};

const countries = (): Promise<AxiosResponse> => {
  return genericGet(`${endpoints.countries.countries}`, '', {});
};

const userEmail = (email: string): Promise<AxiosResponse> => {
  return genericPost(
    `${endpoints.validateUserEmail.userEmail}${email}`,
    '',
    {},
  );
};

const sendRegisterData = (data: IRegisterBody): Promise<AxiosResponse> => {
  data.BusinessHoursJson = JSON.stringify(data.businessHours);
  data.BusinessSocialMediaJson = JSON.stringify(data.businessSocialMedia);
  data.BusinessLocationsJson = JSON.stringify(data.businessLocations);

  const fdata = parsePostToFormData(data);

  return genericPost(`${endpoints.sendRegisterData.registerData}`, '', fdata, {
    headers: {contentType: 'multipart/form-data;'},
  });
};

const parsePostToFormData = (body: IRegisterBody): FormData => {
  const data = new FormData();

  if (body.username) data.append('Username', body.username);

  if (body.password) data.append('Password', body.password);

  if (body.phoneNumber) data.append('PhoneNumber', body.phoneNumber.toString());

  if (body.phoneNumberCountryCode)
    data.append('PhoneNumberCountryCode', body.phoneNumberCountryCode);

  if (body.name) data.append('Name', body.name);

  if (body.lastname) data.append('Lastname', body.lastname);

  if (body.roleName) data.append('RoleName', body.roleName);

  if (body.businessName) data.append('BusinessName', body.businessName);

  if (body.businessDescription)
    data.append('BusinessDescription', body.businessDescription);

  if (body.businessLocationType)
    data.append('BusinessLocationType', body.businessLocationType.toString());

  if (body.countryAlpha2Code)
    data.append('CountryAlpha2Code', body.countryAlpha2Code);

  if (body.webSiteUrl) data.append('WebSiteUrl', body.webSiteUrl);

  if (body.contactEmail) data.append('ContactEmail', body.contactEmail);

  if (body.contactName) data.append('ContactName', body.contactName);

  if (body.contactLastname)
    data.append('ContactLastname', body.contactLastname);

  if (body.contactPhoneNumber)
    data.append('ContactPhoneNumber', body.contactPhoneNumber);

  if (body.contactPhoneNumberCountryCode)
    data.append(
      'ContactPhoneNumberCountryCode',
      body.contactPhoneNumberCountryCode,
    );

  if (body.contactWebSiteUrl)
    data.append('ContactWebSiteUrl', body.contactWebSiteUrl);

  if (body.subCategories) {
    body.subCategories.forEach((subCategory: any) => {
      data.append('SubCategories[]', subCategory);
    });
  }

  if (body.images) {
    for (let i = 0; i < body.images.length; i++) {
      data.append('Images', body.images[i]);
    }
  }

  if (body.profileImage) data.append('ProfileImage', body.profileImage);

  if (body.businessLocations) {
    body.businessLocations.forEach((location: any) => {
      data.append('BusinessLocations[]', location);
    });
  }

  if (body.businessHours) {
    body.businessHours.forEach((hours: any) => {
      data.append('BusinessHours[]', hours);
    });
  }

  if (body.businessSocialMedia) {
    body.businessSocialMedia.forEach((socialMedia: any) => {
      data.append('BusinessSocialMedia[]', socialMedia);
    });
  }

  if (body.BusinessHoursJson)
    data.append('BusinessHoursJson', body.BusinessHoursJson);

  if (body.BusinessLocationsJson)
    data.append('BusinessLocationsJson', body.BusinessLocationsJson);

  if (body.BusinessSocialMediaJson)
    data.append('BusinessSocialMediaJson', body.BusinessSocialMediaJson);

  if (body.timeZone) {
    data.append('TimeZone', body.timeZone);
  }

  if (body.community) {
    data.append('CommunityId', body.community.toString());
  }

  if (body.acceptedToC) {
    data.append('AcceptedToC', body.acceptedToC.toString());
  }

  if (body?.invitationBusinessId) {
    data.append('InvitationBusinessId', body.invitationBusinessId);
  }

  if (body?.invitationToken) {
    data.append('InvitationToken', body.invitationToken);
  }

  return data;
};

const sendConfirmEmail = (data: IConfirmEmail): Promise<AxiosResponse> => {
  return genericPost(
    `${endpoints.sendRegisterData.confirmEmail}`,
    '',
    data,
    {},
  );
};

const resendVerifyEmail = (
  data: IResendVerifyEmail,
): Promise<AxiosResponse> => {
  return genericPost(`${endpoints.sendRegisterData.resendEmail}`, '', data, {});
};

export const registerApi = {
  businessIdentification,
  communities,
  countries,
  resendVerifyEmail,
  sendConfirmEmail,
  sendRegisterData,
  userEmail,
};
