import {EColors} from '../models/enums/colors';

export const buttonsStyles = {
  disabledButton: {
    cursor: 'not-allowed',
    opacity: 0.3,
  },
};

export const modalPreviewStyles = {
  background: EColors.colorGrayLight,
  border: 0,
  borderRadius: '25px',
  bottom: 'auto',
  left: '50%',
  marginRight: '-50%',
  maxHeight: '90%',
  padding: 17,
  right: 'auto',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export const imgPasswordFieldStyles = {
  password: {
    img: {
      float: 'right',
      left: '0',
      marginRight: '10px',
    },
  },
};

export const formBasicStyles = {
  wideButton: {
    enabled: {width: '100%', backgroundColor: EColors.colorBlueRegisterSteps},
    disabled: {width: '100%', backgroundColor: EColors.colorGrayishBlue},
  },
  inputField: {
    generalContainer: {
      width: '100%',
      marginLeft: '10px',
    },
    container: {
      marginBottom: 0,
    },
    input: {
      height: '38px',
    },
  },
  selectField: {
    generalContainer: {
      marginBottom: '0',
      width: '85px',
    },
  },
  linkButton: {
    color: EColors.colorDarkBlue,
    marginTop: '49px',
    marginBottom: '95px',
  },
};

export const selectFieldStyles = {
  selectField: {
    generalContainer: {
      marginTop: '25px',
      marginBottom: '0',
      width: '257px',
    },
  },
};
