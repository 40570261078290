import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StoreType} from '../../../../models/enums/registerForm';
import BusinessPhysicalStep from '../BusinessPhysicalStep';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import BusinessOnlineStep from '../BusinessOnlineStep';
import OptionsStoreType from './components/OptionsStoreType';
import './styles.scss';

type Props = {
  setTitle: (value: string) => void;
  setSubtitle: (value: string) => void;
  setStoreType: (value: StoreType) => void;
};

const BusinessStoreTypeStep: React.FC<Props> = ({
  setTitle,
  setSubtitle,
  setStoreType,
}) => {
  // Hooks
  const {t} = useTranslation('register');

  // State
  const [optionStoreType, setOptionStoreType] = useState<StoreType>(
    StoreType.Unselected,
  );

  // Fuctions
  const handleNext = () => {
    console.log('Send data to State');
  };

  const handleSetStoreType = (storeType: StoreType) => {
    setOptionStoreType(storeType);
  };

  // Effects
  useEffect(() => {
    if (optionStoreType === StoreType.Unselected) {
      setTitle(t('step5Title'));
      setSubtitle(t('step5Subtitle'));
      setStoreType(StoreType.Unselected);
    } else if (optionStoreType === StoreType.Online) {
      setTitle(t('step5OnlineTitle'));
      setSubtitle('');
      setStoreType(StoreType.Online);
    } else {
      setTitle(t('step5PhysicalTitle'));
      setSubtitle(t('step5PhysicalSubtitle'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionStoreType]);

  return (
    <div className="business-store-type-step-form">
      {optionStoreType === StoreType.Unselected && (
        <>
          <OptionsStoreType
            setSelected={handleSetStoreType}
            currentSelection={optionStoreType}
          />

          <PreviousNextButtons
            onNext={handleNext}
            nextDisabled={!optionStoreType}
          />
        </>
      )}

      {optionStoreType === StoreType.Physical && (
        <BusinessPhysicalStep setBackDisplay={setOptionStoreType} />
      )}

      {optionStoreType === StoreType.Online && (
        <BusinessOnlineStep setBackDisplay={setOptionStoreType} />
      )}
    </div>
  );
};

export default BusinessStoreTypeStep;
