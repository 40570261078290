import React from 'react';
import Table from '../Table';
import {AppState} from '../../redux';
import {useSelector} from 'react-redux';
import {ITableItems} from '../../models/interfaces/table';
import {TypeListData} from '../../models/enums/typeListData';
import {useTranslation} from 'react-i18next';
import {
  ETableFieldAlignment,
  ETableLocationsHeads,
  ETableTypeHeads,
} from '../../models/enums/tableDeals';
import './styles.scss';

type Props = {
  handleEditLocation: (val: number) => void;
  handleRemoveLocation: (val: number) => void;
  handleSelectLocation: (val: number) => void;
};

const MyAddresses: React.FC<Props> = ({
  handleEditLocation,
  handleRemoveLocation,
  handleSelectLocation,
}) => {
  // Hooks
  const stateProfile = useSelector((state: AppState) => state.profile);
  const {t} = useTranslation();

  // Data
  const optionsHeadTable: ITableItems[] = [
    {
      label: t('profilePage:locationFieldTableNickname'),
      name: ETableLocationsHeads.Nickname,
      type: ETableTypeHeads.Location,
    },
    {
      label: t('profilePage:locationFieldTableFullAddress'),
      name: ETableLocationsHeads.FullAddress,
      type: ETableTypeHeads.Location,
    },
    {
      alignment: ETableFieldAlignment.Middle,
      label: t('profilePage:locationFieldTableMainLocation'),
      name: ETableLocationsHeads.MainLocation,
      type: ETableTypeHeads.Button,
    },
    {
      label: t('global:actions'),
      name: ETableLocationsHeads.LocationActions,
      type: ETableTypeHeads.Button,
    },
  ];

  return (
    <div className="main-location-container">
      <span className="title-text">{t('profilePage:myAddressesTitle')}</span>
      <Table
        data={stateProfile.data.businessLocations}
        itemsDescription={optionsHeadTable}
        typeAction={TypeListData.Location}
        onEditItem={handleEditLocation}
        onRemoveItem={handleRemoveLocation}
        onSetDefaultItem={handleSelectLocation}
      />
    </div>
  );
};

export default MyAddresses;
