import React from 'react';
import './styles.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  customStyle?: object;
  onClick: () => void;
};

const LinkButton: React.FC<Props> = ({
  children,
  className = '',
  customStyle,
  onClick,
}) => {
  return (
    <span
      onClick={onClick}
      className={`create-account-link-button ${className}`}
      style={customStyle}>
      {children}
    </span>
  );
};

export default LinkButton;
