import {Formik} from 'formik';
import {RootState} from '../../redux';
import {useAuth} from '../../hooks/useAuth';
import {useSelector} from 'react-redux';
import ContactUsForm from './components/ContactUsForm';
import LeftMenu from '../Home/components/LeftMenu';
import React from 'react';
import './styles.css';

export interface IFormValues {
  subject: string;
  message: string;
}

const ContactUs: React.FC = () => {
  const {email} = useSelector((state: RootState) => state.user);
  const {addContactus} = useAuth();

  return (
    <div
      data-testid={'contact-us-page'}
      className="contact-us-page"
      id="layout">
      <LeftMenu />

      <div className="main" id="main">
        <Formik
          initialValues={{subject: '', message: ''}}
          validate={values => {
            const errors: {
              subject?: string;
              message?: string;
            } = {};
            if (!values.subject) {
              errors.subject = 'Required';
            }
            if (!values.message) {
              errors.message = 'Required';
            }
            return errors;
          }}
          onSubmit={(values: any, {setFieldValue}) => {
            addContactus({
              ContactEmail: email,
              text: values.message,
              contactUsRequestType: values.subject,
            });
            setFieldValue('message', '');
          }}>
          {() => <ContactUsForm />}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUs;
