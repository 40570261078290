export enum TypeAction {
    Add = 'ADD',
    Edit = 'EDIT'
}

export enum PackageType {
    Ad = 1,
    Deal,
}

export enum TypeActionModal {
    Confirm = 'CONFIRMATION',
    Unsaved = 'UNSAVED',
}