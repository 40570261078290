import React from 'react';
import CheckBox from '../CheckBox';
import './styles.scss';
interface IProps {
  label: string;
  status: boolean;
  toggleStatus: () => void;
  styles?: {label: object};
}
const CheckBoxWithLabel = ({label, status, toggleStatus, styles}: IProps) => {
  return (
    <div className="checkbox-with-label">
      <CheckBox status={status} toggleStatus={toggleStatus} />
      <span className="label" style={styles ? styles.label : {}}>
        {label}
      </span>
    </div>
  );
};

export default CheckBoxWithLabel;
