import React, {useEffect} from 'react';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {EColors} from '../../../../models/enums/colors';
import {EMenuViews} from '../../../../models/enums/generals';
import {getFail, setErrorCode} from '../../../../redux/actions/registerActions';
import {IResendVerifyEmail} from '../../../../models/interfaces/register';
import {ReactComponent as ImageLogo} from '../../../../assets/images/register/mana-logo.svg';
import {registerApi} from '../../../../services/api/registerAPI';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button';
import ImageStep8 from './../../../../assets/images/register/step-8.svg';
import ProgressBar from '../../../../components/ProgressBar';
import StepTitle from '../StepTitle';
import './styles.scss';

const RequestNewVerificationLink: React.FC = () => {
  // Hooks
  const {t} = useTranslation('register');
  const {eventTrack} = useAnalytics();
  const dispatch = useDispatch();

  // Data
  const image = <ImageStep8 />;

  // Effects
  useEffect(() => {
    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {page_view: EMenuViews.NewVerificationLink},
    });
  }, []);

  // Handlers
  const handleResendEmail = async (body: IResendVerifyEmail) => {
    try {
      const response: any = await registerApi.resendVerifyEmail(body);
      if (response) {
        console.log('Ok response ResendEmail');
        dispatch(getFail(''));
        dispatch(setErrorCode(''));
      }
    } catch (error: any) {
      console.log('Error response ResendEmail');
      dispatch(getFail(error.message));
      dispatch(setErrorCode(error.errorCode));
    }
  };

  return (
    <div className="register-page">
      <div
        className="image-section"
        style={{backgroundImage: `url(${image.type})`}}>
        <ImageLogo />
      </div>

      <div className="form-section">
        <div className="form-content-fields">
          <ProgressBar
            bgColor={EColors.colorBlueCornflower}
            progress={100}
            height={8}
          />

          <StepTitle
            title={t('global:oops')}
            subtitle={t('requestNewVerificationLink')}
          />

          <div className="request-new-link">
            <Button
              label={t('requestNewLinkBtn')}
              onClick={() => {
                const username = localStorage.getItem('registerUsername');
                if (username) {
                  handleResendEmail({
                    email: username,
                  });
                  eventTrack(EAnalyticsCustomEventNames.NewVerificationLink, {
                    new_verification_link: {username: username},
                  });
                }
              }}
              customStylesWrapper={{
                backgroundColor: EColors.colorBlueRegisterSteps,
                border: 'none',
                height: 46,
                width: 160,
              }}
              customStylesText={{color: EColors.colorWhite}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestNewVerificationLink;
