import React from 'react';
import './styles.scss';

interface IProps {
  label: string;
  onClick: any;
  disabled: boolean;
  customStyle?: object;
}
const WideButton = ({label, onClick, disabled, customStyle}: IProps) => {
  return (
    <div
      data-testid={'wide-button'}
      className={`wide-button ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      style={customStyle}
    >
      <span data-testid="wide-button-label">{label}</span>
    </div>
  );
};

export default WideButton;
