// PROFILE DATA
export const CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_PROFILE_DATA_COORDENATES = 'SET_PROFILE_DATA_COORDENATES';
export const SUCCESS_PROFILE_DATA = 'SUCCESS_PROFILE_DATA';

// GENERAL
export const GET_FAIL = 'GET_FAIL';
export const GET_REQUEST = 'GET_REQUEST';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
export const SET_HAS_ERROR = 'SET_HAS_ERROR';
export const SET_PENDING_PROFILE_AUTHORIZATION =
  'SET_PENDING_PROFILE_AUTHORIZATION';
export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';

// BUSINESS IDENTIFICATION
export const GET_BUSINESS_IDENTIFICATION_SUCCESS =
  'GET_BUSINESS_IDENTIFICATION_SUCCESS';

// COUNTRIES
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';

// COMUNITIES
export const GET_COMMUNITIES_SUCCESS = 'GET_COMMUNITIES_SUCCESS';

// SEND DATA
export const SEND_PROFILE_DATA = 'SEND_PROFILE_DATA';

interface ISetProfileData {
  type: typeof SET_PROFILE_DATA;
  payload: object;
}

interface IClearProfileData {
  type: typeof CLEAR_PROFILE_DATA;
}

interface ISetProfileDataCoordenates {
  type: typeof SET_PROFILE_DATA_COORDENATES;
  payload: object;
}

interface ISendProfileData {
  type: typeof SEND_PROFILE_DATA;
  payload: object;
}

export type ProfileActionTypes =
  | IClearProfileData
  | ISendProfileData
  | ISetProfileData
  | ISetProfileDataCoordenates;
