import React from 'react';
import {ReactComponent as QrCodeIcon} from '../../assets/icons/qrCode.svg';
import {ELoadingMotionColors} from '../../models/enums/colors';
import LoaderAnimate from '../LoaderAnimate';
import './styles.scss';

type Props = {
  className?: string;
  customStylesText?: object;
  customStylesWrapper?: object;
  disable?: boolean;
  isLoading?: boolean;
  label: string;
  onClick: any;
  type?: 'qr';
};

const Button: React.FC<Props> = ({
  className = '',
  customStylesText,
  customStylesWrapper,
  disable = false,
  isLoading,
  label,
  onClick,
  type,
}) => {
  return (
    <div
      className={`button-wrapper ${className} ${disable && 'disable'}`}
      onClick={() => !disable && onClick()}
      style={customStylesWrapper && customStylesWrapper}>
      {type === 'qr' && <QrCodeIcon className="button-icon" />}
      <span
        className="button-text"
        style={customStylesText && customStylesText}>
        {isLoading ? (
          <LoaderAnimate
            color={ELoadingMotionColors.White}
            customStyles={{marginTop: -40}}
          />
        ) : (
          label
        )}
      </span>
    </div>
  );
};

export default Button;
