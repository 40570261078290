import {
  CLEAR_DEAL_DATA,
  DealActionTypes,
  SET_CREATE_DEAL_DATA,
} from '../types/dealTypes';

export const setCreateDealData = (payload: object): DealActionTypes => ({
  type: SET_CREATE_DEAL_DATA,
  payload,
});

export const setClearDealData = (): DealActionTypes => ({
  type: CLEAR_DEAL_DATA,
});
