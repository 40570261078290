import {AppState} from '../redux';
import {blobToBase64} from '../utils/blobToB64';
import {DealPromoType} from '../models/enums/dealPromoType';
import {EAnalyticsCustomEventNames} from '../models/enums/analytics';
import {IAddresses} from '../models/interfaces/IAddresses';
import {IAnalyticsParamsCustomEventDeal} from '../models/interfaces/analytics';
import {ICreateDeal, IUpdateDeal} from '../models/api/deals';
import {IDealContentValidationInputs} from '../models/interfaces/deals';
import {IPackage} from '../models/api/packages';
import {isEqual, isFuture} from 'date-fns';
import {postDeal, putDeal} from '../services/api/dealsAPI';
import {setListData, showPopup} from '../redux/actions/uiActions';
import {TypeAction} from '../models/enums/typeAction';
import {TypeListData} from '../models/enums/typeListData';
import {useAnalytics} from './useAnalytics';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {
  setClearDealData,
  setCreateDealData,
} from '../redux/actions/dealActions';
import domtoimage from 'dom-to-image';
import moment from 'moment-timezone';
import pixelCalculator from '../utils/pixelCalculator';

type IProps = {
  currentPackageSelection: IPackage | null;
  id?: number | null;
  isEditPage: boolean;
};

const useDeals = ({currentPackageSelection, id, isEditPage}: IProps) => {
  // Hooks
  const {t} = useTranslation('createDeal');
  const {eventTrack} = useAnalytics();
  const {deal, user} = useSelector((state: AppState) => state);
  const qrRef = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // States
  const [addressSelected, setAddressSelected] = useState<IAddresses | null>(
    null,
  );
  const [community, setCommunity] = useState<number | null>(null);
  const [contentValidations, setContentValidations] =
    useState<IDealContentValidationInputs>({title: true});
  const [date, setDate] = useState<Date | null>(null);
  const [defaultDate, setDefaultDate] = useState<Date | null>(null);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [image, setImage] = useState<any>(null);
  const [isLoadingMotion, setIsLoadingMotion] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [promoCodeValue, setPromoCodeValue] = useState<string>('');
  const [promoQrValue, setPromoQrValue] = useState<string>('');
  const [promoType, setPromoType] = useState<DealPromoType | null>(null);
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [shareLocation, setShareLocation] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [typeProcess, setTypeProcess] = useState<TypeAction>(TypeAction.Add);
  const [website, setWebsite] = useState<string>('');
  const [webSiteIsValid, setWebSiteIsValid] = useState<boolean>(true);
  const [weeks, setWeeks] = useState<number>(1);

  // Data
  const width = pixelCalculator({axis: 'x', px: 496});

  // Effects
  useEffect(() => {
    if (deal.title) {
      setTitle(deal.title);
    }
    if (deal.promoType) {
      setPromoType(deal.promoType);
    }
    if (deal.promotionCode) {
      setPromoCodeValue(deal.promotionCode);
    }
    if (deal.qrCodeValue) {
      setPromoQrValue(deal.qrCodeValue);
    }
    if (deal.startDate) {
      setDefaultDate(new Date(deal.startDate));
    }
    if (deal.communityId) {
      setCommunity(deal.communityId);
    }
    if (deal.location) {
      setShareLocation(true);
      setAddressSelected({name: deal.location});
    }
    if (isEditPage) {
      setTypeProcess(TypeAction.Edit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal]);

  useEffect(() => {
    isValidForm();
  }, [
    contentValidations,
    date,
    deal,
    image,
    isEditPage,
    promoCodeValue,
    promoQrValue,
    promoType,
    title,
    website,
    webSiteIsValid,
  ]);

  useEffect(() => {
    if (!isEditPage) {
      dispatch(setCreateDealData({qrCodePreview: promoQrValue}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoQrValue, isEditPage]);

  useEffect(() => {
    setAddressSelected({name: addressSelected?.name || ''});
    if (!shareLocation) {
      dispatch(setCreateDealData({location: ''}));
    } else {
      dispatch(setCreateDealData({location: addressSelected?.name}));
    }
  }, [shareLocation]);

  // Handlers
  const isValidForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const {title} = deal;

    const titleIsValid = title !== '' && contentValidations.title === true;

    const qrCodeIsValid =
      promoType === DealPromoType.QRCode && promoQrValue.length > 0;

    const promoCodeIsValid =
      promoType === DealPromoType.PromoCode && promoCodeValue.length > 0;

    const qrAndPromoCode =
      promoType === DealPromoType.QRAndPromoCode &&
      promoQrValue.length > 0 &&
      promoCodeValue.length > 0;

    const promoNoCodeIsValid =
      promoType === DealPromoType.PromoNoCode || promoType === null;

    const dateIsValid =
      date !== null && (isEqual(date, today) || isFuture(date));

    const isPackageValid = deal.packageId !== 0;

    if (isEditPage) {
      const isValid =
        titleIsValid &&
        isPackageValid &&
        (webSiteIsValid || website === '') &&
        (qrCodeIsValid ||
          promoCodeIsValid ||
          qrAndPromoCode ||
          promoNoCodeIsValid);

      setDisabledButton(!isValid);
    } else {
      const isValid =
        titleIsValid &&
        isPackageValid &&
        webSiteIsValid &&
        (qrCodeIsValid ||
          promoCodeIsValid ||
          qrAndPromoCode ||
          promoNoCodeIsValid) &&
        dateIsValid &&
        image !== null;

      setDisabledButton(!isValid);
    }
  };

  const createDeal = async (qrCode: string) => {
    setIsLoadingSubmit(true);

    const data: ICreateDeal = {
      communityId: deal.communityId,
      conditions: deal.conditions.map(c => c.condition),
      endDate: moment(date).add(weeks, 'week').tz('utc', true).format(),
      images: image ? [image] : [],
      isPromotionWithoutCode: promoType === DealPromoType.PromoNoCode,
      location: deal.location || '',
      offerTag: promoType !== DealPromoType.PromoNoCode ? deal.offerTag : '',
      packageId: currentPackageSelection?.id || 1,
      promotionCode:
        promoType === DealPromoType.PromoCode ||
        promoType === DealPromoType.QRAndPromoCode
          ? promoCodeValue
          : '',
      qrCode:
        promoType === DealPromoType.QRCode ||
        promoType === DealPromoType.QRAndPromoCode
          ? qrCode
          : '',
      qrCodeValue:
        promoType === DealPromoType.QRCode ||
        promoType === DealPromoType.QRAndPromoCode
          ? promoQrValue
          : '',
      startDate: moment(deal.startDate).tz('utc', true).format(),
      title: deal.title,
      vendorId: user.id,
      visibility: deal.visibility,
      webSiteUrl: deal.webSiteUrl,
    };
    return await postDeal(data);
  };

  const updateDeal = async (id: number, qrCode: string) => {
    setIsLoadingSubmit(true);

    const data: IUpdateDeal = {
      communityId: deal.communityId,
      conditions: deal.conditions.map(c => c.condition),
      images: image && [image],
      isPromotionWithoutCode: promoType === null,
      location: deal.location || '',
      offerTag: deal.offerTag ? deal.offerTag : '',
      packageId: currentPackageSelection?.id || 1,
      promotionCode: promoCodeValue,
      qrCode: qrCode,
      qrCodeValue: promoQrValue,
      title: deal.title,
      visibility: deal.visibility,
      webSiteUrl: deal.webSiteUrl,
    };
    return await putDeal(id, data);
  };

  const getB64QRCode = async () => {
    let qrB64 = '';
    if (
      promoType === DealPromoType.QRCode ||
      promoType === DealPromoType.QRAndPromoCode
    ) {
      const blob = await domtoimage.toBlob(qrRef.current, {
        width: 190,
        height: 190,
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      });
      qrB64 = await blobToBase64(blob);
      dispatch(setCreateDealData({qrCode: qrB64}));
    }
    return qrB64;
  };

  const onSubmitSuccess = (successMessage: string) => {
    dispatch(setClearDealData());
    dispatch(showPopup(successMessage));
    dispatch(setListData(TypeListData.Deal));

    if (id) {
      handleEventTrack(EAnalyticsCustomEventNames.EditDeal, true);
    } else {
      handleEventTrack(EAnalyticsCustomEventNames.CreateDeal);
    }
    navigate('/home');
  };

  const onSubmitFails = () => {
    setDisabledButton(false);
    setIsLoadingMotion(false);
    setIsLoadingSubmit(false);
    setIsOpen(false);
  };

  const submit = async (id?: number | null) => {
    if (isLoadingSubmit || disabledButton) {
      return;
    }

    try {
      setIsLoadingSubmit(true);
      setDisabledButton(true);
      const qr = await getB64QRCode();
      setQrCodeValue(qr);

      if (id) {
        const result = await updateDeal(id, qr);
        if (result.data) onSubmitSuccess(t('createDeal:successEdit'));
      } else {
        const result = await createDeal(qr);
        if (result.data) onSubmitSuccess(t('createDeal:success'));
      }

      setIsLoadingSubmit(false);
    } catch (error: any) {
      console.log(error);
      setErrorCode(error.errorCode);
      setErrorMessage(error.message);
      onSubmitFails();
    }
  };

  const handleEventTrack = (
    eventName: EAnalyticsCustomEventNames,
    isEdit?: boolean,
  ) => {
    const basicParam: IAnalyticsParamsCustomEventDeal = {
      deal_community_id: deal.communityId,
      deal_name: deal.title,
      deal_package_id: currentPackageSelection?.id || null,
      deal_start_date: moment(deal.startDate).tz('utc', true).format(),
      deal_is_promotion_without_code: promoType === null,
      deal_offer_tag: deal.offerTag,
      deal_qr_code: qrCodeValue,
      deal_qr_code_value: promoQrValue,
    };

    if (isEdit) {
      eventTrack(eventName, {deal: {...basicParam, deal_id: id}});
    } else {
      eventTrack(eventName, {deal: basicParam});
    }
  };

  const onDateChange = useCallback((value: Date | null) => {
    dispatch(setCreateDealData({startDate: value}));
    setDate(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLocationChange = useCallback((value: IAddresses | null) => {
    dispatch(setCreateDealData({location: value?.name || ''}));
    setAddressSelected(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetCommunity = useCallback((communityId: number | null) => {
    dispatch(setCreateDealData({communityId}));
    setCommunity(communityId);
  }, []);

  const handleSetPackage = useCallback((packageId: number) => {
    dispatch(setCreateDealData({packageId}));
  }, []);

  return {
    addressSelected,
    community,
    contentValidations,
    date,
    deal,
    defaultDate,
    disabledButton,
    dispatch,
    errorCode,
    errorMessage,
    handleSetCommunity,
    handleSetPackage,
    image,
    isLoadingMotion,
    isLoadingSubmit,
    modalIsOpen,
    onDateChange,
    onLocationChange,
    promoCodeValue,
    promoQrValue,
    promoType,
    qrRef,
    setContentValidations,
    setDisabledButton,
    setErrorCode,
    setImage,
    setIsLoadingMotion,
    setIsOpen,
    setPromoCodeValue,
    setPromoQrValue,
    setPromoType,
    setShareLocation,
    setTitle,
    setWebsite,
    setWebSiteIsValid,
    setWeeks,
    shareLocation,
    submit,
    title,
    typeProcess,
    website,
    weeks,
    width,
  };
};

export default useDeals;
