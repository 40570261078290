import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {createImageFile} from '../../../../utils/createImageFile';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {ErrorTypes} from '../../../../models/enums/registerForm';
import {IBusinessHours} from '../../../../models/interfaces/generals';
import {IProfileFieldErrors} from '../../../../models/interfaces/profile';
import {sortBusinessHours} from '../../../../utils/businessHoursValidation';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {uniqueId} from 'lodash';
import {useTranslation} from 'react-i18next';
import {
  IAnalyticsParamsCustomEventMyBusiness,
  IAnalyticsParamsCustomEventMyInformation,
} from '../../../../models/interfaces/analytics';
import {
  sendProfileEditBusinessData,
  sendProfileEditUserData,
  setErrorCode,
  setIsPendingAuth,
} from '../../../../redux/actions/profileActions';
import {
  EProfileActionConfirmBtn,
  EProfileTypeImage,
  EProfileTypeTabs,
} from '../../../../models/enums/profile';
import Button from '../../../../components/Button';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import useBusinessHours from '../../../../hooks/useBusinessHours';
import './styles.scss';

type Props = {
  onClickBack: (value: boolean) => void;
  typeTab: EProfileTypeTabs;
};

const ButtonsActions: React.FC<Props> = ({typeTab, onClickBack}) => {
  // Hooks
  const {eventTrack} = useAnalytics();
  const {profile, user} = useSelector((state: AppState) => state);
  const {splitBusinessHours} = useBusinessHours();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  // Data
  const {data} = profile;
  const {
    businessHours,
    images,
    newCoverImage,
    newImagesForGallery,
    newProfileImage,
    userProfileImage,
  } = data;

  // States
  const [coverImageFile, setCoverImageFile] = useState<any>('');
  const [hours, setHours] = useState<IBusinessHours[]>([]);
  const [isDisabledBtn, setIsDisabledBtn] = useState<boolean>(false);
  const [isFirstLoader, setIsFirstLoader] = useState<boolean>(true);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
    useState<boolean>(false);
  const [newGalleryImagesFile, setNewGalleryImagesFile] = useState<any>([]);
  const [profileImageFile, setProfileImageFile] = useState<any>('');
  const [profileUserImageFile, setProfileUserImageFile] = useState<any>('');

  // Effects
  useEffect(() => {
    setIsFirstLoader(false);
    setHours(splitBusinessHours(businessHours));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirstLoader) {
      // Business
      if (typeTab === EProfileTypeTabs.MyBusinesses) {
        // NewGallery Images
        if (newImagesForGallery && newImagesForGallery.length > 0) {
          const files: any[] = [];
          newImagesForGallery.forEach((image: any) => {
            if (image.id === 0) {
              const getImageFile = async () => {
                try {
                  const file = await createImageFile(
                    image.url,
                    `newImagesForGallery${uniqueId()}`,
                  );
                  files.push(file);
                } catch (e) {
                  console.log('error');
                }
              };
              getImageFile();
            }
          });
          setNewGalleryImagesFile(files);
        }

        // Profile Image
        if (newProfileImage) {
          convertImageToFile(newProfileImage, EProfileTypeImage.Profile);
        }

        // Cover Image
        if (newCoverImage) {
          convertImageToFile(newCoverImage, EProfileTypeImage.Cover);
        }
      }

      // Account
      if (typeTab === EProfileTypeTabs.MyInformation) {
        // Profile Image
        if (userProfileImage) {
          convertImageToFile(userProfileImage, EProfileTypeImage.UserProfile);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    images,
    newImagesForGallery,
    newProfileImage,
    newCoverImage,
    userProfileImage,
  ]);

  useEffect(() => {
    if (!isFirstLoader && typeTab === EProfileTypeTabs.MyBusinesses) {
      // Hours
      if (businessHours.length) {
        const splitedObject = splitBusinessHours(businessHours);
        splitedObject.length &&
          setHours(
            sortBusinessHours(splitedObject, 'asc', 'weekDay', 'keyItem'),
          );
      }
    }
  }, [businessHours]);

  useEffect(() => {
    if (!isFirstLoader && typeTab === EProfileTypeTabs.MyBusinesses) {
      if (profile.errorCode === ErrorTypes.BusinessNameInUse) {
        dispatch(setIsPendingAuth(false));
        setIsDisabledBtn(true);
        setIsOpenConfirmationModal(false);
      } else if (profile.errorCode === ErrorTypes.NoError) {
        dispatch(setIsPendingAuth(true));
        setIsOpenConfirmationModal(true);
        dispatch(setErrorCode(''));
        handleEventTrack();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.errorCode]);

  useEffect(() => {
    const errors: IProfileFieldErrors = profile.hasErrors;
    if (
      errors.contactInfo ||
      errors.hours ||
      errors.information ||
      errors.location ||
      errors.pictures ||
      errors.profilePicture ||
      errors.vendorInformation
    ) {
      setIsDisabledBtn(true);
    } else {
      setIsDisabledBtn(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.hasErrors]);

  // Handlers - Functions
  const convertImageToFile = async (image: any, type: EProfileTypeImage) => {
    try {
      const file = await createImageFile(
        image,
        type === EProfileTypeImage.Profile ||
          type === EProfileTypeImage.UserProfile
          ? 'profileImage'
          : 'coverImage',
      );

      if (type === EProfileTypeImage.Profile) {
        setProfileImageFile(file);
      } else if (type === EProfileTypeImage.UserProfile) {
        setProfileUserImageFile(file);
      } else {
        setCoverImageFile(file);
      }
    } catch (e) {
      console.log('error');
    }
  };

  const handleSubmit = (action: EProfileActionConfirmBtn) => {
    if (action === EProfileActionConfirmBtn.Send) {
      const {data} = profile;

      if (typeTab === EProfileTypeTabs.MyBusinesses) {
        const filterDataBusiness = [data].map(
          ({
            // B Information
            businessDescription,
            businessId,
            businessName,
            community,
            subCategories,
            // B Pictures
            coverImageUrl,
            modifyImageFiles,
            profileImageUrl,
            // B Locations
            businessLocations,
            businessLocationType,
            countryId,
            webSiteUrl,
            // B Contact Info
            businessSocialMedia,
            contactBirthdate,
            contactCountryFlag,
            contactEmail,
            contactId,
            contactLastname,
            contactName,
            contactPhoneNumber,
            contactTitle,
            contactWebSiteUrl,
            // B Hours
            timeZone,
          }) => {
            return {
              id: businessId,
              businessName,
              businessDescription,
              subCategories,
              contactId,
              contactEmail,
              contactName,
              contactLastname,
              contactPhoneNumber,
              contactPhoneNumberCountryCode: contactCountryFlag,
              contactBirthdate,
              contactTitle,
              contactWebSiteUrl,
              businessLocations,
              profileImageUrl,
              coverImageUrl,
              businessLocationType,
              webSiteUrl,
              images: modifyImageFiles,
              businessHours: hours,
              businessSocialMedia,
              communityId: community,
              newCoverImage: coverImageFile,
              newImagesForGallery: newGalleryImagesFile,
              newProfileImage: profileImageFile,
              countryId,
              timeZone,
            };
          },
        );

        // Send Data
        dispatch(sendProfileEditBusinessData(filterDataBusiness[0]));
      } else {
        const filterDataUser = [data].map(
          ({
            userCommunity,
            userCountryFlag,
            userFirstName,
            userLastName,
            userPhoneNumber,
          }) => {
            return {
              phone: userPhoneNumber,
              countryPhoneNumberCode: userCountryFlag,
              profileImage: profileUserImageFile,
              communityId: userCommunity,
              name: userFirstName,
              lastName: userLastName,
            };
          },
        );

        // Send Data
        dispatch(sendProfileEditUserData(filterDataUser[0]));
        handleEventTrack();
      }
      setIsDisabledBtn(true);
    }
  };

  const handleEventTrack = () => {
    const {data} = profile;
    if (typeTab === EProfileTypeTabs.MyBusinesses) {
      const basicParam: IAnalyticsParamsCustomEventMyBusiness = {
        business_description: data.businessDescription,
        business_hours: hours,
        business_id: data.businessId,
        business_locations: data.businessLocations,
        business_name: data.businessName,
        business_socialMedia: data.businessSocialMedia,
        community_id: data.community,
        contact_email: data.contactEmail,
        contact_lastname: data.contactLastname,
        contact_name: data.contactName,
        contact_phone_number: data.contactPhoneNumber,
        web_site_url: data.webSiteUrl,
      };
      eventTrack(EAnalyticsCustomEventNames.EditBusinesses, {
        profile_business: basicParam,
      });
    } else {
      const basicParam: IAnalyticsParamsCustomEventMyInformation = {
        community_id: data.community,
        lastname: data.contactLastname,
        name: data.contactName,
        phone_number: data.contactPhoneNumber,
        user_id: user.id,
      };
      eventTrack(EAnalyticsCustomEventNames.EditMyInformation, {
        profile_user: basicParam,
      });
    }
  };

  return (
    <div className="buttons-actions-wrapper">
      <Button
        label={t('global:discartChanges')}
        onClick={() => onClickBack(true)}
      />

      <Button
        label={t('global:saveChanges')}
        onClick={() => handleSubmit(EProfileActionConfirmBtn.Send)}
        disable={isDisabledBtn}
      />

      {isOpenConfirmationModal && (
        <ModalConfirmation
          confirmProfileAuth={true}
          isCloseInOutsideClick={false}
          modalIsOpen={isOpenConfirmationModal}
          onConfirm={() => onClickBack(true)}
          setIsOpen={() => onClickBack(true)}
          subtitle={t('profilePage:modalConfirmationDescription')}
          title={t('profilePage:modalConfirmationTitle')}
          type={'confirm'}
        />
      )}
    </div>
  );
};

export default ButtonsActions;
