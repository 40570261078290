import React, {useState} from 'react';
import {EColors} from '../../../../models/enums/colors';
import {RootState} from '../../../../redux';
import {setRegisterData} from '../../../../redux/actions/registerActions';
import {useDispatch, useSelector} from 'react-redux';
import {useSteps} from '../../../../hooks/useSteps';
import {useTranslation} from 'react-i18next';
import CompleteTextField from '../../../../components/CompleteTextField';
import LinkButton from '../../../../components/LinkButton';
import PreviousNextButtons from '../../../../components/PreviousNextButtons';
import './styles.css';

const BusinessDescriptionStep = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {incrementStep} = useSteps();
  const {data} = useSelector((store: RootState) => store.register);

  const [description, setDescription] = useState<string>(
    data.businessDescription,
  );

  const handleNextButton = () => {
    dispatch(
      setRegisterData({
        businessDescription: description,
      }),
    );
    incrementStep();
  };

  return (
    <div className="business-description-step-form">
      <CompleteTextField
        value={description}
        setValue={setDescription}
        customStyles={{
          input: {height: '150px'},
          generalContainer: {height: '185px'},
        }}
        placeholder={t('register:businessDescriptionPlaceholder')}
        type="textarea"
        label={t('register:businessDescription')}
        maxLength={1000}
        withContentValidation={false}
        withCounter={true}
        hideLabel={false}
      />

      <div className="business-description-bottom-buttons">
        <LinkButton
          onClick={() => incrementStep()}
          customStyle={{color: EColors.colorBlueRegisterSteps}}>
          {t('global:skip')}
        </LinkButton>
        <PreviousNextButtons
          onNext={handleNextButton}
          nextDisabled={!description}
        />
      </div>
    </div>
  );
};

export default BusinessDescriptionStep;
