import ReactGA, {FieldsObject} from 'react-ga';
import {initializeApp} from 'firebase/app';
import {useEffect, useState} from 'react';
import {EAnalyticsCustomEventNames} from '../models/enums/analytics';
import {IAnalyticsParamsCustomEvent} from '../models/interfaces/analytics';
import {Analytics, getAnalytics, logEvent, setUserId} from 'firebase/analytics';
import {AUTH_DOMAIN, PROJECT_ID, STORAGE_BUCKET} from '../constants/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GA_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_GA_SENDER_ID,
  appId: process.env.REACT_APP_GA_APP_ID,
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID,
};

export const useAnalytics = () => {
  const [analytics, setAnalytics] = useState<Analytics>();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      initAnalytics();
    }
  }, []);

  const initAnalytics = () => {
    const app = initializeApp(firebaseConfig);
    const analyticsInstance = getAnalytics(app);

    setAnalytics(analyticsInstance);
  };

  const setEventFields = (fields: FieldsObject) => {
    ReactGA.set(fields);
  };

  const eventTrack = (
    eventName: EAnalyticsCustomEventNames | string,
    eventParams: IAnalyticsParamsCustomEvent,
  ) => {
    if (analytics) {
      logEvent(analytics, eventName, eventParams);
    }
  };

  const eventSetUserId = (userId: string) => {
    if (analytics) {
      setUserId(analytics, userId);
    }
  };

  return {
    eventSetUserId,
    eventTrack,
    setEventFields,
  };
};
