import { contactUsRequestType } from "../models/enums/contactUsRequestType";

 
export interface IContactUs {
  id: number;
  label: string;
}

export const contactUs: IContactUs[] = [
  {
    id: contactUsRequestType.INeedHelpAccount,
    label: 'iNeedHelpAccount',
  },
  {
    id: contactUsRequestType.ReportNSFW18PlusUserContentMessage,
    label: 'reportNSFW18PlusUserContentMessage',
  },
  {
    id: contactUsRequestType.ReportChildSexualExploitation,
    label: 'reportChildSexualExploitation',
  },
  {
    id: contactUsRequestType.ReportViolationCodeConduct,
    label: 'reportViolationCodeConduct',
  },
  {
    id: contactUsRequestType.ReportBug,
    label: 'reportBug',
  },
  {
    id: contactUsRequestType.ReportContentPolicyViolation,
    label: 'reportContentPolicyViolation',
  },
  {
    id: contactUsRequestType.ReportSuicideSelfHarm,
    label: 'reportSuicideSelfHarm',
  },
  {
    id: contactUsRequestType.CopyrightInfrigementDMCA,
    label: 'copyrightInfrigementDMCA',
  },
  {
    id: contactUsRequestType.CopyrightCounterNotice,
    label: 'copyrightCounterNotice',
  },
  {
    id: contactUsRequestType.Investigations,
    label: 'investigations',
  },
  {
    id: contactUsRequestType.MobileHelp,
    label: 'mobileHelp',
  },
  {
    id: contactUsRequestType.PurchasesInquiries,
    label: 'purchasesInquiries',
  },
  {
    id: contactUsRequestType.ProblemsAdsDeals,
    label: 'problemsAdsDeals',
  },
  {
    id: contactUsRequestType.PurchaseProblemTicketFairy,
    label: 'purchaseProblemTicketFairy',
  },
  {
    id: contactUsRequestType.TrademarkRequest,
    label: 'trademarkRequest',
  },
  {
    id: contactUsRequestType.InquiriesRelatedYourPrivacyRights,
    label: 'inquiriesRelatedYourPrivacyRights',
  },
  {
    id: contactUsRequestType.OtherHelp,
    label: 'otherHelp',
  },
];
