import React from 'react';
import {ITextFieldCustomStyles} from '../../../../../../components/TextField';
import {useTranslation} from 'react-i18next';
import CompleteTextField from '../../../../../../components/CompleteTextField';
import FormField from '../../../../../../components/FormField/FormField';

type Props = {
  styles: ITextFieldCustomStyles;
  value: string;
  setValue: (newValue: string) => void;
  setIsValid?: (value: boolean) => void;
  isValid?: boolean | null;
};

const FieldHeadline: React.FC<Props> = ({
  styles,
  value,
  setValue,
  setIsValid,
  isValid = null,
}: Props) => {
  const {t} = useTranslation('createAd');

  return (
    <FormField
      title={t('fieldHeadlineTitle')}
      description={t('fieldHeadlineDescription')}>
      <CompleteTextField
        id={'description'}
        value={value ? value : ''}
        setValue={setValue}
        maxLength={25}
        placeholder={t('createAd:fieldHeadlinePlaceholder')}
        hideLabel={true}
        customStyles={styles}
        withCounter
        withContentValidation
        isValid={isValid}
        setIsValid={setIsValid}
      />
    </FormField>
  );
};

export default FieldHeadline;
