import React from 'react';
import './styles.scss';

interface IProps {
  label: JSX.Element;
  selected: boolean;
  onClick: (value:any) => void;
  customStyle?: any;
}

const RadioButton = ({selected, onClick, label, customStyle = {}}: IProps) => {
  return (
    <div
      className="radio-button-component"
      style={customStyle}
      onClick={onClick}>
      <div className="radio-button">
        {selected && <div className={'selected'} />}
      </div>
      <span className="radio-button-label">{label}</span>
    </div>
  );
};

export default RadioButton;
