export enum TypeListData {
  Ad = 'AD',
  Deal = 'DEAL',
  Location = 'LOCATION',
  BusinessVendor ='BUSINESSVENDOR'
}

export enum ETypeView {
  Home = 'HOME',
  Profile = 'PROFILE',
  Register = 'REGISTER',
}
