import React, {useEffect, useState} from 'react';
import {checkContent} from '../../../services/api/contentValidatorAPI';
import Spinner from '../../../assets/images/spinner.gif';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ValidationOk} from '../../../assets/icons/green-tick-ok.svg';
import {ReactComponent as WrongContentIcon} from '../../../assets/icons/red-cross-error.svg';
import './styles.scss';
import {ErrorCodes} from '../../../models/enums/errors';

interface IProps {
  content: string;
  isValid: boolean | null;
  setIsValid: (isValid: any) => void;
}

const ContentValidation = ({content, isValid, setIsValid}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation('shared');

  useEffect(() => {
    const checkIfContentIsValid = async () => {
      try {
        setIsLoading(true);
        await checkContent(content);
        setIsValid(true);
        setIsLoading(false);
      } catch (err: any) {
        if (err.errorCode === ErrorCodes.inappropriateContent) {
          setIsValid(false);
          setIsLoading(false);
        } else {
          console.log(err);
          setIsValid(null);
          setIsLoading(false);
        }
      }
    };
    checkIfContentIsValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-validation">
      {isLoading ? (
        <div className="result-wrapper">
          <img src={Spinner} alt="spinner" width={20} height={20} />
          <span>{t('checkingLanguage')}</span>
        </div>
      ) : (
        ''
      )}
      {isValid !== null && !isLoading ? (
        isValid ? (
          <div className="result-wrapper">
            <ValidationOk width={10} height={10} />
            <span className="lng-safe">{t('This content seems safe!')}</span>
          </div>
        ) : (
          <div className="result-wrapper">
            <WrongContentIcon width={10} height={10} />
            <span className="lng-inapropiate">
              {t('This contains inappropriate language')}
            </span>
          </div>
        )
      ) : (
        ''
      )}
    </div>
  );
};

export default ContentValidation;
