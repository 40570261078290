import React from 'react';
import {ITextFieldCustomStyles} from '..';
import './styles.scss';

interface IProps {
  customStyles?: ITextFieldCustomStyles;
  disabled?: boolean;
  error: boolean;
  hideLabel?: boolean;
  id: string;
  label: string;
  maxLength?: number;
  minLength?: number;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onInputChange: (e: any) => void;
  options?: ITextAreaOptions;
  placeHolder: string;
  required?: boolean;
  value: string;
}

export interface ITextAreaOptions {
  rows: number;
  cols: number;
  wrap: 'soft' | 'hard';
}

const InputTextArea = ({
  customStyles = {container: {}, input: {}, label: {}},
  disabled = false,
  error,
  hideLabel = false,
  id,
  label = '',
  maxLength = 20,
  minLength = 0,
  onBlur,
  onFocus,
  onInputChange,
  options = {rows: 2, cols: 30, wrap: 'soft'},
  placeHolder,
  required = false,
  value,
}: IProps) => {
  return (
    <div className="input-text-area-wrapper" style={customStyles.container}>
      {!hideLabel && (
        <label
          className={'field-label'}
          htmlFor={id}
          style={customStyles.label}>
          {label ? label : placeHolder}
        </label>
      )}
      <textarea
        rows={options.rows}
        cols={options.cols}
        wrap={options.wrap}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        id={id}
        name={id}
        className={`field-input text-area ${disabled ? 'disabled' : ''} ${
          error ? 'error' : ''
        }`}
        style={customStyles.input}
        data-testid={'input-text-area'}
        value={value}
        onChange={onInputChange}
        placeholder={placeHolder}
        maxLength={maxLength}
        minLength={minLength}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

export default InputTextArea;
