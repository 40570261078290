export enum UserStatus {
  Active = 1,
  'Pending Email Confirmation',
  Unverified,
  Verified,
  Pending,
  Inactive,
  Deactivated,
  Suspended,
  Deleted,
}
