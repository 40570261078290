import React from 'react';
import './styles.scss';
import {ReactComponent as Add} from '../../assets/icons/add.svg';
import {ReactComponent as Substract} from '../../assets/icons/substract.svg';

interface IProps {
  value: number;
  label: string;
  setValue: (newValue: number) => void;
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
  styles?: object;
}
const InputNumber = ({
  value,
  label,
  setValue,
  maxValue = 999,
  minValue = 1,
  disabled = false,
  styles,
}: IProps) => {
  const handleUpdate = (value: any) => {
    if (disabled || isNaN(value) || value < minValue || value > maxValue) {
      return;
    }

    setValue(Number(value));
  };

  return (
    <div
      className={`input-number-component ${disabled ? 'disabled' : ''}`}
      style={styles}>
      <span className={`in-label ${disabled ? 'disabled' : ''}`}>{label}</span>
      <div className="input-wrapper">
        <input
          className={`input-number ${disabled ? 'disabled' : ''}`}
          type={'text'}
          name="input-number"
          value={value}
          onChange={e => handleUpdate(e.target.value)}
          max={maxValue ? maxValue : 999}
          min={minValue ? minValue : 0}
          disabled={disabled}
        />
        <Add
          className="add-button"
          width={13}
          onClick={() => handleUpdate(Number(value) + 1)}
        />
        <Substract
          className="substract-button"
          width={13}
          onClick={() => handleUpdate(Number(value) - 1)}
        />
      </div>
    </div>
  );
};

export default InputNumber;
