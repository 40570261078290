import React from 'react';
import {useTranslation} from 'react-i18next';
import Calendar from '../Calendar';
import Disabled from '../DisabledComponent';
import FormField from '../FormField/FormField';
import InputNumber from '../InputNumber';
import './styles.scss';

interface IProps {
  setDate: (date: Date) => void;
  date: Date | null;
  defaultDate?: Date | null;
  setWeeks: (newValue: number) => void;
  weeks: number;
  maxWeeks?: number;
  disabledField?: boolean;
}
const DurationField = ({
  setDate,
  date,
  setWeeks,
  weeks,
  maxWeeks = 1,
  disabledField = false,
  defaultDate,
}: IProps) => {
  const {t} = useTranslation('createAd');
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  minDate.setHours(0, 0, 0, 0);

  return (
    <FormField
      title={t('fieldDurationTitle')}
      description={t('fieldDurationDescription')}>
      <Disabled disabled={disabledField}>
        <div className="duration-wrapper">
          <Calendar
            setterDate={date => setDate(date)}
            label={t('shared:startDate')}
            minDate={minDate}
            defaultDate={defaultDate}
          />
          <InputNumber
            styles={{display: 'flex', alignItems: 'center'}}
            setValue={setWeeks}
            label={t('shared:weeks')}
            value={weeks}
            disabled={!date}
            maxValue={maxWeeks}
          />
        </div>
      </Disabled>
    </FormField>
  );
};

export default DurationField;
