import React from 'react';
import {ReactComponent as CheckBoxDisabled} from '../../assets/icons/checkbox-disabled.svg';
import {ReactComponent as CheckBoxEnabled} from '../../assets/icons/check-checked.svg';
import './styles.scss';

interface IProps {
  toggleStatus: () => void;
  status: boolean;
}

const CheckBox = ({toggleStatus, status}: IProps) => {
  return (
    <div className="check-box" onClick={() => toggleStatus()}>
      {status ? <CheckBoxEnabled /> : <CheckBoxDisabled />}
    </div>
  );
};

export default CheckBox;
