import React, {useState} from 'react';
import './styles.scss';
import {ReactComponent as MinusCircle} from '../../../../../../assets/icons/minus-circle.svg';
import {ReactComponent as PlusCircle} from '../../../../../../assets/icons/plus-circle.svg';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {setCreateDealData} from '../../../../../../redux/actions/dealActions';
import {MAX_LENGTH_CONDITION_LIST} from '../../../../../../constants/createDeal';
import truncateLongString from '../../../../../../utils/truncateLongString';
import {IDeal} from '../../../../../../models/interfaces/deals';
import CharacterCounter from '../../../../../../components/CharacterCounter';
import ContentValidation from '../../../../../../components/CompleteTextField/ContentValidation';
type Props = {
  deal: IDeal;
};
const ConditionsList: React.FC<Props> = ({deal}) => {
  const {t} = useTranslation('createDeal');
  const dispatch = useDispatch();

  const [newItem, setNewItem] = useState<string>('');

  const [lastIsValid, setLastIsValid] = useState<boolean | null>(null);

  const [validate, setValidate] = useState<boolean | null>(null);

  const handleRemoveItem = (id: number) => {
    dispatch(
      setCreateDealData({
        conditions: deal.conditions.filter(item => item.id !== id),
      }),
    );
  };
  const handleAddItem = () => {
    setValidate(true);
  };

  const pressEnterAddItem = (e: any) => {
    if (e.keyCode === 13) {
      setValidate(true);
    }
  };

  const handleValidation = (isValid: boolean | null) => {
    setLastIsValid(isValid);
    if (isValid) {
      add();
    }
  };

  const add = () => {
    if (newItem && deal.conditions.length < MAX_LENGTH_CONDITION_LIST) {
      dispatch(
        setCreateDealData({
          conditions: [
            ...deal.conditions,
            {id: Date.now(), condition: newItem},
          ],
        }),
      );
      setNewItem('');
      setValidate(false);
    }
  };

  const onInputChange = (e: any) => {
    e.preventDefault();
    setNewItem(e.target.value);
    setValidate(false);
  };
  return (
    <div className="condition-list-wrapper">
      {deal?.conditions &&
        deal?.conditions.map((item, index) => (
          <div key={index} className="condition-list-item-wrapper">
            <div
              className="condition-list-item-icon"
              onClick={() => handleRemoveItem(item.id)}>
              <MinusCircle />
            </div>
            <span className="condition-list-item-text">
              {truncateLongString(item.condition, 33)}
            </span>
            <CharacterCounter
              maxLength={150}
              string={item.condition}
              style={{marginLeft: 'auto'}}
            />
          </div>
        ))}

      <div className="condition-list-item-wrapper">
        <div
          className="condition-list-item-icon"
          onClick={() => handleAddItem()}>
          <PlusCircle />
        </div>
        <input
          id="new-item"
          name="new-item"
          placeholder={t('newCondition')}
          className="condition-list-input"
          value={newItem}
          onChange={onInputChange}
          onBlur={() => setValidate(true)}
          maxLength={150}
          onKeyDown={e => pressEnterAddItem(e)}
        />
      </div>
      {validate && newItem && newItem.length > 0 && (
        <div style={{width: '100%'}}>
          <ContentValidation
            isValid={lastIsValid}
            setIsValid={handleValidation}
            content={newItem}
          />
        </div>
      )}
    </div>
  );
};

export default ConditionsList;
