import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {EMenuViews} from '../../../../models/enums/generals';
import {IPanelFormProfile} from '../../../../models/interfaces/profile';
import {setProfileData} from '../../../../redux/actions/profileActions';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import BusinessContactInfo from '../../../../pages/ProfileEdition/components/BusinessContactInfo';
import BusinessHour from '../../../../pages/ProfileEdition/components/BusinessHours';
import BusinessImagesProfile from '../../../../pages/ProfileEdition/components/BusinessImages';
import BusinessInformation from '../../../../pages/ProfileEdition/components/BusinessInformation';
import BusinessLocation from '../../../../pages/ProfileEdition/components/BusinessLocation';
import Disabled from '../../../DisabledComponent';
import ProfileEditionPanelTab from '../../../ProfileEditPanelTab';
import BusinessUsers from '../../../../pages/ProfileEdition/components/BusinessUsers/index';

type Props = {
  setShowPanelForm: (val: IPanelFormProfile) => void;
};

const MyBusinesses: React.FC<Props> = ({setShowPanelForm}) => {
  // Hooks
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {isPendingAuth} = useSelector((state: AppState) => state.profile);
  const {eventTrack} = useAnalytics();

  // States
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    dispatch(setProfileData({showSaveBtns: true}));
    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {page_view: EMenuViews.Profile},
    });
  }, []);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList className="tab-list">
        <Tab className="tab-component">
          <ProfileEditionPanelTab isSelected={tabIndex === 0}>
            {t('profilePage:tabMybusinessesInfo')}
          </ProfileEditionPanelTab>
        </Tab>

        <Tab className="tab-component">
          <ProfileEditionPanelTab isSelected={tabIndex === 1}>
            {t('profilePage:tabMybusinessesContactInfo')}
          </ProfileEditionPanelTab>
        </Tab>

        <Tab className="tab-component">
          <ProfileEditionPanelTab isSelected={tabIndex === 2}>
            {t('profilePage:tabMybusinessesImages')}
          </ProfileEditionPanelTab>
        </Tab>

        <Tab className="tab-component">
          <ProfileEditionPanelTab isSelected={tabIndex === 3}>
            {t('profilePage:tabMybusinessesLocation')}
          </ProfileEditionPanelTab>
        </Tab>

        <Tab className="tab-component">
          <ProfileEditionPanelTab isSelected={tabIndex === 4}>
            {t('profilePage:tabMybusinessesHours')}
          </ProfileEditionPanelTab>
        </Tab>

        {/* TODO: Users section is enabled to perform QA */}
        {false &&
        <Tab className="tab-component">
          <ProfileEditionPanelTab isSelected={tabIndex === 5}>
            {t('profilePage:tabMyUsers')}
          </ProfileEditionPanelTab>
        </Tab>
        }
      </TabList>

      <TabPanel>
        <Disabled disabled={isPendingAuth}>
          <BusinessInformation />
        </Disabled>
      </TabPanel>

      <TabPanel>
        <Disabled disabled={isPendingAuth}>
          <BusinessContactInfo />
        </Disabled>
      </TabPanel>

      <TabPanel>
        <Disabled disabled={isPendingAuth}>
          <BusinessImagesProfile />
        </Disabled>
      </TabPanel>

      <TabPanel>
        <Disabled disabled={isPendingAuth}>
          <BusinessLocation setShowPanelForm={setShowPanelForm} />
        </Disabled>
      </TabPanel>

      <TabPanel>
        <Disabled disabled={isPendingAuth}>
          <BusinessHour />
        </Disabled>
      </TabPanel>

      {/* TODO: Users section is enabled to perform QA */}
    {false &&(
    <TabPanel>
        <Disabled disabled={isPendingAuth}>
          <BusinessUsers />
        </Disabled>
      </TabPanel>
    )}
    </Tabs>
  );
};

export default MyBusinesses;
