import React, {useState, useEffect} from 'react';
import InputText from './InputText';
import InputPassword from './InputPassword';
import InputTextArea, {ITextAreaOptions} from './InputTextArea';
import './styles.scss';

interface IProps {
  className?: string;
  customStyles?: ITextFieldCustomStyles;
  disabled?: boolean;
  error?: boolean;
  errorElement?: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  hideLabel?: boolean;
  id: string;
  isOptional?: boolean;
  label?: string;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  pattern?: string;
  placeHolder?: string;
  required?: boolean;
  showTooltip?: boolean;
  textAreaOptions?: ITextAreaOptions | undefined;
  type: 'email' | 'text' | 'password' | 'textarea';
  value: string;
  allowPaste?: boolean;
}

export interface ITextFieldCustomStyles {
  container?: object;
  generalContainer?: object;
  img?: object;
  input?: object;
  label?: object;
  mainContainer?: object;
}

export const TextField = ({
  className = '',
  customStyles,
  disabled = false,
  error = false,
  errorElement,
  handleChange,
  hideLabel = false,
  id,
  isOptional = false,
  label = '',
  maxLength,
  minLength,
  onBlur,
  onFocus,
  onKeyDown,
  pattern = undefined,
  placeHolder,
  required,
  showTooltip,
  textAreaOptions,
  type,
  value,
  allowPaste = true,
}: IProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const onInputChange = (e: any) => {
    e.preventDefault();
    handleChange(e);
    if (!isFocused && e.target?.value?.length === 0) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (setIsFocused) {
      setIsFocused(isFocused);
    }
  }, [isFocused]);

  const renderFieldFromType = () => {
    switch (type) {
      case 'textarea':
        return (
          <InputTextArea
            id={id}
            disabled={disabled}
            options={textAreaOptions}
            customStyles={customStyles}
            label={label}
            hideLabel={hideLabel}
            value={value}
            onFocus={(e: any) => {
              setIsFocused(true);
              if (onFocus) {
                onFocus(e);
              }
            }}
            onBlur={e => {
              setIsFocused(true);
              if (onBlur) {
                onBlur(e);
              }
            }}
            onInputChange={onInputChange}
            placeHolder={placeHolder ? placeHolder : ''}
            maxLength={maxLength}
            minLength={minLength}
            required={required}
            error={error}
          />
        );
      case 'password':
        return (
          <InputPassword
            id={id}
            disabled={disabled}
            label={label}
            hideLabel={hideLabel}
            value={value}
            onFocus={(e: any) => {
              setIsFocused(true);
              if (onFocus) {
                onFocus(e);
              }
            }}
            onBlur={e => {
              setIsFocused(true);
              if (onBlur) {
                onBlur(e);
              }
            }}
            onKeyDown={e => {
              if (onKeyDown) {
                onKeyDown(e);
              }
            }}
            onInputChange={onInputChange}
            placeHolder={placeHolder ? placeHolder : ''}
            maxLength={maxLength}
            minLength={minLength}
            required={required}
            error={error}
            customStyles={customStyles}
            showTooltip={showTooltip}
          />
        );
      default:
        return (
          <InputText
            customStyles={customStyles}
            disabled={disabled}
            error={error}
            hideLabel={hideLabel}
            id={id}
            isOptional={isOptional}
            label={label}
            maxLength={maxLength}
            minLength={minLength}
            onInputChange={onInputChange}
            pattern={pattern}
            placeHolder={placeHolder ? placeHolder : ''}
            required={required}
            type={type}
            value={value}
            onFocus={(e: any) => {
              setIsFocused(true);
              if (onFocus) {
                onFocus(e);
              }
            }}
            onBlur={e => {
              setIsFocused(true);
              if (onBlur) {
                onBlur(e);
              }
            }}
            allowPaste={allowPaste}
          />
        );
    }
  };

  return (
    <div
      data-testid="text-field"
      className={`text-field ${className}`}
      style={customStyles?.generalContainer}>
      {renderFieldFromType()}
      {errorElement && errorElement}
    </div>
  );
};
