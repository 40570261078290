import {ITimeZones} from '../models/interfaces/register';
import {t} from 'i18next';

export const NUMBER_OF_STEPS = 9;
export const BUSINESS_IMAGES_LIMIT = 5;
export const DEFAULT_ROLE_NAME = 'ManaVendor';

export const legalLinks = {
  privacyPolicy: 'https://app.manacommon.com/privacy-policy',
  termsAndConditions: 'https://app.manacommon.com/terms-of-use',
};

export const timeZones: ITimeZones[] = [
  {
    name: 'AT - Atlantic Standard Time',
    id: 'America/Halifax',
    gmt: 'GMT-4',
    utc: 'UTC-4',
  },
  {
    name: 'ET - Eastern Standard Time',
    id: 'America/New_York',
    gmt: 'GMT-5',
    utc: 'UTC-5',
  },
  {
    name: 'CT - Central Standard Time',
    id: 'America/Chicago',
    gmt: 'GMT-6',
    utc: 'UTC-6',
  },
  {
    name: 'MT - Mountain Standard Time',
    id: 'America/Denver',
    gmt: 'GMT-7',
    utc: 'UTC-7',
  },
  {
    name: 'PT - Pacific Standard Time',
    id: 'America/Los_Angeles',
    gmt: 'GMT-8',
    utc: 'UTC-8',
  },
  {
    name: 'AKT - Alaska Standard Time',
    id: 'America/Anchorage',
    gmt: 'GMT-9',
    utc: 'UTC-9',
  },
  {
    name: 'HAT - Hawaii Standard Time',
    id: 'Pacific/Honolulu',
    gmt: 'GMT-10',
    utc: 'UTC-10',
  },
];

export const itemsDays = () => {
  return [
    t('register:businessHoursSunday'),
    t('register:businessHoursMonday'),
    t('register:businessHoursTuesday'),
    t('register:businessHoursWednesday'),
    t('register:businessHoursThursday'),
    t('register:businessHoursFriday'),
    t('register:businessHoursSaturday'),
  ];
};
