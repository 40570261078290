import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  IaddContactusRequest,
  IAuthenticateRequest,
  IResetPasswordByEmailCode,
  IResetPasswordByPhone,
} from '../models/api/authenticate';
import {EAnalyticsLoginMethod} from '../models/enums/analytics';
import {IAnalyticsParamsCustomEventLogIn} from '../models/interfaces/analytics';
import {AppState} from '../redux';
import {
  clearAuthErrors,
  loginUser,
  logoutUser,
} from '../redux/actions/authActions';
import {
  addContactusRequest,
  checkValidPhoneCodeRequest,
  clearUser,
  forgotPasswordByEmailRequestCodeInit,
  forgotPasswordByPhoneRequestCodeInit,
  forgotPasswordByPhoneSetCodeAction,
  forgotPasswordCodeResetStateAction,
  forgotPasswordRequestCodeByEmailService,
  forgotPasswordRequestCodeByPhoneService,
  resetPasswordByEmailCodeService,
  resetPasswordByPhoneService,
  validEmailCodeService,
  validPhoneCodeService,
} from '../redux/actions/userActions';
import { getUserPublicProfileService } from '../services/api/userAPI';
import {useAnalytics} from './useAnalytics';

interface IUseAuth {
  signIn: (credentials: IAuthenticateRequest, onSuccess: () => void) => void;
  signOut: (onSignOut: () => void) => void;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: boolean;
  errorCode: string | undefined;
  token: string;
  addContactus: (data: IaddContactusRequest) => void;
  forgotPasswordByPhoneRequestCode: (phoneNumber: string) => void;
  forgotPasswordByEmailRequestCode: (userEmail: string) => void;
  forgotPasswordRequestResetState: () => void;
  forgotPasswordByPhoneSetCode: (code: string) => void;
  checkCodePhone: (phoneNumber: string, code: string) => void;
  checkCodeEmailCode: (email: string, code: string) => void;
  resetPasswordByPhone: (data: IResetPasswordByPhone) => void;
  resetPasswordByEmailCode: (data: IResetPasswordByEmailCode) => void;
  userPublicProfile: (id: string) => void;
}

export const useAuth = (): IUseAuth => {
  const dispatch = useDispatch();
  const auth = useSelector((state: AppState) => state.auth);
  const user = useSelector((state: AppState) => state.user);
  const {eventTrack, eventSetUserId} = useAnalytics();

  const [isAlreadyAuthenticated, setIsAlreadyAuthenticated] = useState(false);

  useEffect(() => {
    dispatch(clearAuthErrors());
  }, []);

  useEffect(() => {
    if (!isAlreadyAuthenticated && user.id && auth.isAuthenticated) {
      setIsAlreadyAuthenticated(true);
      handleEventTrack(true);
    } else {
      setIsAlreadyAuthenticated(false);
    }
  }, [auth]);

  const resetPasswordByEmailCode = async (data: IResetPasswordByEmailCode) => {
    dispatch(resetPasswordByEmailCodeService(data));
  };
  const resetPasswordByPhone = async (data: IResetPasswordByPhone) => {
    dispatch(resetPasswordByPhoneService(data));
  };

  const signIn = (credentials: IAuthenticateRequest, onSuccess: () => void) => {
    try {
      dispatch(loginUser(credentials));
      onSuccess();
    } catch (error: any) {
      console.log(error);
    }
  };

  const signOut = (onSignOut: () => void) => {
    dispatch(logoutUser());
    dispatch(clearUser());
    handleEventTrack(false);
    onSignOut();
  };
  const addContactus = async (data: IaddContactusRequest) => {
    dispatch(addContactusRequest(data));
  };
  const userPublicProfile = async (id: string) => {
    dispatch(getUserPublicProfileService(id));
  };
  const handleEventTrack = (isAuth: boolean) => {
    const basicParam: IAnalyticsParamsCustomEventLogIn = {
      method: isAuth
        ? EAnalyticsLoginMethod.LogInMethod
        : EAnalyticsLoginMethod.LogOutMethod,
      user_id: user.id,
    };

    if (isAuth) {
      eventTrack('login', {login: basicParam});
      eventSetUserId(user.id);
    } else {
      eventTrack('logout', {login: basicParam});
    }
  };

  //Request Code by Phone
  const forgotPasswordByPhoneRequestCode = async (phoneNumber: string) => {
    dispatch(forgotPasswordByPhoneRequestCodeInit(phoneNumber));

    dispatch(forgotPasswordRequestCodeByPhoneService(phoneNumber));
  };

  //Request Code by email
  const forgotPasswordByEmailRequestCode = async (userEmail: string) => {
    dispatch(forgotPasswordByEmailRequestCodeInit(userEmail));
    dispatch(forgotPasswordRequestCodeByEmailService(userEmail));
  };

  const forgotPasswordRequestResetState = () => {
    dispatch(forgotPasswordCodeResetStateAction());
  };
  const forgotPasswordByPhoneSetCode = (code: string) => {
    dispatch(forgotPasswordByPhoneSetCodeAction(code));
  };

  const checkCodePhone = async (phoneNumber: string, code: string) => {
    dispatch(checkValidPhoneCodeRequest());
    dispatch(validPhoneCodeService(phoneNumber, code));
  };

  const checkCodeEmailCode = async (email: string, code: string) => {
    dispatch(checkValidPhoneCodeRequest());
    dispatch(validEmailCodeService(email, code));
  };
  return {
    isLoading: auth.isLoading as boolean,
    signIn,
    signOut,
    isAuthenticated: (auth.isAuthenticated as boolean) && auth.token.length > 0,
    error: auth.error as boolean,
    errorCode: auth.errorCode,
    token: auth.token,
    addContactus,
    forgotPasswordByPhoneRequestCode,
    forgotPasswordByEmailRequestCode,
    forgotPasswordRequestResetState,
    forgotPasswordByPhoneSetCode,
    checkCodePhone,
    checkCodeEmailCode,
    resetPasswordByPhone,
    resetPasswordByEmailCode,
    userPublicProfile
  };
};
