import React, {useCallback, useEffect, useRef, useState} from 'react';
import {EColors} from '../../../../models/enums/colors';
import {Loader} from '../../../../components/Loader';
import {RootState} from '../../../../redux';
import {useAuth} from '../../../../hooks/useAuth';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import LinkButton from '../../../../components/LinkButton';
import LoginArea from '../../../LoginPage/components/LoginArea';
import pages from '../../../../constants/pages';
import VerificationInput from 'react-verification-input';
import './styles.scss';

export const VerificationCode = () => {
  const {
    isLoading,
    forgotPasswordByPhoneSetCode,
    checkCodePhone,
    checkCodeEmailCode,
    forgotPasswordByPhoneRequestCode,
    forgotPasswordByEmailRequestCode,
  } = useAuth();
  const {
    phoneNumberRequest,
    emailRequest,
    loading,
    isValidCode,
    forgotPasswordErrors,
    smsSent,
    emailSent,
  } = useSelector((store: RootState) => store.user);

  const navigate = useNavigate();
  const {t} = useTranslation('login');
  const dispatch = useDispatch();
  const refReactCodeInput = useRef(null);

  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [resendValidCode, setresendValidCode] = useState<boolean>(false);

  const handleComplete = (value: string) => {
    setCode(value);
    if (phoneNumberRequest.length) {
      forgotPasswordByPhoneSetCode(value);
      checkCodePhone(phoneNumberRequest, value);
    } else if (emailRequest.length) {
      forgotPasswordByPhoneSetCode(value);
      checkCodeEmailCode(emailRequest, value);
    }
  };

  const handlePress = (value: string) => {
    if (value.length <= 3) {
      setError(false);
    }
    setCode(value);
  };

  const handleResendCode = useCallback(() => {
    if (phoneNumberRequest.length) {
      forgotPasswordByPhoneRequestCode(phoneNumberRequest);
    } else if (emailRequest.length) {
      forgotPasswordByEmailRequestCode(emailRequest);
    }
    setresendValidCode(!resendValidCode);
  }, [dispatch, phoneNumberRequest, emailRequest]);

  useEffect(() => {
    if (!loading && isValidCode && code.length === 4) {
      navigate(pages.newPassword);
    }
  }, [loading, isValidCode, code]);

  useEffect(() => {
    if (!loading && !isValidCode && forgotPasswordErrors.length) {
      setError(true);
    } else {
      setError(false);
    }
  }, [forgotPasswordErrors, isValidCode, loading]);

  useEffect(() => {
    if (!smsSent && !emailSent) {
      navigate(pages.root);
    }
    setError(false);
  }, []);

  useEffect(() => {
    if (code.length === 4) {
      handleComplete(code);
    }
  }, [code]);

  return (
    <div data-testid={'verification-code'} className="verification-code">
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <LoginArea isOpacity={error}>
          <span className="title">{t('verificationCode')}</span>
          <div className="form-container">
            <VerificationInput
              ref={refReactCodeInput}
              length={4}
              onChange={handlePress}
              autoFocus
              validChars={'[0-9]*'}
              placeholder={''}
              classNames={{
                container: 'container',
                character: error ? 'character-error' : 'character',
                characterInactive: 'character--inactive',
                characterSelected: 'character--selected',
              }}
            />
          </div>
          {!resendValidCode && (
            <LinkButton
              onClick={handleResendCode}
              customStyle={customStyleLinkButton}>
              <>
                <span>{t('didntReceiveTheCode')}&nbsp;</span>
                <strong>{t('resend')}</strong>
              </>
            </LinkButton>
          )}
        </LoginArea>
      </div>
    </div>
  );
};
const customStyleLinkButton = {
  color: EColors.colorBackLight,
  marginTop: '10px',
  marginBottom: '30px',
};
export default VerificationCode;
