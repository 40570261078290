import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux';
import pixelCalculator from '../../../../utils/pixelCalculator';
import FullPreview from './components/FullPreview';
import CardPreview from './components/CardPreview';
import './styles.scss';

type Props = {
  isPromotion: boolean;
};

const CreateDealPreview: React.FC<Props> = ({isPromotion}) => {
  const {t} = useTranslation('createDeal');
  const {user, deal} = useSelector((state: AppState) => state);
  const {innerHeight: height} = window;
  const width = pixelCalculator({axis: 'x', px: 944});

  return (
    <div style={{width: width}} className="create-deal-preview">
      <div className="create-deal-preview-row">
        <CardPreview deal={deal} name={user.name} />
        <FullPreview deal={deal} isPromotion={isPromotion} name={user.name} />
      </div>
      {height > 880 && (
        <span className="create-deal-preview-message">
          {t('previewDealInformationalMessage')}
        </span>
      )}
    </div>
  );
};

export default CreateDealPreview;
