export enum contactUsRequestType {
  INeedHelpAccount = 1,
  ReportNSFW18PlusUserContentMessage,
  ReportChildSexualExploitation,
  ReportViolationCodeConduct,
  ReportBug,
  ReportContentPolicyViolation,
  ReportSuicideSelfHarm,
  CopyrightInfrigementDMCA,
  CopyrightCounterNotice,
  Investigations,
  MobileHelp,
  PurchasesInquiries,
  ProblemsAdsDeals,
  PurchaseProblemTicketFairy,
  TrademarkRequest,
  InquiriesRelatedYourPrivacyRights,
  OtherHelp,
}
