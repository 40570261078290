import {AppState} from '../redux';
import {createAd, updateAd} from '../services/api/adsAPI';
import {EAnalyticsCustomEventNames} from '../models/enums/analytics';
import {EMenuViews, EVocabularyFields} from '../models/enums/generals';
import {ICreateAd, IUpdateAd} from '../models/api/ads';
import {IAddresses} from '../models/interfaces/IAddresses';
import {IAnalyticsParamsCustomEventAd} from '../models/interfaces/analytics';
import {IPackage} from '../models/api/packages';
import {isEqual, isFuture} from 'date-fns';
import {setClearAdData, setCreateadData} from '../redux/actions/adActions';
import {setListData, showPopup} from '../redux/actions/uiActions';
import {TypeListData} from '../models/enums/typeListData';
import {useAnalytics} from './useAnalytics';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {validateUrl} from '../utils/validateUrl';
import {vocavularyValidations} from '../constants/generals';
import moment from 'moment-timezone';
import pixelCalculator from '../utils/pixelCalculator';

type IProps = {
  isEditPage?: boolean;
};

export const useAds = ({isEditPage}: IProps) => {
  // Hooks
  const {ad} = useSelector((state: AppState) => state);
  const {eventTrack} = useAnalytics();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local State
  const [addressSelected, setAddressSelected] = useState<IAddresses | null>(
    null,
  );
  const [community, setCommunity] = useState<number | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const [defaultDate, setDefaultDate] = useState<Date | null>(null);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [image, setImage] = useState<any>('');
  const [isLoadingMotion, setIsLoadingMotion] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [shareLocation, setShareLocation] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [validatedFields, setValidatedFields] = useState(vocavularyValidations);

  // Data
  const width = pixelCalculator({axis: 'x', px: 496});

  // Effects
  useEffect(() => {
    if (ad.startDate) setDefaultDate(new Date(ad.startDate));
    if (ad.communityId) setCommunity(ad.communityId);
    if (ad.location) {
      setShareLocation(true);
      setAddressSelected({name: ad.location});
    }
    isValidForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ad]);

  useEffect(() => {
    setAddressSelected({name: addressSelected?.name || ''});
    if (!shareLocation) {
      dispatch(setCreateadData({location: ''}));
    } else {
      dispatch(setCreateadData({location: addressSelected?.name}));
    }
  }, [shareLocation]);

  useEffect(() => {
    if (isEditPage) {
      handleFieldIsValid(EVocabularyFields.Title, true);
      handleFieldIsValid(EVocabularyFields.Description, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPage]);

  // Handlers
  const handleChangeInterceptor = useCallback((value: string) => {
    dispatch(setCreateadData({buttonURL: value}));
    setTitle(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectChangeInterceptor = useCallback((selectValue: any) => {
    dispatch(setCreateadData({buttonLabel: selectValue.value}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTitleChange = useCallback((value: string) => {
    dispatch(setCreateadData({title: value}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateChange = useCallback((value: Date | null) => {
    dispatch(setCreateadData({startDate: value}));
    setDate(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDescriptionChange = useCallback((value: string) => {
    dispatch(setCreateadData({description: value}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHeadlineChange = useCallback((value: string) => {
    dispatch(setCreateadData({headline: value}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWeeksChange = useCallback((value: number) => {
    dispatch(setCreateadData({weeks: value}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCommunityChange = useCallback((communityId: number | null) => {
    dispatch(setCreateadData({communityId}));
    setCommunity(communityId);
  }, []);

  const onPackageChange = useCallback((value: number) => {
    dispatch(setCreateadData({packageId: value}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldIsValid = (
    fieldId: EVocabularyFields,
    fieldIsValid: boolean,
  ) => {
    const newValidatedFields = [...validatedFields].map(field => {
      if (field.fieldId === fieldId) {
        field.isValid = fieldIsValid;
      }
      return field;
    });
    setValidatedFields(newValidatedFields);
  };

  const handleEventTrack = (
    eventName: EAnalyticsCustomEventNames,
    currentPackageSelection: IPackage | null,
    isEdit?: boolean,
  ) => {
    const basicParam: IAnalyticsParamsCustomEventAd = {
      ad_community_id: ad.communityId,
      ad_name: ad.title,
      ad_package_id: currentPackageSelection?.id || null,
      ad_start_date: moment(date).tz('utc', true).format(),
    };

    if (isEdit) {
      eventTrack(eventName, {ad: {...basicParam, ad_id: ad.id}});
    } else {
      eventTrack(eventName, {ad: basicParam});
    }
  };

  const handleFailProcess = (error: any) => {
    setErrorCode(error.errorCode);
    setErrorMessage(error.message);
    setDisabledButton(false);
    setIsLoadingMotion(false);
    setIsLoadingSubmit(false);
    setIsOpen(false);
  };

  const isValidForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (isEditPage) {
      const isValid =
        ad.title !== '' &&
        ad.description !== '' &&
        ad.filePreview &&
        ad.buttonLabel !== '' &&
        ad.buttonURL !== '' &&
        validateUrl(ad.buttonURL) &&
        ad.packageId !== 0 &&
        validatedFields.length &&
        !validatedFields.find(f => f.isValid === false);

      setDisabledButton(!isValid);
    } else {
      const isValid =
        ad.title !== '' &&
        ad.description !== '' &&
        ad.files.length > 0 &&
        ad.buttonLabel !== '' &&
        ad.buttonURL !== '' &&
        validateUrl(ad.buttonURL) &&
        ad.packageId !== 0 &&
        ad.weeks > 0 &&
        validatedFields.length &&
        !validatedFields.find(f => f.isValid === false) &&
        date !== null &&
        (isEqual(date, today) || isFuture(date));

      setDisabledButton(!isValid);
    }
  };

  const setAd = async (currentPackageSelection: IPackage | null) => {
    setIsLoadingSubmit(true);
    try {
      const data: ICreateAd = {
        buttonLabel: ad.buttonLabel,
        buttonURL: ad.buttonURL,
        communityId: ad.communityId,
        description: ad.description,
        headline: ad.headline,
        images: ad.files,
        location: ad.location || '',
        packageId: currentPackageSelection?.id || 1,
        startDate: moment(date).tz('utc', true).format(),
        title: ad.title,
        Weeks: Number(ad.weeks),
      };

      const result = await createAd(data);

      if (result.data) {
        dispatch(setClearAdData());
        dispatch(showPopup(t('createAd:success')));
        dispatch(setListData(TypeListData.Ad));
        handleEventTrack(
          EAnalyticsCustomEventNames.CreateAd,
          currentPackageSelection,
        );
        navigate(EMenuViews.Home);
      }
      setIsLoadingSubmit(false);
    } catch (error: any) {
      console.log(error);
      handleFailProcess(error);
    }
  };

  const putAd = async (
    currentPackageSelection: IPackage | null,
    id: number,
  ) => {
    setIsLoadingSubmit(true);

    try {
      const data: IUpdateAd = {
        buttonLabel: ad.buttonLabel,
        buttonURL: ad.buttonURL,
        communityId: ad.communityId,
        description: ad.description,
        headline: ad.headline,
        images: image ? [image] : ad.files,
        location: ad.location || '',
        packageId: currentPackageSelection?.id || 1,
        title: ad.title,
      };

      const result = await updateAd(data, id);

      if (result.data) {
        dispatch(setClearAdData());
        dispatch(showPopup(t('ads:successEdit')));
        dispatch(setListData(TypeListData.Ad));
        handleEventTrack(
          EAnalyticsCustomEventNames.EditAd,
          currentPackageSelection,
          true,
        );
        navigate(EMenuViews.Home);
      }
      setIsLoadingSubmit(false);
    } catch (error: any) {
      console.log(error);
      handleFailProcess(error);
    }
  };

  const submit = async (
    currentPackageSelection: IPackage | null,
    id?: number | null,
  ) => {
    if (isLoadingSubmit || disabledButton) {
      return;
    }

    if (isEditPage && id) {
      await putAd(currentPackageSelection, id);
    } else {
      await setAd(currentPackageSelection);
    }
  };

  return {
    addressSelected,
    community,
    date,
    defaultDate,
    disabledButton,
    error,
    errorCode,
    errorMessage,
    handleChangeInterceptor,
    handleFieldIsValid,
    handleSelectChangeInterceptor,
    image,
    isLoadingMotion,
    isLoadingSubmit,
    modalIsOpen,
    onCommunityChange,
    onDateChange,
    onDescriptionChange,
    onHeadlineChange,
    onPackageChange,
    onTitleChange,
    onWeeksChange,
    setAddressSelected,
    setDisabledButton,
    setError,
    setErrorCode,
    setImage,
    setIsLoadingMotion,
    setIsOpen,
    setShareLocation,
    setTitle,
    shareLocation,
    submit,
    title,
    validatedFields,
    width,
  };
};
