import React, {useState} from 'react';
import HeaderItem from '../HeaderItem';
import {IResult} from '../../../../models/api/deals';
import {sortResults} from '../../../../utils/sortResults';
import {ITableItems} from '../../../../models/interfaces/table';
import {useTranslation} from 'react-i18next';
import {
  ETableFieldAlignment,
  ETableOrder,
  ETableTypeHeads,
} from '../../../../models/enums/tableDeals';
import './styles.scss';

type Props = {
  results?: IResult[] | null;
  setResults?: (value: IResult[]) => void;
  items: ITableItems[];
};

const HeaderTable: React.FC<Props> = ({results, setResults, items}) => {
  const {t} = useTranslation();
  const [currentFilter, setCurrentFilter] = useState<string>('');
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(false);

  const manageOrder = (nameItem: string) => {
    if (nameItem === currentFilter) {
      setAscendingOrder(!ascendingOrder);
    } else {
      setAscendingOrder(true);
      setCurrentFilter(nameItem);
    }

    if (ascendingOrder) {
      sortResults(currentFilter, ETableOrder.Upwards, setResults, results);
    } else {
      sortResults(currentFilter, ETableOrder.Downwards, setResults, results);
    }
  };

  return (
    <thead>
      <tr className="head">
        {items.map((item, index) => (
          <HeaderItem
            key={index}
            position={item?.alignment || ETableFieldAlignment.Left}
            label={t(item.label)}
            name={item.name}
            currentFilter={currentFilter}
            ascendingOrder={ascendingOrder}
            manageOrder={
              item.type === ETableTypeHeads.Button ||
              item.type === ETableTypeHeads.Location
                ? () => false
                : manageOrder
            }
            anyLabel={
              item.name === ETableTypeHeads.EditedText ||
              item.type === ETableTypeHeads.Button ||
              item.type === ETableTypeHeads.Location
            }
          />
        ))}
      </tr>
    </thead>
  );
};
export default HeaderTable;
