import React from 'react';
import {EBadgeColors} from '../../models/enums/ui';
import './styles.scss';

type Props = {
  color: EBadgeColors;
  icon: JSX.Element;
  text: string;
};

const BadgePopUp: React.FC<Props> = ({color, icon, text}) => {
  return (
    <div className={`badge-wrapper ${color}`}>
      {icon}
      <span className="badge-text">{text}</span>
    </div>
  );
};

export default BadgePopUp;
