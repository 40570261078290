import React from 'react';
import './styles.scss';

type Props = {
  children: JSX.Element;
  title: string;
};

const LateralPanelForm: React.FC<Props> = ({children, title}) => {
  return (
    <div className="lateral-panel-form-wrapper">
      <div className="form-wrapper">
        <span className="title">{title}</span>
        {children}
      </div>
    </div>
  );
};

export default LateralPanelForm;
