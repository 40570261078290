import React, {useEffect} from 'react';
import {setCreateadData} from '../../../../../../redux/actions/adActions';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import ButtonUploadImage from '../../../../../../components/ButtonUploadImage';
import FormField from '../../../../../../components/FormField/FormField';

type Props = {
  image: any;
  setImage: (value: any) => void;
};

const FieldImage: React.FC<Props> = ({image, setImage}: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      const imageURL = URL.createObjectURL(image);
      dispatch(
        setCreateadData({
          files: [image],
          filePreview: imageURL,
        }),
      );
    } else {
      dispatch(
        setCreateadData({
          files: [],
          filePreview: '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <FormField
      title={t('createDeal:fieldImageTitle')}
      description={t('createAd:fieldImageDescription')}>
      <ButtonUploadImage
        label={t('createDeal:buttonFiles')}
        onClick={setImage}
      />
    </FormField>
  );
};

export default FieldImage;
