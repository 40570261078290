import React from 'react';
import './styles.scss';
import {useTranslation} from 'react-i18next';
import {ReactComponent as Ticket} from '../../assets/icons/ticket.svg';
import {IDeal} from '../../models/interfaces/deals';

type Props = {
  deal: IDeal;
};
const PreviewPromoCode: React.FC<Props> = ({deal}) => {
  const {t} = useTranslation('previewDeal');
  return (
    <div className="preview-code-wrapper">
      <div className="preview-code-title-wrapper">
        <Ticket />
        <span className="preview-code-title">{t('useCode')}</span>
      </div>
      <span className="preview-code-code">{deal.promotionCode}</span>
    </div>
  );
};

export default PreviewPromoCode;
