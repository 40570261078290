import React, {useEffect, useState} from 'react';
import {EBusinessWeekDay} from '../../models/enums/businessHours';
import {EColors} from '../../models/enums/colors';
import {IBusinessHours} from '../../models/interfaces/generals';
import {ReactComponent as CopyIcon} from '../../assets/icons/copy.svg';
import {ReactComponent as PlusAddIcon} from '../../assets/icons/add-plus.svg';
import {setNewMomentDate} from '../../utils/businessHoursValidation';
import {uniqueId} from 'lodash';
import {useTranslation} from 'react-i18next';
import {
  isOpenTimeShorter,
  isValidTimeRange,
} from '../../utils/businessHoursValidation';
import BusinessHour from '../BuisinessHour';
import DayAction from './components/DayAction/index';
import Switch from 'react-switch';
import './styles.scss';

type Props = {
  businessHours: IBusinessHours[];
  day: string;
  keyItemDay: EBusinessWeekDay;
  setBusinessHours: (value: IBusinessHours[]) => void;
  setChangeCurrentDay: (day: EBusinessWeekDay) => void;
  setIsChangedHour?: (changed: boolean) => void;
};

const BusinessDay: React.FC<Props> = ({
  businessHours,
  day,
  keyItemDay,
  setBusinessHours,
  setChangeCurrentDay,
  setIsChangedHour,
}) => {
  // Hooks
  const {t} = useTranslation('register');

  // States
  const [checked, setChecked] = useState(false);
  const [isFirstLoader, setIsFirstLoader] = useState(true);
  const [showReplicateAction, setShowReplicateAction] = useState(true);

  // Effects
  useEffect(() => {
    if (isFirstLoader) {
      setIsFirstLoader(false);
      handleSetCheckedDays();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirstLoader) {
      if (!businessHours.length) {
        setChecked(false);
        setShowReplicateAction(true);
      } else if (!isFirstLoader && businessHours) {
        handleSetCheckedDays();
      }
    }
  }, [businessHours]);

  useEffect(() => {
    if (checked) {
      if (!isFirstLoader) {
        if (!businessHours.some(hour => hour.weekDay === keyItemDay)) {
          handleCreateTimerange(keyItemDay);
        }
      } else {
        handleDeleteDayTimeranges();
      }
    } else {
      if (
        businessHours.some(hour => hour.weekDay === keyItemDay) &&
        !isFirstLoader
      ) {
        setBusinessHours(
          businessHours.filter(hour => hour.weekDay !== keyItemDay),
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  // Handlers/Functions
  const handleCreateTimerange = (weekDay: number) => {
    const isPredefinedTimeRange = weekDay === 0 || weekDay === 6;

    let closeValue = isPredefinedTimeRange ? '' : new Date().setHours(17, 0);
    const openValue = isPredefinedTimeRange ? '' : new Date().setHours(9, 0);

    if (closeValue <= openValue) {
      closeValue = new Date(closeValue).setDate(
        new Date(closeValue).getDate() + 1,
      );
    }

    const newTimerange: IBusinessHours = {
      closeTime: setNewMomentDate(closeValue),
      id: 0,
      keyItem: uniqueId(),
      openTime: setNewMomentDate(openValue),
      weekDay,
    };
    setIsChangedHour && setIsChangedHour(true);
    setBusinessHours([...businessHours, newTimerange]);
  };

  const handleAddTimerange = () => {
    handleCreateTimerange(keyItemDay);
  };

  const handleReplicateTimeranges = (
    currentDay: number,
    openTime: string,
    closeTime: string,
  ) => {
    setShowReplicateAction(false);
    let replicateHoursObject: IBusinessHours[] = businessHours;
    for (
      let replicateTimerange = EBusinessWeekDay.Sunday;
      replicateTimerange <= EBusinessWeekDay.Saturday;
      replicateTimerange++
    ) {
      if (replicateTimerange !== currentDay) {
        const newTimerange: IBusinessHours = {
          closeTime,
          id: 0,
          keyItem: uniqueId() + replicateTimerange,
          openTime,
          weekDay: replicateTimerange,
        };
        replicateHoursObject = [...replicateHoursObject, newTimerange];
      }
    }
    setBusinessHours(replicateHoursObject);
  };

  const handleDeleteDayTimeranges = () => {
    const newBusinessHours: IBusinessHours[] = businessHours.filter(
      hour => hour.weekDay !== keyItemDay,
    );
    if (!newBusinessHours.some(hour => hour.weekDay === keyItemDay)) {
      setChecked(false);
    }
    setIsChangedHour && setIsChangedHour(true);
    setBusinessHours(newBusinessHours);
  };

  const handleSetCheckedDays = () => {
    businessHours.forEach((hour: IBusinessHours) => {
      if (hour.openTime && hour.closeTime && hour.weekDay === keyItemDay) {
        setChecked(true);
      }
    });
  };

  return (
    <div className="business-day-wrapper">
      <div className="business-day-container">
        <p className="business-day-label">{day}</p>

        <div className="business-day-switch">
          <Switch
            onChange={setChecked}
            checked={checked}
            onColor={EColors.colorGreenSuccess}
            offColor={EColors.colorGray}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={16}
            height={20}
            width={38.89}
          />
        </div>

        <p className={`business-day-opening ${checked && 'open'}`}>
          {checked ? t('businessHoursOpen') : t('businessHoursClosed')}
        </p>
      </div>

      {checked && businessHours.length && (
        <div className="business-hours-container">
          {businessHours.map((hour: IBusinessHours, idx: number) => {
            if (hour.weekDay === keyItemDay) {
              return (
                <BusinessHour
                  businessHours={businessHours}
                  defaultValueCloseTime={hour.closeTime}
                  defaultValueOpenTime={hour.openTime}
                  idRangetime={hour.id || 0}
                  key={idx}
                  keyItemDay={keyItemDay}
                  keyItemHour={hour.keyItem || ''}
                  setBusinessHours={setBusinessHours}
                  setChangeCurrentDay={setChangeCurrentDay}
                  setChecked={setChecked}
                  setIsChangedHour={setIsChangedHour}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {checked &&
        businessHours.length &&
        businessHours.map((hour: IBusinessHours) => {
          if (isValidTimeRange(hour.openTime, hour.closeTime)) {
            const filterWeekDayObject = businessHours.filter(
              hour => hour.weekDay === keyItemDay,
            );

            if (
              hour.weekDay === keyItemDay &&
              filterWeekDayObject.length === 1 &&
              isOpenTimeShorter(hour.openTime, hour.closeTime)
            ) {
              return (
                <div className="business-actions-container" key={uniqueId()}>
                  <DayAction
                    icon={<PlusAddIcon />}
                    key={uniqueId()}
                    onClick={handleAddTimerange}
                    text={t('hours:businessAddHours')}
                  />
                  {businessHours.length === 1 && showReplicateAction && (
                    <DayAction
                      icon={<CopyIcon />}
                      key={uniqueId()}
                      onClick={() =>
                        handleReplicateTimeranges(
                          hour.weekDay,
                          hour.openTime,
                          hour.closeTime,
                        )
                      }
                      text={t('hours:replicateHours')}
                    />
                  )}
                </div>
              );
            }
            return null;
          }
          return null;
        })}
    </div>
  );
};

export default BusinessDay;
