import React from 'react';
import './styles.scss';

type IProps = {
  icon: JSX.Element;
  isDisabled?: boolean;
  text: string;
  onClick: () => void;
};

const DayAction: React.FC<IProps> = ({
  icon,
  isDisabled = false,
  text,
  onClick,
}) => {
  return (
    <p
      className={`action-wrapper ${isDisabled && 'disabled'}`}
      onClick={onClick}>
      <div
        className={`icon-add-second-timerange ${isDisabled ? 'disabled' : ''}`}>
        {icon}
      </div>
      <span className="text-action-replicate">{text}</span>
    </p>
  );
};

export default DayAction;
