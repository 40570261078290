import React from 'react';
import Button from '../Button';
import {useTranslation} from 'react-i18next';
import {IDeal} from '../../models/interfaces/deals';
import useWindowDimensions from '../../hooks/useWindowsDimensions';

type Props = {
  deal: IDeal;
};
const PreviewWebsiteButton: React.FC<Props> = ({deal}) => {
  const {height} = useWindowDimensions();
  const {t} = useTranslation('previewDeal');
  return (
    <Button
      label={t('buttonWebsite')}
      onClick={() => console.log(deal.webSiteUrl)}
      customStylesWrapper={
        height < 719
          ? {
              height: 32,
              width: 190,
              marginBottom: 10,
            }
          : {
              height: 52,
              width: 254,
              marginBottom: 10,
            }
      }
      customStylesText={
        height < 719
          ? {fontSize: 13, lineHeight: 22}
          : {fontSize: 18, lineHeight: 24}
      }
    />
  );
};

export default PreviewWebsiteButton;
