import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.scss';
import {ReactComponent as TicketIcon} from '../../../../assets/icons/ticket.svg';
import {ReactComponent as MegaphoneIcon} from '../../../../assets/icons/megaphone.svg';
import ModalWrapper from '../../../../components/Modal';
import CardOption from '../../../../components/CardOption';
import {useNavigate} from 'react-router';

type Props = {
  setIsOpen: (value: boolean) => void;
  modalIsOpen: boolean;
};

const ModalOptionsCreate: React.FC<Props> = ({modalIsOpen, setIsOpen}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <ModalWrapper modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
      <div className="options-create-wrapper">
        <span className="options-create-title">{t('global:create')}</span>
        <span className="options-create-subtitle">
          {t('global:selectOption')}
        </span>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div onClick={() => navigate('/create-ad')}>
            <CardOption
              icon={
                <MegaphoneIcon
                  width={27}
                  height={30}
                  className="option-create-card-option-wrapper"
                />
              }
              title={t('ads:titleSingular')}
              description={t('ads:createDescription')}
            />
          </div>
          <div onClick={() => navigate('/create-deal')}>
            <CardOption
              icon={
                <TicketIcon
                  width={30}
                  height={21}
                  className="option-create-card-option-wrapper"
                />
              }
              title={t('dealsPage:titleSingular')}
              description={t('dealsPage:createDescription')}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalOptionsCreate;
