import React, {useState} from 'react';
import CharacterCounter from '../CharacterCounter';
import MsgError from '../MsgError';
import {ITextFieldCustomStyles, TextField} from '../TextField';
import {ITextAreaOptions} from '../TextField/InputTextArea';
import ContentValidation from './ContentValidation';
import './styles.scss';
interface IProps {
  customStyles?: ITextFieldCustomStyles;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  hideLabel?: boolean;
  id?: string;
  isValid?: boolean | null;
  label?: string;
  maxLength?: number;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  placeholder: string;
  setIsValid?: (value: boolean) => void;
  setValue: (value: string) => void;
  showCounterWord?: boolean;
  textAreaOptions?: ITextAreaOptions | undefined;
  type?: 'text' | 'email' | 'password' | 'textarea';
  value: string;
  withContentValidation: boolean;
  withCounter: boolean;
}

const CompleteTextField = ({
  customStyles,
  disabled = false,
  error = false,
  errorMessage = '',
  hideLabel = true,
  id,
  isValid = null,
  label = '',
  maxLength = 50,
  onBlur,
  onFocus,
  placeholder,
  setIsValid,
  setValue,
  showCounterWord = false,
  textAreaOptions,
  type = 'text',
  value,
  withContentValidation,
  withCounter = true,
}: IProps) => {
  const [isReadyToCheck, setIsReadyToCheck] = useState(false);

  return (
    <div className="complete-text-field" style={customStyles?.mainContainer}>
      <TextField
        id={id ? id : ''}
        type={type}
        value={value}
        handleChange={e => {
          setValue(e.target.value);
          setIsReadyToCheck(false);
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(e);
          }
          setIsReadyToCheck(true);
        }}
        onFocus={e => {
          if (onFocus) onFocus(e);
        }}
        maxLength={maxLength}
        placeHolder={placeholder}
        hideLabel={hideLabel}
        customStyles={customStyles}
        disabled={disabled}
        textAreaOptions={textAreaOptions}
        label={label}
      />
      <div className="field-bottom-section">
        <div style={{width: '70%'}}>
          {error && <MsgError text={errorMessage} />}
          {!error &&
            value &&
            value.length > 0 &&
            isReadyToCheck &&
            withContentValidation &&
            setIsValid !== undefined && (
              <ContentValidation
                isValid={isValid}
                setIsValid={setIsValid}
                content={value ? value : ''}
              />
            )}
        </div>
        {withCounter && (
          <CharacterCounter
            string={value ? value : ''}
            maxLength={maxLength}
            showCounterWord={showCounterWord}
          />
        )}
      </div>
    </div>
  );
};

export default CompleteTextField;
