export enum ErrorTypes {
  BusinessNameInUse = 'BusinessNameInUse',
  DoesntMatchPassword = 'DoesntMatchPassword',
  DoesntMatchZipcode = 'DoesntMatchZipcode',
  EmailAlreadyAssociated = 'EmailAlreadyAssociated',
  InvalidEmailFormat = 'InvalidEmailFormat',
  InvalidCredentials = 'InvalidCredentials',
  InvalidPhoneNumber = 'InvalidPhoneNumber',
  InvalidUrlFormat = 'InvalidUrlFormat',
  InvalidZipcodeFormat = 'InvalidZipcodeFormat',
  LocationDoesntExist = 'LocationDoesntExist',
  NoError = 'NoError',
  PasswordConditions = 'PasswordConditions',
  Required = 'Required',
  UsernameInUse = 'UsernameInUse',
  DoesntMatchEmail = 'DoesntMatchEmail',
}

export enum PasswordValidations {
  Characters = 'Characters',
  Uppercase = 'Uppercase',
  Number = 'Number',
  SpecialCharacter = 'SpecialCharacter',
}

export enum StoreType {
  Physical = 1,
  Online = 2,
  Unselected = 0,
}

export enum SocialMediaType {
  Facebook = 1,
  Instagram,
  Twitter,
}

export enum HourDays {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum RegusterStep {
  CREATE_ACCOUNT = 1,
}
