import React from 'react';
import {IResult} from '../../models/api/deals';
import {TypeListData} from '../../models/enums/typeListData';
import Items from './components/Items';
import HeaderTable from './components/HeaderTable';
import ItemSkeleton from './components/ItemSkeleton';
import GeneralItems from './components/GeneralItems';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles.scss';

type Props = {
  actionEditItem?: (value: any) => void;
  className?: string;
  data: IResult[];
  isLoading?: boolean;
  itemsDescription: {name: string; label: string; type: string}[];
  noResults?: JSX.Element;
  onEditItem?: (val: number) => void;
  onRemoveItem?: (val: number) => void;
  onSetDefaultItem?: (val: number) => void;
  setData?: (value: IResult[]) => void;
  setFirstLoader?: (value: boolean) => void;
  setReloadData?: (value: boolean) => void;
  typeAction?: TypeListData;
  userStatus?: boolean;
};

const Table: React.FC<Props> = ({
  actionEditItem,
  className = '',
  data,
  isLoading,
  itemsDescription,
  noResults,
  onEditItem,
  onRemoveItem,
  onSetDefaultItem,
  setData,
  setFirstLoader,
  setReloadData,
  typeAction,
  userStatus = false,
}) => {
  return (
    <div className={`table-fixed-head ${className}`}>
      <table className="table" data-testid="table">
        <HeaderTable
          results={typeAction === TypeListData.Location ? null : data}
          setResults={setData}
          items={itemsDescription}
        />
        {isLoading && (
          <>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </>
        )}
        <tbody>
          {(data.length || isLoading) &&
          typeAction !== TypeListData.Location ? (
            data.map((values: any, index: number) => (
              <Items
                key={index}
                value={values}
                itemsDescription={itemsDescription}
                actionEditItem={actionEditItem}
                typeAction={typeAction}
                setReloadData={setReloadData}
                setFirstLoader={setFirstLoader}
                userStatus={userStatus}
              />
            ))
          ) : typeAction === TypeListData.Location ? (
            data.map((values: any, index: number) => (
              <GeneralItems
                actionEditItem={actionEditItem}
                itemsDescription={itemsDescription}
                key={index}
                keyIdxItem={index}
                onEditItem={onEditItem}
                onRemoveItem={onRemoveItem}
                onSetDefaultItem={onSetDefaultItem}
                value={values}
              />
            ))
          ) : (
            <tr className="no-results-position">
              <td>{noResults}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
