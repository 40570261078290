import React, {useEffect, useRef, useState} from 'react';
import {t} from 'i18next';
import ModalConfirmation from '../../../ModalConfirmation';
import {deleteDeal} from '../../../../services/api/dealsAPI';
import {deleteAd} from '../../../../services/api/adsAPI';
import {useDispatch} from 'react-redux';
import {showPopup} from '../../../../redux/actions/uiActions';
import {TypeListData} from '../../../../models/enums/typeListData';
import {TypeAction} from '../../../../models/enums/typeAction';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {useLocation} from 'react-router';
import useBusiness from '../../../../hooks/useBusiness';

type Props = {
  actionEditItem?: (value: any) => void;
  menuOptions: any;
  setDropDown: (value: any) => void;
  setReloadData?: (value: any) => void;
  typeAction?: string;
  value: any;
};

const DropdownActions: React.FC<Props> = ({
  actionEditItem,
  menuOptions,
  setDropDown,
  setReloadData,
  typeAction,
  value,
}) => {
  // Hooks
  const elementUlActions = useRef(null);
  const dispatch = useDispatch();
  const {eventTrack} = useAnalytics();
  const {deleteBusinessUser} = useBusiness();

  // State
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const location = useLocation();

  const {pathname} = location;
  const params = pathname.split('/');

  const businessId = params[3];
  // Effects
  useEffect(() => {
    const useOutsideAlerter = (ref: any) => {
      const handleOutsideClick = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(false);
        }
      };
      document.addEventListener('click', handleOutsideClick);
    };
    useOutsideAlerter(elementUlActions);
  }, []);

  const callToAction = (actionType: string, actionId: number) => {
    if (actionType === TypeAction.Edit.toLowerCase() && actionEditItem) {
      actionEditItem(actionId);
    } else {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleDelete = async (actionId: number) => {
    if (typeAction === TypeListData.Deal) {
      const response = await deleteDeal(actionId);
      if (Object.keys(response).length === 0) {
        shootMessage(t('createDeal:successDelete'));
        eventTrack(EAnalyticsCustomEventNames.DeleteDeal, {
          deal: {deal_id: actionId},
        });
      }
    } else if (typeAction === TypeListData.BusinessVendor) {
      if (businessId) {
        deleteBusinessUser({
          BusinessId: parseInt(businessId),
          UserId: `${actionId}`,
        });
      }
    } else {
      const response = await deleteAd(actionId);
      if (Object.keys(response).length === 0) {
        shootMessage(t('createAd:successDelete'));
        eventTrack(EAnalyticsCustomEventNames.DeleteAd, {
          ad: {ad_id: actionId},
        });
      }
    }
  };

  const shootMessage = (meesage: string) => {
    dispatch(showPopup(meesage));
    if (setReloadData) {
      setReloadData(true);
    }
  };

  const titleModal =
    typeAction === TypeListData.Deal
      ? t('dealsPage:deleteDealTitle')
      : typeAction === TypeListData.BusinessVendor
      ? t('business:deleteUserTitle')
      : t('createAd:deleteAdTitle');

  const subtitleModal =
    typeAction === TypeListData.Deal
      ? t('dealsPage:deleteDealSubtitle')
      : typeAction === TypeListData.BusinessVendor
      ? t('business:deleteUserSubtitle')
      : t('createAd:deleteAdSubtitle');

  return (
    <>
      <ul className="ul-drop-down-action-list" ref={elementUlActions}>
        {menuOptions.map((elem: any) => {
          const typeAtion = elem.label;
          return (
            <li
              key={elem.value}
              className="li-drop-down-action-list"
              onClick={() => callToAction(typeAtion.toLowerCase(), value.id)}>
              {typeAtion}
            </li>
          );
        })}
      </ul>

      {isModalOpen && (
        <ModalConfirmation
          modalIsOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onConfirm={() => handleDelete(value.id)}
          title={titleModal}
          subtitle={subtitleModal}
          type="delete"
        />
      )}
    </>
  );
};

export default DropdownActions;
