import React from 'react';
import {ReactComponent as InstagramIcon} from '../../../../../../assets/icons/register/social-media-in.svg';
import '../../styles.scss';

type Props = {
  title?: string;
  children?: React.ReactNode;
  customStyles?: object;
};

const SocialMediaIn: React.FC<Props> = ({ children, title, customStyles }: Props) => {
  return (
    <div className='social-media-container-field' style={customStyles && customStyles}>
        {
          title && (
            <span className='social-media-title-field'>{title}</span>
          )
        }
        <div className='social-media-group-form'>
            <InstagramIcon className='social-media-icon'/>
            {children && children}
        </div>
    </div>
  )
}

export default SocialMediaIn;