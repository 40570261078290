import {IBusinessState} from '../../models/interfaces/business';
import {
  BusinessActionTypes,
  CLEAR_BUSINESS_STATE,
  REMOVE_BUSINESS_USER,
  SET_BUSSINESS_USERS,
  SET_LIST_BUSINESS_USERS,
} from '../types/businessTypes';
import {SET_BUSINESS_VENDOR_USER} from '../types/userTypes';

const initialState: IBusinessState = {
  page: 0,
  perPage: 10,
  businessVendor: [],
  usersBusiness: [],
};

const businessReducer = (
  state = initialState,
  action: BusinessActionTypes,
): IBusinessState => {
  switch (action.type) {
    case SET_LIST_BUSINESS_USERS:
      return {...state, ...action.payload};
    case SET_BUSINESS_VENDOR_USER:
      return {
        ...state,
        businessVendor: action.payload,
      };
    case SET_BUSSINESS_USERS:
      return {
        ...state,
        page: action.payload.page,
        usersBusiness: [...action.payload.usersBusiness],
      };
    case REMOVE_BUSINESS_USER:
      return {
        ...state,
        usersBusiness: state.usersBusiness.filter(
          userBusiness => userBusiness.vendorId !== action.payload,
        ),
      };
    case CLEAR_BUSINESS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default businessReducer;
