import React, {useEffect, useState} from 'react';
import Title from '../../pages/Register/components/BusinessImagesStep/components/Title';
import Subtitle from '../../pages/Register/components/BusinessImagesStep/components/Subtitle';
import ButtonUploadImage from '../ButtonUploadImage';
import DefaultCoverPicture from '../../assets/images/DefaultCoverPicture.png';
import {RootState} from '../../redux';
import {setProfileData} from '../../redux/actions/profileActions';
import {useTranslation} from 'react-i18next';
import {createImageFile} from '../../utils/createImageFile';
import {useDispatch, useSelector} from 'react-redux';
import './styles.scss';

const CoverPicture = () => {
  // Hooks
  const {t} = useTranslation('');
  const {data} = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  // States
  const [coverImagePreview, setCoverImagePreview] = useState<string>(
    data?.newCoverImage || DefaultCoverPicture,
  );
  const [coverPicture, setCoverPicture] = useState<any>();

  // Effects
  useEffect(() => {
    if (data.coverImage && data.coverImage.length) {
      setCoverImagePreview(data.coverImage);
      getImageFile(data.coverImage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.coverImage) {
      setCoverImagePreview(data.coverImage);
    } else {
      setCoverImagePreview(DefaultCoverPicture);
    }
  }, [data.coverImage]);

  useEffect(() => {
    if (coverPicture) {
      const imageUrl = URL.createObjectURL(coverPicture);
      setCoverImagePreview(imageUrl);

      dispatch(
        setProfileData({
          coverImage: imageUrl,
          newCoverImage: imageUrl,
        }),
      );
    } else {
      setCoverImagePreview(DefaultCoverPicture);
    }
  }, [coverPicture]);

  // Functions
  const getImageFile = async (image: any) => {
    try {
      const imageFile = await createImageFile(image, 'coverImage');
      setCoverPicture(imageFile);
    } catch (e) {
      console.log('error');
    }
  };

  return (
    <div className="banner-picture-wrapper">
      <Title text={t('profilePage:bannerImageTitle')} />
      <Subtitle text={t('register:profileImageSubtitle')} />
      <div className="business-images-files">
        <img
          className="business-images-cover-picture"
          src={coverImagePreview}
          alt="cover-photo"
        />
        <div className="business-images-upload-button">
          <ButtonUploadImage
            acceptVideo={false}
            hideImageName={true}
            id={'upload-cover-image'}
            label={t('profilePage:changeBannerPicture')}
            onClick={setCoverPicture}
          />
        </div>
      </div>
    </div>
  );
};

export default CoverPicture;
