import React, {useEffect, useState} from 'react';
import {AppState} from '../../../../redux';
import {EAnalyticsCustomEventNames} from '../../../../models/enums/analytics';
import {EBusinessWeekDay} from '../../../../models/enums/businessHours';
import {EMenuViews, ETabsViews} from '../../../../models/enums/generals';
import {EPopupType} from '../../../../models/enums/ui';
import {Form, Formik} from 'formik';
import {getDefaultTimeZone} from '../../../../utils/isValidTimeZone';
import {IBusinessHours} from '../../../../models/interfaces/generals';
import {IOptionsTimeZone} from '../../../../models/interfaces/register';
import {isTimeRangeRepeated} from '../../../../utils/businessHoursValidation';
import {itemsDays} from '../../../../constants/generals';
import {selectFieldStyles} from '../../../../constants/themes';
import {timeZones} from '../../../../constants/register';
import {uniqueId} from 'lodash';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  setHasErrors,
  setProfileData,
} from '../../../../redux/actions/profileActions';
import {
  showPopup,
  setTimeZoneNameState,
} from '../../../../redux/actions/uiActions';
import BusinessDay from '../../../../components/BusinessDay';
import LinkButton from '../../../../components/LinkButton/index';
import SelectField from '../../../../components/SelectField/index';
import './styles.scss';

export interface IBusinessHoursForm {
  formIsValid: boolean;
  timeZone: string;
}

const BusinessHour = () => {
  //Hooks
  const {eventTrack} = useAnalytics();
  const {profile} = useSelector((state: AppState) => state);
  const {t} = useTranslation('register');
  const dispatch = useDispatch();
  const stateProfile = profile;

  //Data
  const initialValues: IBusinessHoursForm = {
    formIsValid: true,
    timeZone: getDefaultTimeZone(stateProfile.data.timeZone),
  };
  const weekDays = itemsDays(t);

  //States
  const [businessHours, setBusinessHours] = useState<IBusinessHours[]>(
    stateProfile.data.businessHours,
  );
  const [currentDay, setCurrentDay] = useState<EBusinessWeekDay>(
    EBusinessWeekDay.Sunday,
  );
  const [isChangedHour, setIsChangedHour] = useState(false);
  const [showDeleteHours, setShowDeleteHours] = useState<boolean>(false);
  const [timeZoneName, setTimeZoneName] = useState(stateProfile.data.timeZone);
  const [timeZoneOptions, setTimeZoneOptions] = useState<IOptionsTimeZone[]>(
    [],
  );

  //Effects
  useEffect(() => {
    dispatch(setProfileData({showSaveBtns: true}));
    eventTrack(EAnalyticsCustomEventNames.PageView, {
      view: {
        current_tab_view: ETabsViews.BusinessHours,
        page_view: EMenuViews.Profile,
        sub_page_view: EMenuViews.MyBusiness,
      },
    });
    getTimeZoneOptions();
  }, []);

  useEffect(() => {
    if (businessHours.length) {
      setShowDeleteHours(true);
      if (isChangedHour) {
        isTimerangeCorrect(businessHours);
        dispatch(
          setProfileData({
            businessHours,
          }),
        );
      }
    } else {
      handleSetErrors(true);
    }
  }, [businessHours]);

  useEffect(() => {
    dispatch(setTimeZoneNameState(timeZoneName));
    dispatch(setProfileData({timeZone: timeZoneName}));
  }, [timeZoneName]);

  // Handlers - Functions
  const handleDeleteAllHours = () => {
    setBusinessHours([]);
    setShowDeleteHours(false);
  };

  const isTimerangeCorrect = (businessHours: IBusinessHours[]) => {
    const filterObjectsCurrentDay = businessHours.filter(
      (hour: IBusinessHours) => hour.weekDay === currentDay,
    );
    handleSetErrors(isTimeRangeRepeated(filterObjectsCurrentDay));
  };

  const handleSetErrors = (error: boolean) => {
    dispatch(
      setHasErrors({
        ...stateProfile.hasErrors,
        hours: error,
      }),
    );

    {
      error &&
        dispatch(
          showPopup(
            t('global:generalMessages:errorTimeRange'),
            3000,
            EPopupType.Error,
          ),
        );
    }
  };

  const getTimeZoneOptions = () => {
    const items: IOptionsTimeZone[] = [];
    for (const [, utc] of Object.entries(timeZones)) {
      items.push({
        value: utc.id,
        label: utc.name,
      });
    }
    setTimeZoneOptions(items);
  };

  return (
    <div className="business-hour-form">
      {showDeleteHours && (
        <LinkButton
          className="delete-all-hours-action"
          onClick={handleDeleteAllHours}>
          {t('hours:deleteAllHours')}
        </LinkButton>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          // TODO: We need this callback to avoid Formik errors
          console.log('Not Submit');
        }}>
        {({setFieldValue, values}) => {
          const {timeZone} = values;
          return (
            <Form>
              {weekDays.map((day, idx: EBusinessWeekDay) => (
                <div className="container-day" key={uniqueId()}>
                  <BusinessDay
                    key={uniqueId()}
                    day={day}
                    keyItemDay={idx}
                    businessHours={businessHours}
                    setBusinessHours={setBusinessHours}
                    setChangeCurrentDay={setCurrentDay}
                    setIsChangedHour={setIsChangedHour}
                  />
                </div>
              ))}

              <SelectField
                items={timeZoneOptions}
                onChange={value => {
                  setTimeZoneName(value.value);
                  setFieldValue('timeZone', value.value);
                }}
                placeHolder={t('businessHoursTimezonePlaceholder')}
                label={t('businessHoursTimezoneTitle')}
                isEdit
                customStylesComponent={selectFieldStyles.selectField}
                defaultOption={timeZone}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BusinessHour;
