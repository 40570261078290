import { IUserInformation } from '../../models/api/userInformation';

export const SET_USER = 'SET_AUTH_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const FORGOT_PASSWORD_PHONE_REQUEST_CODE = 'FORGOT_PASSWORD_PHONE_REQUEST_CODE';
export const FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS = 'FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS';
export const FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN = 'FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN';
export const FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS = 'FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS';
export const FORGOT_PASSWORD_REQUEST_CODE_FAIL = 'FORGOT_PASSWORD_REQUEST_CODE_FAIL';
export const FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE = 'FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE';
export const FORGOT_PASSWORD_SET_PHONE_CODE = 'FORGOT_PASSWORD_SET_PHONE_CODE';
export const FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_REQUEST = 'FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_REQUEST';
export const FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_SUCCESS = 'FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_SUCCESS';
export const FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_FAIL = 'FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_FAIL';
export const SET_BUSINESS_VENDOR_USER = 'SET_BUSINESS_VENDOR_USER';

interface ISetUserAction {
  type: typeof SET_USER;
  payload: IUserInformation;
}

interface IClearUserAction {
  type: typeof CLEAR_USER;
}
interface IForgotPasswordPhoneRequestCode {
  type: typeof FORGOT_PASSWORD_PHONE_REQUEST_CODE;
  payload: string
}
interface IForgotPasswordPhoneRequestCodeSuccess {
  type: typeof FORGOT_PASSWORD_PHONE_REQUEST_CODE_SUCCESS;
  payload: string
}
interface IForgotPasswordEmailRequestCode {
  type: typeof FORGOT_PASSWORD_EMAIL_REQUEST_TOKEN;
  payload: string
}
interface IForgotPasswordEmailRequestCodeSuccess {
  type: typeof FORGOT_PASSWORD_EMAIL_REQUEST_CODE_SUCCESS;
  payload: string
}

interface IForgotPasswordRequestCodeFail {
  type: typeof FORGOT_PASSWORD_REQUEST_CODE_FAIL;
  payload: string
}
interface IForgotPasswordRequestCodeResetState {
  type: typeof FORGOT_PASSWORD_REQUEST_CODE_RESET_STATE;
  payload: string
}
interface IForgotPasswordSetPhoneCode {
  type: typeof FORGOT_PASSWORD_SET_PHONE_CODE;
  payload: string
}
interface IForgotPasswordCheckPhoneCodeValidRequest {
  type: typeof FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_REQUEST;
  payload: string
}
interface IForgotPasswordCheckPhoneCodeValidSuccess {
  type: typeof FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_SUCCESS;
  payload: string
}
interface IForgotPasswordCheckPhoneCodeInvalidFail {
  type: typeof FORGOT_PASSWORD_CHECK_PHONE_CODE_VALID_FAIL;
  payload: string
}

export type UserActionTypes =
  ISetUserAction |
  IClearUserAction |
  IForgotPasswordPhoneRequestCode |
  IForgotPasswordEmailRequestCode |
  IForgotPasswordPhoneRequestCodeSuccess |
  IForgotPasswordEmailRequestCodeSuccess |
  IForgotPasswordRequestCodeFail |
  IForgotPasswordRequestCodeResetState |
  IForgotPasswordSetPhoneCode |
  IForgotPasswordCheckPhoneCodeValidRequest |
  IForgotPasswordCheckPhoneCodeValidSuccess |
  IForgotPasswordCheckPhoneCodeInvalidFail
