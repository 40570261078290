import endpoints from './endpoints';
import {AxiosResponse} from 'axios';
import {genericPost} from './genericAPI';
import {store} from '../../redux';
import {
  IAddUserToBusinessRequest,
  IDeleteUserFromBusinessRequest,
  IGetBusinessUsersRequest,
} from '../../models/interfaces/business';

const getBusinessUsers = (
  data: IGetBusinessUsersRequest,
): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.business.listBusinessUsers, token, data, {});
};
const createBusinessUser = (
  data: IAddUserToBusinessRequest,
): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.business.addUserToBusiness, token, data, {});
};
const deleteBusinessUser = (
  data: IDeleteUserFromBusinessRequest,
): Promise<AxiosResponse> => {
  const token = store.getState().auth.token;
  return genericPost(
    endpoints.business.deleteUserFromBusiness,
    token,
    data,
    {},
  );
};

export const businessApi = {
  getBusinessUsers,
  createBusinessUser,
  deleteBusinessUser,
};
