import React, {useEffect, useState} from 'react';
import {AppState} from '../../redux';
import {EPanelFormProfile, EProfileTypeTabs} from '../../models/enums/profile';
import {ETypeView} from '../../models/enums/typeListData';
import {getCommunities} from '../../redux/actions/profileActions';
import {IPanelFormProfile} from '../../models/interfaces/profile';
import {setCurrentView} from '../../redux/actions/uiActions';
import {TypeActionModal} from '../../models/enums/typeAction';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import ButtonsActions from './components/ButtonsActions';
import ChangeEmailForm from './components/MyInformation/components/ChangeEmailForm';
import ChangePasswordForm from './components/MyInformation/components/ChangePasswordForm';
import HeaderPage from '../../components/HeaderPage';
import HeaderProfile from './components/HeaderProfile';
import InfoPage from '../../components/InfoPage';
import LateralPanelForm from '../../components/LateralPanelForm';
import LocationForm from './components/BusinessLocation/components/LocationForm';
import ModalConfirmation from '../../components/ModalConfirmation';
import ProfileEditTabList from '../../components/ProfileEditTabList';
import './styles.scss';

export const ProfileEdition = () => {
  // Hooks
  const {t} = useTranslation();
  const {ui, profile} = useSelector((state: AppState) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Data
  const {pathname} = location;
  const params = pathname.split('/');
  const myBusinessPage = params[2];
  const userId = Number(params[3]);
  const isProfileEditionView = myBusinessPage && !isNaN(userId);

  // States
  const [openUnsavedModal, setOpenUnsavedModal] = useState<boolean>(false);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [showPanelForm, setShowPanelForm] = useState<IPanelFormProfile>({
    open: false,
    type: EPanelFormProfile.None,
  });

  // Effects
  useEffect(() => {
    dispatch(setCurrentView(ETypeView.Profile));
    dispatch(getCommunities());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowButtons(profile.data.showSaveBtns);
  }, [profile.data.showSaveBtns]);

  // Handlers
  const goBack = () => {
    if (isProfileEditionView) {
      navigate('/profile-edition');
    } else {
      navigate('/home');
    }
  };

  return (
    <div className="profile-edition-page">
      <div
        className={`create-deal-page-header-wrapper ${
          ui.currentView === ETypeView.Profile && 'profile-page-header-wrapper'
        }`}>
        <InfoPage
          title={t('profilePage:infoTitle')}
          item={t('profilePage:infoAction')}
          item2={
            (!isNaN(userId) && myBusinessPage && profile.data.businessName) ||
            ''
          }
        />

        <HeaderPage
          onClickBack={ui.hideUnsavedModal ? goBack : setOpenUnsavedModal}
          title={
            !isNaN(userId) && myBusinessPage
              ? profile.data.businessName
              : t('profilePage:infoActionText')
          }
        />
      </div>

      <div className="profile-edition-page-content-wrapper">
        <div className="profile-edition-page-content">
          <HeaderProfile />
          <ProfileEditTabList setShowPanelForm={setShowPanelForm} />

          {showButtons && !isProfileEditionView && (
            <ButtonsActions
              onClickBack={
                ui.hideUnsavedModal
                  ? () => navigate('/home')
                  : setOpenUnsavedModal
              }
              typeTab={EProfileTypeTabs.MyInformation}
            />
          )}

          {showButtons && isProfileEditionView && !profile.isPendingAuth && (
            <ButtonsActions
              onClickBack={
                ui.hideUnsavedModal
                  ? () => navigate('/home')
                  : setOpenUnsavedModal
              }
              typeTab={EProfileTypeTabs.MyBusinesses}
            />
          )}
        </div>
      </div>

      {openUnsavedModal && (
        <ModalConfirmation
          modalIsOpen={openUnsavedModal}
          setIsOpen={setOpenUnsavedModal}
          onConfirm={goBack}
          title={t('createDeal:unsavedChanges')}
          subtitle={t('createDeal:unsavedChangesMsg')}
          type="alert"
          actionType={TypeActionModal.Unsaved}
        />
      )}

      {showPanelForm.open &&
        showPanelForm.type === EPanelFormProfile.Location && (
          <LateralPanelForm
            title={
              typeof profile.selectedAddress === 'number'
                ? t('profilePage:editLocationTitle')
                : t('profilePage:createNewLocationTitle')
            }>
            <LocationForm setShowPanelForm={setShowPanelForm} />
          </LateralPanelForm>
        )}

      {showPanelForm.open &&
        showPanelForm.type === EPanelFormProfile.Password && (
          <LateralPanelForm title={t('profilePage:changePasswordText')}>
            <ChangePasswordForm setShowPanelForm={setShowPanelForm} />
          </LateralPanelForm>
        )}

      {showPanelForm.open && showPanelForm.type === EPanelFormProfile.Email && (
        <LateralPanelForm title={t('profilePage:changeEmailText')}>
          <ChangeEmailForm setShowPanelForm={setShowPanelForm} />
        </LateralPanelForm>
      )}
    </div>
  );
};
