import endpoints from './endpoints';
import { genericGet } from './genericAPI';
import { AxiosResponse } from 'axios';

export const communities = async (): Promise<AxiosResponse> => {
  return await genericGet(endpoints.communities.list, '');
};

export const uiApi = {
  communities,
};