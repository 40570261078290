import React from 'react';
import {ITextAreaOptions} from '../../../../../../components/TextField/InputTextArea';
import {ITextFieldCustomStyles} from '../../../../../../components/TextField';
import {useTranslation} from 'react-i18next';
import CompleteTextField from '../../../../../../components/CompleteTextField';
import FormField from '../../../../../../components/FormField/FormField';

type Props = {
  styles: ITextFieldCustomStyles;
  value: string;
  setValue: (newValue: string) => void;
  setIsValid?: (value: boolean) => void /* Vocavulary control */;
  isValid?: boolean | null;
  textAreaOptions: ITextAreaOptions | undefined;
};

const FieldDescription: React.FC<Props> = ({
  styles,
  value,
  setValue,
  setIsValid,
  textAreaOptions,
  isValid = null,
}: Props) => {
  const {t} = useTranslation();

  return (
    <FormField
      title={t('createAd:fieldDescriptionTitle')}
      description={t('createAd:fieldDescriptionDescription')}
      underlinedLinkLabel={t('global:helpfulTips')}
      underlinedLinkOnClick={() => console.log('open helpful tips')}>
      <CompleteTextField
        customStyles={styles}
        hideLabel={true}
        id={'description'}
        isValid={isValid}
        maxLength={180}
        placeholder={t('createAd:fieldDescriptionPlaceholder')}
        setIsValid={setIsValid}
        setValue={setValue}
        textAreaOptions={textAreaOptions}
        type="textarea"
        value={value}
        withContentValidation={true}
        withCounter={true}
      />
    </FormField>
  );
};

export default FieldDescription;
