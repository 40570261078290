import React from 'react';
import {IPackage} from '../../../models/api/packages';
import {PackageType} from '../../../models/enums/typeAction';
import {useTranslation} from 'react-i18next';
import './styles.scss';

interface IProps {
  data: IPackage;
  selected: boolean;
  packageType: PackageType;
  onSelect: () => void;
}
const Package = ({data, selected, onSelect, packageType}: IProps) => {
  // hooks
  const {t} = useTranslation();

  // CONSTANTS
  const {name, durationWeeks, impressions, price} = data;

  // Duration Weeks
  let durationString = '';
  if (durationWeeks === 1) {
    durationString = t('shared:fixedDurationWeek', {duration: durationWeeks});
  } else {
    durationString = t('shared:fixedDurationWeeks', {duration: durationWeeks});
  }

  // Impressions
  let impressionsStringPart1 = '';
  if (packageType === PackageType.Deal) {
    impressionsStringPart1 = t('dealsPage:packageImpressions');
  } else if (packageType === PackageType.Ad) {
    impressionsStringPart1 = t('ads:packageImpressions');
  }

  let impressionsStringPart2 = `${impressions} ${t('shared:moreThanTwice')}`;
  if (impressions === 1) {
    impressionsStringPart2 = `${t('shared:once')}`;
  } else if (impressions === 2) {
    impressionsStringPart2 = `${t('shared:twice')}`;
  }

  // Price
  const priceSplited = price.toString().split('.');
  const decimals = priceSplited[1];

  return (
    <div
      className={`package-component ${selected && 'selected'}`}
      onClick={onSelect}>
      <div className="left">
        <span className="p-name">{name}</span>
        <>
          <span className="p-description">{durationString}</span>
          {packageType !== PackageType.Deal && (
            <span className="p-description">
              {`${impressionsStringPart1} ${impressionsStringPart2}`}
            </span>
          )}
        </>
      </div>

      <div className="right">
        <span className={`price ${selected && 'price-selected'}`}>
          {price === 0
            ? t('shared:free')
            : `$${decimals ? priceSplited[0] : price}`}
        </span>
        {decimals && (
          <span className={`decimals ${selected && 'price-selected'}`}>
            {decimals}
          </span>
        )}
      </div>
    </div>
  );
};

export default Package;
