import React from 'react';
import SmallLogo from '../../assets/images/SmallLogo.png';

interface IProps {
  width?: number;
  height?: number;
}

const AppSmallLogo = ({width = 177, height = 71}: IProps) => {
  return (
    <div data-testid={'app-logo'}>
      <img src={SmallLogo} width={width} height={height} alt="logo" />
    </div>
  );
};

export default AppSmallLogo;
