import {EVocabularyFields} from '../models/enums/generals';
import {IVocavularyValidation} from '../models/interfaces/generals';
import {PackageType} from '../models/enums/typeAction';
import {t} from 'i18next';

export const itemsDays = (t: (key: string) => string) => {
  return [
    t('businessHoursSunday'),
    t('businessHoursMonday'),
    t('businessHoursTuesday'),
    t('businessHoursWednesday'),
    t('businessHoursThursday'),
    t('businessHoursFriday'),
    t('businessHoursSaturday'),
  ];
};

export const adCallToActionsLabel = (t: (key: string) => string) => {
  return [
    {value: t('labelApplyNow'), label: t('labelApplyNow')},
    {value: t('labelBookNow'), label: t('labelBookNow')},
    {value: t('labelBuyNow'), label: t('labelBuyNow')},
    {value: t('labelContactNow'), label: t('labelContactNow')},
    {value: t('labelDonateNow'), label: t('labelDonateNow')},
    {value: t('labelDownload'), label: t('labelDownload')},
  ];
};

export const vocavularyValidations: IVocavularyValidation[] = [
  {
    fieldId: EVocabularyFields.Title,
    isValid: null,
  },
  {
    fieldId: EVocabularyFields.Description,
    isValid: null,
  },
];

export const freePackageObject = (packageType: PackageType) => {
  return {
    durationWeeks: 1,
    impressions: 1,
    name: t('global:free'),
    packageType,
    price: 0,
  };
};
