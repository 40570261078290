// REGISTER DATA
export const CLEAR_REGISTER_DATA = 'CLEAR_REGISTER_DATA';
export const SET_REGISTER_DATA = 'SET_REGISTER_DATA';
export const SET_REGISTER_DATA_COORDENATES = 'SET_REGISTER_DATA_COORDENATES';
export const SET_REGISTER_DATA_SUGGESTIONS = 'SET_REGISTER_DATA_SUGGESTIONS';
export const SUCCESS_REGISTER_DATA = 'SUCCESS_REGISTER_DATA';

// GENERAL
export const GET_FAIL = 'GET_FAIL';
export const GET_REQUEST = 'GET_REQUEST';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
export const SET_STEP = 'SET_STEP';

// BUSINESS IDENTIFICATION
export const GET_BUSINESS_IDENTIFICATION_SUCCESS = 'GET_BUSINESS_IDENTIFICATION_SUCCESS';

// COUNTRIES
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';

// COMMUNITIES
export const GET_COMMUNITIES_SUCCESS = 'GET_COMMUNITIES_SUCCESS';

// SEND DATA
export const SEND_REGISTER_DATA = 'SEND_REGISTER_DATA';

interface ISetRegisterData {
  type: typeof SET_REGISTER_DATA;
  payload: object;
}

interface IClearRegisterData {
  type: typeof CLEAR_REGISTER_DATA;
}

interface ISetRegisterDataCoordenates {
  type: typeof SET_REGISTER_DATA_COORDENATES;
  payload: object;
}

interface ISetRegisterDataSuggestions {
  type: typeof SET_REGISTER_DATA_SUGGESTIONS;
  payload: object;
}

interface ISendRegisterData {
  type: typeof SEND_REGISTER_DATA;
  payload: object;
}

export type RegisterActionTypes = 
  ISetRegisterData | 
  IClearRegisterData | 
  ISendRegisterData | 
  ISetRegisterDataCoordenates | 
  ISetRegisterDataSuggestions;
