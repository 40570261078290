import React from 'react';
import RadioButton from '../../../../../../components/RadioButton';
import { StoreType } from '../../../../../../models/enums/registerForm';
import { useTranslation } from 'react-i18next';
import './styles.scss'

interface IProps {
  setSelected: (selection: StoreType) => void;
  currentSelection: StoreType | undefined;
}

const OptionsStoreType = ({setSelected, currentSelection}: IProps) => {

  const {t} = useTranslation('register');

  return (
    <div className="business-store-type-step-options">
      <RadioButton
        selected={currentSelection === StoreType.Physical}
        onClick={() => setSelected(StoreType.Physical)}
        label={<span>{ t('optionsStoreTypePhysicalOption') }</span>}
        customStyle={{marginBottom: 34}}
      />
      <RadioButton
        selected={currentSelection === StoreType.Online}
        onClick={() => setSelected(StoreType.Online)}
        label={<span>{ t('optionsStoreTypeOnlineOption')} </span>}
        customStyle={{marginBottom: 34}}
      />
    </div>
  );
};

export default OptionsStoreType;