import React from 'react';
import {EMenuToggle} from '../../../../../../models/enums/menu';
import {useNavigate} from 'react-router';
import {EColors} from '../../../../../../models/enums/colors';
import {menuItems} from '../../../../../../constants/menu';
import {useTranslation} from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

type Props = {
  menuIsOpen: 'open' | 'closed';
};

const Content: React.FC<Props> = ({menuIsOpen}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="content">
      {menuItems.map(
        (item, index) =>
          item.showItem && (
            <div
              key={index}
              data-tip={t(item.label)}
              className={`left-menu-item${
                item.url === location.pathname ? ' active-item' : ''
              }`}
              onClick={() => {
                if (item.url === location.pathname) {
                  navigate(0);
                } else {
                  navigate(item.url);
                }
              }}>
              <div className="img-container">
                <img
                  className="left-menu-item-icon"
                  src={item.icon}
                  alt="left-menu-icon"
                />
              </div>
              {menuIsOpen === EMenuToggle.Open && (
                <span className="left-menu-item-text">{t(item.label)}</span>
              )}
            </div>
          ),
      )}
      {menuIsOpen !== 'open' && (
        <ReactTooltip
          place="right"
          effect="solid"
          className="left-menu-tooltip"
          arrowColor={EColors.colorBluePrimaryButtons}
        />
      )}

      <ReactTooltip
        id={t('menu:profileEdition')}
        place="right"
        effect="solid"
        className="left-menu-tooltip"
        arrowColor={EColors.colorBluePrimaryButtons}
      />
    </div>
  );
};

export default Content;
