export enum TableStatus {
  'Active',
  'Scheduled',
  'Expired',
  'Pending',
  'Deleted',
  'Change Requested',
  'Declined',
}

export enum TableItemStatus {
  Active = 0,
  Scheduled,
  Expired,
  Pending,
  Deleted,
  UpdateRequested,
  Rejected,
}

export enum ETableTypeHeads {
  Button = 'button',
  Date = 'date',
  EditedText = 'editedText',
  Info = 'info',
  Location = 'location',
  Status = 'status',
  Type = 'type',
}

export enum ETableLocationsHeads {
  Nickname = 'nickname',
  FullAddress = 'full-address',
  MainLocation = 'main-location',
  LocationActions = 'location-actions',
}
export enum ETableLUsersHeads {
  Name = 'name',
  Role = 'role',
  Email = 'email',
  Status = 'status',
}

export enum ETableFieldAlignment {
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
}

export enum ETableOrder {
  Downwards = 'downwards',
  Upwards = 'upwards',
}
