import React from 'react';
import Button from '../../../Button';
import {ITableItems} from '../../../../models/interfaces/table';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckIcon} from '../../../../assets/icons/check-blue.svg';
import {ReactComponent as TrashIcon} from '../../../../assets/icons/trash-alt.svg';
import './styles.scss';

type Props = {
  actionEditItem?: (value: any) => void;
  itemsDescription: ITableItems[];
  keyIdxItem?: number;
  onEditItem?: (val: number) => void;
  onRemoveItem?: (val: number) => void;
  onSetDefaultItem?: (val: number) => void;
  value: any;
};
const GeneralItems: React.FC<Props> = ({
  itemsDescription,
  keyIdxItem,
  onEditItem,
  onRemoveItem,
  onSetDefaultItem,
  value,
}) => {
  const {t} = useTranslation();

  return (
    <tr className="body">
      {itemsDescription.map((item, index) => (
        <td key={index} className={`body-item`}>
          {item.type === 'location' &&
            item.name === 'nickname' &&
            value.nickName}

          {item.type === 'location' &&
            item.name === 'full-address' &&
            `${value.location.cityName}, ${value.location.state} ${value.location.country}, ${value.location.zipCode} ${value.location.streetAddress}`}

          {item.type === 'button' && item.name === 'main-location' && (
            <div className="main-location">
              {value.primary ? (
                <>
                  <CheckIcon className="selected-location-icon" />
                  <span>{t('profilePage:mainLocationText')}</span>
                </>
              ) : (
                <Button
                  label={t('profilePage:selectAsMainText')}
                  onClick={() =>
                    onSetDefaultItem &&
                    typeof keyIdxItem === 'number' &&
                    onSetDefaultItem(keyIdxItem)
                  }
                />
              )}
            </div>
          )}

          {item.type === 'button' && item.name === 'location-actions' && (
            <div className="location-actions-wrapper">
              <Button
                label="Edit address"
                onClick={() =>
                  onEditItem &&
                  typeof keyIdxItem === 'number' &&
                  onEditItem(keyIdxItem)
                }
              />
              <TrashIcon
                className="trash-location-icon"
                onClick={() =>
                  onRemoveItem &&
                  typeof keyIdxItem === 'number' &&
                  onRemoveItem(keyIdxItem)
                }
              />
            </div>
          )}
        </td>
      ))}
    </tr>
  );
};

export default GeneralItems;
